/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum ParameterValueType {
    Text = 0,
    Numeric = 1,
    Enum = 2,
    DateTime = 3,
    TimeSpan = 4,
}
