import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICountryTranslationDto } from 'app/common/dto/common-dto';
import { ICountryDto } from 'app/models/country';
import { EnvironmentType } from 'app/models/enums/environment-type';
import { IEshopDto } from 'app/models/eshop/eshop-dto';
import { forkJoin, Observable, ReplaySubject } from 'rxjs';
import { User } from '../dto/user-dto';
import { Country, Languages } from '../enums';
import { CommonDataService } from './common-data.service';

@Injectable()
export class SessionService {
    public isInitialized = false;
    /**
     * If true hides navbar and topbar. Used for login pages
     */
    public fullView = false;
    private _user: User;
    private _eshops: Array<IEshopDto>;
    private _languages: Array<ICountryTranslationDto>;
    private _countries: Array<ICountryDto>;

    public get user() {
        return this._user;
    }
    public get eshops() {
        return this._eshops;
    }
    public get languages() {
        return this._languages;
    }
    public get countries() {
        return this._countries;
    }
    public user$: Observable<User>;
    private _userSub = new ReplaySubject<User>(1);

    constructor(private readonly http: HttpClient, private commonData: CommonDataService) {
        this.user$ = this._userSub.asObservable();
    }

    public initializeUser() {
        return new Promise<void>((resolve, reject) => {
            forkJoin([this.http.get<any>('api/session/user'), this.commonData.eshop.getEshops()]).subscribe(
                ([user, eshops]) => {
                    this._user = new User();
                    this._eshops = eshops;

                    Object.assign(this.user, user);
                    this._userSub.next(this._user);

                    this.isInitialized = true;
                    resolve();
                },
                (err) => {
                    this.isInitialized = false;
                    reject(err);
                }
            );
        });
    }

    public initialize() {
        return new Promise<void>((resolve, reject) => {
            forkJoin([this.commonData.language.getAllLanguages(), this.commonData.country.getAllCountries()]).subscribe(
                ([languages, countries]) => {
                    this._languages = languages;
                    this._countries = countries;
                    resolve();
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

    public getAlzaUrl(
        platform: 'web' | 'mobile' = 'web',
        environment: EnvironmentType = this.user.environment,
        country: Country = Country.CzechRepublic,
        language: Languages = null
    ): string {
        const eshopUrl = this.getEshopUrl(environment, country, language);
        if (platform === 'web') {
            return `https://${eshopUrl}`;
        }
        if (eshopUrl.startsWith('www')) {
            return `https://${eshopUrl.replace('www', 'm')}`;
        }
        return `https://m${eshopUrl}`;
    }

    public getEshopUrl(environment: EnvironmentType, country: Country, language: Languages = null): string {
        return country !== null ? this.getEshopByCountry(environment, country)?.url : this.getEshopByLangulage(environment, language)?.url;
    }

    private getEshopByCountry(environment: EnvironmentType, country: Country): IEshopDto {
        const countryEshops = this.eshops.filter((x) => x.countryId === country);
        return this.getEshopEnviroment(countryEshops, environment);
    }

    private getEshopByLangulage(environment: EnvironmentType, language: Languages): IEshopDto {
        const eshops = this.eshops.filter((x) => x.languageId === language);
        return this.getEshopEnviroment(eshops, environment);
    }

    private getEshopEnviroment(eshops: Array<IEshopDto>, environment: EnvironmentType) {
        if (environment === EnvironmentType.Beta) {
            return eshops.find((x) => !x.visible);
        } else {
            return eshops.find((x) => x.visible);
        }
    }
}
