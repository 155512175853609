import { Component, Input, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-on-off-switch',
    templateUrl: './on-off-switch.component.html',
    styleUrls: ['./on-off-switch.component.scss']
})
export class OnOffSwitchComponent {
    @Input() value = false;
    @Input() tooltip: string;
    @Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    constructor(private readonly translateService: TranslateService) {
        if (!this.tooltip) {
            this.translateService.get('OnOffSwitch_DefaultTooltip').subscribe((defaultTooltip) => {
                this.tooltip = defaultTooltip;
            });
        }
    }
    public toggle() {
        this.value = !this.value;
        this.valueChange.emit(this.value);
    }
}
