<div class="row form-group">
    <div class="col-xs-12">
        <cms-label
            *ngIf="labelText"
            [required]="labelRequired"
            [for]="id"
            [text]="labelText"
            [tooltip]="labelTooltip"
            [tooltipDirection]="labelTooltipDirection"
            [tooltipIcon]="labelTooltipIcon"
            [class]="labelClass"
        ></cms-label>
        <textarea
            [froalaModel]="value"
            (froalaModelChange)="onValueChange($event)"
            [attr.id]="id"
            class="form-control"
            autocomplete="off"
            [froalaEditor]="options"
            (froalaInit)="initialize($event)"
            *ngIf="!disabled && !showCode"
        ></textarea>
        <app-monaco-editor
            [ngModel]="value"
            (ngModelChange)="onValueChange($event)"
            [id]="id"
            *ngIf="showCode"
            (hideEditor)="switchEditor()"
            [displayToolbar]="true"
            [editSentences]="true"
            [standalone]="false"
        ></app-monaco-editor>
        <iframe src="about:blank" [attr.id]="'iframe' + id" frameborder="0" style="width: 100%" *ngIf="disabled"> </iframe>
        <cms-field-errors [submitted]="submitted" [control]="control"></cms-field-errors>
        <div class="row mt-2">
            <div class="col-xs-12">
                <div class="expl sentence-checked display-inline-block mr-2"></div>
                {{ 'FroalaSentences_CheckedSentence' | translate }}
            </div>
            <div class="col-xs-12">
                <div class="expl sentence-notchecked display-inline-block mr-2"></div>
                {{ 'FroalaSentences_NotCheckedSentence' | translate }}
            </div>
            <div class="col-xs-12">
                <div class="expl sentence-noteditable display-inline-block mr-2"></div>
                {{ 'FroalaSentences_NotEditableSentence' | translate }}
            </div>
            <div class="col-xs-12">
                <div class="expl sentence-tocheck display-inline-block mr-2"></div>
                {{ 'FroalaSentences_ToCheckSentence' | translate }}
            </div>
        </div>
    </div>
</div>
