import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EnumsService, IEnumValue } from 'app/common/services/enums.service';
import { EnumNames } from 'app/models/enums';

const BUTTON_GROUP_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EnumButtongroupComponent),
    multi: true
};

@Component({
    selector: 'app-enum-buttongroup',
    templateUrl: './enum-buttongroup.component.html',
    providers: [BUTTON_GROUP_VALUE_ACCESSOR]
})
export class EnumButtongroupComponent implements OnInit, ControlValueAccessor {
    private _changeFn?: (value: any) => void;
    private _touchedFn?: () => void;
    @Input() public id: string;
    @Input()
    enum: EnumNames;
    @Input()
    value: any;
    @Input()
    disabled: boolean;
    @Output()
    valueChange = new EventEmitter<any>();

    public enumValues: Array<IEnumValue>;

    constructor(private enums: EnumsService) {}

    ngOnInit() {
        this.enums.getEnum(this.enum).subscribe((res) => {
            this.enumValues = res.sort((a: any, b: any) => a.order - b.order);
        });
    }

    setDisabledState?(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    public registerOnChange(fn: () => void): void {
        this._changeFn = fn;
    }
    public registerOnTouched(fn: () => void): void {
        this._touchedFn = fn;
    }

    public writeValue(value: number): void {
        this.setValue(value);
    }
    public setValue(value: number) {
        this.value = value;
        if (this._changeFn) {
            this._changeFn(value);
        }
        if (this._touchedFn) {
            this._touchedFn();
        }
        this.valueChange.emit(value);
    }

    public getTid(prefix: string) {
        if (!this.id) {
            return prefix;
        }
        return prefix + this.id.charAt(0).toUpperCase() + this.id.slice(1);
    }

    public getTidItem(prefix: string, item: IEnumValue) {
        let tidVal = item && item.name ? item.name : '';
        tidVal = tidVal + '';
        tidVal = tidVal.replace(/[^a-z0-9]/gi, '');
        return this.getTid(prefix) + '_' + tidVal;
    }
}
