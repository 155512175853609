import { Validators } from '@alza/cms-components';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY } from 'app/common/constants';
import { DialogsService } from 'app/common/services';
import { ITranslationSentenceItemDto } from 'app/models/translation-sentence';
import { Subscription } from 'rxjs';
import { FormArray, FormGroup } from '../../typed-controls';
import { TypedFormBuilder } from '../../typed-forms';
import { INewSentenceContext, NewSentenceModalComponent } from '../new-sentence-modal/new-sentence-modal.component';

export interface ISentenceContext {
    sentenceId?: number;
    sentence?: { id: number; text: string; checked: boolean; notEditable: boolean };
    remove?: boolean;
}

export type SentenceType = ITranslationSentenceItemDto & { groupItemId?: number; selected?: boolean };

@Component({
    selector: 'app-sentence-modal',
    templateUrl: './sentence-modal.component.html',
    styleUrls: ['./sentence-modal.component.scss']
})
export class SentenceModalComponent implements OnInit, OnDestroy {
    private _subscription: Subscription;
    public sentence: ITranslationSentenceItemDto;
    public form: FormArray<SentenceType>;
    public get hasVariants() {
        return this.form?.value.length > 1;
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) readonly context: ISentenceContext,
        private http: HttpClient,
        private dialogs: DialogsService,
        private translate: TranslateService,
        private readonly dialogRef: MatDialogRef<SentenceModalComponent>,
        private fb: TypedFormBuilder,
        private readonly matDialog: MatDialog
    ) {}

    ngOnInit() {
        this.loadData();
    }

    ngOnDestroy() {
        this._subscription.unsubscribe();
    }

    private loadData() {
        this.getSentenceItemWithVariants(this.context.sentenceId).subscribe((sentence) => {
            this.form = this.initSentenceForm(sentence);
        });
    }

    public close() {
        this.dialogRef.close();
    }

    private initSentenceForm(sentences: Array<ITranslationSentenceItemDto>) {
        const form = this.fb.array<SentenceType>([]);
        sentences.forEach((variant) => {
            form.push(this.createVariant(variant));
        });

        return form;
    }

    private formToDto(): Array<ITranslationSentenceItemDto> {
        const formData = this.form.getRawValue();
        const sentences: Array<ITranslationSentenceItemDto> = [];

        formData.forEach((sentence) => {
            const data: ITranslationSentenceItemDto = {
                checked: true,
                groupId: sentence.groupId,
                languageId: sentence.languageId,
                notEditable: sentence.notEditable,
                id: sentence.id,
                text: sentence.text
            };
            sentences.push(data);
        });
        return sentences;
    }

    public createVariant(sentence?: SentenceType) {
        const group = this.fb.group<SentenceType>({
            id: 0,
            groupId: null,
            languageId: null,
            text: [null, Validators.required],
            checked: [{ value: false, disabled: true }],
            notEditable: false,
            selected: false
        });

        this._subscription = group.get('selected').valueChanges.subscribe((val) => {
            if (val) {
                group.get('selected').disable({ emitEvent: false });
                this.form?.controls.forEach((control: FormGroup<SentenceType>) => {
                    if (control !== group) {
                        control.get('selected').setValue(false);
                        control.get('selected').enable({ emitEvent: false });
                    }
                });
            }
        });

        if (sentence) {
            group.patchValue(sentence);
            if (sentence.notEditable) {
                group.get('checked').disable();
                group.get('text').disable();
            }

            if (sentence.id === this.context.sentenceId) {
                group.get('selected').setValue(true);
            }
        } else {
            const origin = this.form.value.find((x) => x.id === this.context.sentenceId);
            group.get('groupId').setValue(origin?.groupId);
            group.get('languageId').setValue(origin?.languageId);
        }

        return group;
    }

    public addVariant() {
        const origin = this.form.value.find((x) => x.id === this.context.sentenceId);
        this.matDialog
            .open<NewSentenceModalComponent, INewSentenceContext>(NewSentenceModalComponent, {
                data: { groupId: origin?.groupId, languageId: origin?.languageId },
                panelClass: 'dialog-lg'
            })
            .afterClosed()
            .subscribe((reload) => {
                if (reload) {
                    this.loadData();
                }
            });
    }

    public update() {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return;
        }

        if (this.form.touched && this.form.dirty) {
            this.dialogs
                .confirm(this.translate.instant('SentenceModal_ConfirmUpdate_Title'), this.translate.instant('SentenceModal_ConfirmUpdate_Text'))
                .then(() => {
                    this.closeWithData();
                }, EMPTY);
        } else {
            this.closeWithData();
        }
    }

    private closeWithData() {
        const data = this.formToDto();
        const selectedVariant = this.form.getRawValue().find((x) => x.selected);
        this.updateSentenceItems(data).subscribe(() => {
            this.dialogRef.close({
                sentence: { id: selectedVariant.id, text: selectedVariant.text, checked: true, notEditable: selectedVariant.notEditable }
            });
        });
    }

    public remove() {
        this.dialogs
            .confirm(this.translate.instant('SentenceModal_ConfirmRemove_Title'), this.translate.instant('SentenceModal_ConfirmRemove_Text'))
            .then(() => {
                this.dialogRef.close({ remove: true });
            }, EMPTY);
    }

    private getSentenceItemWithVariants(itemId: number) {
        return this.http.get<Array<ITranslationSentenceItemDto>>(`/api/translation-sentences/item/${itemId}/variants`);
    }

    private updateSentenceItems(dtos: Array<ITranslationSentenceItemDto>) {
        return this.http.put<any>(`/api/translation-sentences/items/`, dtos);
    }
}
