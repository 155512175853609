<div mat-dialog-title>
    <button type="button" class="close" mat-dialog-close><span aria-hidden="true">×</span></button>
    <h4 class="modal-title">{{ 'ParameterSelect_Title' | translate }}</h4>
</div>
<mat-dialog-content>
    <app-parameter-select (parameterChange)="result.id = $event"></app-parameter-select>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button data-tid="btnStorno" type="button" class="btn btn-default" mat-dialog-close>{{ 'Common_Storno' | translate }}</button>
    <button data-tid="btnOk" type="button" class="btn btn-primary" (click)="ok()">{{ 'Common_Ok' | translate }}</button>
</mat-dialog-actions>
