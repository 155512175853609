import { HttpClient } from '@angular/common/http';
import { ISegmentSelectDto } from 'app/models/commodity';
import { ISegmentDto } from 'app/models/commodity-segment/segment-dto';
import { Observable, of } from 'rxjs';
import { memoryCache } from '../http-memory-cache';
import { BaseApi } from './base-api';

export class CommoditySegmentApi extends BaseApi {
    public constructor(http: HttpClient) {
        super(http);
    }

    // Get commodity segment path
    public getCommoditySegmentPath(id: number) {
        return this.http.get<any>(`api/common/commodity-segments/path/${id}`);
    }

    public getCommoditySegments(parentIds: number | Array<number> = 0): Observable<Array<ISegmentDto>> {
        if (parentIds === null || parentIds === undefined || (Array.isArray(parentIds) && parentIds.length === 0)) {
            return of([]);
        }
        if (!Array.isArray(parentIds)) {
            parentIds = [parentIds];
        }
        return this.http
            .post<Array<ISegmentDto>>('api/common/commodity-segments/for-parents', parentIds)
            .pipe(memoryCache('CommoditySegmentApi.getCommoditySegments' + parentIds.sort().join('.'), this.cache));
    }

    public getCommoditySegmentsForChildren(childrenIds: number | Array<number> = 0): Observable<ISegmentSelectDto> {
        if (childrenIds === null || childrenIds === undefined || (Array.isArray(childrenIds) && childrenIds.length === 0)) {
            return of(null);
        }
        if (!Array.isArray(childrenIds)) {
            childrenIds = [childrenIds];
        }
        return this.http.post<ISegmentSelectDto>('api/common/commodity-segments/for-children', childrenIds);
    }
}
