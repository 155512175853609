import { ErrorMessages } from '@alza/cms-components';
import { ChangeDetectorRef, Component, ElementRef, forwardRef, Host, NgZone, Optional, SkipSelf } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DialogsService } from 'app/common/services/dialogs.service';
import { FroalaMessageService } from 'app/components/forms/froala/froala-message.service';
import 'froala-editor/js/languages/en_gb.js';
import 'froala-editor/js/plugins.pkgd.min.js';
import { IFroalaOptions } from '../froala/froala-options';
import { FroalaComponent } from '../froala/froala.component';
import { ISentenceContext, SentenceModalComponent } from './sentence-modal/sentence-modal.component';
// eslint-disable-next-line no-var
declare var $: any;

const INPUT_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FroalaSentencesComponent),
    multi: true
};

@Component({
    selector: 'app-froala-sentences',
    templateUrl: './froala-sentences.component.html',
    styleUrls: ['./froala-sentences.component.scss'],
    providers: [INPUT_VALUE_ACCESSOR],
    viewProviders: [{ provide: ErrorMessages, deps: [TranslateService], useClass: ErrorMessages }]
})
export class FroalaSentencesComponent extends FroalaComponent {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    protected _froalaOptions: Partial<IFroalaOptions> = {
        key: 'MZC1rE2G4H4A3B17B11iAd1Te1YZNYAc1CUKUEQOHFVANUqB2C1E7C7E1E5E4A1E3C10==',
        attribution: false,
        fontFamily: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Segoe UI,sans-serif': 'Segoe UI',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Arial,Helvetica,sans-serif': 'Arial',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Georgia,serif': 'Georgia',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Impact,Charcoal,sans-serif': 'Impact',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Tahoma,Geneva,sans-serif': 'Tahoma',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Times New Roman,Times,serif': 'Times New Roman',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Verdana,Geneva,sans-serif': 'Verdana'
        },
        toolbarButtons: [
            ['html', 'fullscreen', 'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript'],
            ['fontFamily', 'fontSize', 'textColor', 'clearFormatting'],
            ['paragraphFormat', 'lineHeight', 'paragraphStyle', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 'quote'],
            ['insertLink', 'insertImage', 'youtubeButton', 'insertTable'],
            ['emoticons', 'specialCharacters', 'insertHR', 'selectAll'],
            ['help', 'undo', 'redo']
        ],
        pluginsEnabled: [
            'align',
            'charCounter',
            'codeBeautifier',
            'codeView',
            'colors',
            'embedly',
            'emoticons',
            'file',
            'fontFamily',
            'fontSize',
            'fullscreen',
            'help',
            'image',
            'imageManager',
            'inlineStyle',
            'lineBreaker',
            'lineHeight',
            'link',
            'lists',
            'paragraphFormat',
            'paragraphStyle',
            'quote',
            'save',
            'specialCharacters',
            'table',
            'url',
            'video',
            'wordPaste',
            'youtubePlugin'
        ],
        codeViewKeepActiveButtons: ['fullscreen', 'format'],
        imageInsertButtons: ['imageBack', '|', 'imageUpload', 'imageByURL', 'imageManager'],
        imageUploadURL: '/api/file-system/froala-image',
        // Additional upload params.
        imageUploadParams: { path: this.imagesPath },
        imageUploadMethod: 'POST',
        imageMaxSize: 5 * 1024 * 1024,
        imageAllowedTypes: ['jpeg', 'jpg', 'png'],
        imageManagerLoadURL: '/api/file-system/froala-images',
        imageManagerLoadMethod: 'GET',
        imageManagerLoadParams: { path: this.imagesPath, extensions: '*.jpeg|*.jpg|*.png' },
        imageManagerDeleteURL: '/api/file-system/froala-image',
        imageManagerDeleteMethod: 'DELETE',
        imageManagerDeleteParams: { path: this.imagesPath },
        videoInsertButtons: ['videoBack', '|', 'videoByURL'],
        quickInsertButtons: [],
        htmlRemoveTags: [],
        htmlAllowedAttrs: ['.*'],
        htmlAllowedTags: ['.*'],
        htmlAllowedEmptyTags: [
            '.fa',
            'a',
            'abbr',
            'acronym',
            'address',
            'applet',
            'area',
            'article',
            'aside',
            'audio',
            'b',
            'base',
            'basefont',
            'bdi',
            'bdo',
            'big',
            'blockquote',
            'body',
            'button',
            'canvas',
            'caption',
            'center',
            'cite',
            'code',
            'col',
            'colgroup',
            'datalist',
            'dd',
            'del',
            'dfn',
            'div',
            'dl',
            'dt',
            'em',
            'embed',
            'fieldset',
            'figcaption',
            'figure',
            'font',
            'footer',
            'form',
            'frameset',
            'head',
            'header',
            'h1',
            'h2',
            'h3',
            'h4',
            'h5',
            'h6',
            'hr',
            'html',
            'i',
            'iframe',
            'img',
            'input',
            'ins',
            'kbd',
            'label',
            'legend',
            'li',
            'link',
            'main',
            'map',
            'mark',
            'meta',
            'meter',
            'nav',
            'noscript',
            'object',
            'ol',
            'optgroup',
            'option',
            'param',
            'pre',
            'progress',
            'q',
            's',
            'samp',
            'script',
            'section',
            'select',
            'small',
            'source',
            'span',
            'strike',
            'strong',
            'style',
            'sub',
            'sup',
            'table',
            'tbody',
            'td',
            'textarea',
            'tfoot',
            'th',
            'thead',
            'time',
            'title',
            'tr',
            'u',
            'ul',
            'var',
            'video',
            'wbr',
            'altGlyph',
            'altGlyphDef',
            'altGlyphItem',
            'animate',
            'animateColor',
            'animateMotion',
            'animateTransform',
            'animation',
            'circle',
            'clipPath',
            'color-profile',
            'cursor',
            'defs',
            'desc',
            'discard',
            'ellipse',
            'feBlend',
            'feColorMatrix',
            'feComponentTransfer',
            'feComposite',
            'feConvolveMatrix',
            'feDiffuseLighting',
            'feDisplacementMap',
            'feDistantLight',
            'feDropShadow',
            'feFlood',
            'feFuncA',
            'feFuncB',
            'feFuncG',
            'feFuncR',
            'feGaussianBlur',
            'feImage',
            'feMerge',
            'feMergeNode',
            'feMorphology',
            'feOffset',
            'fePointLight',
            'feSpecularLighting',
            'feSpotLight',
            'feTile',
            'feTurbulence',
            'filter',
            'font-face',
            'font-face-format',
            'font-face-name',
            'font-face-src',
            'font-face-uri',
            'foreignObject',
            'g',
            'glyph',
            'glyphRef',
            'handler',
            'hatch',
            'hatchpath',
            'hkern',
            'image',
            'line',
            'linearGradient',
            'listener',
            'marker',
            'mask',
            'mesh',
            'meshgradient',
            'meshpatch',
            'meshrow',
            'metadata',
            'missing-glyph',
            'mpath',
            'path',
            'pattern',
            'polygon',
            'polyline',
            'prefetch',
            'radialGradient',
            'rect',
            'set',
            'solidColor',
            'solidcolor',
            'stop',
            'svg',
            'switch',
            'symbol',
            'tbreak',
            'text',
            'textArea',
            'textPath',
            'tref',
            'tspan',
            'unknown',
            'use',
            'view',
            'vkern'
        ],
        htmlUntouched: true,
        htmlExecuteScripts: false,
        iframe: true,
        iframeStyle:
            'az-stc{cursor: pointer;border-radius: 5px;padding: 0 3px !important;display: inline !important}.sentence-checked{background-color: #95ff95cc !important;}.sentence-notchecked{background-color: #ff9595d9 !important;}.sentence-noteditable{background-color: #cccc !important;}.sentence-tocheck{background-color: #ffb76ce6 !important;}',
        charCounterCount: true,
        imagePaste: false,
        heightMax: this.maxHeight,
        charCounterMax: this.maxLength,
        toolbarSticky: true,
        zIndex: 200,
        linkAutoPrefix: 'https://',
        events: {
            initialized: () => {
                /* value has to be set after froala is initialized. If value is set before initialization it can influence content outside editor. */
                if (!this.isInitialized) {
                    if (this.value !== this.tempValue) {
                        this.value = this.tempValue;
                    }
                    this.isInitialized = true;
                }
                const editor = this.initControls.getEditor();
                editor.froalaComponentInstance = this;
                const froalaHtml: string = editor.html.get();
                if (this.value && froalaHtml !== this.value) {
                    editor.html.set(this.value);
                }
                // disable drag and drop
                editor.events?.on(
                    'drop',
                    function () {
                        return false;
                    },
                    true
                );
                // delayed sync of content and editor height, because external styles may be loaded.
                setTimeout(() => {
                    if (!this.disabled) {
                        editor.size.syncIframe();
                    }
                });
            },
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'commands.after': (cmd) => {
                const editor = this.initControls.getEditor();
                if (cmd === 'fullscreen') {
                    if (editor.fullscreen.isActive()) {
                        $('.navbar-static-top,.page-heading,.footer-floating').hide();
                    } else {
                        $('.navbar-static-top,.page-heading,.footer-floating').show();
                    }
                }
                if (cmd === 'html') {
                    $('.navbar-static-top,.page-heading,.footer-floating').show();
                    this.showCode = true;
                    this.changeDetectionRef.detectChanges();
                }
            },
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'image.beforeUpload': () => {
                if (!this._froalaOptions.imageUploadParams.path || !this._froalaOptions.imageUploadParams.path) {
                    this.dialogs.errorMessage(this.translate.instant('Froala_Error_FileUpload_PathNotSet'), '');
                    return false;
                }
            },
            click: (event: MouseEvent) => {
                this.findSentence(event.target as HTMLElement);
            }
        }
    };

    constructor(
        @Optional()
        @Host()
        @SkipSelf()
        controlContainer: ControlContainer,
        errorMessages: ErrorMessages,
        public dialogs: DialogsService,
        public translate: TranslateService,
        public element: ElementRef<HTMLElement>,
        protected messageService: FroalaMessageService,
        protected changeDetectionRef: ChangeDetectorRef,
        private matDialog: MatDialog,
        private ngZone: NgZone
    ) {
        super(controlContainer, errorMessages, dialogs, translate, element, messageService, changeDetectionRef);
    }

    public findSentence(el: HTMLElement) {
        if (!el) {
            return;
        }

        if (el.tagName.toLowerCase() === 'az-stc') {
            const sid = el.attributes.getNamedItem('data-sid');
            if (sid && !isNaN(+sid.value)) {
                this.editSentence(+sid.value, el);
            }
        } else {
            if (el.parentElement) {
                this.findSentence(el.parentElement);
            }
        }
    }

    private editSentence(sentenceId: number, element: HTMLElement) {
        this.ngZone.run(() => {
            this.matDialog
                .open<SentenceModalComponent, ISentenceContext, ISentenceContext>(SentenceModalComponent, {
                    data: { sentenceId },
                    panelClass: 'dialog-lg'
                })
                .afterClosed()
                .subscribe((context) => {
                    if (context) {
                        if (context.remove) {
                            element.remove();
                            return;
                        }

                        const sent = context.sentence;
                        if (sent) {
                            element.classList.remove('sentence-tocheck');
                            element.setAttribute('data-sid', sent.id.toString());

                            if (element.innerHTML !== sent.text) {
                                element.setAttribute('data-updated', 'true');
                            }

                            if (!sent.notEditable) {
                                if (sent.checked) {
                                    element.classList.add('sentence-checked');
                                    element.classList.remove('sentence-notchecked');
                                } else {
                                    element.classList.remove('sentence-checked');
                                    element.classList.add('sentence-notchecked');
                                }
                                element.classList.remove('sentence-noteditable');
                            } else {
                                element.classList.remove('sentence-notchecked');
                                element.classList.remove('sentence-checked');
                                element.classList.add('sentence-noteditable');
                            }
                            element.innerHTML = sent.text;
                        }

                        this.initControls?.getEditor().undo.saveStep();
                    }
                });
        });
    }
}
