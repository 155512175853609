<div class="form-group" *ngIf="formGroup">
    <app-country-select
        class="pull-right"
        (countryChange)="country = $event"
        [mode]="'single'"
        [formGroup]="formGroup"
        [countryBy]="countryBy"
    ></app-country-select>
    <ng-template
        *ngTemplateOutlet="
            formInput;
            context: {
                $implicit: formGroup.get(country.toString()),
                country: country
            }
        "
    ></ng-template>
</div>
