import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { CommonDataService } from 'app/common/services';
import { map } from 'rxjs/operators';

@Pipe({
    name: 'userDisplayName',
    pure: false
})
export class UserDisplayNamePipe implements PipeTransform {
    private _displayName = '';
    private _isLoading = false;
    private _lastValue: any;

    constructor(private readonly common: CommonDataService, private changeDetector: ChangeDetectorRef) {}

    transform(value: any): any {
        if (this._lastValue !== value) {
            this._displayName = null;
        }
        this._lastValue = value;
        if (this._displayName || this._isLoading) {
            return this._displayName;
        }
        this._isLoading = true;
        this.common.selectList
            .getUsers('All')
            .pipe(map((x) => x.find((i) => i.id === value)))
            .subscribe((res) => {
                if (res) {
                    this._displayName = res.text;
                    this.changeDetector.markForCheck();
                }
                this._isLoading = false;
            });
        return this._displayName;
    }
}
