/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum TranslationSource {
    None = 0,
    Konzole = 1,
    Web = 2,
    SOAP = 3,
    IceCat = 4,
    ManualAIKImport = 5,
    MuveCZ = 6,
    VAPOL = 7,
    Microsoft = 8,
    XML = 9,
}
