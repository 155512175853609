/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum ParameterRelationOperator {
    GreaterThan = 1,
    GreaterThanOrEqual = 2,
    Equal = 3,
    LessThanOrEqual = 4,
    LessThan = 5,
}
