import { Directive, EventEmitter, Output } from '@angular/core';
import { StornoButtonEvent } from './button-events';
import { IButton } from './page-footer.component';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'storno-button' })
export class StornoButtonDirective implements IButton {
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() click = new EventEmitter<StornoButtonEvent>();
    public loading = false;
}
