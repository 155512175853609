import { Directive, inject, OnDestroy } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { defaultCountryTranslation } from 'app/common/constants';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ICountryLocalization } from '../country-localization-switch/country-localization';
import { CountryLocalizationSwitchService } from '../country-localization-switch/country-localization-switch.service';
import { FormHelper } from './form-helper.service';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class FormComponentBase<TForm extends UntypedFormArray | UntypedFormGroup = UntypedFormGroup> implements OnDestroy {
    protected unsubscribe = new Subject<void>();
    public selectedLocalization: ICountryLocalization;
    public form?: TForm;

    public get localizationArray(): UntypedFormArray {
        return this.control('localization') as UntypedFormArray;
    }

    constructor(protected readonly formHelper: FormHelper) {
        this.selectedLocalization = { languageId: defaultCountryTranslation, countryId: null };

        const countryLocalizationService = inject(CountryLocalizationSwitchService);
        countryLocalizationService.currentLocalization$
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((currentLocalization) => (this.selectedLocalization = currentLocalization));
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public control(key: string | Array<string | number>) {
        return this.form.get(key);
    }

    public controls(key: string) {
        return <Array<AbstractControl>>(<UntypedFormArray | UntypedFormGroup>this.form.get(key)).controls;
    }

    public localized(key: string, languageId?: number, countryId: number | null = null, controlName: string = null) {
        const control = this.control(controlName ?? 'localization');

        if (control instanceof UntypedFormGroup) {
            return control.get([this.selectedLocalization.languageId, key]);
        } else if (control instanceof UntypedFormArray) {
            return this.formHelper
                .getLocalizationGroup(control, {
                    languageId: languageId ?? this.selectedLocalization.languageId,
                    countryId: !languageId && !(countryId ?? null) ? this.selectedLocalization.countryId : countryId
                })
                .get(key);
        }
    }

    public isDeleted(formGroup: UntypedFormGroup) {
        return formGroup.get('$state').value === 'deleted';
    }

    public isNew(formGroup: UntypedFormGroup) {
        return formGroup.get('$state').value === 'added';
    }

    public deleteAt(container: UntypedFormArray, index: number) {
        const item = container.controls[index];
        if (!item) {
            return;
        }
        if (item.get('$state') && item.get('$state').value !== 'added') {
            item.get('$state').setValue('deleted');
        } else {
            container.removeAt(index);
        }
    }

    public restore(item: UntypedFormGroup) {
        item.get('$state').setValue('modified');
    }
}
