import { Component, Input } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { animationTime } from 'app/common/constants';

@Component({
    selector: 'app-open-close-content',
    animations: [
        trigger('openClose', [
            state(
                'open',
                style({
                    height: '*'
                })
            ),
            state(
                'closed',
                style({
                    height: '0px'
                })
            ),
            transition('open => closed', [animate(animationTime + ' ease-in-out')]),
            transition('closed => open', [animate(animationTime + ' ease-in-out')])
        ])
    ],
    templateUrl: 'open-close-content.component.html',
    styleUrls: ['open-close-content.component.css']
})
export class OpenCloseContentComponent {
    @Input()
    isOpen = false;

    public overflowVis: boolean;

    toggle() {
        this.isOpen = !this.isOpen;
    }
}
