<div mat-dialog-title>
    <button type="button" class="close" mat-dialog-close><span aria-hidden="true">×</span></button>
    <h4>{{ 'Components_FileInputChooser_Title' | translate }}</h4>
    <div class="row">
        <cms-input
            class="col-md-12"
            [id]="'filter'"
            [(ngModel)]="nameFilter"
            (keyup.enter)="filter()"
            [labelText]="'Components_FileInputChooser_Filter' | translate"
            *ngIf="showFilter"
        >
            <span right-addon class="input-group-btn">
                <button type="button" class="btn btn-primary" (click)="filter()" data-tid="btnFilter">
                    {{ 'Common_Search' | translate }}
                </button>
                <button type="button" class="btn btn-default" (click)="clearFilter()" data-tid="btnClearFilter">
                    {{ 'Common_ClearSearch' | translate }}
                </button>
            </span>
        </cms-input>
    </div>
</div>
<mat-dialog-content>
    <div class="row">
        <div class="col-md-4" *ngIf="rootPathLength < path.length">
            <div class="thumbnail clickable" (click)="up()">
                <i class="item-icon fas fa-level-up-alt"></i>
                <div class="caption">
                    <h3>..</h3>
                </div>
            </div>
        </div>

        <div class="col-md-4" *ngFor="let i of items">
            <div class="thumbnail clickable" style="height: 230px" *ngIf="i.type === 0" (click)="chooseFile(i)">
                <i *ngIf="!i.isImage" class="item-icon far fa-file"></i>
                <img class="img-responsive item-img" *ngIf="i.isImage" [src]="i.url" [alt]="i.name" />
                <div class="caption item-title" [cmsTooltip]="i.name">
                    {{ i.name }}
                </div>
            </div>
            <div (click)="openDirectory(i)" class="thumbnail clickable" *ngIf="i.type === 1">
                <i *ngIf="!i.previewUrl" class="item-icon fas fa-folder"></i>
                <div class="caption item-title" [cmsTooltip]="i.name">
                    {{ i.name }}
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button data-tid="btnClose" mat-dialog-close type="button" class="btn btn-white">{{ 'Common_Close' | translate }}</button>
</mat-dialog-actions>
