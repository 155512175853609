<div mat-dialog-title>
    <button type="button" class="close" mat-dialog-close><span aria-hidden="true">×</span></button>
    <h4>{{ 'Configuration_Select_Config' | translate }}</h4>
</div>
<mat-dialog-content>
    <app-loading-panel [loading]="loading">
        <div class="form-group" *ngIf="search">
            <div class="row">
                <div class="col-md-7">
                    <label for="search">{{ 'Common_Search' | translate }}</label>
                    <input
                        class="form-control"
                        type="text"
                        name="search"
                        id="search"
                        [(ngModel)]="searchString"
                        placeholder="{{ 'Common_Search' | translate }}"
                    />
                </div>
                <div class="col-md-5">
                    <div class="control-buttons btn-toolbar withoutHeading">
                        <div class="btn-group">
                            <button type="button" data-tid="btnSearch" class="btn btn-primary" (click)="searchWord()">{{ 'Common_Search' | translate }}</button>
                            <button type="button" data-tid="btnClear" class="btn btn-secondary" (click)="clearSearch(true)">
                                {{ 'Common_ClearSearch' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="categoryTree ml-4">
            <app-configuration-select-modal-items [items]="items"></app-configuration-select-modal-items>
        </div>
    </app-loading-panel>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button data-tid="btnClose" type="button" class="btn btn-default" mat-dialog-close>{{ 'Common_Close' | translate }}</button>
    <button data-tid="btnOk" type="button" class="btn btn-primary" (click)="ok()">{{ 'Common_Ok' | translate }}</button>
</mat-dialog-actions>
