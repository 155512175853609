<div mat-dialog-title>
    <button type="button" class="close" mat-dialog-close>
        <span aria-hidden="true"><i class="fas fa-times"></i></span>
    </button>
    <h4>{{ 'Category_SelectCategory' | translate }}</h4>
</div>
<mat-dialog-content>
    <cms-input [id]="'searchString'" (keydown)="searchKeydown($event)" [labelText]="'Category_Search' | translate" [(ngModel)]="searchString">
        <span right-addon class="input-group-addon cursor-pointer" data-tid="btnSearch" (click)="search()"><i class="fas fa-search"></i></span>
        <span right-addon class="input-group-addon cursor-pointer" data-tid="btnClear" (click)="clearSearch()"><i class="fas fa-times"></i></span>
    </cms-input>
    <app-category-tree-view toogleOnSelect="true" [categoryId]="categoryId" (categoryIdChange)="categoryId = $event" [filter]="filter">
    </app-category-tree-view>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-dialog-close type="button" data-tid="btnClose" class="btn btn-white">{{ 'Common_Close' | translate }}</button>
    <button type="button" class="btn btn-primary" data-tid="btnOk" (click)="submit()">{{ 'Common_Ok' | translate }}</button>
</mat-dialog-actions>
