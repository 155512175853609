import { ChangeDetectorRef, Component, ContentChild, ContentChildren, EventEmitter, HostListener, Input, OnDestroy, OnInit, QueryList } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY } from 'app/common/constants';
import { DialogsService } from 'app/common/services/dialogs.service';
import { MenuService } from 'app/common/services/menu.service';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { BusyService } from '../../common/services/busy.service';
import { QueryString } from '../../common/utils';
import { CustomButtonEvent, DeleteButtonEvent, IButtonEventRoute, SaveButtonEvent, StornoButtonEvent } from './button-events';
import { CustomButtonDirective } from './custom-button.directive';
import { DeleteButtonDirective } from './delete-button.directive';
import { SaveAndCloseButtonDirective } from './save-and-close.directive';
import { SaveButtonDirective } from './save-button.directive';
import { StornoButtonDirective } from './storno-button.directive';

export interface IButton {
    click: EventEmitter<any>;
    loading: boolean;
}

@Component({
    selector: 'app-page-footer',
    templateUrl: './page-footer.component.html',
    styleUrls: ['./page-footer.component.scss']
})
export class PageFooterComponent implements OnInit, OnDestroy {
    @ContentChild(SaveButtonDirective) btnSave: SaveButtonDirective;
    @ContentChild(SaveAndCloseButtonDirective) btnSaveAndClose: SaveAndCloseButtonDirective;
    @ContentChild(StornoButtonDirective) btnStorno: StornoButtonDirective;
    @ContentChild(DeleteButtonDirective) btnDelete: DeleteButtonDirective;
    @ContentChildren(CustomButtonDirective) customButtons: QueryList<CustomButtonDirective>;

    protected unsubscribe = new Subject<void>();

    private _returnRoute: Array<any>;
    private _returnQuery: any;
    private _isHistoryTabSelected: boolean;

    @Input()
    public set returnRoute(value: Array<any> | string) {
        if (typeof value === 'string' || value instanceof String) {
            this._returnRoute = [value];
        } else {
            this._returnRoute = value;
        }
    }

    public get isHistoryTabSelected() {
        return this._isHistoryTabSelected;
    }

    public get returnRoute() {
        return this._returnRoute;
    }
    @Input()
    public set returnQuery(value: any) {
        this._returnQuery = [value];
    }

    public get returnQuery() {
        return this._returnQuery;
    }

    @Input() skipLocationChange = true;

    private _busySub: Subscription;
    public closeOnNextSaveDone = false;
    public activeRequests = false;

    public showScrollup = false;

    private _lastButtonClicked: IButton;

    constructor(
        private readonly busy: BusyService,
        private readonly router: Router,
        private readonly dialogs: DialogsService,
        readonly route: ActivatedRoute,
        private readonly translate: TranslateService,
        private readonly menuService: MenuService,
        private readonly changeDetection: ChangeDetectorRef
    ) {
        route.queryParams.subscribe((qs) => {
            if (qs['returnQuery']) {
                const value = qs['returnQuery'] as string;
                this._returnQuery = QueryString.parse(value);
            }
        });

        router.events.pipe(takeUntil(this.unsubscribe)).subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this._isHistoryTabSelected = event.url.split('?')[0].endsWith('/history');
            }
        });
    }

    ngOnInit() {
        this._busySub = this.busy.busy$.subscribe((evt) => {
            switch (evt.method) {
                case 'ANY':
                    this.activeRequests = evt.hasActiveRequests;
                    if (this.customButtons) {
                        this.changeDetection.detectChanges();
                    }
                    break;
                case 'DELETE':
                    if (this.btnDelete) {
                        this.btnDelete.loading = evt.hasActiveRequests;
                        if (this.customButtons) {
                            this.changeDetection.detectChanges();
                        }
                    }
                    break;
                case 'PUT':
                case 'PATCH':
                case 'POST':
                    if (this._lastButtonClicked) {
                        this._lastButtonClicked.loading = evt.hasActiveRequests;
                        if (this.customButtons) {
                            this.changeDetection.detectChanges();
                        }
                    }
                    break;
            }
        });
    }

    ngOnDestroy() {
        if (this._busySub) {
            this._busySub.unsubscribe();
        }

        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    @HostListener('window:scroll', ['$event'])
    onScroll() {
        this.scrollCheck();
    }

    scrollup() {
        window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    }

    scrollCheck() {
        const top = window.pageYOffset || document.documentElement.scrollTop;
        if (window.innerWidth > 999) {
            this.showScrollup = top < 100 ? false : true;
        } else {
            this.showScrollup = false;
        }
    }

    public btnclick(btn: IButton, event: MouseEvent) {
        this._lastButtonClicked = btn;
        if (btn instanceof DeleteButtonDirective) {
            const deleteEvent = new DeleteButtonEvent(event);
            deleteEvent.next.then(() => {
                this.navigateToReturnRoute(null);
                this.dialogs.successMessage(this.translate.instant('ConfirmedDialog_Delete'), '');
            }, EMPTY);
            if (btn.requireConfirm) {
                this.dialogs.confirmDelete(btn.confirmTitleMsg, btn.confirmBodyMsg).then(() => {
                    btn.click.emit(deleteEvent);
                }, EMPTY);
            } else {
                btn.click.emit(deleteEvent);
            }
        } else if (btn instanceof SaveAndCloseButtonDirective) {
            const saveAndCloseEvent = new SaveButtonEvent(event, 'saveAndClose');
            saveAndCloseEvent.next.then((route) => {
                this.navigateToReturnRoute(route);
                this.dialogs.successMessage(this.translate.instant('ConfirmedDialog_SaveAndClose'), '');
            }, EMPTY);
            btn.click.emit(saveAndCloseEvent);
        } else if (btn instanceof SaveButtonDirective) {
            const saveEvent = new SaveButtonEvent(event, 'save');
            saveEvent.next.then((route) => {
                if (route?.route) {
                    this.navigateToReturnRoute(route);
                }
                this.dialogs.successMessage(this.translate.instant('ConfirmedDialog_Save'), '');
            }, EMPTY);
            btn.click.emit(saveEvent);
        } else if (btn instanceof StornoButtonDirective) {
            const stornoEvent = new StornoButtonEvent(event);
            btn.click.emit(stornoEvent);
            if (!stornoEvent.defaultPrevented) {
                this.navigateToReturnRoute(null);
            } else {
                stornoEvent.next.then((route) => {
                    this.navigateToReturnRoute(route);
                }, EMPTY);
            }
        } else if (btn instanceof CustomButtonDirective) {
            const customEvent = new CustomButtonEvent(event);
            customEvent.next.then((route) => {
                this.navigateToReturnRoute(route);
            }, EMPTY);
            btn.click.emit(customEvent);
        } else {
            btn.click.emit(event);
        }
    }

    isMiniNavbar() {
        return this.menuService.isMiniNavbar;
    }

    private navigateToReturnRoute(event: IButtonEventRoute) {
        const route = event?.route || this._returnRoute;
        if (!route) {
            console.error(`You must specify returnRoute on app-page-footer or pass route through click event.`);
            return;
        }
        const extras: NavigationExtras = {};
        if (this._returnQuery) {
            extras.queryParams = this._returnQuery;
            this.router.navigate(route, extras).catch(EMPTY);
            return;
        }

        if (!event?.route) {
            extras.queryParams = { restoreFilter: 1 };
            extras.skipLocationChange = this.skipLocationChange;
            this.router.navigate(route, extras).catch(EMPTY);
            return;
        }

        if (event?.relativeToActivated) {
            extras.relativeTo = this.route;
        }
        this.router.navigate(route, extras).catch(EMPTY);
    }
}
