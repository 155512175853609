<div class="localizationGroup">
    <app-country-localization-switch
        [localizedArray]="form.get('localization')"
        [properties]="['url', 'linkType', 'categoryType', 'categoryId', 'articleId', 'commodityId', 'commodityPackId']"
        [countryBy]="'translations'"
        [showCountries]="showCountries"
    ></app-country-localization-switch>
    <div class="items ibox-content">
        <div class="form-group" [class.mb-0]="!labelText" [ngClass]="formGroupClass">
            <cms-label
                *ngIf="labelText"
                [required]="labelRequired"
                [for]="id"
                [text]="labelText"
                [tooltip]="labelTooltip"
                [tooltipDirection]="labelTooltipDirection"
                [tooltipIcon]="labelTooltipIcon"
                [class]="labelClass"
            ></cms-label>
            <div class="input-group" [class.cursor-not-allowed]="disabled" [class.cursor-pointer]="!disabled">
                <label left-addon class="input-group-addon gray-bg"> {{ getEshopUrl() }} </label>
                <input
                    [value]="text"
                    [attr.id]="'textPlacement'"
                    type="text"
                    class="form-control"
                    [placeholder]="placeholder"
                    ngModel
                    [required]="isRequired"
                    [disabled]="isDisabled"
                    [class.required]="isRequired"
                    (change)="onInputChange($event)"
                />
                <div class="input-group-btn">
                    <button
                        (click)="openModal()"
                        [attr.data-tid]="'btnCategorySelector'"
                        tabindex="-1"
                        class="btn btn-primary"
                        type="button"
                        [disabled]="disabled"
                    >
                        {{ 'Link_OpenModal_Action' | translate }}
                    </button>
                </div>
            </div>
            {{ getLinkInfo() }}
            <cms-field-errors [submitted]="submitted" [control]="control">
                <error-message [errorCode]="'requiredLanguages'" [message]="'Link_RequiredLanguagesError' | translate"></error-message>
            </cms-field-errors>
        </div>
    </div>
</div>
