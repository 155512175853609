import { Component, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { IParameterTypeSelectedDto } from './dto/parameter-type-selected-dto';
import { MessageService } from 'app/common/services';

@Component({
    selector: 'app-parameter-type-select',
    templateUrl: './parameter-type-select.component.html'
})
export class ParameterTypeSelectComponent implements OnDestroy {
    private _categoryId: number;
    public groupId: number;
    public typeId: number;
    private _typeGroupId: number;
    private _subscribers: Array<Subscription> = [];
    public searchedValue: string;
    get categoryId() {
        return this._categoryId;
    }

    @Input() set categoryId(value: number) {
        if (value) {
            this._categoryId = value;
            this.messageService.sendMessage('categoryId', this._categoryId);
            this.messageService.sendMessage('categoryToSelect', this._categoryId);
        }
    }

    @Output() parameterChange = new EventEmitter<any>();

    constructor(private messageService: MessageService) {
        this._subscribers.push(
            this.messageService.getMessage('categoryId').subscribe((value) => {
                this._categoryId = value;
            })
        );

        this._subscribers.push(
            this.messageService.getMessage('groupId').subscribe((value) => {
                this.groupId = value;
            })
        );

        this._subscribers.push(
            this.messageService.getMessage('typeGroupId').subscribe((value) => {
                this._typeGroupId = value;

                const parameter: IParameterTypeSelectedDto = {
                    parameterTypeId: this.typeId,
                    parameterGroupId: this.groupId,
                    categoryId: this._categoryId,
                    parameterTypeGroupId: this._typeGroupId
                };

                this.parameterChange.emit(parameter);
            })
        );

        this._subscribers.push(
            this.messageService.getMessage('typeId').subscribe((value) => {
                this.typeId = value;
            })
        );

        this._subscribers.push(
            this.messageService.getMessage('searchedValue').subscribe((value) => {
                this.searchedValue = value;
            })
        );
    }

    public select(categoryId: number) {
        this.messageService.sendMessage('categoryId', categoryId);
    }

    ngOnDestroy(): void {
        this._subscribers.forEach((subscriber) => {
            subscriber.unsubscribe();
        });
    }
}
