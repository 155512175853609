import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { animationTime } from 'app/common/constants';

@Component({
    selector: 'app-show-more-button',
    templateUrl: './show-more-button.component.html',
    styleUrls: ['./show-more-button.component.scss'],
    animations: [
        trigger('openClose', [
            state('open', style({ transform: 'rotate(0)' })),
            state('closed', style({ transform: 'rotate(180deg)' })),
            transition('open => closed', animate(animationTime + ' ease-in')),
            transition('closed => open', animate(animationTime + ' ease-out'))
        ])
    ]
})
export class ShowMoreButtonComponent {
    @Input()
    isOpen = false;

    private _customClass: object = ['pull-right'];
    @Input() set customClass(value: object) {
        this._customClass = value;
    }

    get customClass() {
        return this._customClass;
    }
}
