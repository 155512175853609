<div mat-dialog-title>
    <button type="button" class="close" mat-dialog-close><span aria-hidden="true">×</span></button>
    <h4>{{ 'SelectCountryModal_Title' | translate }}</h4>
</div>
<mat-dialog-content>
    <div class="row">
        <cms-checkbox
            *ngFor="let country of countries"
            [labelText]="country.name"
            [labelRequired]="country.required"
            [disabled]="country.required"
            [id]="country.countryCode"
            name="country.countryCode"
            class="col-xs-12 col-sm-6 col-md-4 col-lg-4"
            [(ngModel)]="country.selected"
        ></cms-checkbox>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button type="button" data-tid="btnCancel" class="btn btn-default" mat-dialog-close>{{ 'Common_Storno' | translate }}</button>
    <button type="button" data-tid="btnOk" class="btn btn-primary" (click)="ok()">{{ 'Common_Ok' | translate }}</button>
</mat-dialog-actions>
