import { FileSystemItemType } from 'app/models/enums';
import { IFileSystemFileDto } from 'app/models/file-system';

export function readFileSystemFile(file: File, serverDirectory: string = null): Promise<IFileSystemFileDto> {
    const result: IFileSystemFileDto = {
        name: file.name,
        path: [serverDirectory, file.name].join('\\'),
        mimeType: file.type,
        size: file.size,
        type: FileSystemItemType.File,
        width: null,
        cdnUrl: null,
        url: null,
        height: null,
        isImage: false,
        data: null,
        extension: null,
        isOnDevFileSystem: null,
        id: null
    };
    result.extension = file.name.split('.').pop();
    if (result.extension) {
        result.extension = '.' + result.extension;
    }
    const reader = new FileReader();
    return new Promise<IFileSystemFileDto>((resolve, reject) => {
        reader.onload = () => {
            result.data = reader.result as string;
            if (file.type.startsWith('image/')) {
                const image = new Image();
                image.onload = () => {
                    result.isImage = true;
                    result.width = image.width;
                    result.height = image.height;
                    image.remove();
                    resolve(result);
                };
                image.onerror = () => {
                    resolve(result);
                };
                image.src = result.data;
            } else {
                resolve(result);
            }
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });
}
