/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { ErrorMessages, FormInputBase } from '@alza/cms-components';
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Host,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    Provider,
    SkipSelf,
    forwardRef
} from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY } from 'app/common/constants';
import { DialogsService } from 'app/common/services';
import { LocalStorageService } from 'app/common/services/local-storage.service';
import { StringUtils } from 'app/common/utils';
import { MonacoEditor, editor as monacoEditor } from 'monaco-types';
import { Subscription } from 'rxjs';
import { ISentenceContext, SentenceModalComponent } from '../froala-sentences/sentence-modal/sentence-modal.component';
import { registerFormattingProviders } from './monaco-editor-formatters';

interface IMonacoEditorOptions extends monacoEditor.IEditorConstructionOptions {
    theme?: string;
    language?: string;
}

const INPUT_VALUE_ACCESSOR: Provider = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MonacoEditorComponent),
    multi: true
};

@Component({
    selector: 'app-monaco-editor',
    templateUrl: './monaco-editor.component.html',
    styleUrls: ['./monaco-editor.component.scss'],
    providers: [INPUT_VALUE_ACCESSOR],
    viewProviders: [{ provide: ErrorMessages, deps: [TranslateService], useClass: ErrorMessages }]
})
export class MonacoEditorComponent extends FormInputBase implements ControlValueAccessor, OnInit, OnDestroy {
    @Input() public standalone = true;
    @Input() public displayToolbar = false;
    @Input() public editSentences = false;
    @Input() public options: IMonacoEditorOptions = {};
    @Input() public editorHeight: number;
    @Output() hideEditor = new EventEmitter<Event>();

    @Output() editorInit = new EventEmitter<monacoEditor.ICodeEditor>();

    private _subscription: Subscription;
    private _timeout: number = null;

    public value = '';
    public contentHeight = 0;
    public codeEditor: monacoEditor.ICodeEditor;
    public codeEditorOptions: IMonacoEditorOptions = {};
    public editorTheme: string;
    private _defaultTheme = 'vs';
    public isFullscreen = false;

    private get globalEditorInstance() {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const value = (window as any).monaco as MonacoEditor;
        return value;
    }

    constructor(
        @Optional()
        @Host()
        @SkipSelf()
        controlContainer: ControlContainer,
        errorMessages: ErrorMessages,
        public dialogs: DialogsService,
        public translate: TranslateService,
        public element: ElementRef,
        private changeDetectionRef: ChangeDetectorRef,
        private localStorage: LocalStorageService,
        private ngZone: NgZone,
        private matDialog: MatDialog
    ) {
        super(controlContainer, errorMessages, { tidPrefix: 'mo' });

        this.localStorage.getItem<string>('codeEditor_theme').subscribe((res) => {
            this.editorTheme = res || this._defaultTheme;
            this.initOptions(this.options);
            this.changeDetectionRef.detectChanges();
        });

        this._subscription = this.localStorage.getItemSubscription<string>('codeEditor_theme').subscribe(
            (theme) => {
                this.setTheme(theme || this._defaultTheme);
            },
            () => {
                this.setTheme(this._defaultTheme);
                this.globalEditorInstance.editor.setTheme(this._defaultTheme);
            }
        );
    }

    ngOnInit() {
        super.ngOnInit();
    }

    ngOnDestroy() {
        this._subscription.unsubscribe();
        if (this.codeEditor) {
            this.codeEditor.dispose();
        }
    }

    private initOptions(options: IMonacoEditorOptions) {
        this.codeEditorOptions = {
            theme: this.editorTheme,
            language: 'html',
            automaticLayout: true,
            autoIndent: 'advanced',
            formatOnPaste: false,
            wordWrap: 'on',
            wrappingIndent: 'indent',
            scrollBeyondLastLine: false,
            ...options
        };
    }

    updateValue(obj: string): void {
        this.value = obj;
    }

    onValueChange(value: string) {
        this.value = value;
        this.raiseChange(value);
    }

    onHideEditor() {
        if (this.isFullscreen) {
            this.toggleFullscreen();
        }
        this.hideEditor.emit();
    }

    private setTheme(theme: string) {
        this.editorTheme = theme;
        this.codeEditorOptions.theme = this.editorTheme;
        this.changeDetectionRef.detectChanges();
    }

    public onEditorInit(editor: monacoEditor.ICodeEditor) {
        this.codeEditor = editor;
        this.codeEditor.onDidChangeModelContent(() => this.onEditorChange());
        if (this.editSentences) {
            this.codeEditor.onMouseUp(() => this.onCursorPositionChange());
            this.codeEditor.onKeyUp(() => this.onCursorPositionChange());
        }
        this.codeEditor.onDidChangeCursorPosition((event) => {
            if (event.reason === this.globalEditorInstance.editor.CursorChangeReason.Paste) {
                this.formatCode();
            }
        });

        editor.onDidBlurEditorText(() => {
            this.raiseTouched();
        });

        this.setEditorHeight();
        this.changeDetectionRef.detectChanges();

        setTimeout(() => {
            registerFormattingProviders(this.globalEditorInstance, this.codeEditor);
            this.formatCode();
        }, 100);
        this.editorInit.emit(editor);
    }

    public onEditorChange() {
        clearTimeout(this._timeout);
        this._timeout = setTimeout(() => {
            this.setEditorHeight();
        }, 200) as unknown as number;
    }

    public onCursorPositionChange() {
        const selection = this.codeEditor.getSelection();
        if (selection.startLineNumber !== selection.endLineNumber || selection.startColumn !== selection.endColumn) {
            return;
        }

        const parser = new DOMParser();
        const model = this.codeEditor.getModel();

        const offset = model.getOffsetAt(selection.getPosition());
        const wholeText = model.getValue();
        const textFromCursorToEnd = wholeText.substring(offset);
        if (!new RegExp('^((?!<az-stc)(.))*(</az-stc>)').test(textFromCursorToEnd)) {
            return;
        }

        const textFromCursorToStart = wholeText.substring(0, offset);
        const azElementOffset = textFromCursorToStart.lastIndexOf('<az-stc');
        if (azElementOffset === -1) {
            return;
        }
        const textFromOffsetToEnd = wholeText.substring(azElementOffset);
        const matches = new RegExp('data-sid=\\"(\\d*)\\"').exec(textFromOffsetToEnd);

        const docComplete = parser.parseFromString(wholeText, 'text/html');
        if (matches && matches.length === 2 && matches[1]) {
            this.editSentence(parseInt(matches[1]), docComplete);
        }
    }

    private editSentence(sentenceId: number, document: Document) {
        const element = document.querySelector(`[data-sid="${sentenceId}"]`);
        if (element) {
            this.ngZone.run(() => {
                this.matDialog
                    .open<SentenceModalComponent, ISentenceContext, ISentenceContext>(SentenceModalComponent, {
                        data: { sentenceId },
                        panelClass: 'dialog-lg'
                    })
                    .afterClosed()
                    .subscribe((context) => {
                        if (context) {
                            if (context.remove) {
                                element.remove();
                                this.codeEditor.setValue(new XMLSerializer().serializeToString(document));
                                return;
                            }

                            const sent = context.sentence;
                            if (sent) {
                                element.classList.remove('sentence-tocheck');
                                element.setAttribute('data-sid', sent.id.toString());

                                if (element.innerHTML !== sent.text) {
                                    element.setAttribute('data-updated', 'true');
                                }
                                if (!sent.notEditable) {
                                    if (sent.checked) {
                                        element.classList.add('sentence-checked');
                                        element.classList.remove('sentence-notchecked');
                                    } else {
                                        element.classList.remove('sentence-checked');
                                        element.classList.add('sentence-notchecked');
                                    }
                                    element.classList.remove('sentence-noteditable');
                                } else {
                                    element.classList.remove('sentence-notchecked');
                                    element.classList.remove('sentence-checked');
                                    element.classList.add('sentence-noteditable');
                                }
                                element.innerHTML = sent.text;
                                this.codeEditor.setValue(new XMLSerializer().serializeToString(document));
                            }
                        }
                    });
            });
        }
    }

    private setEditorHeight() {
        if (this.editorHeight) {
            this.contentHeight = this.editorHeight;
            return;
        }
        const maxHeight = window.innerHeight - 150;
        if (this.codeEditor.getScrollHeight() >= this.contentHeight && this.contentHeight < maxHeight) {
            this.contentHeight =
                this.codeEditor.getScrollHeight() + this.codeEditor.getLayoutInfo().horizontalScrollbarHeight > maxHeight
                    ? maxHeight
                    : this.codeEditor.getScrollHeight() + this.codeEditor.getLayoutInfo().horizontalScrollbarHeight;
            this.codeEditor.layout();
        }
    }

    public switchTheme() {
        const newTheme = this.editorTheme === 'vs' ? 'vs-dark' : 'vs';
        this.localStorage.setItem('codeEditor_theme', newTheme);
        this.globalEditorInstance.editor.setTheme(newTheme);
    }

    public formatCode() {
        this.codeEditor.getAction('editor.action.formatDocument').run().then(EMPTY, EMPTY);
    }

    public showCommandPallete() {
        this.codeEditor.getAction('editor.action.quickCommand').run().then(EMPTY, EMPTY);
    }

    public toggleFullscreen() {
        this.isFullscreen = !this.isFullscreen;
        if (this.isFullscreen) {
            $('.navbar-static-top,.page-heading,.footer-floating').hide();
            $('.monaco-container').parents('.wrapper-content,body').addClass('fullscreen');
        } else {
            $('.navbar-static-top,.page-heading,.footer-floating').show();
            $('.monaco-container').parents('.wrapper-content,body').removeClass('fullscreen');
        }
    }

    public replaceSpaces() {
        // get selected text
        const selection = this.codeEditor.getModel().getValueInRange(this.codeEditor.getSelection());
        const element = document.createElement('div');
        element.innerHTML = selection.length ? selection : this.codeEditor.getValue();
        this.getTextNodesIn(element).forEach((elem) => {
            elem.textContent = this.replaceSpacesInText(elem.textContent);
        });
        if (selection.length) {
            this.codeEditor.trigger('keyboard', 'type', { text: element.innerHTML.replace(/@nbsp;/gi, '&nbsp;') });
        } else {
            this.codeEditor.setValue(element.innerHTML.replace(/@nbsp;/gi, '&nbsp;'));
        }
    }

    private getTextNodesIn(elem: HTMLElement) {
        let textNodes: Array<Node> = [];
        if (elem) {
            for (let i = 0; i < elem.childNodes.length; i++) {
                const node = elem.childNodes[i],
                    nodeType = node.nodeType;
                if (nodeType === 3) {
                    textNodes.push(node);
                } else if (nodeType === 1 || nodeType === 9 || nodeType === 11) {
                    textNodes = textNodes.concat(this.getTextNodesIn(node as HTMLElement));
                }
            }
        }
        return textNodes;
    }

    private replaceSpacesInText(text: string) {
        text = text.replace(/(\d)( )(\d)/gi, '$1@nbsp;$3'); // 500&nbsp;000
        // Lookagead regex with reverse text is used because of lookbehind regex is not supported in Firefox.
        text = StringUtils.reverseString(StringUtils.reverseString(text).replace(/( )(?=[a-zA-ZÀ-ž] |[a-zA-ZÀ-ž]$|[a-zA-Z0-9À-ž][^a-zA-Z0-9À-ž])/gi, ';psbn@')); // A&nbsp;technologickým
        text = text.replace(/(\d)( )([a-zA-Z0-9À-ž€$£µ%]{1,2}[^a-zA-Z0-9À-ž])/gi, '$1@nbsp;$3'); // 15&nbsp;cm. 120&nbsp;Hz.
        text = text.replace(/([0-9]{1,2}\.)( )([0-9]{1,2}\.)( )([0-9]+)/gi, '$1@nbsp;$3@nbsp;$5'); // 24.&nbsp;12.&nbsp;2019
        text = text.replace(/([0-9]\.)( )([a-z])/g, '$1@nbsp;$3'); // 8.&nbsp;generace
        return text;
    }
}
