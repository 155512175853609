<div class="footer-floating tooltip-demo" [style.width]="isMiniNavbar() ? 'calc(100% - 70px)' : 'calc(100% - 220px)'">
    <div class="footer-buttons control-buttons">
        <app-button
            *ngIf="btnDelete"
            [loading]="btnDelete.loading"
            [color]="'danger'"
            [id]="'Delete'"
            type="button"
            (click)="btnclick(btnDelete, $event)"
            [disabled]="btnDelete.disabled || activeRequests"
        >
            <i class="fas fa-trash-alt fa-fw"></i>{{ 'Common_Delete' | translate }}
        </app-button>

        <app-button *ngIf="btnStorno" type="button" (click)="btnclick(btnStorno, $event)" [id]="'Cancel'" [color]="'warning'">
            <i class="fas fa-fw fa-ban"></i> {{ 'Common_Storno' | translate }}
        </app-button>

        <app-button
            *ngIf="btnSave"
            [loading]="btnSave.loading"
            [color]="'primary'"
            [disabled]="activeRequests || btnSave.disabled || isHistoryTabSelected"
            [id]="'Save'"
            [type]="btnSave.type"
            (click)="btnclick(btnSave, $event)"
        >
            <i class="far fa-save fa-fw"></i>{{ btnSave.text || ('Common_Save' | translate) }}
        </app-button>

        <app-button
            *ngIf="btnSaveAndClose"
            [loading]="btnSaveAndClose.loading"
            [color]="'primary'"
            [disabled]="activeRequests || btnSaveAndClose.disabled || isHistoryTabSelected"
            [id]="'SaveAndClose'"
            [type]="btnSaveAndClose.type"
            (click)="btnclick(btnSaveAndClose, $event)"
        >
            <i class="far fa-save fa-fw"></i>{{ btnSaveAndClose.text || ('Common_SaveAndClose' | translate) }}
        </app-button>

        <app-button
            *ngFor="let b of customButtons.toArray(); let i = index"
            [loading]="b.loading"
            [color]="b.color"
            [disabled]="activeRequests || b.disabled"
            [id]="'CustomButton' + i"
            [type]="b.type"
            (click)="btnclick(b, $event)"
        >
            <i *ngIf="b.iconClass" class="fa-fw" [ngClass]="b.iconClass"></i>{{ b.text }}
        </app-button>

        <ng-content></ng-content>
    </div>
    <div [ngStyle]="{ 'margin-right.px': showScrollup ? 45 : 5 }">
        <app-footer-info></app-footer-info>
    </div>
    <a
        [ngClass]="{
            fadeOutDown: !showScrollup,
            fadeInDown: showScrollup
        }"
        class="btn btn-primary animated scroll-up ml-3"
        title="Scroll up"
        (click)="scrollup()"
        ><i class="fas fa-chevron-up"></i
    ></a>
</div>
