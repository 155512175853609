<div [ngClass]="classes[segmentInputDisplayed]">
    <div class="form-group">
        <label
            [attr.id]="id + 'segmentSelectLabel'"
            [attr.for]="id + '_segment' + segmentInputDisplayed"
            class="sgs-label"
            [ngClass]="{ 'br-label': !breakLabelOnOverflow }"
        >
            <span
                *ngFor="let br of this.selectedSegmentsBreadcrumbTexts; let i = index"
                (click)="setSegmentAsEditable(i)"
                [attr.data-tid]="tid + '_label_segment' + i"
                ><span *ngIf="i > 0"> / </span>
                <strong class="segment-selected" *ngIf="i === segmentInputDisplayed">{{ br }}</strong>
                <span *ngIf="i !== segmentInputDisplayed" class="small">{{ br }}</span>
            </span>
        </label>
        <div *ngIf="segmentInputDisplayed === 0" [attr.data-tid]="tid + '_segment0'">
            <ng-select
                [items]="segments[segmentDepth.root]"
                [clearable]="true"
                [bindLabel]="'name'"
                [bindValue]="'id'"
                [disabled]="disabled"
                [multiple]="false"
                [ngModel]="segmentIds[segmentDepth.root]"
                [labelForId]="id + '_segment0'"
                (ngModelChange)="segmentChanged($event, segmentDepth.root)"
                [placeholder]="'Common_ChooseSegments' | translate"
                [disabled]="disabled || loading !== 0"
                [addTagText]="'Common_SelectAddTagText' | translate"
            >
                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div [attr.data-tid]="tid + '_segment0' + '_item_' + item.id">{{ item.name }}</div>
                </ng-template>
            </ng-select>
            <ng-content select="[right-addon]"></ng-content>
        </div>
        <div *ngIf="segmentInputDisplayed === 1" [attr.data-tid]="tid + '_segment1'">
            <ng-select
                [items]="segments[segmentDepth.branch]"
                [clearable]="true"
                [bindLabel]="'name'"
                [bindValue]="'id'"
                [labelForId]="id + '_segment1'"
                [disabled]="disabled"
                [multiple]="false"
                [ngModel]="segmentIds[segmentDepth.branch]"
                (ngModelChange)="segmentChanged($event, segmentDepth.branch)"
                [placeholder]="'Common_ChooseSegments' | translate"
                [disabled]="disabled || loading !== 0"
                [addTagText]="'Common_SelectAddTagText' | translate"
            >
                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div [attr.data-tid]="tid + '_segment0' + '_item_' + item.id">{{ item.name }}</div>
                </ng-template>
            </ng-select>
            <ng-content select="[right-addon]"></ng-content>
        </div>
        <div *ngIf="segmentInputDisplayed === 2" [attr.data-tid]="tid + '_segment2'">
            <ng-select
                [items]="segments[segmentDepth.leaf]"
                [clearable]="true"
                [bindLabel]="'name'"
                [bindValue]="'id'"
                [labelForId]="id + '_segment2'"
                [disabled]="disabled"
                [multiple]="false"
                [ngModel]="segmentIds[segmentDepth.leaf]"
                (ngModelChange)="segmentChanged($event, segmentDepth.leaf)"
                [placeholder]="'Common_ChooseSegments' | translate"
                [disabled]="disabled || loading !== 0"
                [addTagText]="'Common_SelectAddTagText' | translate"
            >
                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div [attr.data-tid]="tid + '_segment0' + '_item_' + item.id">{{ item.name }}</div>
                </ng-template>
            </ng-select>
            <ng-content select="[right-addon]"></ng-content>
        </div>
    </div>
</div>
<div [ngClass]="classes[segmentDepth.Prefix]">
    <div class="form-group">
        <label [attr.for]="id + 'prefix'" [attr.data-tid]="tid + '_label_prefix'">
            {{ 'Slot_Prefix' | translate }}
        </label>
        <div [attr.data-tid]="tid + '_prefix'">
            <ng-select
                [items]="prefixes"
                [clearable]="true"
                [bindLabel]="'text'"
                [bindValue]="'id'"
                [labelForId]="id + '_prefix'"
                [disabled]="disabled"
                [multiple]="false"
                [ngModel]="prefixId"
                (ngModelChange)="prefixChanged($event)"
                [placeholder]="'Slot_Prefix' | translate"
            >
                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div [attr.data-tid]="tid + '_prefix' + '_item_' + item.value">{{ item.text }}</div>
                </ng-template>
            </ng-select>
            <ng-content select="[right-addon]"></ng-content>
        </div>
    </div>
</div>
