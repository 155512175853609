/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum RedisDatabase {
    JeanProduction = 0,
    JeanBeta = 1,
    JeanDev = 2,
    JeanDebug = 3,
    ThrottlingProduction = 4,
    ThrottlingBeta = 5,
    ThrottlingDev = 6,
    ThrottlingDebug = 7,
    JeanDelayedPublishesProd = 8,
    JeanDelayedPublishesBeta = 9,
    JeanDelayedPublishesDev = 10,
    JeanDelayedPubilshesDebug = 11,
    JeanPendingProd = 12,
    JeanPendingBeta = 13,
    JeanPendingDev = 14,
    JeanPendingDebug = 15,
    JeanTeamProd = 16,
    JeanTeamBeta = 17,
    JeanTeamDev = 18,
    JeanTeamDebug = 19,
    SignalRTests = 20,
    SignalRConnectionStoreProd = 21,
    SignalRConnectionStoreBeta = 22,
    SignalRConnectionStoreDev = 23,
    SignalRConnectionStoreDebug = 24,
    JeanBlacklistProd = 25,
    JeanBlacklistBeta = 26,
    JeanBlacklistDev = 27,
    JeanBlacklistDebug = 28,
    WebDebug = 29,
    WebDev = 30,
    WebBeta = 31,
    WebProduction = 32,
    IdentityDebug = 33,
    IdentityDev = 34,
    IdentityBeta = 35,
    IdentityProduction = 36,
}
