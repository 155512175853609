import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-alphabet-select',
    templateUrl: './alphabet-select.component.html',
    styleUrls: ['./alphabet-select.component.scss']
})
export class AlphabetSelectComponent {
    public chars = [
        '#',
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'CH',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z'
    ];

    @Input()
    value: string;
    @Output()
    valueChange = new EventEmitter<string>();

    public selectChar(char: string) {
        this.value = char;
        this.valueChange.emit(char);
    }
}
