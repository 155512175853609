import { Directive, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, filter, map, Subject, switchMap, takeUntil } from 'rxjs';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'breadcrumb-detail' })
export class BreadcrumbDetailDirective implements OnInit, OnDestroy {
    public text = '';

    public titleSuffix$ = new BehaviorSubject<string>('');

    public unsubscribe = new Subject<void>();

    constructor(private readonly route: ActivatedRoute, private readonly translate: TranslateService) {}

    ngOnInit() {
        this.route.params
            .pipe(
                filter((p) => 'id' in p),
                map((p) => p.id),
                switchMap((id) => {
                    if (id === 'new') {
                        return this.translate.stream('Common_DetailBreadcrumbNewTitle');
                    } else {
                        return this.translate.stream('Common_DetailBreadcrumbEditTitle');
                    }
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe((translation) => {
                const id = this.route.snapshot.params.id;
                if (id === 'new') {
                    this.text = translation;
                } else {
                    this.text = `${translation} (${id})`;
                }

                this.titleSuffix$.next(` - ${translation}`);
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
