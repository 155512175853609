import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CountrySelectService implements OnDestroy {
    private _currentLanguageSub = new BehaviorSubject<number>(1);
    public readonly currentLanguage$ = this._currentLanguageSub.asObservable();

    private _currentCountrySub = new BehaviorSubject<number>(1);
    public readonly currentCountry$ = this._currentCountrySub.asObservable();

    constructor() {
        this.changeLanguage(1);
        this.changeCountry(0);
    }

    public changeLanguage(languageId: number) {
        // setTimeout() added to fix Froala problems (value not set to form control in code view) when country is changed
        setTimeout(() => {
            this._currentLanguageSub.next(languageId);
        });
    }

    public changeCountry(countryId: number) {
        setTimeout(() => {
            this._currentCountrySub.next(countryId);
        });
    }

    ngOnDestroy() {
        this._currentLanguageSub.complete();
        this._currentCountrySub.complete();
    }
}
