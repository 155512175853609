import { Injectable } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

interface IMessage {
    key: string;
    data: any;
}

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
    private _subject = new Subject<IMessage>();

    constructor(private readonly localStorage: StorageMap) {}

    setItem(key: string, data: any) {
        this.localStorage.set(key, data).subscribe(() => {
            this._subject.next({ key: key, data: data });
        });
    }

    clear() {
        this.localStorage.clear().subscribe();
    }

    removeItem(key: string) {
        this.localStorage.delete(key).subscribe(() => {
            this._subject.next({ key: key, data: null });
        });
    }

    getItem<T>(key: string): Observable<T> {
        return this.localStorage.get(key) as Observable<T>;
    }

    getItemSubscription<T = any>(key: string): Observable<T> {
        return this._subject.asObservable().pipe(
            filter((y) => y.key === key),
            map((x) => x.data)
        );
    }
}
