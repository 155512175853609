/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum ActionPriceType {
    None = 0,
    PriceBomb = 1,
    SuperPrice = 2,
    Discount = 3,
    New = 4,
}
