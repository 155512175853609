<div class="form-group" [class.mb-0]="!labelText" [ngClass]="formGroupClass">
    <cms-label *ngIf="labelText" [required]="labelRequired" [for]="id" [text]="labelText" [tooltip]="labelTooltip" [class]="labelClass"></cms-label>
    <div #wrapper [class.input-group]="wrapper && wrapper.childElementCount !== 1">
        <div class="input-group">
            <input class="form-control" type="text" readonly [value]="value" />
            <input #hiddenFileInput type="file" class="hidden" [attr.accept]="acceptString" (change)="onInputFileChange($event.target.files)" />
            <div class="input-group-btn">
                <button
                    [disabled]="disabled"
                    type="button"
                    class="btn btn-primary dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    {{ 'Components_FileInput_ChooseFile' | translate }} <i class="fas fa-chevron-down"></i>
                </button>
                <ul class="dropdown-menu dropdown-menu-right">
                    <li>
                        <a (click)="hiddenFileInput.click()" href="javascript:void(0)" data-tid="btnChooseFromComputer"
                            ><i class="mr-1 fas fa-desktop"></i> {{ 'Components_FileInput_ChooseFromComputer' | translate }}</a
                        >
                    </li>
                    <li *ngIf="serverUploadEnabled" (click)="openServerFileChooser()">
                        <a href="javascript:void(0)" data-tid="brnChooseFromServer"
                            ><i class="mr-1 fas fa-server"></i>{{ 'Components_FileInput_ChooseFromServer' | translate }}</a
                        >
                    </li>
                    <li *ngIf="urlUploadEnabled">
                        <a (click)="openUrlChooser()" href="javascript:void(0)" data-tid="btnChooseFromUrl"
                            ><i class="mr-1 fas fa-link"></i> {{ 'Components_FileInput_ChooseFromUrl' | translate }}</a
                        >
                    </li>
                    <li *ngIf="value" role="separator" class="divider"></li>
                    <li *ngIf="value">
                        <a (click)="remove()" href="javascript:void(0)" data-tid="btnRemoveFile"
                            ><i class="mr-1 fas fa-trash"></i> {{ 'Components_FileInput_RemoveFile' | translate }}</a
                        >
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <cms-field-errors [submitted]="submitted" [control]="control"></cms-field-errors>
</div>
