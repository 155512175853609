import { Directive, ElementRef, Input } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/naming-convention
import * as Ladda from 'ladda';

@Directive({ selector: '[appLadaLoading]' })
export class LaddaLoadingDirective {
    @Input() set appLadaLoading(value: boolean) {
        if (!this._ladda) {
            return;
        }
        if (value) {
            this._ladda.start();
        } else {
            this._ladda.stop();
        }
    }

    private _ladda: Ladda.LaddaButton;

    constructor(el: ElementRef) {
        if (el.nativeElement) {
            this._ladda = Ladda.create(el.nativeElement);
        }
    }
}
