import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CategorySelectModalComponent } from 'app/components/category-select/category-select-modal/category-select-modal';
import { ICategoryTreeFilterDto } from 'app/models/category';

@Component({
    selector: 'app-category-select-one-modal',
    templateUrl: './category-select-one-modal.component.html',
    styleUrls: ['./category-select-one-modal.component.scss']
})
export class CategorySelectOneModalComponent {
    private _defaultFilter: Partial<ICategoryTreeFilterDto> = { searchValue: '', expanded: true };
    public categoryId?: number;
    public searchString: string;
    public filter: Partial<ICategoryTreeFilterDto> = { ...this._defaultFilter };
    constructor(@Inject(MAT_DIALOG_DATA) private readonly context: number, private readonly dialogRef: MatDialogRef<CategorySelectModalComponent, number>) {
        this.categoryId = this.context;
        if (this.categoryId) {
            this.filter = { ...this.filter, expanded: false, searchValue: String(this.categoryId) };
        }
    }

    submit() {
        this.dialogRef.close(this.categoryId);
    }

    public searchKeydown($event: KeyboardEvent) {
        if ($event && $event.key === 'Enter') {
            this.search();
        }
    }

    public clearSearch() {
        this.searchString = null;
        this.filter = { ...this._defaultFilter };
    }

    public search() {
        if (Number(this.searchString) > 0) {
            this.categoryId = Number(this.searchString);
        }
        this.filter = { ...this.filter, expanded: false, searchValue: this.searchString };
    }
}
