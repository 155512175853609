import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'hideDeleted',
    pure: false
})
export class HideDeletedPipe implements PipeTransform {
    transform(value: Array<any>): any {
        if (value) {
            const res: Array<any> = [];
            value.forEach((i: any) => {
                if (i.$state && i.$state !== 'deleted') {
                    res.push(i);
                } else if (typeof i.get === 'function') {
                    const state = i.get('$state');
                    if (state && state.value !== 'deleted') {
                        res.push(i);
                    }
                }
            });
            return res;
        } else {
            return value;
        }
    }
}
