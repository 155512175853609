<div mat-dialog-title>
    <button type="button" class="close" mat-dialog-close><span aria-hidden="true">×</span></button>
    <h4>{{ 'Components_FileInput_FileExists_Title' | translate }}</h4>
</div>
<mat-dialog-content>
    <div class="list-group bg-white">
        <a href="javascript:void(0)" class="list-group-item" (click)="keep()">
            <h3 class="list-group-item-heading">{{ 'Components_FileInput_FileExists_Keep' | translate }}</h3>
            <p class="list-group-item-text mb-0">{{ 'Components_FileInput_FileExists_KeepDescription' | translate }}</p>
        </a>
        <a href="javascript:void(0)" class="list-group-item" (click)="rename()">
            <h3 class="list-group-item-heading">{{ 'Components_FileInput_FileExists_Rename' | translate }}</h3>
            <p class="list-group-item-text mb-0">{{ 'Components_FileInput_FileExists_RenameDescription' | translate }}</p>
        </a>
        <a href="javascript:void(0)" class="list-group-item" (click)="rewrite()">
            <h3 class="list-group-item-heading">{{ 'Components_FileInput_FileExists_Rewrite' | translate }}</h3>
            <p class="list-group-item-text mb-0">{{ 'Components_FileInput_FileExists_RewriteDescription' | translate }}</p>
        </a>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button data-tid="btnClose" mat-dialog-close type="button" class="btn btn-white">{{ 'Common_Close' | translate }}</button>
</mat-dialog-actions>
