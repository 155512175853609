<div class="form-group" [class.mb-0]="!labelText" [ngClass]="formGroupClass">
    <cms-label *ngIf="labelText" [required]="labelRequired" [for]="id" [text]="labelText" [tooltip]="labelTooltip" [class]="labelClass"></cms-label>
    <div class="input-group">
        <div class="input-group-btn">
            <button
                (click)="openModal()"
                [attr.data-tid]="'btnCategorySelection' + id"
                tabindex="-1"
                class="btn btn-primary"
                type="button"
                [disabled]="disabled"
            >
                <i class="fas fa-sitemap fa-rotate-270"></i>
            </button>
        </div>
        <input
            class="form-control categoryId"
            [attr.data-tid]="'tbCategoryId' + id"
            [(ngModel)]="input"
            (keydown)="inputKeyDown($event)"
            [disabled]="sectionCategories || disabled"
            [attr.placeholder]="'CategorySelect_IdCategory' | translate"
        />
        <div class="input-group-btn" *ngIf="input && inputIsNumber">
            <button
                (click)="plusClicked()"
                [attr.data-tid]="'btnAddCategory' + id"
                abindex="-1"
                class="btn btn-primary"
                [disabled]="sectionCategories || disabled"
                type="button"
            >
                <i class="fas fa-plus"></i>
            </button>
        </div>
    </div>
    <div class="categorySelection" *ngIf="items.length">
        <div class="selectedCategoriesCnt">
            <div
                class="item"
                *ngFor="let i of searchCategory() | slice: n:n + 15; let index = index"
                (click)="setMain(i)"
                [ngStyle]="{ 'background-color': i.isMain ? '#dbf6f1' : '' }"
            >
                <div class="id">{{ i.id }}</div>
                <div class="name">{{ i.name }}</div>
                <div class="actions">
                    <i class="fas fa-times remove" [attr.data-tid]="'btnRemCat' + id + index" (click)="remove(i.id)" *ngIf="!disabled"></i>
                </div>
            </div>
        </div>
        <div class="pull-right" *ngIf="searchCategory().length >= 15">
            <button class="btn btn-white btn-sm" [disabled]="n <= 0" (click)="n = n - 15"><i class="fas fa-chevron-left"></i></button>
            <button class="btn btn-white btn-sm" [disabled]="n >= searchCategory().length - 15" (click)="n = n + 15">
                <i class="fas fa-chevron-right"></i>
            </button>
        </div>
    </div>
    <cms-field-errors [submitted]="submitted" [control]="control"></cms-field-errors>
</div>
