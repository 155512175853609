import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICategoryBaseDto, ICategoryTreeItemDto } from 'app/models/category';
import { map } from 'rxjs/operators';
import { ICategoryTreeFilterClientDto, ICategoryTreeItemClientDto } from '.';

@Injectable()
export class CategoryTreeDataService {
    private _apiRoute = '/api/category/';
    private _params: { expanded?: string; searchValue?: string; countryIds?: Array<string> } = {};

    constructor(private http: HttpClient) {}

    public categoryBase(id: number) {
        return this.http.get<ICategoryBaseDto>(this._apiRoute + id + '/base');
    }

    public categoryTree(filter: ICategoryTreeFilterClientDto) {
        if (filter.countryIds) {
            this._params.countryIds = filter.countryIds?.map((id) => String(id));
        } else {
            delete this._params.countryIds;
        }
        this._params.expanded = String(!!filter.expanded);
        if (filter.searchValue) {
            this._params.searchValue = String(filter.searchValue);
        } else {
            delete this._params.searchValue;
        }
        return this.http.post<Array<ICategoryTreeItemDto>>(this._apiRoute + 'tree', this._params);
    }

    public categoryChildren(id?: number) {
        const params: any = {};
        if (id) {
            params.rootId = '' + id;
        }
        if (this._params?.countryIds) {
            params.countryIds = this._params.countryIds;
        }

        return this.http.get<Array<ICategoryTreeItemClientDto>>(this._apiRoute + 'sub-tree', { params: params }).pipe(
            map((items) =>
                items.map((item) => {
                    item.isExpanded = false;
                    return item;
                })
            )
        );
    }
}
