import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import swal, { SweetAlertOptions } from 'sweetalert2';
import { EMPTY } from '../constants';
import { IModalComponent, IModalOptions } from '../modal-component';
import { isRunningWithinTestEnvironment } from '../utils';

class ModalRef {
    public promise: Promise<any>;
    private _resolve?: (value?: PromiseLike<any>) => void;
    private _reject?: (reason?: any) => void;
    constructor(private bsModalRef: BsModalRef) {
        this.promise = new Promise((resolve, reject) => {
            this._resolve = resolve;
            this._reject = reject;
        });
    }

    close() {
        if (!this._reject || !this._resolve) {
            return;
        }
        if (!this.bsModalRef.content) {
            this._resolve();
        }
        const content = this.bsModalRef.content as IModalComponent<any>;
        if (content.result) {
            this._resolve(content.result);
        } else {
            this._reject();
        }
    }
}

@Injectable()
export class DialogsService {
    public timerInterval;
    private _activeHtmlElement: any;
    private _modalRefs: Array<ModalRef> = [];

    constructor(private readonly modal: BsModalService, private readonly translate: TranslateService) {
        this.modal.onHidden.subscribe(
            () => {
                const ix = this.modal.getModalsCount();
                if (this._modalRefs[ix]) {
                    const ref = this._modalRefs.pop();
                    if (ref) {
                        ref.close();
                    }
                }
            },
            (err: any) => {
                console.error(err);
            }
        );
    }

    private _swalCustom = swal.mixin({
        position: 'top-end',
        showConfirmButton: false,
        backdrop: false,
        allowOutsideClick: false,
        customClass: 'cursor-pointer',
        willOpen: (toast) => {
            toast.addEventListener('mouseenter', () => {
                swal.stopTimer();
            });

            toast.addEventListener('click', () => {
                swal.close();
            });

            toast.addEventListener('mouseleave', () => {
                swal.resumeTimer();
            });
        },
        willClose: () => {
            this._activeHtmlElement = document.activeElement;
            clearInterval(this.timerInterval);
        },
        didClose: () => {
            this._activeHtmlElement.focus();
        }
    });

    public confirmDelete(confirmTitleMsg: string = null, confirmBodyMsg: string = null): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            swal.fire({
                title: confirmTitleMsg || this.translate.instant('ConfirmDialog_Delete'),
                text: confirmBodyMsg || this.translate.instant('ConfirmDialog_DeleteExtended'),
                icon: 'warning',
                showCancelButton: true,
                showConfirmButton: true,
                cancelButtonText: this.translate.instant('Common_Storno'),
                confirmButtonText: this.translate.instant('ConfirmDialog_YesDelete'),
                buttonsStyling: true,
                reverseButtons: true,
                customClass: {
                    confirmButton: 'dialog-confirm',
                    cancelButton: 'dialog-storno',
                    popup: 'animated bounceIn'
                }
            }).then(
                (res) => {
                    if (res.value) {
                        resolve();
                    } else {
                        reject();
                    }
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

    public confirm(
        title: string,
        body: string,
        okBtnText: string | null = null,
        stornoBtnText: string | null = null,
        config?: Partial<SweetAlertOptions>
    ): Promise<void> {
        return new Promise((resolve, reject) => {
            swal.fire({
                title,
                text: body,
                icon: 'warning',
                showCancelButton: true,
                showConfirmButton: true,
                cancelButtonText: stornoBtnText || this.translate.instant('Common_Storno'),
                confirmButtonText: okBtnText || this.translate.instant('ConfirmDialog_Yes'),
                buttonsStyling: true,
                reverseButtons: true,
                customClass: {
                    cancelButton: 'dialog-storno',
                    confirmButton: 'dialog-confirm',
                    popup: 'animated bounceIn'
                },
                ...(config as SweetAlertOptions)
            }).then(
                (res) => {
                    if (res.value) {
                        resolve();
                    } else {
                        reject();
                    }
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

    /**
     * @deprecated Use MatDialog service instead
     */
    showModal<TResult>(component: any, context: any = null, options: IModalOptions = {}): Promise<TResult> {
        const modalRef = this.modal.show(component, options);
        const c = modalRef.content as IModalComponent<TResult>;
        const ref = new ModalRef(modalRef);
        this._modalRefs.push(ref);
        if (typeof c.setContext === 'function') {
            c.setContext(context);
        }
        return ref.promise;
    }

    public successMessage(header: string, message?: string, config?: Partial<SweetAlertOptions>) {
        this._swalCustom
            .fire({
                title: header,
                text: message,
                icon: 'success',
                timer: isRunningWithinTestEnvironment() ? null : 3000,
                ...(config as SweetAlertOptions)
            })
            .then(EMPTY, EMPTY);
    }
    public infoMessage(header: string, message?: string, config?: Partial<SweetAlertOptions>) {
        this._swalCustom
            .fire({
                title: header,
                text: message,
                icon: 'info',
                timer: isRunningWithinTestEnvironment() ? null : 3000,
                ...(config as SweetAlertOptions)
            })
            .then(EMPTY, EMPTY);
    }
    public warningMessage(header: string, message?: string, config?: Partial<SweetAlertOptions>) {
        this._swalCustom
            .fire({
                title: header,
                text: message,
                icon: 'warning',
                timer: isRunningWithinTestEnvironment() ? null : 3000,
                ...(config as SweetAlertOptions)
            })
            .then(EMPTY, EMPTY);
    }
    public errorMessage(header: string, message?: string, config?: Partial<SweetAlertOptions>) {
        this._swalCustom
            .fire({
                title: header,
                text: message,
                icon: 'error',
                timer: isRunningWithinTestEnvironment() ? null : 3000,
                ...(config as SweetAlertOptions)
            })
            .then(EMPTY, EMPTY);
    }

    public badRequestMessage(response: any) {
        if (!(response instanceof HttpErrorResponse)) {
            this.warningMessage('Common_BadRequestGenericError', this.translate.instant('Common_BadRequestMsgTitle'));
            return;
        }
        const body = response.error?.errors ?? response.error;
        const lines: Array<string> = [];
        if (body instanceof Object) {
            for (const [, value] of Object.entries(body)) {
                const tmp: any = value;
                if (value && tmp.join) {
                    const line = tmp.join(', ');
                    lines.push(line);
                }
            }
        }
        if (lines.length === 0) {
            lines.push(this.translate.instant('Common_BadRequestGenericError'));
        }
        this.errorMessage(lines.join('<br>'), this.translate.instant('Common_BadRequestMsgTitle'));
    }

    public infoWithConfirm(title: string, body: string, config?: Partial<SweetAlertOptions>): Promise<void> {
        return new Promise((resolve, reject) => {
            swal.fire({
                title,
                text: body,
                icon: 'info',
                showConfirmButton: true,
                confirmButtonText: this.translate.instant('ConfirmDialog_Ok'),
                buttonsStyling: true,
                customClass: {
                    confirmButton: 'info-confirm',
                    popup: 'animated bounceIn'
                },
                ...(config as SweetAlertOptions)
            }).then(
                (res) => {
                    if (res.value) {
                        resolve();
                    } else {
                        reject();
                    }
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }
}
