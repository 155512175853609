<div class="input-group">
    <div class="input-group-btn">
        <button (click)="openModal()" [attr.data-tid]="'btnCategorySelection' + id" tabindex="-1" class="btn btn-primary" type="button" [disabled]="disabled">
            <i class="fas fa-sitemap fa-rotate-270"></i>
        </button>
    </div>
    <input
        class="form-control categoryId"
        [attr.data-tid]="'tbCategoryId' + id"
        [(ngModel)]="input"
        (keydown)="inputKeyDown($event)"
        [disabled]="disabled"
        [attr.placeholder]="'CategorySelect_IdCategory' | translate"
    />
    <div class="input-group-btn" *ngIf="input && inputIsNumber">
        <button (click)="plusClicked()" [attr.data-tid]="'btnAddCategory' + id" abindex="-1" class="btn btn-primary" [disabled]="disabled" type="button">
            <i class="fas fa-plus"></i>
        </button>
    </div>
</div>
<div class="categorySelection" *ngIf="items.length">
    <div class="categories-container">
        <div class="categories-grid" *ngFor="let i of searchConfiguration() | slice: n:n + 15; let index = index">
            <div class="item">{{ i.id }}</div>
            <div class="item">{{ i.eshopName }}</div>
            <div class="item">{{ i.sectionName }}</div>
            <div class="item">
                <i class="fas fa-times fa-danger-hover" [attr.data-tid]="'btnRemCat' + id + index" (click)="remove(i.id)" *ngIf="!disabled"></i>
            </div>
        </div>
    </div>
    <div class="pull-right" *ngIf="searchConfiguration().length >= 15">
        <button class="btn btn-white btn-sm" [disabled]="n <= 0" (click)="n = n - 15"><i class="fas fa-chevron-left"></i></button>
        <button class="btn btn-white btn-sm" [disabled]="n >= searchConfiguration().length - 15" (click)="n = n + 15">
            <i class="fas fa-chevron-right"></i>
        </button>
    </div>
</div>
