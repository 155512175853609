/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum NotificationVariant {
    Alert = 1,
    Error = 2,
    Info = 3,
    Success = 4,
    Warning = 5,
    Plain = 6,
}
