import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

export interface IParameterSelectResult {
    id: number;
}

@Component({
    templateUrl: './parameter-select-modal.component.html'
})
export class ParameterSelectModalComponent {
    public result: IParameterSelectResult = {
        id: null
    };

    constructor(private readonly dialogRef: MatDialogRef<ParameterSelectModalComponent, IParameterSelectResult>) {}

    public ok() {
        if (this.result.id) {
            this.dialogRef.close(this.result);
        } else {
            this.dialogRef.close();
        }
    }
}
