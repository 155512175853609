<div class="btns" [attr.data-tid]="'btnGrpLocalization'">
    <div class="grid-container" [ngStyle]="getGridColumns()" [ngClass]="containerClass">
        <div *ngFor="let language of languages; let index = index" class="grid-item" [ngStyle]="getBtnGridposition(language, index)">
            <div (mouseenter)="buttonEntered = index" (mouseleave)="buttonEntered = null">
                <button
                    class="btn btn-xs"
                    *ngIf="index <= shownLanguages"
                    [ngClass]="getButtonClass(language)"
                    type="button"
                    [attr.data-tid]="'btn_' + language.code | lowercase"
                    (mousedown)="selectLocalization({ languageId: language.id, countryId: null })"
                >
                    {{ language.code | lowercase }}
                </button>

                <button
                    class="btn btn-xs"
                    *ngIf="index > shownLanguages && isExpanded"
                    [ngClass]="getButtonClass(language)"
                    [attr.data-tid]="'btnGrp_' + language.code | lowercase"
                    type="button"
                    (mousedown)="selectLocalization({ languageId: language.id, countryId: null })"
                >
                    {{ language.code | lowercase }}
                </button>
                <div
                    class="currentCountry"
                    *ngIf="this.localization.languageId == language.id && this.localization.countryId !== null && this.localization.countryId !== undefined"
                >
                    <span class="circle">{{ getCountryCodebyId(this.localization.countryId) }}</span>
                </div>
                <div *ngIf="showCountries" class="countriesHolder">
                    <div class="countries" [class.hidden]="index !== buttonEntered">
                        <button
                            *ngFor="let country of language.countries"
                            class="btn btn-xs"
                            type="button"
                            [attr.data-tid]="'btn_' + (language.code | lowercase) + '_' + country.countryCode"
                            [ngClass]="getButtonClass(language, country)"
                            (mousedown)="selectLocalization({ languageId: language.id, countryId: country.id })"
                        >
                            {{ country.countryCode }}
                        </button>
                        <button
                            class="btn btn-xs"
                            type="button"
                            [attr.data-tid]="'btnSelectCountry_' + language.code | lowercase"
                            (mousedown)="selectCountryModal(language)"
                        >
                            +/-
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <button *ngIf="this.languages.length > 6" class="btn btn-xs more-button" type="button" (click)="isExpanded = !isExpanded" (blur)="isExpanded = false">
            <span class="invalid-count" [ngClass]="{ 'is-active': getInvalidCount() > 0 }">{{ getInvalidCount() }}</span>
            ...
        </button>
    </div>
</div>
