import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionService } from 'app/common/services';
import { ISelectListItemDto } from 'app/models';
import { IBulkDeleteDto, IBulkUpdateDto, ICommodityValidationErrorDto, IDeleteParameterDto } from 'app/models/commodity-parameter';
import {
    IAutoFillRuleGroupDto,
    IAutoFillRuleParameterDto,
    IAutoFillRulesDto,
    IBulkUpdateParametricCategoryDto,
    ICommodityParameterGroupDto,
    IParameterEnumValueDto,
    IParameterNameNoteDto,
    IParameterTypeCollectionItemDto,
    IParameterTypeDetailDto,
    IParameterTypeEnumCollectionItemDto,
    IParameterTypeEnumDuplicityCheckDto,
    IParameterTypeEnumDuplicityCheckResultDto,
    IParameterTypesOrderDto
} from 'app/models/parameter';
import { Observable } from 'rxjs';
import { IParameterTypeComposite } from './dto/parameter-type-composite-dto';
import { IParameterTypeEnumBulkInsert } from './dto/parameter-type-enum-bulk-insert-dto';
import { IParameterTypeEnum, IParameterTypeEnumOrder } from './dto/parameter-type-enum-dto';

@Injectable()
export class ParameterTypeDataService {
    private _apiRoute = '/api/parameter';
    private _commodityParameterApiRoute = '/api/commodity-parameter';

    constructor(private http: HttpClient, private session: SessionService) {}

    public getTypesByGroup(groupId: number) {
        return this.http.get<Array<IParameterTypeCollectionItemDto>>(`${this._apiRoute}/types-by-group/${groupId}`);
    }

    public getUnitTypes() {
        return this.http.get<Array<ISelectListItemDto>>(`/api/common/select-lists/unit-types`);
    }

    public getParentTypes(typeId: number, categoryId: number) {
        return this.http.get<Array<ISelectListItemDto>>(`${this._apiRoute}/parameter-type-parent-types/${typeId}/${categoryId}`);
    }

    public getUnitsByUnitType(unitTypeId: number) {
        return this.http.get<Array<ISelectListItemDto>>(`${this._apiRoute}/parameter-units-by-unit-type/${unitTypeId}`);
    }

    public getUnitsByTypeGroup(typeGroupId: number) {
        return this.http.get<Array<ISelectListItemDto>>(`${this._apiRoute}/parameter-units-by-type-group/${typeGroupId}`);
    }

    public loadDetail(id: number) {
        return this.http.get<IParameterTypeDetailDto>(`${this._apiRoute}/parameter-type/${id}`);
    }

    public loadFilterInfo(typeGroupId: number, enumId?: number, cableLeftId?: number, cableRightId?: number) {
        if (enumId) {
            return this.http.get<any>(`${this._apiRoute}/parameter-type-group/${typeGroupId}/filter-info?typeEnumId=${enumId}`);
        } else if (cableLeftId || cableRightId) {
            return this.http.get<any>(
                `${this._apiRoute}/parameter-type-group/${typeGroupId}/filter-info?CableLeftTypeEnumId=${cableLeftId}&CableRightTypeEnumId=${
                    cableRightId || ''
                }`
            );
        }
        return this.http.get<any>(`${this._apiRoute}/parameter-type-group/${typeGroupId}/filter-info`);
    }

    public loadCategoryDetailNumeric(typeGroupId: number, categoryId: number) {
        return this.http.get<any>(`${this._apiRoute}/parameter-type-group/${typeGroupId}/numeric-values?categoryId=${categoryId}`);
    }

    public loadLocalCategory(typeGroupId: number, categoryId: number) {
        return this.http.get<any>(`${this._apiRoute}/parameter-type-localcategory/${typeGroupId}/${categoryId}`);
    }

    public updateParameterType(dto: IParameterTypeComposite) {
        return this.http.put<any>(`${this._apiRoute}/parameter-type`, dto);
    }

    public createParameterType(dto: IParameterTypeComposite) {
        return this.http.post<any>(`${this._apiRoute}/parameter-type`, dto);
    }

    public getAutoFillRules(typeGroupId: number, typeId: number, groupId: number) {
        return this.http.get<IAutoFillRulesDto>(`${this._apiRoute}/auto-fill-rules/${typeGroupId}/${typeId}/${groupId}`);
    }

    public getParameterForAutoFillRule(groupId: number, typeGroupId: number) {
        return this.http.get<Array<IAutoFillRuleParameterDto>>(`${this._apiRoute}/auto-fill-parameter/${groupId}/${typeGroupId}`);
    }

    public getEnumValuesByParameterType(parameterTypeId: number, categoryId?: number, parentId?: number) {
        return this.http.get<Array<IParameterEnumValueDto>>(`${this._apiRoute}/parameter-type/${parameterTypeId}/enum-values`, {
            params: { categoryId: (categoryId || '').toString(), parentId: (parentId || '').toString() }
        });
    }

    public getCommoditiesByAutoFillGroup(dto: IAutoFillRuleGroupDto) {
        return this.http.post<any>(`${this._apiRoute}/commodities-by-autofill-group/`, dto);
    }

    public getCommoditiesByAutoFillGroups(dto: IAutoFillRulesDto) {
        return this.http.post<any>(`${this._apiRoute}/commodities-by-autofill-groups/`, dto);
    }

    public getTypeEnumChildren(typeEnumId: number): Observable<Array<IParameterTypeEnumCollectionItemDto>> {
        return this.http.get<Array<IParameterTypeEnumCollectionItemDto>>(`${this._apiRoute}/type-enum-children/${typeEnumId}`);
    }

    public loadTypeEnumDetail(id: number) {
        return this.http.get<any>(`${this._apiRoute}/parameter-type-enum/${id}`);
    }

    public updateParameterTypeEnum(dto: IParameterTypeEnum) {
        return this.http.put<any>(`${this._apiRoute}/parameter-type-enum`, dto);
    }

    public createParameterTypeEnum(dto: IParameterTypeEnum) {
        return this.http.post<any>(`${this._apiRoute}/parameter-type-enum`, dto);
    }

    public deleteParameterTypeEnum(id: number) {
        return this.http.delete<any>(`${this._apiRoute}/parameter-type-enum/${id}`);
    }

    public bulkInsertParameterTypeEnums(dto: IParameterTypeEnumBulkInsert) {
        return this.http.post<any>(`${this._apiRoute}/parameter-type-enum-bulkinsert`, dto);
    }

    public insertValuesToCommodities(data: IBulkUpdateDto) {
        return this.http.post<Array<ICommodityValidationErrorDto>>(`${this._commodityParameterApiRoute}/bulk-update/`, data);
    }

    public deleteValueFromCommodities(data: IBulkDeleteDto) {
        return this.http.post<Array<ICommodityValidationErrorDto>>(`${this._commodityParameterApiRoute}/bulk-delete/`, data);
    }

    public orderParameterTypeEnums(dto: IParameterTypeEnumOrder) {
        return this.http.post<any>(`${this._apiRoute}/order-parameter-type-enums`, dto);
    }

    public checkParameterTypeEnumDuplicity(dto: IParameterTypeEnumDuplicityCheckDto) {
        return this.http.post<IParameterTypeEnumDuplicityCheckResultDto>(`${this._apiRoute}/check-enum-values-duplicity`, dto);
    }

    public orderParameterTypes(dto: IParameterTypesOrderDto) {
        return this.http.post<any>(`${this._apiRoute}/order-parameter-types`, dto);
    }

    public cutTypeFromGroup(typeId: number, groupId: number) {
        return this.http.delete<any>(`${this._apiRoute}/remove-from-group/${typeId}/${groupId}`);
    }

    public deleteParameterType(typeId: number, groupId?: number) {
        return this.http.delete<any>(`${this._apiRoute}/parameter-type/${typeId}/${groupId}`);
    }

    public addTypesToGroup(groupId: number, types: Array<number>) {
        return this.http.post<any>(`${this._apiRoute}/add-types-to-group/${groupId}`, types);
    }

    public getCommoditiesByParameterTypeEnum(parameterTypeEnumId: number) {
        return this.http.get<Array<ICommodityParameterGroupDto>>(`${this._apiRoute}/commodities-by-parameter-type-enum/${parameterTypeEnumId}`);
    }

    public deleteCommoditiesParameters(data: IDeleteParameterDto) {
        return this.http.post(`${this._commodityParameterApiRoute}/delete-parameters`, data);
    }

    public bulkUpdateParametricCategory(dto: IBulkUpdateParametricCategoryDto) {
        return this.http.post<any>(`${this._apiRoute}/bulk-update-parametric-category`, dto);
    }

    public getParameterTypename(id: number) {
        return this.http.get<IParameterNameNoteDto>(`${this._apiRoute}/parameter-type-name/${id}`);
    }

    public isHierarchic(groupId: number) {
        return this.http.get<boolean>(`${this._apiRoute}/is-group-hierarchic/${groupId}`);
    }
}
