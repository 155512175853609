/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum ClientPlatform {
    Web = 0,
    Android = 1,
    IOS = 2,
    AndroidTablet = 3,
    IOSTablet = 4,
    Backend = 5,
}
