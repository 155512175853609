/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum ParameterSource {
    None = 0,
    Konzole = 1,
    Web = 2,
    Soap = 3,
    IceCat = 4,
    CustomAikImport = 5,
    MuveCz = 6,
    Vapol = 7,
    Microsoft = 8,
    TecDoc = 9,
    AlzaRentProduct = 10,
    AutomaticWebParameters = 11,
}
