/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum SiteMapType {
    Products = 0,
    Econtent = 1,
    Reviews = 2,
    Accessories = 3,
    Categories = 4,
    Articles = 5,
    ProductGroups = 6,
    Producers = 7,
    Forums = 8,
    Bazar = 9,
    SeoParameterCategories = 10,
    ParametricCategoriesOneParam = 11,
    ParametricCategoriesTwoParams = 12,
    ParametricCategoriesProducerAndOneParam = 13,
    SeoSortedCategories = 14,
    LiveProducts = 20,
    DeadProducts = 22,
    CategoriesProducers = 24,
    CategoriesSale = 25,
    CategoriesBazaar = 26,
    DeadEcontent = 27,
    BranchPickupCategories = 28,
    SlotAccessories = 29,
    CheapBazaarProducts = 30,
    BlackFriday = 31,
    CategoryInstallments = 32,
    BeforeListingProducts = 33,
    DeadCategories = 34,
    DeadCategoriesProducers = 35,
}
