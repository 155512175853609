import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { SessionService } from 'app/common/services';
import { UserRight } from 'app/common/user-right';

@Directive({
    selector: '[appHasPermission]'
})
export class HasPermissionDirective {
    private _hasView = false;

    constructor(private readonly templateRef: TemplateRef<any>, private readonly viewContainer: ViewContainerRef, private readonly session: SessionService) {}

    @Input() set appHasPermission(value: UserRight | Array<UserRight>) {
        if (value) {
            let hasPermission = false;
            if (Array.isArray(value)) {
                hasPermission = value.some((x) => this.session.user.hasPermission(x));
            } else {
                hasPermission = this.session.user.hasPermission(value);
            }
            if (hasPermission && !this._hasView) {
                this.viewContainer.createEmbeddedView(this.templateRef);
                this._hasView = true;
            } else if (!hasPermission && this._hasView) {
                this.viewContainer.clear();
                this._hasView = true;
            }
        }
    }
}
