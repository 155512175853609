import { Injectable, OnDestroy } from '@angular/core';
import { defaultCountryTranslation } from 'app/common/constants';
import { SessionService } from 'app/common/services';
import { BehaviorSubject, Observable } from 'rxjs';
import { ICountryLocalization } from './country-localization';
import { ILanguageExtended } from './language-extended';

@Injectable({ providedIn: 'root' })
export class CountryLocalizationSwitchService implements OnDestroy {
    public currentLocalization$: Observable<ICountryLocalization>;
    public languages: Array<ILanguageExtended> = [];

    private _currentLocalization$ = new BehaviorSubject<ICountryLocalization>({ languageId: defaultCountryTranslation, countryId: null });

    constructor(private readonly session: SessionService) {
        this.currentLocalization$ = this._currentLocalization$.asObservable();
        this.changeCountry({ languageId: defaultCountryTranslation, countryId: null });

        this.languages = this.session.languages
            .filter((x) => !x.disabled)
            .map((x) => {
                const language = <ILanguageExtended>x;
                language.countries = [];
                return language;
            });
    }

    public changeCountry(localization: ICountryLocalization) {
        /*
            setTimeout() added to fix Froala problems (value not set to form control in code view) when country is changed
        */

        setTimeout(() => {
            this._currentLocalization$.next(localization);
        });
    }

    ngOnDestroy() {
        this._currentLocalization$.complete();
    }
}
