import { Component, OnInit } from '@angular/core';
import { SessionService } from 'app/common/services/session.service';
import moment from 'moment';
import { EnvironmentType } from 'app/common/enums/enums';
import { MatDialog } from '@angular/material/dialog';
import { ReleaseNotesModalComponent } from '../release-notes-modal/release-notes-modal.component';

@Component({
    selector: 'app-footer-info',
    templateUrl: './footer-info.component.html'
})
export class FooterInfoComponent implements OnInit {
    public year: number;
    public machineName: string;
    public buildNumber: string;
    public buildId: number;
    public latestTag: string;
    public showBuildInfo = false;
    constructor(private session: SessionService, private readonly dialog: MatDialog) {}

    ngOnInit() {
        this.year = moment().year();
        this.machineName = this.session.user.machineName;
        this.buildNumber = this.session.user.buildInfo.buildNumber;
        this.buildId = this.session.user.buildInfo.buildId;
        this.showBuildInfo = this.session.user.environment === EnvironmentType.Beta || this.session.user.environment === EnvironmentType.Dev;
        this.latestTag = this.session.user.latestTag;
    }

    public showReleaseNotes() {
        this.dialog.open(ReleaseNotesModalComponent, { panelClass: 'dialog-lg' });
    }
}
