/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum AlzaRadioStyle {
    None = 0,
    Default = 1,
    Primary = 2,
    Success = 3,
    Info = 4,
    Warning = 5,
    Danger = 6,
}
