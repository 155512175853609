import { Component, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'app/common/services';
import { Subscription } from 'rxjs';
import { IParametersSearchResultDto } from '../dto/parameters-search-result-dto';
import { ParameterTypeSelectDataService } from '../parameter-type-select-data.service';

@Component({
    selector: 'app-parameter-type-select-search',
    templateUrl: './parameter-type-select-search.component.html',
    styleUrls: ['./parameter-type-select-search.component.scss']
})
export class ParameterTypeSelectSearchComponent implements OnDestroy {
    public selectedTab = 'all';
    public searchedValue: string;
    public valueToSearch: string;
    public searchResult: Array<IParametersSearchResultDto> = [];
    public resultCount = 0;
    public loading = false;
    public extendedSearch = false;
    private _subscribers: Array<Subscription> = [];

    constructor(
        private readonly dataService: ParameterTypeSelectDataService,
        private readonly messageService: MessageService,
        public translateService: TranslateService
    ) {
        this._subscribers.push(
            this.messageService.getMessage('valueToSearch').subscribe((value) => {
                this.valueToSearch = value;
                this.search();
            })
        );
    }

    search() {
        if (this.valueToSearch && this.valueToSearch !== this.searchedValue) {
            this.loading = true;
            this.dataService.search(this.valueToSearch).subscribe((result) => {
                this.searchResult = result;
                this.searchedValue = this.valueToSearch;
                this.messageService.sendMessage('searchedValue', this.searchedValue);
                this.loading = false;
            });
        }

        this.extendedSearch = true;
    }

    public getResultsForTab(tabName: string) {
        let results: Array<IParametersSearchResultDto> = [];

        switch (tabName) {
            case 'categories':
                results = this.searchResult.filter((x) => x.categories && !x.parameterGroup && !x.parameterType);
                break;
            case 'groups':
                results = this.searchResult.filter((x) => x.parameterGroup && !x.parameterType);
                break;
            case 'types':
                results = this.searchResult.filter((x) => x.parameterType);
                break;
            default:
                results = this.searchResult;
        }

        this.resultCount = results ? results.length : 0;
        return results;
    }

    resultSelected(result: IParametersSearchResultDto) {
        if (!result) {
            return;
        }
        this.messageService.sendMessage(
            'categoryId',
            result.categories[result.categories.length - 1] ? result.categories[result.categories.length - 1].id : null
        );
        this.messageService.sendMessage(
            'categoryToSelect',
            result.categories[result.categories.length - 1] ? result.categories[result.categories.length - 1].id : null
        );
        this.messageService.sendMessage('groupId', result.parameterGroup ? result.parameterGroup.id : null);
        this.messageService.sendMessage('typeId', result.parameterType ? result.parameterType.id : null);
        this.extendedSearch = false;
    }

    clearFilter() {
        this.extendedSearch = false;
        if (!this.valueToSearch) {
            return;
        }
        this.valueToSearch = null;
        this.searchResult = [];
        this.searchedValue = null;
        this.messageService.sendMessage('categoryId', null);
        this.messageService.sendMessage('categoryToSelect', null);
        this.messageService.sendMessage('groupId', null);
        this.messageService.sendMessage('typeGroupId', null);
        this.messageService.sendMessage('typeId', null);
        this.messageService.sendMessage('searchedValue', null);
    }

    ngOnDestroy(): void {
        this._subscribers.forEach((subscriber) => {
            subscriber.unsubscribe();
        });
    }

    public toggleSearch() {
        this.extendedSearch = !this.extendedSearch;
    }
}
