import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';
import { IImageSelectorData, ImageSelectorModalComponent } from '../image-selector-modal/image-selector-modal.component';

const IMAGE_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ImageSelectorComponent),
    multi: true
};

@Component({
    selector: 'app-image-selector',
    styleUrls: ['./image-selector.component.scss'],
    templateUrl: './image-selector.component.html',
    providers: [IMAGE_VALUE_ACCESSOR]
})
export class ImageSelectorComponent implements ControlValueAccessor {
    private _changeFn: (value: string) => void;
    private _touchedFn: () => void;

    @Input()
    urlTitle: string;
    @Input()
    urlTitleTooltip?: string;
    @Input()
    required?: boolean;
    public disabled = false;
    public imageUrl: string;

    constructor(private matDialog: MatDialog) {
        this.required = false;
    }

    public registerOnChange(fn: any): void {
        this._changeFn = fn;
    }
    public registerOnTouched(fn: any): void {
        this._touchedFn = fn;
    }
    public setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public writeValue(imageUrl: string): void {
        this.imageUrl = imageUrl;
    }

    public changeUrl(url?: string) {
        this.imageUrl = url ? url : null;
        if (this._changeFn) {
            this._changeFn(url);
        }
        if (this._touchedFn) {
            this._touchedFn();
        }
    }

    public show() {
        this.matDialog
            .open<ImageSelectorModalComponent, IImageSelectorData>(ImageSelectorModalComponent, {
                data: { url: this.imageUrl, urlLabel: this.urlTitle, labelTooltip: this.urlTitleTooltip, required: this.required },
                panelClass: 'dialog-md'
            })
            .afterClosed()
            .pipe(filter((x) => !!x))
            .subscribe((res) => {
                this.changeUrl(res.url);
            });
    }
}
