<div class="btns" [attr.data-tid]="getTid('btnLoc')">
    <div class="grid-container" [ngStyle]="getGridColumns()">
        <span *ngFor="let c of countryArray; let index = index" class="grid-item" [ngStyle]="getBtnGridposition(c, index)" (mousedown)="selectCountry(c)">
            <button
                class="btn btn-xs"
                *ngIf="index <= shownCountries"
                [ngClass]="getButtonClass(c, index)"
                type="button"
                [attr.data-tid]="getTidItem('btnLoc', countryLocalization ? c.countryCode : c.code)"
            >
                {{ countryLocalization ? c.countryCode : c.code }}
            </button>
            <button
                class="btn btn-xs"
                *ngIf="index > shownCountries && expand"
                [ngClass]="getButtonClass(c, index)"
                [attr.data-tid]="getTidItem('btnLoc', countryLocalization ? c.countryCode : c.code)"
                type="button"
            >
                {{ countryLocalization ? c.countryCode : c.code }}
            </button>
        </span>
        <button
            [attr.data-tid]="getTidItem('btnLoc', 'expand')"
            *ngIf="this.countryArray.length > 6"
            class="btn btn-xs more-button"
            type="button"
            (click)="collapseExpand()"
            (blur)="expand = false"
        >
            <span class="invalid-count" [ngClass]="{ 'is-active': getInvalidCount() > 0 }">{{ getInvalidCount() }}</span>
            ...
        </button>
    </div>
</div>
