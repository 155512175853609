import { HttpClient } from '@angular/common/http';
import { IEshopDto } from 'app/models/eshop/eshop-dto';
import { BaseApi } from './base-api';

export class EshopApi extends BaseApi {
    public constructor(http: HttpClient) {
        super(http);
    }

    // Gets eshops from EshopCollection
    public getEshops() {
        return this.load<Array<IEshopDto>>('api/common/eshops', 'eshops');
    }
}
