<button
    [attr.data-tid]="getTid('btn')"
    [attr.id]="'btn' + id"
    #btn
    class="btn ladda-button"
    [ngClass]="buttonClass"
    [disabled]="disabled || loading"
    [class.disabled]="disabled || loading"
    data-style="zoom-out"
    [attr.type]="type"
>
    <span class="ladda-label">
        <ng-content></ng-content>
    </span>
    <span class="ladda-spinner"> </span>
</button>
