import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpMemoryCache } from 'app/common/http-memory-cache';
import { ISelectListItemDto } from 'app/models';
import { Observable, of } from 'rxjs';
import { BaseApi } from './base-api';

export class SelectListApi extends BaseApi {
    private _cache = new HttpMemoryCache();
    public constructor(http: HttpClient) {
        super(http);
    }

    // Gets sections from SectionCollection
    public getSections() {
        return this.http.get<Array<ISelectListItemDto>>('api/common/select-lists/sections');
    }

    public getSectionsCategory() {
        return this.http.get<Array<ISelectListItemDto>>('api/common/select-lists/sections-category');
    }

    // Gets segments from CommoditySegmentDictionary for given parent segment
    public getCommoditySegments(parentId = 0) {
        return this.http.get<Array<ISelectListItemDto>>(`api/common/select-lists/commodity-segments/${parentId}`);
    }

    public getSegmentPrefixes(segmentIds: number | Array<number> = 0) {
        if (segmentIds == null || (Array.isArray(segmentIds) && segmentIds.length === 0) || (!Array.isArray(segmentIds) && segmentIds == null)) {
            return of([]) as Observable<Array<ISelectListItemDto>>;
        }
        if (!Array.isArray(segmentIds)) {
            segmentIds = [segmentIds];
        }
        return this.cache.get('SelectListApi.getSegmentPrefixes' + segmentIds.sort().join('.'), () =>
            this.http.post<Array<ISelectListItemDto>>('api/commodity-prefix/for-segments', segmentIds)
        );
    }

    // Gets users from AdminUserCollection by user type
    public getUsers(userType: 'All' | 'Employes' | 'Externist' | 'UserAndExternist', fullNameOrder: 'FullNameLastFirst' | 'FullName' = 'FullNameLastFirst') {
        return this.load<Array<ISelectListItemDto>>(`api/common/select-lists/users/${userType}/${fullNameOrder}`, `users_${userType}_${fullNameOrder}`);
    }

    // Gets badges from BadgeCollection
    public getBadges() {
        return this.http.get<Array<ISelectListItemDto>>('api/common/select-lists/badges');
    }

    // Gets platforms from PlatformCollection
    public getPackUnits() {
        return this.http.get<Array<ISelectListItemDto>>('api/common/select-lists/pack-units');
    }

    // Gets platforms from PlatformCollection
    public getPlatforms() {
        return this.load<Array<ISelectListItemDto>>('api/common/select-lists/platforms', 'platforms');
    }

    // Gets countries from CountryCollection
    public getCountries() {
        return this.load<Array<ISelectListItemDto>>('api/common/select-lists/countries', 'countries');
    }

    // Gets default countries for all eshops from CountryCollection
    public getEshopCountries() {
        return this.http.get<Array<ISelectListItemDto>>('api/common/select-lists/eshop-countries');
    }

    // Gets default countries for translation from CountryCollection
    public getTranslationCountries() {
        return this.http.get<Array<ISelectListItemDto>>('api/common/select-lists/translation-countries');
    }

    // Gets all commodityprefixes
    public getCommodityPrefixes() {
        return this.http.get<Array<ISelectListItemDto>>('api/common/select-lists/commodity-prefixes');
    }

    // Gets tags
    public getTags() {
        return this.http.get<Array<ISelectListItemDto>>('api/common/select-lists/tags');
    }

    // Gets user agencies
    public getUserAgencies() {
        return this.http.get<Array<ISelectListItemDto>>('api/common/select-lists/user-agencies');
    }

    // Gets commodity producers
    public getCommodityProducers() {
        return this.http.get<Array<ISelectListItemDto>>('api/common/select-lists/commodity-producers');
    }

    public getSegmentCommodityProducers(segmentId: number) {
        return this.http.get<Array<ISelectListItemDto>>(`api/common/select-lists/commodity-producers/segment/${segmentId}`);
    }

    // Gets promo sections
    public getPromoSections() {
        return this.http.get<Array<ISelectListItemDto>>('api/common/select-lists/promo-sections');
    }

    // Gets Articles
    public getArticles(id: number, searchValue: string) {
        let params = new HttpParams();

        if (id) {
            params = params.set('id', id);
        }
        if (searchValue) {
            params = params.set('searchValue', searchValue);
        }
        return this.http.get<Array<ISelectListItemDto>>(`api/articles/search-select-list/`, { params: params });
    }

    // Gets action categoriess
    public getActionCategories() {
        return this.http.get<Array<ISelectListItemDto>>('api/common/select-lists/action-categories');
    }
    // Gets Mailing Actions
    public getMailingActions() {
        return this.http.get<Array<ISelectListItemDto>>('api/common/select-lists/mailing-actions');
    }

    // Gets Generated category
    public getGeneratedCategory(id: number, searchValue: string) {
        let params = new HttpParams();

        if (id) {
            params = params.set('id', id);
        }
        if (searchValue) {
            params = params.set('searchValue', searchValue);
        }
        return this.http.get<Array<ISelectListItemDto>>(`api/common/select-list/generated-categories`, { params: params });
    }
}
