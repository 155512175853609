import { TranslateService } from '@ngx-translate/core';
import { IParameterFilterDto } from 'app/models/parameter';
import { IParameterPreviewType, IParameterPreviewValueHelper, IParemeterPreviewGroup } from './models';

export function createParameterPreview(values: Array<IParameterFilterDto>, translate: TranslateService) {
    if (!values) {
        return [];
    }
    const displayParams: Array<IParemeterPreviewGroup> = [];
    for (const el of values) {
        if (el.$state !== 'deleted') {
            let group: IParemeterPreviewGroup = displayParams.find((dp) => dp.groupId === el.filterInfo.groupId);
            if (!group) {
                group = {
                    groupId: el.filterInfo.groupId,
                    groupName: el.filterInfo.groupName,
                    types: []
                };
                displayParams.push(group);
            }
            let type: IParameterPreviewType = group.types.find((t) => t.typeId === el.filterInfo.typeId);
            if (!type) {
                type = {
                    typeId: el.filterInfo.typeId,
                    typeName: el.filterInfo.typeName,
                    values: [],
                    order: el.filterInfo.typeOrder
                };
                group.types.push(type);
            }
            const value: IParameterPreviewValueHelper = {
                id: el.id,
                value: el.value,
                valueFrom: el.valueFrom,
                valueTo: el.valueTo,
                unitName: el.filterInfo.unitName,
                typeEnumName: el.filterInfo.typeEnumName,
                typeEnumParentNames: el.filterInfo.isHierarchic ? getParentNames(el, values) : null,
                cableLeftTypeEnumId: el.filterInfo.cableLeftTypeEnumId,
                cableLeftTypeEnumName: el.filterInfo.cableLeftTypeEnumName,
                cableRightTypeEnumId: el.filterInfo.cableRightTypeEnumId,
                cableRightTypeEnumName: el.filterInfo.cableRightTypeEnumName
            };

            type.values.push({ id: value.id, text: getValueText(value, translate) });
        }
    }

    displayParams.forEach((x) => x.types.sort((x1, x2) => x1.order - x2.order));

    return displayParams;
}

function getValueText(item: IParameterPreviewValueHelper, translate: TranslateService) {
    const unit = (itm: IParameterPreviewValueHelper) => {
        return `${itm.unitName ? ` ${itm.unitName}` : ''}`;
    };
    let ret = '';
    if (item.value) {
        ret = `${item.value}${unit(item)}`;
    } else if (item.typeEnumName) {
        ret = `${item.typeEnumName}${unit(item)}`;
        if (item.typeEnumParentNames?.length) {
            ret += ` (${item.typeEnumParentNames.reverse().join('/')})`;
        }
    } else if (item.valueFrom || item.valueTo) {
        if (item.valueFrom !== null) {
            ret = `${translate.instant('Category_Parameters_ValueFrom')} ${item.valueFrom}${unit(item)}${item.valueTo !== null ? ' - ' : ''}`;
        }
        if (item.valueTo !== null) {
            ret += `${translate.instant('Category_Parameters_ValueTo')} ${item.valueTo}${unit(item)}`;
        }
    } else if (item.cableLeftTypeEnumId || item.cableRightTypeEnumId) {
        if (item.cableLeftTypeEnumName) {
            ret = `${item.cableLeftTypeEnumName}${unit(item)}${item.cableRightTypeEnumId !== null ? ' - ' : ''}`;
        }
        if (item.cableRightTypeEnumId) {
            ret = `${ret}${item.cableRightTypeEnumName}${unit(item)}`;
        }
    }
    return ret;
}

function getParentNames(value: IParameterFilterDto, values: Array<IParameterFilterDto>, parentNames: Array<string> = null) {
    if (!parentNames) {
        parentNames = [];
    }

    if (value.filterInfo?.typeEnumParentId) {
        const parent = values.find((x) => x.parameterTypeEnumId === value.filterInfo?.typeEnumParentId);

        if (parent) {
            parentNames.push(parent.filterInfo.typeEnumName);
            getParentNames(parent, values, parentNames);
        }
    }

    return parentNames;
}
