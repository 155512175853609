<div
    class="stated-item"
    [attr.data-tid]="'remCtnt'"
    [class.disabled]="disabled"
    [class.deleted]="state === 'deleted'"
    [class.added]="state === 'added'"
    *ngIf="
        (state !== 'deleted' && !ignoreState) ||
        deleteButtonVisible ||
        (state === 'deleted' && restorable && !ignoreState) ||
        restoreButtonVisible ||
        restorable
    "
    [class.row-content]="styledAs === 'row'"
    [class.none]="styledAs === 'none'"
>
    <a
        (click)="deleteAction($event)"
        class="rc-action-btn delete"
        *ngIf="(state !== 'deleted' && !ignoreState) || deleteButtonVisible"
        [attr.data-tid]="deleteButtonTid ? deleteButtonTid : 'btnDelete'"
        [cmsTooltip]="deleteTitle ? deleteTitle : ''"
        [ngClass]="buttonClass"
        ><i class="fas fa-times"></i>
    </a>
    <a
        (click)="restoreAction($event)"
        class="rc-action-btn restore"
        *ngIf="(state === 'deleted' && restorable && !ignoreState) || restoreButtonVisible"
        [attr.data-tid]="restoreButtonTid ? restoreButtonTid : 'btnRestore'"
        [cmsTooltip]="restoreTitle ? restoreTitle : ''"
        [ngClass]="buttonClass"
        ><i class="fas fa-undo"></i>
    </a>
    <ng-content></ng-content>
</div>
