import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { CommonDataService } from 'app/common/services';
import { map } from 'rxjs/operators';

@Pipe({
    name: 'language',
    pure: false
})
export class LanguagePipe implements PipeTransform {
    private _displayText = '';
    private _isLoading = false;
    private _lastValue: any;

    constructor(private readonly commonService: CommonDataService, private changeDetector: ChangeDetectorRef) {}

    transform(value: any): any {
        if (this._lastValue !== value) {
            this._displayText = null;
        }
        this._lastValue = value;
        if (this._displayText || this._isLoading) {
            return this._displayText;
        }
        this._isLoading = true;

        this.commonService.language
            .getAllLanguages()
            .pipe(map((languages) => languages.find((lang) => lang.id === value)))
            .subscribe((languages) => {
                if (languages) {
                    this._displayText = languages.name;
                    this.changeDetector.markForCheck();
                }
                this._isLoading = false;
            });
        return this._displayText;
    }
}
