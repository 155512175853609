<div class="breadcrumbs">
    <span *ngFor="let category of categoryPath.path" (click)="categorySelected = category"> / {{ category.name }} </span>
</div>

<div class="row">
    <div *ngIf="categoryList" class="col-xs-4">
        <h2>{{ 'CompatibleParameterValues_Categories' | translate }}</h2>
        <div class="modalCategoryList">
            <div class="item" *ngIf="categoryPath.isPathVisible" (click)="categorySelected = categoryPath.previousNode">..</div>
            <div class="item" *ngFor="let category of categoryList" (click)="categorySelected = category">{{ category.name }}</div>
            <div *ngIf="!categoryList || !categoryList.length">
                <h3 class="text-muted text-center">{{ 'CompatibleParameterValues_Empty' | translate }}</h3>
            </div>
        </div>
    </div>

    <div *ngIf="parameterGroupList" class="col-xs-4">
        <h2>{{ 'CompatibleParameterValues_Groups' | translate }}</h2>
        <div *ngIf="!!(parameterGroupList && parameterGroupList.length > 0)" class="modalGroupList">
            <div
                *ngFor="let group of parameterGroupList"
                (click)="parameterGroupSelected = group"
                [class.selected]="!!(parameterGroupSelected && parameterGroupSelected.id === group.id)"
                class="item"
            >
                {{ group.name }}
            </div>
        </div>
        <div *ngIf="!parameterGroupList || !parameterGroupList.length">
            <h3 class="text-muted text-center">{{ 'CompatibleParameterValues_Empty' | translate }}</h3>
        </div>
    </div>

    <div *ngIf="parameterList" class="col-xs-4">
        <h2>{{ 'CompatibleParameterValues_Types' | translate }}</h2>
        <div class="modalParameterList">
            <div
                [class.selected]="!!(parameterSelected && parameterSelected.id === parameter.id)"
                *ngFor="let parameter of parameterListWithoutCurrent"
                (click)="selectParameter(parameter)"
                class="item"
            >
                {{ parameter.name }}
            </div>
            <div *ngIf="!parameterListWithoutCurrent || !parameterListWithoutCurrent.length">
                <h3 class="text-muted text-center">{{ 'CompatibleParameterValues_Empty' | translate }}</h3>
            </div>
        </div>
    </div>
</div>
