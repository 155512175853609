import { ErrorMessages, FormInputBase } from '@alza/cms-components';
import { Component, forwardRef, Host, Input, Optional, SkipSelf } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import 'moment-timezone';
const INPUT_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ColorPickerComponent),
    multi: true
};
@Component({
    selector: 'app-color-picker',
    templateUrl: './color-picker.component.html',
    styleUrls: ['./color-picker.component.scss'],
    providers: [INPUT_VALUE_ACCESSOR],
    viewProviders: [{ provide: ErrorMessages, deps: [TranslateService], useClass: ErrorMessages }]
})
export class ColorPickerComponent extends FormInputBase implements ControlValueAccessor {
    @Input()
    public type = 'text';
    public value = '';
    constructor(
        @Optional()
        @Host()
        @SkipSelf()
        controlContainer: ControlContainer,
        errorMessages: ErrorMessages
    ) {
        super(controlContainer, errorMessages, { tidPrefix: 'cp' });
    }
    updateValue(obj: any): void {
        this.value = obj;
    }

    onValueChange(value: any) {
        value = (value && (<string>value).startsWith('#')) || !value ? value : `#${value}`;
        this.value = value;
        this.raiseChange(this.value);
        this.raiseTouched();
    }
}
