/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum PriceGroup {
    B2BSilver = 1,
    B2BGold = 2,
    Silver = 3,
    Gold = 4,
    Bronze = 5,
    B2BBronze = 6,
    Minimal = 7,
    Default = 8,
    Evolution = 11,
    B2BEshop = 12,
}
