export interface IButtonEvent {
    navigateToReturnRoute: (route?: Array<any>) => void;
}

export interface IButtonEventRoute {
    route: Array<any>;
    relativeToActivated?: boolean;
}

class ButtonEvent implements IButtonEvent {
    public readonly event: MouseEvent;
    public readonly next: Promise<IButtonEventRoute>;
    protected resolve: (value: IButtonEventRoute) => void;
    constructor(event: MouseEvent) {
        this.event = event;
        this.next = new Promise<IButtonEventRoute>((resolve) => {
            this.resolve = resolve;
        });
    }

    public navigateToReturnRoute(route: Array<any> = null) {
        this.resolve({ route });
    }
}

export class DeleteButtonEvent extends ButtonEvent {
    constructor(event: MouseEvent) {
        super(event);
    }
}

export class StornoButtonEvent extends ButtonEvent {
    private _defaultPrevented = false;

    constructor(event: MouseEvent) {
        super(event);
    }

    public preventDefault() {
        this._defaultPrevented = true;
    }

    public get defaultPrevented() {
        return this._defaultPrevented;
    }
}

export class CustomButtonEvent extends ButtonEvent {
    constructor(event: MouseEvent) {
        super(event);
    }
}

export class SaveButtonEvent extends ButtonEvent {
    public readonly action: 'save' | 'saveAndClose';
    constructor(event: MouseEvent, action: 'save' | 'saveAndClose') {
        super(event);
        this.action = action;
    }

    public navigateAfterSave(id: number, tab?: string) {
        if (this.action === 'saveAndClose') {
            this.resolve(null);
        } else {
            if (window.location.href.includes('/history/')) {
                this.resolve(null);
            } else {
                this.resolve({ route: tab ? ['..', id, tab] : ['..', id], relativeToActivated: true });
            }
        }
    }
}
