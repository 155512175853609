import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'app-page-box',
    templateUrl: './page-box.component.html',
    styleUrls: ['./page-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageBoxComponent {
    @Input()
    title: string = null;
}
