import { Directive, inject, Input, OnDestroy } from '@angular/core';
import { ICountryLocalizationDto, ICountryLocalizedEntityDto } from 'app/models';
import { distinct, Subject, takeUntil } from 'rxjs';
import { ICountryLocalization } from '../country-localization-switch/country-localization';
import { CountryLocalizationSwitchService } from '../country-localization-switch/country-localization-switch.service';
import { DeleteButtonEvent, SaveButtonEvent } from '../page-footer/button-events';
import { FormHelper } from './form-helper.service';
import { FormArray, FormGroup, FormValue } from './typed-controls';
import { TypedFormService } from './typed-form-service';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class TypedDetailComponent<T> implements OnDestroy {
    public readonly unsubscribe = new Subject<void>();
    protected readonly notFoundControls$ = new Subject<string>();
    private readonly _formHelper = inject(FormHelper);
    public get form() {
        return this.formService.form;
    }

    constructor(protected readonly formService: TypedFormService<T>) {
        this.notFoundControls$.pipe(takeUntil(this.unsubscribe), distinct()).subscribe((path) => {
            console.warn(`Control ${path} was not found in form group`);
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.notFoundControls$.complete();
    }

    public control<K extends keyof T>(path: K): FormValue<T[K]> {
        const control = this.form.get(path);
        if (!control) {
            this.notFoundControls$.next(String(path));
        }
        return control;
    }

    public save(event: SaveButtonEvent, tab?: string) {
        const reload = event.action === 'save';

        const value = this._formHelper.getRawDirtyValue(this.form) as T;
        this.formService.save(value, reload).subscribe((res: any) => {
            event.navigateAfterSave(res.id, tab);
        });
    }

    public delete(event: DeleteButtonEvent) {
        this.formService.delete(false).subscribe(() => event.navigateToReturnRoute());
    }
}

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class LocalizedTypedDetailComponent<
    T extends ICountryLocalizedEntityDto<L>,
    L extends ICountryLocalizationDto
> extends TypedDetailComponent<T> {
    public get selectedLocalization() {
        return this.#selectedLocalization;
    }

    public get localizationArray(): FormArray<L> {
        return this.form.get('localization') as FormArray<L>;
    }

    #selectedLocalization: ICountryLocalization;
    #formHelper = inject(FormHelper);

    constructor(protected readonly formService: TypedFormService<T>) {
        super(formService);
        inject(CountryLocalizationSwitchService)
            .currentLocalization$.pipe(takeUntil(this.unsubscribe))
            .subscribe((x) => (this.#selectedLocalization = x));
    }

    public localizedControl<K extends keyof L>(path: K, countryLocalization?: ICountryLocalization): FormValue<L[K]> {
        const localization = this.form.get('localization') as FormArray<L>;
        const index = this.#formHelper.localizationToIndex(countryLocalization ?? this.#selectedLocalization);
        const control = localization.at(index).get([String(path)]) as FormValue<L[K]>;
        if (!control) {
            this.notFoundControls$.next(`localization.${index}.${String(path)}`);
        }
        return control;
    }
}

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class TypedComponent<T> implements OnDestroy {
    public readonly unsubscribe = new Subject<void>();
    protected readonly notFoundControls$ = new Subject<string>();
    private readonly _formHelper = inject(FormHelper);
    @Input()
    public form?: FormGroup<T>;

    constructor() {
        this.notFoundControls$.pipe(takeUntil(this.unsubscribe), distinct()).subscribe((path) => {
            console.warn(`Control ${path} was not found in form group`);
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.notFoundControls$.complete();
    }

    public control<K extends keyof T>(path: K): FormValue<T[K]> {
        const control = this.form.get(path);
        if (!control) {
            this.notFoundControls$.next(String(path));
        }
        return control;
    }
}

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class LocalizedTypedComponent<T extends ICountryLocalizedEntityDto<L>, L extends ICountryLocalizationDto> extends TypedComponent<T> {
    public get selectedLocalization() {
        return this.#selectedLocalization;
    }

    public get localizationArray(): FormArray<L> {
        return this.form.get('localization') as FormArray<L>;
    }

    #selectedLocalization: ICountryLocalization;
    #formHelper = inject(FormHelper);

    constructor() {
        super();
        inject(CountryLocalizationSwitchService)
            .currentLocalization$.pipe(takeUntil(this.unsubscribe))
            .subscribe((x) => (this.#selectedLocalization = x));
    }

    public localizedControl<K extends keyof L>(path: K, countryLocalization?: ICountryLocalization): FormValue<L[K]> {
        const localization = this.form.get('localization') as FormArray<L>;
        const index = this.#formHelper.localizationToIndex(countryLocalization ?? this.#selectedLocalization);
        const control = localization.at(index).get([String(path)]) as FormValue<L[K]>;
        if (!control) {
            this.notFoundControls$.next(`localization.${index}.${String(path)}`);
        }
        return control;
    }
}
