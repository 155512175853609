import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IConfigurationInfo, IConfigurationSelect } from '../configuration-info';

@Component({
    templateUrl: './configuration-select-modal.html',
    styleUrls: ['./configuration-select-modal.scss']
})
export class ConfigurationSelectModalComponent implements OnInit {
    public selectedItems: Array<IConfigurationInfo> = [];
    public receivedItems: Array<IConfigurationInfo> = [];

    public items: Array<IConfigurationInfo>;

    public searchString?: string;
    // Defines whether the select is supposed to contain the beta or alfa configurations (default: false)
    public isBeta: boolean;
    // If modal is suppiosed to be with search (default: false)
    public search: boolean;
    public loading = true;

    constructor(
        private http: HttpClient,
        @Inject(MAT_DIALOG_DATA) private readonly context: IConfigurationSelect,
        private readonly dialogRef: MatDialogRef<ConfigurationSelectModalComponent, Array<IConfigurationInfo>>
    ) {}

    ngOnInit() {
        this.search = this.context.search;
        this.receivedItems = this.context.items;
        this.isBeta = this.context.isBeta;

        const selectedIds = this.context.items.map((x) => x.id);

        const url = 'api/new-configuration/select-list';
        this.http.post(url, { selectedIds: selectedIds, isBeta: this.isBeta }).subscribe((res) => {
            this.setItems(res);
            this.selectedItems = this.getSelectedItems();
            this.loading = false;
        });
    }

    setItems(res: any) {
        const rootItems = [];
        for (const i of res) {
            rootItems.push({ id: i.eshopId, name: i.eshopName, children: [], isRoot: true });
        }
        this.items = rootItems.filter((item, index, self) => index === self.findIndex((t) => t.id === item.id && t.name === item.name));
        for (const i of this.items) {
            for (const r of res) {
                if (i.id === r.eshopId) {
                    i.children.push(r);
                }
            }
        }
    }

    searchWord() {
        if (!this.searchString) {
            return;
        }
        const foundItems = new Set();
        this.clearSearch(false);

        const searchChildren = (children) => {
            for (const el of children) {
                let lowerName = el.name ? el.name : el.sectionName;
                lowerName = lowerName.toLowerCase();
                if (lowerName.includes(this.searchString)) {
                    el.isFound = true;
                    el.isExpanded = true;
                    foundItems.add(el);
                }
                searchChildren(el.children);
            }
        };

        for (const el of this.items) {
            const lowerName = el.name.toLowerCase();
            if (lowerName.includes(this.searchString)) {
                el.isFound = true;
                el.isExpanded = true;
                foundItems.add(el);
            }
            searchChildren(el.children);
        }

        const resultArr = Array.from(foundItems);
        for (const res of resultArr) {
            this.findParent(res);
        }
    }

    findParent(el) {
        const flat: Array<any> = [];

        const flatten = (items) => {
            for (const item of items) {
                flat.push(item);
                if (item.children) {
                    flatten(item);
                }
            }
        };

        for (const item of this.items) {
            if (item.children) {
                flatten(item.children);
            }
            flat.push(item);
        }

        for (const item of flat) {
            if (item.id === el.id && item.sectionName === el.sectionName) {
                item.isSelected = true;
            }
            if (el.eshopId === item.id) {
                item.isExpanded = true;
            }
        }
    }

    clearSearch(withString: boolean) {
        if (withString) {
            this.searchString = '';
        }

        const searchChildren = (children) => {
            for (const el of children) {
                el.isFound = false;
                el.isExpanded = false;
                searchChildren(el.children);
            }
        };

        for (const el of this.items) {
            el.isFound = false;
            el.isExpanded = false;
            searchChildren(el.children);
        }
        this.getSelectedItems();
    }

    public ok() {
        this.dialogRef.close(this.getSelectedItems());
    }

    public getSelectedItems(): Array<IConfigurationInfo> {
        const res: Array<IConfigurationInfo> = [];
        const select = (input: Array<IConfigurationInfo>) => {
            input.forEach((i) => {
                if (i.isSelected) {
                    res.push(i);
                }
                if (i.children) {
                    select(i.children);
                }
            });
        };
        if (this.receivedItems) {
            select(this.receivedItems);
        }
        select(this.items);

        for (const el of res) {
            this.findParent(el);
        }
        return res;
    }
}
