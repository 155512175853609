import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IFileSystemFileDto } from 'app/models/file-system';

export interface IFileExistsData {
    server: IFileSystemFileDto;
    client: IFileSystemFileDto;
}

@Component({
    selector: 'app-file-input-file-exists',
    templateUrl: './file-input-file-exists.component.html',
    styleUrls: ['./file-input-file-exists.component.scss']
})
export class FileInputFileExistsComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: IFileExistsData,
        private readonly dialogRef: MatDialogRef<FileInputFileExistsComponent, IFileSystemFileDto>,
        private readonly http: HttpClient
    ) {}

    public rename() {
        this.http.get<{ fileName: string }>('/api/file-system/unique-filename', { params: { path: this.data.client.path } }).subscribe((res) => {
            this.data.client.name = res.fileName;
            const path = this.data.client.path.split('\\');
            path[path.length - 1] = res.fileName;
            this.data.client.path = path.join('\\');
            this.dialogRef.close(this.data.client);
        });
    }

    public keep() {
        this.dialogRef.close(this.data.server);
    }

    public rewrite() {
        this.dialogRef.close(this.data.client);
    }
}
