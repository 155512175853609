import { Directive, ElementRef } from '@angular/core';

@Directive({
    selector: 'cms-textarea[appInitialfocus],cms-input[appInitialfocus]'
})
export class InitialFocusDirective {
    constructor(element: ElementRef<HTMLElement>) {
        setTimeout(() => {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
            const input = element.nativeElement.querySelector('textarea,input') as HTMLElement;
            if (input) {
                input.focus();
            }
        });
    }
}
