import { ErrorMessages, FormInputBase } from '@alza/cms-components';
import { AfterViewInit, Component, ElementRef, Host, Optional, SkipSelf, ViewChild, forwardRef } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Feature, Map, View } from 'ol';
// eslint-disable-next-line @typescript-eslint/naming-convention
import Geocoder from 'ol-geocoder';
import { Coordinate } from 'ol/coordinate';
import { WKT } from 'ol/format';
import { Circle, Geometry, GeometryCollection, LineString, MultiLineString, MultiPoint, MultiPolygon, Point, Polygon } from 'ol/geom';
import { Type } from 'ol/geom/Geometry';
import { fromCircle } from 'ol/geom/Polygon';
import { Draw, Modify, Select, defaults as defaultInteractions } from 'ol/interaction';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { getPointResolution } from 'ol/proj';
import { OSM, Vector as VectorSource } from 'ol/source';
import { OlStyles } from './ol-styles';

const VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OpenLayersComponent),
    multi: true
};

@Component({
    selector: 'app-open-layers',
    templateUrl: './open-layers.component.html',
    styleUrls: ['./open-layers.component.scss'],
    providers: [VALUE_ACCESSOR],
    viewProviders: [{ provide: ErrorMessages, deps: [TranslateService], useClass: ErrorMessages }]
})
export class OpenLayersComponent extends FormInputBase<string> implements ControlValueAccessor, AfterViewInit {
    readonly dataProjection = 'EPSG:4326';
    readonly featureProjection = 'EPSG:3857';
    readonly defaultZoom = 7;
    readonly maxZoom = 17;
    public map: Map;
    private _draw: Draw;
    private _modify: Modify;
    private _source: VectorSource;
    private _format = new WKT();
    private _geometryTypes: Array<Type> = [
        'Point',
        'LineString',
        'LinearRing',
        'Polygon',
        'MultiPoint',
        'MultiLineString',
        'MultiPolygon',
        'GeometryCollection',
        'Circle'
    ];
    private _multiGeometries: Array<Type> = ['Point', 'LineString', 'Polygon'];
    private _joinableGeometries: Array<Type> = ['MultiPoint', 'MultiLineString', 'MultiPolygon'];
    public drawType: Type;
    public value = null;
    private _editInitialized = false;

    @ViewChild('map')
    private _mapElement: ElementRef<HTMLElement>;

    constructor(
        @Optional()
        @Host()
        @SkipSelf()
        controlContainer: ControlContainer,
        errorMessages: ErrorMessages,
        private readonly translate: TranslateService
    ) {
        super(controlContainer, errorMessages, { tidPrefix: 'ol' });
    }

    ngAfterViewInit() {
        const raster = new TileLayer({
            source: new OSM()
        });

        this._source = new VectorSource({ wrapX: false });
        const vector = new VectorLayer({
            source: this._source,
            style: OlStyles.getStyles()
        });

        const select = new Select();
        this._modify = new Modify({
            features: select.getFeatures()
        });

        const geocoder = new Geocoder('nominatim', {
            provider: 'osm',
            key: '',
            lang: 'cs-CZ',
            placeholder: this.translate.instant('OpenLayers_Search'),
            targetType: 'glass-button',
            autoComplete: true,
            autoCompleteMinLength: 3,
            autoCompleteTimeout: 300,
            countrycodes: 'CZ',
            limit: 5,
            keepOpen: false
        });

        this.map = new Map({
            target: this._mapElement.nativeElement,
            layers: [raster, vector],
            interactions: defaultInteractions({ mouseWheelZoom: false }).extend([select, this._modify]),
            view: new View({
                center: [1722659.0465309955, 6415152.24626576],
                zoom: this.defaultZoom,
                maxZoom: this.maxZoom
            })
        });
        this.map.addControl(geocoder);
        this.addDraw();
        this.registerModifyEvents();
        this.registerVectorEvents();

        if (this.value && !this._editInitialized) {
            this.editObject();
        }
    }

    updateValue(obj: string): void {
        if (obj && obj !== this.value) {
            this.value = obj;
            if (this.map && !this._editInitialized) {
                this.editObject();
            }

            if (this.disabled) {
                this.map?.removeInteraction(this._modify);
            }
        }
    }

    onValueChange(value: any) {
        this.value = value;
        this.raiseChange(value);
        this.raiseTouched();
    }

    private editObject() {
        this._editInitialized = true;
        let feature = null;

        if (!this.value.startsWith('{')) {
            feature = this._format.readFeature(this.value, {
                dataProjection: this.dataProjection,
                featureProjection: this.featureProjection
            });

            this._source = new VectorSource({
                features: [feature]
            });
        } else {
            const json = JSON.parse(this.value);
            feature = new WKT().readFeature(json.wkt, {
                dataProjection: this.dataProjection,
                featureProjection: this.featureProjection
            });

            const pointFeature = new WKT().readFeature(json.wkt, {
                dataProjection: this.dataProjection,
                featureProjection: this.featureProjection
            });

            if (json.radius && feature.getGeometry().getType() === 'Point') {
                feature.setGeometry(
                    new Circle(
                        feature.getGeometry().getCoordinates(),
                        json.radius / getPointResolution(this.featureProjection, 1, feature.getGeometry().getCoordinates(), 'm')
                    )
                );
            }

            this._source = new VectorSource({
                features: [feature, pointFeature]
            });
        }

        const layers = this.map.getLayers().getArray();
        if (layers.length > 1) {
            for (let i = 1; i < layers.length; i++) {
                this.map.removeLayer(layers[i]);
            }
        }

        const vector = new VectorLayer({
            source: this._source,
            style: OlStyles.getStyles()
        });

        this.map.addLayer(vector);
        if (!this._draw) {
            this.addDraw();
        }
        this.map.getView().fit(feature.getGeometry().getExtent(), { size: this.map.getSize() });
        this.map.getView().setZoom(this.map.getView().getZoom() - 1);
        this.registerVectorEvents();
    }

    public onTypeChange() {
        if (this._draw) {
            this.map.removeInteraction(this._draw);
        }
        this.addDraw();
    }

    private addDraw() {
        if (this.drawType) {
            this._draw = new Draw({
                source: this._source,
                type: this.drawType
            });
            this.map.addInteraction(this._draw);
            this.registerDrawEvents();
        }
    }

    private registerModifyEvents() {
        if (this._modify) {
            this._modify.on('modifyend', () => {
                this.getVectorValue();
            });
        }
    }

    private registerDrawEvents() {
        if (this._draw) {
            this._draw.on('drawend', () => {
                this.getVectorValue();
            });
        }
    }

    private registerVectorEvents() {
        if (this._source) {
            this._source.on('addfeature', () => {
                this.getVectorValue();
            });
        }
    }

    private getVectorValue() {
        const features = this._source?.getFeatures();
        if (features.length) {
            const geometries = features.map((feature: Feature) => feature.getGeometry());
            const resultGeometry = this.mergeGeometries(geometries).clone();
            const transformedGeometry = resultGeometry.transform(this.featureProjection, this.dataProjection);
            this.onValueChange(this._format.writeGeometry(transformedGeometry));
        }
    }

    private mergeGeometries(geometries: Array<Geometry>): Geometry {
        // convert circle to polygon
        for (let i = 0; i < geometries.length; i++) {
            const geo = geometries[i];
            if (geo.getType() === 'Circle') {
                geometries[i] = fromCircle(geo as Circle);
            }
        }

        // join single types to multitypes
        let multi: Array<Geometry> = [];
        this._geometryTypes.forEach((type) => {
            const geometriesWithType = geometries.filter((x) => x.getType() === type);
            if (geometriesWithType.length > 1 && type in this._multiGeometries) {
                switch (type) {
                    case 'Point':
                        multi.push(new MultiPoint(geometriesWithType.map((x: Point) => x.getCoordinates())));
                        break;
                    case 'LineString':
                        multi.push(new MultiLineString(geometriesWithType.map((x: LineString) => x.getCoordinates())));
                        break;
                    case 'Polygon':
                        multi.push(new MultiPolygon(geometriesWithType.map((x: Polygon) => x.getCoordinates())));
                        break;
                }
            } else if (geometriesWithType.length) {
                multi = multi.concat(geometriesWithType);
            }
        });

        // join mutlitypes
        let joined: Array<Geometry> = [];
        this._geometryTypes.forEach((type) => {
            const geometriesToJoin = multi.filter((x) => x.getType() === type);
            if (geometriesToJoin.length > 1 && type in this._joinableGeometries) {
                switch (type) {
                    case 'MultiPoint': {
                        let pointCoordinates: Array<Coordinate> = [];
                        geometriesToJoin.forEach((geom: MultiPoint) => {
                            pointCoordinates = pointCoordinates.concat(geom.getCoordinates());
                        });
                        joined.push(new MultiPoint(pointCoordinates));
                        break;
                    }
                    case 'MultiLineString': {
                        let lineCoordinates: Array<Array<Coordinate>> = [];
                        geometriesToJoin.forEach((geom: MultiLineString) => {
                            lineCoordinates = lineCoordinates.concat(geom.getCoordinates());
                        });
                        joined.push(new MultiLineString(lineCoordinates));
                        break;
                    }
                    case 'MultiPolygon': {
                        let polygonCoordinates: Array<Array<Array<Coordinate>>> = [];
                        geometriesToJoin.forEach((geom: MultiPolygon) => {
                            polygonCoordinates = polygonCoordinates.concat(geom.getCoordinates());
                        });
                        joined.push(new MultiPolygon(polygonCoordinates));
                    }
                }
            } else if (geometriesToJoin.length) {
                joined = joined.concat(geometriesToJoin);
            }
        });

        if (!joined.length) {
            return null;
        } else if (joined.length === 1) {
            return joined[0];
        } else {
            return new GeometryCollection(joined);
        }
    }
}
