/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum FeedbackType {
    None = 0,
    BugInApplication = 1,
    MyOpinion = 2,
    ProductDescriptionBug = 3,
    BadShipping = 4,
    FacilityBug = 5,
}
