<div class="form-group" [class.mb-0]="!labelText" [ngClass]="formGroupClass">
    <cms-label
        *ngIf="labelText"
        [required]="labelRequired"
        [for]="id"
        [text]="labelText"
        [tooltip]="labelTooltip"
        [tooltipDirection]="labelTooltipDirection"
        [tooltipIcon]="labelTooltipIcon"
        [class]="labelClass"
    ></cms-label>
    <div #map [attr.id]="id" class="map"></div>
    <cms-select
        class="col-xs-6 col-sm-4 col-md-3 col-lg-2 col-xl-2 object-manipulation"
        [labelText]="'OpenLayers_AddEditObject' | translate"
        [labelClass]="'color-black'"
        [items]="[
            { value: 'Polygon', name: 'OpenLayers_AddPolygon' | translate },
            { value: 'Circle', name: 'OpenLayers_AddCircle' | translate },
            { value: 'Point', name: 'OpenLayers_AddPoint' | translate }
        ]"
        [disabled]="disabled"
        [placeholder]="'OpenLayers_AreasEdit' | translate"
        [id]="'drawType_' + id"
        [clearable]="true"
        bindLabel="name"
        bindValue="value"
        [(ngModel)]="drawType"
        (ngModelChange)="onTypeChange()"
        appendTo="body"
    ></cms-select>
    <cms-field-errors [submitted]="submitted" [control]="control"></cms-field-errors>
</div>
