/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum ParameterTypeType {
    Text = 0,
    Number = 1,
    Decimal = 2,
    Enum = 3,
    MultiChoiceEnum = 4,
    Cable = 5,
    DateTime = 6,
    TimeSpan = 7,
    Geo = 8,
}
