import { ErrorMessages, FormInputBase } from '@alza/cms-components';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Host, Input, OnDestroy, OnInit, Optional, Output, SkipSelf, forwardRef } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DialogsService } from 'app/common/services/dialogs.service';
import { FroalaMessageService } from 'app/components/forms/froala/froala-message.service';
// eslint-disable-next-line @typescript-eslint/naming-convention
import FroalaEditor from 'froala-editor';
import 'froala-editor/js/languages/en_gb.js';
import 'froala-editor/js/plugins.pkgd.min.js';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { IFroalaOptions } from './froala-options';
// eslint-disable-next-line no-var
declare var $: any;

const INPUT_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FroalaComponent),
    multi: true
};

@Component({
    selector: 'app-froala',
    templateUrl: './froala.component.html',
    styleUrls: ['./froala.component.scss'],
    providers: [INPUT_VALUE_ACCESSOR],
    viewProviders: [{ provide: ErrorMessages, deps: [TranslateService], useClass: ErrorMessages }]
})
export class FroalaComponent extends FormInputBase implements ControlValueAccessor, OnInit, OnDestroy {
    @Input() public maxHeight?: number = null;
    @Input() public maxLength = -1;
    @Input() public set imagesPath(value: string) {
        this._froalaOptions = {
            ...this._froalaOptions,
            ...{
                imageUploadParams: { path: value },
                imageManagerLoadParams: { path: value, extensions: '*.jpeg|*.jpg|*.png' },
                imageManagerDeleteParams: { path: value }
            }
        };
    }

    public value = '';
    public showCode = false;
    protected initControls: any;
    protected tempValue: any;
    protected isInitialized;
    protected subscription: Subscription;
    public options: any;
    private _initSyncTimeout: any;

    @Output()
    public froalaInitialized = new EventEmitter<any>();
    @Output()
    public froalaBlur = new EventEmitter<void>();
    @Output()
    public froalaContentChanged = new EventEmitter<void>();

    #unsubscribe = new Subject();

    // eslint-disable-next-line @typescript-eslint/naming-convention
    protected _froalaOptions: Partial<IFroalaOptions> = {
        key: 'ERB2zF2D1A8B8A2B1C3rXYf1VPUGRHYZNRJb2JVOOe1HAb2zA3B2A1G2F4E1B1A10D2D7==',
        attribution: false,
        fontFamily: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Segoe UI,sans-serif': 'Segoe UI',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Arial,Helvetica,sans-serif': 'Arial',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Georgia,serif': 'Georgia',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Impact,Charcoal,sans-serif': 'Impact',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Tahoma,Geneva,sans-serif': 'Tahoma',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Times New Roman,Times,serif': 'Times New Roman',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Verdana,Geneva,sans-serif': 'Verdana'
        },
        toolbarButtons: [
            ['html', 'fullscreen', 'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript'],
            ['fontFamily', 'fontSize', 'textColor', 'clearFormatting'],
            ['paragraphFormat', 'lineHeight', 'paragraphStyle', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 'quote'],
            ['insertLink', 'insertImage', 'youtubeButton', 'insertTable'],
            ['emoticons', 'specialCharacters', 'insertHR', 'selectAll'],
            ['help', 'undo', 'redo']
        ],
        pluginsEnabled: [
            'align',
            'charCounter',
            'codeBeautifier',
            'codeView',
            'colors',
            'embedly',
            'emoticons',
            'file',
            'fontFamily',
            'fontSize',
            'fullscreen',
            'help',
            'image',
            'imageManager',
            'inlineStyle',
            'lineBreaker',
            'lineHeight',
            'link',
            'lists',
            'paragraphFormat',
            'paragraphStyle',
            'quote',
            'save',
            'specialCharacters',
            'table',
            'url',
            'video',
            'wordPaste',
            'youtubePlugin'
        ],
        codeViewKeepActiveButtons: ['fullscreen', 'format'],
        imageInsertButtons: ['imageBack', '|', 'imageUpload', 'imageByURL', 'imageManager'],
        imageUploadURL: '/api/file-system/froala-image',
        // Additional upload params.
        imageUploadParams: { path: this.imagesPath },
        imageUploadMethod: 'POST',
        imageMaxSize: 5 * 1024 * 1024,
        imageAllowedTypes: ['jpeg', 'jpg', 'png'],
        imageManagerLoadURL: '/api/file-system/froala-images',
        imageManagerLoadMethod: 'GET',
        imageManagerLoadParams: { path: this.imagesPath, extensions: '*.jpeg|*.jpg|*.png' },
        imageManagerDeleteURL: '/api/file-system/froala-image',
        imageManagerDeleteMethod: 'DELETE',
        imageManagerDeleteParams: { path: this.imagesPath },
        videoInsertButtons: ['videoBack', '|', 'videoByURL'],
        quickInsertButtons: [],
        htmlRemoveTags: [],
        htmlAllowedAttrs: ['.*'],
        htmlAllowedTags: ['.*'],
        htmlAllowedEmptyTags: [
            '.fa',
            'a',
            'abbr',
            'acronym',
            'address',
            'applet',
            'area',
            'article',
            'aside',
            'audio',
            'b',
            'base',
            'basefont',
            'bdi',
            'bdo',
            'big',
            'blockquote',
            'body',
            'button',
            'canvas',
            'caption',
            'center',
            'cite',
            'code',
            'col',
            'colgroup',
            'datalist',
            'dd',
            'del',
            'dfn',
            'div',
            'dl',
            'dt',
            'em',
            'embed',
            'fieldset',
            'figcaption',
            'figure',
            'font',
            'footer',
            'form',
            'frameset',
            'head',
            'header',
            'h1',
            'h2',
            'h3',
            'h4',
            'h5',
            'h6',
            'hr',
            'html',
            'i',
            'iframe',
            'img',
            'input',
            'ins',
            'kbd',
            'label',
            'legend',
            'li',
            'link',
            'main',
            'map',
            'mark',
            'meta',
            'meter',
            'nav',
            'noscript',
            'object',
            'ol',
            'optgroup',
            'option',
            'param',
            'pre',
            'progress',
            'q',
            's',
            'samp',
            'script',
            'section',
            'select',
            'small',
            'source',
            'span',
            'strike',
            'strong',
            'style',
            'sub',
            'sup',
            'table',
            'tbody',
            'td',
            'textarea',
            'tfoot',
            'th',
            'thead',
            'time',
            'title',
            'tr',
            'u',
            'ul',
            'var',
            'video',
            'wbr',
            'altGlyph',
            'altGlyphDef',
            'altGlyphItem',
            'animate',
            'animateColor',
            'animateMotion',
            'animateTransform',
            'animation',
            'circle',
            'clipPath',
            'color-profile',
            'cursor',
            'defs',
            'desc',
            'discard',
            'ellipse',
            'feBlend',
            'feColorMatrix',
            'feComponentTransfer',
            'feComposite',
            'feConvolveMatrix',
            'feDiffuseLighting',
            'feDisplacementMap',
            'feDistantLight',
            'feDropShadow',
            'feFlood',
            'feFuncA',
            'feFuncB',
            'feFuncG',
            'feFuncR',
            'feGaussianBlur',
            'feImage',
            'feMerge',
            'feMergeNode',
            'feMorphology',
            'feOffset',
            'fePointLight',
            'feSpecularLighting',
            'feSpotLight',
            'feTile',
            'feTurbulence',
            'filter',
            'font-face',
            'font-face-format',
            'font-face-name',
            'font-face-src',
            'font-face-uri',
            'foreignObject',
            'g',
            'glyph',
            'glyphRef',
            'handler',
            'hatch',
            'hatchpath',
            'hkern',
            'image',
            'line',
            'linearGradient',
            'listener',
            'marker',
            'mask',
            'mesh',
            'meshgradient',
            'meshpatch',
            'meshrow',
            'metadata',
            'missing-glyph',
            'mpath',
            'path',
            'pattern',
            'polygon',
            'polyline',
            'prefetch',
            'radialGradient',
            'rect',
            'set',
            'solidColor',
            'solidcolor',
            'stop',
            'svg',
            'switch',
            'symbol',
            'tbreak',
            'text',
            'textArea',
            'textPath',
            'tref',
            'tspan',
            'unknown',
            'use',
            'view',
            'vkern'
        ],
        htmlUntouched: true,
        htmlExecuteScripts: false,
        iframe: true,
        charCounterCount: true,
        imagePaste: false,
        heightMax: this.maxHeight,
        charCounterMax: this.maxLength,
        toolbarSticky: true,
        zIndex: 200,
        linkAutoPrefix: 'https://',
        events: {
            initialized: () => {
                /* value has to be set after froala is initialized. If value is set before initialization it can influence content outside editor. */
                if (!this.isInitialized) {
                    if (this.value !== this.tempValue) {
                        this.value = this.tempValue;
                    }
                    this.isInitialized = true;
                }
                const editor = this.initControls.getEditor();
                this.froalaInitialized.emit(editor);
                editor.froalaComponentInstance = this;
                const froalaHtml: string = editor.html.get();
                if (this.value && froalaHtml !== this.value) {
                    editor.html.set(this.value);
                }
                // disable drag and drop
                editor.events?.on(
                    'drop',
                    function () {
                        return false;
                    },
                    true
                );
                // delayed sync of content and editor height, because external styles may be loaded.
                this._initSyncTimeout = setTimeout(() => {
                    if (!this.disabled) {
                        editor.size.syncIframe();
                    }
                }, 2000);
            },
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'commands.after': (cmd) => {
                const editor = this.initControls.getEditor();
                if (cmd === 'fullscreen') {
                    if (editor.fullscreen.isActive()) {
                        $('.navbar-static-top,.page-heading,.footer-floating').hide();
                    } else {
                        $('.navbar-static-top,.page-heading,.footer-floating').show();
                    }
                }
                if (cmd === 'html') {
                    $('.navbar-static-top,.page-heading,.footer-floating').show();
                    this.showCode = true;
                    this.changeDetectionRef.detectChanges();
                }
            },
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'image.beforeUpload': () => {
                if (!this._froalaOptions.imageUploadParams.path || !this._froalaOptions.imageUploadParams.path) {
                    this.dialogs.errorMessage(this.translate.instant('Froala_Error_FileUpload_PathNotSet'), '');
                    return false;
                }
            },
            blur: () => {
                this.froalaBlur.emit();
            },
            contentChanged: () => {
                this.froalaContentChanged.emit();
            }
        }
    };

    public get froalaOptions() {
        return this._froalaOptions;
    }

    @Input() public set froalaOptions(value: Partial<IFroalaOptions>) {
        this._froalaOptions = { ...this._froalaOptions, ...value };
    }

    /**
     * If true, calls the froala editor destroy method with setTimeout. Solves issue when destroying froala steals focus from other inputs, but has a bug when switching between code and editor mode.
     */
    @Input() delayDestroy = false;

    constructor(
        @Optional()
        @Host()
        @SkipSelf()
        controlContainer: ControlContainer,
        errorMessages: ErrorMessages,
        public dialogs: DialogsService,
        public translate: TranslateService,
        public element: ElementRef<HTMLElement>,
        protected messageService: FroalaMessageService,
        protected changeDetectionRef: ChangeDetectorRef
    ) {
        super(controlContainer, errorMessages, { tidPrefix: 'ft' });

        this.messageService
            .getMessage()
            .pipe(takeUntil(this.#unsubscribe))
            .subscribe((message) => {
                if (message.reInit) {
                    this.reInitialize();
                }
            });
    }

    updateValue(obj: any): void {
        /* value has to be set after froala is initialized. If value is set before initialization it can influence content outside editor. */
        this.tempValue = obj;
        if (this.isInitialized) {
            this.value = this.tempValue;
        }
        setTimeout(() => {
            if (this.disabled) {
                this.value = obj;
                this.setIframeValue();
            }
        }, 2100);
    }

    onValueChange(value: any) {
        this.value = value;
        this.raiseChange(value);
        this.raiseTouched();
    }

    ngOnInit() {
        super.ngOnInit();

        this.initCustomPopups();
        this.initCustomHelpers();

        this.options = this.froalaOptions;
    }

    ngOnDestroy() {
        this.#unsubscribe.next(true);
        this.#unsubscribe.complete();
        clearTimeout(this._initSyncTimeout);
    }

    public switchEditor() {
        this.showCode = !this.showCode;
        this.changeDetectionRef.detectChanges();
    }

    public initialize(initControls) {
        setTimeout(() => {
            this.initControls = initControls;
            this.initControls.initialize();
        });
    }

    private reInitialize() {
        if (this.initControls) {
            this.initControls.destroy();
            this.initControls.initialize();
        }
    }

    private setIframeValue() {
        const iframe = document.getElementById('iframe' + this.id) as HTMLIFrameElement;

        if (iframe) {
            const iframeDoc = iframe.contentDocument;

            iframeDoc.open();
            iframeDoc.write(this.value || '');
            iframeDoc.close();

            const iframeWin = iframe.contentWindow;
            if (iframeWin.document.body) {
                iframe.height = (iframeWin.document.documentElement.scrollHeight || iframeWin.document.body.scrollHeight).toString();
            }
        }
    }

    private initCustomPopups() {
        this.youtubePopup();
    }

    private initCustomHelpers() {
        const originalHelpers = FroalaEditor.MODULES.helpers;
        FroalaEditor.MODULES.helpers = function (editor) {
            const helpers = originalHelpers(editor);

            const isURL = helpers.isURL;

            // Switch off url sanitizer
            helpers.sanitizeURL = function (url) {
                if (/^(https?:|ftps?:|)\/\//i.test(url)) {
                    if (!isURL(url) && !isURL('http:' + url)) {
                        return '';
                    }
                }

                return url;
            };

            return helpers;
        };
    }

    /* POPUPS */
    public youtubePopup() {
        // Define popup template.
        Object.assign(FroalaEditor.POPUP_TEMPLATES, {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'youtubePlugin.popup': '[_CUSTOM_LAYER_]'
        });
        // The custom popup is defined inside a plugin (new or existing).
        FroalaEditor.PLUGINS.youtubePlugin = function (editor) {
            const testRegex = /^.*((youtu.be)|(youtube.com))\/((v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))?\??v?=?([^#&?]*).*/;
            const urlRegex = /(?:https?:\/\/)?(?:www\.)?(?:m\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=|embed\/)?([0-9a-zA-Z_-]+)(.+)?/g;
            const urlText = 'https://www.youtube.com/embed/$1';
            const html =
                '<div class="articleVideoWrapper" contenteditable="false">' +
                '<iframe width="560" height="315" src="{url}" frameborder="0" allowfullscreen></iframe >' +
                '</div >';
            // Create custom popup.
            function initPopup() {
                const formTemplate =
                    '<div class="fr-video-by-url-layer fr-layer fr-active" >' +
                    '<div class="fr-input-line">' +
                    '<input id="fr-youtube-video-by-url-layer-text-1" type="text" placeholder="Paste in a video URL"' +
                    'tabindex="1" aria-required="true" dir="auto">' +
                    '<label for="fr-youtube-video-by-url-layer-text-1">Paste in a video URL</label>' +
                    '</div >' +
                    '<div class="fr-action-buttons">' +
                    '<button type="button" class="fr-command fr-submit" data-cmd="insertYoutubeVideo" tabindex="2" role="button">Insert</button>' +
                    '</div > ' +
                    '</div >';
                // Load popup template.
                const template = {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    custom_layer: formTemplate
                };
                // Create popup.
                const $popup = editor.popups.create('youtubePlugin.popup', template);
                return $popup;
            }
            // Show the popup
            function showPopup() {
                // Get the popup object defined above.
                let $popup = editor.popups.get('youtubePlugin.popup');
                // If popup doesn't exist then create it.
                // To improve performance it is best to create the popup when it is first needed
                // and not when the editor is initialized.
                if (!$popup) {
                    $popup = initPopup();
                }
                // Set the editor toolbar as the popup's container.
                editor.popups.setContainer('youtubePlugin.popup', editor.$tb);
                // This will trigger the refresh event assigned to the popup.
                // editor.popups.refresh('youtubePlugin.popup');
                // This custom popup is opened by pressing a button from the editor's toolbar.
                // Get the button's object in order to place the popup relative to it.
                const $btn = editor.$tb.find('.fr-command[data-cmd="youtubeButton"]');
                // Set the popup's position.
                const left = $btn.offset().left + $btn.outerWidth() / 2;
                const top = $btn.offset().top + (editor.opts.toolbarBottom ? 10 : $btn.outerHeight() - 10);
                // Show the custom popup.
                // The button's outerHeight is required in case the popup needs to be displayed above it.
                editor.popups.show('youtubePlugin.popup', left, top, $btn.outerHeight());
            }
            function insertYoutubeVideo() {
                let value = (editor.popups.get('youtubePlugin.popup').find('.fr-video-by-url-layer input[type="text"]').val() || '').trim();
                let videoTag = null;
                if ((/^http/.test(value) || (value = 'https://' + value), editor.helpers.isURL(value))) {
                    if (testRegex.test(value)) {
                        const url = value.replace(urlRegex, urlText);
                        videoTag = html.replace(/\{url\}/, url);
                    }
                }
                videoTag ? insertHtml(videoTag) : editor.events.trigger('video.linkError', [value]);
            }
            function insertHtml(value) {
                editor.html.insert(value);
                editor.froalaComponentInstance.onValueChange(editor.html.get());
                hidePopup();
            }
            // Hide the custom popup.
            function hidePopup() {
                editor.popups.hide('youtubePlugin.popup');
            }
            // Methods visible outside the plugin.
            return {
                showPopup: showPopup,
                hidePopup: hidePopup,
                insertYoutubeVideo: insertYoutubeVideo
            };
        };
        FroalaEditor.DefineIconTemplate('fullIconClass', '<i class="[NAME]"></i>');

        // Define an icon and command for the button that opens the custom popup.
        // eslint-disable-next-line @typescript-eslint/naming-convention
        FroalaEditor.DefineIcon('youtubeIcon', { NAME: 'video-camera', SVG_KEY: 'insertVideo' });
        FroalaEditor.RegisterCommand('youtubeButton', {
            title: 'Insert Youtube video',
            icon: 'youtubeIcon',
            undo: false,
            focus: false,
            plugin: 'youtubePlugin',
            callback: function () {
                this.youtubePlugin.showPopup();
            }
        });
        FroalaEditor.RegisterCommand('insertYoutubeVideo', {
            undo: false,
            focus: false,
            callback: function () {
                this.youtubePlugin.insertYoutubeVideo();
            }
        });
    }
}
