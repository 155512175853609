/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum PriceBoxType {
    NoPrice = 0,
    Normal = 1,
    PriceBomb = 2,
    SuperPrice = 3,
    Discount = 4,
    Third = 5,
    Neo = 6,
    News = 7,
    Isic = 8,
    Preorder = 9,
    B2BPrice = 10,
    Installments = 11,
    BlackFriday = 12,
}
