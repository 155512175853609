/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum TranslationStateItem {
    SeoPrefix = 0,
    ActiveForOR = 1,
    Generated = 2,
    GeneratedEmptyLegend = 3,
    TranslationChecked = 4,
    TranslatedByGTAndActive = 5,
    OffOrAnalysis = 6,
    WaitingForGenerating = 7,
    ImportedCSV = 8,
    EmptyInCZ = 9,
}
