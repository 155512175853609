import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-trend',
    templateUrl: './trend.component.html',
    styleUrls: ['./trend.component.scss']
})
export class TrendComponent implements OnInit {
    @Input() difference: number;
    private _number: number;
    @Input() set number(value: number) {
        this._number = value;
        this.difference = value - this._numberCompareTo;
    }
    private _numberCompareTo: number;
    @Input() set numberCompareTo(value: number) {
        this._numberCompareTo = value;
        this.difference = this._number - value;
    }

    public color = 'white';
    public glyphClass: Array<string> = ['fa-long-arrow-alt-right', 'orange'];

    private _glyphClasses: { [id: number]: Array<string> } = {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        0: ['fa-long-arrow-alt-up', 'green'],
        // eslint-disable-next-line @typescript-eslint/naming-convention
        1: ['fa-long-arrow-alt-right', 'orange'],
        // eslint-disable-next-line @typescript-eslint/naming-convention
        2: ['fa-long-arrow-alt-down', 'red']
    };

    ngOnInit() {
        this.glyphClass = this.difference === 0 ? this._glyphClasses[1] : this.difference < 0 ? this._glyphClasses[2] : this._glyphClasses[0];
    }
}
