import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { EnumsService } from 'app/common/services/enums.service';
import { map } from 'rxjs/operators';

@Pipe({
    name: 'flagEnum',
    pure: false
})
export class FlagEnumPipe implements PipeTransform {
    private _enumDisplayName = '';
    private _isLoading = false;
    private _lastValue: any;

    constructor(private readonly enums: EnumsService, private changeDetector: ChangeDetectorRef) {}

    transform(value: number, args?: any): any {
        if (this._lastValue !== value) {
            this._enumDisplayName = null;
        }
        if (this._enumDisplayName || this._isLoading) {
            return this._enumDisplayName;
        }
        this._isLoading = true;
        this.enums
            .getEnum(args)
            .pipe(map((x) => x.filter((i) => i.value === value || (i.value && (i.value & value) === i.value))))
            .subscribe((res) => {
                if (res) {
                    this._enumDisplayName = res.map((x) => x.displayName).join(', ');
                    this._lastValue = value;
                    this.changeDetector.markForCheck();
                }
                this._isLoading = false;
            });
        return this._enumDisplayName;
    }
}
