import { AfterViewChecked, Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CategoryTree, CategoryTreeDataService, ICategoryTreeFilterClientDto, ICategoryTreeItemClientDto } from '.';

@Component({
    selector: 'app-category-tree-view',
    exportAs: 'treeView',
    templateUrl: './category-tree-view.component.html',
    styleUrls: ['./category-tree-view.component.scss'],
    viewProviders: [CategoryTreeDataService]
})
export class CategoryTreeViewComponent implements AfterViewChecked, OnInit, OnDestroy {
    private _scrollPage = false;
    private _filter: Partial<ICategoryTreeFilterClientDto>;
    private _unsubscribe = new Subject<void>();
    private _categoryId: number;
    public categoryTree: CategoryTree;

    @Output() public categoryIdChange = new EventEmitter<number>();
    @Output() public expandedIds: EventEmitter<Array<number>>;
    @Input() public itemTemplate: TemplateRef<ICategoryTreeItemClientDto>;
    @Input() public toogleOnSelect = false;

    @Input() public set categoryId(value: number | null) {
        if (value !== this._categoryId) {
            this._categoryId = value;
            this.categoryTree
                .select(this._categoryId)
                .pipe(takeUntil(this._unsubscribe))
                .subscribe(() => {
                    this._scrollPage = true;
                });
        }
    }

    @Input() public set filter(filter: Partial<ICategoryTreeFilterClientDto>) {
        this._filter = filter;
        this.categoryTree
            .filterBy(this._filter)
            .pipe(takeUntil(this._unsubscribe))
            .subscribe(() => {
                this._scrollPage = true;
            });
    }

    constructor(readonly categoryTreeDataService: CategoryTreeDataService) {
        this.categoryTree = new CategoryTree(categoryTreeDataService);
        this.expandedIds = this.categoryTree.expandedIds;
    }

    ngOnInit() {
        this.categoryTree.selected.pipe(takeUntil(this._unsubscribe)).subscribe((item) => {
            this._categoryId = item?.id;
            this.categoryIdChange.emit(this._categoryId);
            if (this.toogleOnSelect) {
                this.categoryTree.toggleExpand(item);
            }
        });
        if (!this._categoryId && !this._filter) {
            this.categoryTree.filterBy(this._filter).subscribe();
        }
    }

    ngAfterViewChecked() {
        if (!this._scrollPage) {
            return;
        }
        let item = this.categoryTree.findFirst((i) => i.isSelected);
        if (!item) {
            item = this.categoryTree.findFirst((i) => i.isMatch);
        }
        if (item) {
            this.scrollTo(item.id);
        }
        this._scrollPage = false;
    }

    ngOnDestroy() {
        this._unsubscribe.next();
        this._unsubscribe.complete();
    }

    public scrollTo(id: number) {
        const targetElement = document.querySelector('#item' + id);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
        }
    }

    public select(itemId: number) {
        this.categoryTree.select(itemId);
    }
}
