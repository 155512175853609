import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { User } from 'app/common/dto/user-dto';
import { IMenuItem, MenuService } from 'app/common/services/menu.service';
import { SessionService } from 'app/common/services/session.service';
import { environment } from '../../../environments/environment';
import { SearchModalComponent } from '../search-modal/search-modal.component';

interface IFlatMenuItem {
    path: Array<{ title: string; loweredTitle: string; active: boolean }>;
    url: string;
    isMvcModule: boolean;
}

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, AfterViewInit {
    public items: Array<IMenuItem> = [];
    public shownItems: Array<IMenuItem>;
    public user?: User;
    public displayNameInitials = '';
    public isProd: boolean = environment.production;
    public isProduction: boolean;
    public searchTerm = '';
    public searchFocused = false;
    public searchHovered = false;
    public menuMouseOver = false;
    public isMobile = false;
    public currentUrl = '';
    public itemTree: Array<IMenuItem> = [];
    public menuItems: Array<IFlatMenuItem> = [];
    public isModalOpen = false;

    @HostListener('window:keyup', ['$event'])
    onClick(e: KeyboardEvent) {
        if (e.ctrlKey && e.keyCode === 81) {
            if (this.isModalOpen === false) {
                this.isModalOpen = true;
                this.matDialog
                    .open<any>(SearchModalComponent, {
                        data: { itemTree: this.items, isModalOpen: this.isModalOpen },
                        panelClass: ['dialog-lg', 'search-modal'],
                        position: { top: '30px' },
                        autoFocus: true
                    })
                    .afterClosed()
                    .subscribe(() => {
                        this.isModalOpen = false;
                    });
            }
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.resizeCheck();
    }
    @HostListener('window:scroll', ['$event'])
    onScroll(e: Event) {
        this.resizeCheck(e.type);
    }

    constructor(
        private readonly router: Router,
        public readonly session: SessionService,
        private readonly matDialog: MatDialog,
        private readonly menuService: MenuService
    ) {}

    ngOnInit() {
        this.router.events.subscribe((evt) => {
            if (evt instanceof NavigationEnd) {
                this.setActive(this.router.url, this.shownItems);
            }
        });

        this.menuService.getVisibleItems().subscribe((res) => {
            this.items = res;
            this.shownItems = res;
            this.setActive(this.router.url, this.shownItems);
        });

        this.user = this.session.user;
        this.displayNameInitials = this.user.displayName
            .split(' ')
            .filter((x) => !!x)
            .map((x) => x[0].toUpperCase())
            .reduce((res, curr) => res + curr);
        this.isProduction = this.session.user.environment === 2 || this.session.user.environment === 3;
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.resizeCheck();
        }, 100);
    }

    resizeCheck(event?: string) {
        // resize page-wrapper if its bigger than menu and vice versa
        const navbarHeight = $('nav.navbar-default').height();
        const wrapperHeight = window.innerHeight;
        // magical number to match the footers real height
        const footerHeight = $('.footer-floating').height() + 21;

        if (navbarHeight > wrapperHeight) {
            document.getElementById('page-wrapper').style.minHeight = navbarHeight + 'px';
        }
        if (navbarHeight < wrapperHeight) {
            document.getElementById('page-wrapper').style.minHeight = window.innerHeight - footerHeight + 'px';
        }

        if (event !== 'scroll') {
            const body = document.querySelector('body');
            if (window.innerWidth < 769) {
                body.classList.add('mini-navbar', 'body-small');
                this.isMobile = true;
            } else {
                body.classList.remove('mini-navbar', 'body-small');
                this.isMobile = false;
            }
        }
    }

    public searchTermSub() {
        this.shownItems = JSON.parse(JSON.stringify(this.items));

        if (this.searchTerm.length > 1) {
            const normalizedSearchTerm = this.searchTerm
                .toLocaleLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '');
            for (const lvl0 of this.shownItems) {
                if (lvl0.items.length > 0) {
                    for (const lvl1 of lvl0.items) {
                        if (lvl1.items.length > 0) {
                            if (!lvl1.normalizedTitle.includes(normalizedSearchTerm)) {
                                lvl1.items = lvl1.items.filter((x) => x.normalizedTitle.includes(normalizedSearchTerm));
                            }
                            if (lvl1.items.length) {
                                lvl1.foundChildren = true;
                            }

                            lvl1.isActive = true;
                        }
                    }
                    lvl0.items = lvl0.items.filter((x) => x.normalizedTitle.includes(normalizedSearchTerm) || x.foundChildren);
                    lvl0.isActive = true;
                } else {
                    this.shownItems = this.shownItems.filter((x) => x !== lvl0);
                }
            }
        }
    }

    private setActive(url: string, items: Array<IMenuItem>) {
        if (url && url.indexOf('?') !== -1) {
            url = url.split('?')[0];
        }
        for (const i of items) {
            if (i.items.length) {
                this.setActive(url, i.items);
            }
            i.isActive = i.items.some((x) => !!x.isActive) || this.menuService.isMatch(url, i);
        }
    }

    public menuItemClick(event: Event, item: IMenuItem) {
        event.stopPropagation();
        if (item.isActive && item.items.length) {
            item.isActive = false;
        } else {
            if (item.lvl === 0 && this.searchTerm.length < 1) {
                this.deactivateAll(this.shownItems, true);
            }
            const i: IMenuItem | null = item;
            if (i !== null || i) {
                i.isActive = true;
            }
        }
        if (!this.isMiniNavbar()) {
            setTimeout(() => {
                this.resizeCheck();
            }, 300);
        }
    }

    private deactivateAll(items: Array<IMenuItem>, isMenuClick = false) {
        for (const i of items) {
            i.isActive = false;
            if (!isMenuClick) {
                this.deactivateAll(i.items);
            }
        }
    }

    public clearSearch() {
        this.searchTerm = '';
        this.searchTermSub();
    }

    public toggleMenu() {
        document.body.classList.toggle('mini-navbar');
    }

    showSearchModal() {
        this.matDialog.open(SearchModalComponent, { data: { itemTree: this.items }, panelClass: 'dialog-lg' });
    }

    public isMiniNavbar() {
        this.menuService.isMiniNavbar = document.body.classList.contains('mini-navbar');
        return document.body.classList.contains('mini-navbar');
    }
}
