import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonDataService } from 'app/common/services/common-data.service';
import { ICategoryDto, IParameterDto, IParameterGroupDto } from './models';

export interface IParameterSelectState {
    categoryId: number;
    groupId: number | null;
}

@Injectable()
export class ParameterSelectService {
    constructor(private http: HttpClient, private commonData: CommonDataService) {}

    getCategories(parentId: number) {
        return this.http.get<Array<ICategoryDto>>('/api/parameter-select/categories', { params: new HttpParams().set('parentId', parentId.toString()) });
    }

    getCategoryGroups(categoryId: number) {
        return this.http.get<Array<IParameterGroupDto>>('/api/parameter-select/category-groups', {
            params: new HttpParams().set('categoryId', categoryId.toString())
        });
    }

    getGroupParameters(groupId: number) {
        return this.http.get<Array<IParameterDto>>('/api/parameter-select/group-parameters', {
            params: new HttpParams().set('groupId', groupId.toString()).set('enumsOnly', 'false')
        });
    }

    getPath(categoryId: number) {
        return this.http.get<Array<ICategoryDto>>('/api/parameter-select/category-path', { params: new HttpParams().set('categoryId', categoryId.toString()) });
    }

    saveState(state: IParameterSelectState) {
        window.sessionStorage.setItem('ParameterSelectService_State', JSON.stringify(state));
    }

    loadState(): IParameterSelectState {
        const val = window.sessionStorage.getItem('ParameterSelectService_State');
        if (val) {
            return JSON.parse(val);
        } else {
            return { categoryId: 1, groupId: null };
        }
    }
}
