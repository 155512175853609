/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum ParameterId {
    KeyboardLocalization = 458,
    AudioBookAuthor = 807,
    AudioBookArtist = 808,
    AudioBookLanguage = 809,
    AudioBookLength = 810,
    AudioBookCategories = 811,
    AudioBookSize = 813,
    MusicInterpret = 884,
    AudioReleaseDate = 885,
    AudioSize = 886,
    AudioLength = 887,
    AudioTrackNumber = 888,
    BookLanguage = 898,
    BookPagesCount = 900,
    ElectronicLicenceLanguage = 908,
    ElectronicLicenceOs = 914,
    ElectronicLicencePeriod = 916,
    ElectronicGamePegiAge = 928,
    ElectronicGamePegi = 929,
    ElectronicGameType = 930,
    ElectronicGameLanguage = 933,
    ElectronicGameOs = 943,
    BookAuthor = 1026,
    BookGenres = 1027,
    BookYear = 1028,
    BookSeries = 1029,
    AudioBookSeriesPart = 1030,
    PhysicalBookSeriesPart = 1030,
    BookSeriesPart = 1030,
    VideoLanguage = 1117,
    VideoDirector = 1119,
    VideoActor = 1124,
    MagazinePeriodicity = 1277,
    MagazinePublishDate = 1278,
    MagazineLanguage = 1279,
    MagazinePagesCount = 1280,
    MagazinePublisher = 1281,
    MagazineSubscriptionNoIssues = 1282,
    MagazineSubscriptionSince = 1283,
    MagazineSubscriptionUntil = 1284,
    NotebookLocalization = 1307,
    MagazineSubscriptionPromo = 1321,
    VideoPreviewLength = 1438,
    VideoPreviewImageURL = 1441,
    RecommendedDistanceForSD = 1682,
    RecommendedDistanceForFullHD = 1683,
    BookAttachment = 3124,
    AudioBookSeries = 3299,
    PhysicalBookYear = 11948,
    PhysicalBookGenres = 11949,
    PhysicalBookLanguage = 11950,
    PhysicalBookPagesCount = 11951,
    PhysicalBookAuthor = 11952,
    PhysicalBookSeries = 11954,
    PhysicalBookBinding = 11959,
    BikeServis = 13208,
    IndexRobot = 19606,
    AppleMusicOffer = 20004,
    Warning = 20093,
}
