/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum LinkType {
    Article = 0,
    Category = 1,
    Commodity = 2,
    CommodityPack = 3,
    Other = 4,
}
