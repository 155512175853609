import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Observable, ReplaySubject } from 'rxjs';

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'ANY';

type CounterType = { [key in HttpMethod]: number };

export class BusyEvent {
    public readonly method: HttpMethod = 'ANY';
    public readonly hasActiveRequests: boolean = false;

    constructor(method: HttpMethod, isBusy: boolean) {
        this.method = method;
        this.hasActiveRequests = isBusy;
    }
}

@Injectable({
    providedIn: 'root'
})
export class BusyService {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    private _counter: CounterType = { ANY: 0, DELETE: 0, GET: 0, PATCH: 0, POST: 0, PUT: 0 };

    public readonly busy$: Observable<BusyEvent>;
    private _busySub = new ReplaySubject<BusyEvent>(1);
    private _isNavigating = false;

    constructor(router: Router) {
        this.busy$ = this._busySub.asObservable();

        router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                this._isNavigating = true;
                this.onNgBusyChanged();
            } else if (event instanceof NavigationEnd) {
                this._isNavigating = false;
                this.onNgBusyChanged();
            }
        });
    }

    public add(req: HttpRequest<unknown>) {
        this._counter[req.method]++;
        this._counter.ANY++;
        if (this._counter[req.method] === 1) {
            this._busySub.next(new BusyEvent(req.method as HttpMethod, true));
        }
        if (this._counter.ANY === 1) {
            this._busySub.next(new BusyEvent('ANY', true));
            this.onNgBusyChanged();
        }
    }

    public sub(req: HttpRequest<unknown>) {
        this._counter[req.method]--;
        this._counter.ANY--;
        if (this._counter[req.method] === 0) {
            this._busySub.next(new BusyEvent(req.method as HttpMethod, false));
        }
        if (this._counter.ANY === 0) {
            this._busySub.next(new BusyEvent('ANY', false));
            this.onNgBusyChanged();
        }
    }

    private onNgBusyChanged() {
        window['ngBusy'] = this._counter['ANY'] > 0 || this._isNavigating;
    }
}
