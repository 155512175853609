import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, Output, ViewChild } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/naming-convention
import * as Ladda from 'ladda';

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent implements OnDestroy, AfterViewInit {
    @Input() id: string;
    @Input() disabled = false;
    @Input() type = 'button';
    @Input() set size(value: 'xs' | 'sm' | 'md' | 'lg') {
        if (value === 'xs' || value === 'sm' || value === 'lg') {
            this.buttonClass[1] = `btn-${value}`;
        } else {
            this.buttonClass[0] = null;
        }
    }
    @Input() set color(value: 'primary' | 'info' | 'default' | 'success' | 'warning' | 'danger' | 'link' | 'secondary' | 'white') {
        this.buttonClass[0] = `btn-${value}`;
    }
    get loading() {
        return this._loading;
    }

    @Input() set loading(value: boolean) {
        this._loading = value;

        if (this._ladda) {
            if (value) {
                this._ladda.start();
            } else {
                this._ladda.stop();
            }
        }
    }
    @Input() set progress(value: number) {
        if (value && value < 0) {
            this._progress = 0;
        } else if (value && value > 100) {
            this._progress = 100;
        } else {
            this._progress = value;
        }
        if (this._ladda) {
            this._ladda.setProgress(this._progress / 100);
        }
    }
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() click = new EventEmitter<MouseEvent>();
    @ViewChild('btn') buttonRef: ElementRef<HTMLButtonElement>;

    private _ladda: Ladda.LaddaButton;
    private _loading = false;
    private _progress = 0;
    public buttonClass: [string, string?] = ['btn-primary'];

    @HostBinding('class.disabled')
    get hostDisabled() {
        return this.disabled || this.loading;
    }

    public getTid(prefix: string) {
        if (!this.id) {
            return prefix;
        }
        return prefix + this.id.charAt(0).toUpperCase() + this.id.slice(1);
    }

    ngOnDestroy() {
        this._ladda.remove();
    }

    ngAfterViewInit(): void {
        this._ladda = Ladda.create(this.buttonRef.nativeElement);
        if (this._loading) {
            this._ladda.start();
            this._ladda.setProgress(this._progress / 100);
        } else {
            this._ladda.stop();
        }
    }
}
