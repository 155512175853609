<div class="form-group" [class.mb-0]="!labelText" [ngClass]="formGroupClass">
    <cms-label
        *ngIf="labelText"
        [required]="labelRequired"
        [for]="id"
        [text]="labelText"
        [tooltip]="labelTooltip"
        [tooltipDirection]="labelTooltipDirection"
        [tooltipIcon]="labelTooltipIcon"
        [class]="labelClass"
    ></cms-label>
    <div #wrapper [class.input-group]="wrapper.childElementCount !== 1">
        <mat-slider [min]="minValue" [max]="maxValue" [attr.id]="id" (blur)="onBlur()" discrete
            ><input matSliderThumb [(ngModel)]="value" (ngModelChange)="onValueChange()" [disabled]="disabled"
        /></mat-slider>
    </div>
    <cms-field-errors [submitted]="submitted" [control]="control"></cms-field-errors>
</div>
