/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum PushDeviceSystemType {
    Unknown = 0,
    Android = 1,
    IOS = 2,
    Windows = 3,
    Browser = 4,
}
