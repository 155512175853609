/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum LegendStateItem {
    SeoPrefix = 0,
    ActiveForOR = 1,
    Custom = 2,
    OffOrAnalysis = 3,
    BasicChecked = 4,
    Checked = 5,
    EmptyLegend = 6,
    Generated = 7,
    GeneratedEmptyLegend = 8,
    ImportedCSV = 9,
    Top = 10,
    EmptyLegendAndChecked = 11,
    GeneratedEmptyLegendChecked = 12,
    DeadlineMet = 13,
    DeadlineNotMet = 14,
    GeneratedWithPictures = 15,
    GeneratedWithoutPictures = 16,
    ShortLegend = 17,
}
