import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnumNames } from 'app/models/enums';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpMemoryCache, memoryCache } from '../http-memory-cache';
export interface IEnumValue {
    value: number;
    name: string;
    displayName: string;
    isSelected?: boolean;
    order: number;
    properties: { [key: string]: any };
}

@Injectable()
export class EnumsService {
    constructor(private http: HttpClient) {}

    private readonly _cache = new HttpMemoryCache();

    public getEnum(name: EnumNames): Observable<Array<IEnumValue>> {
        return this.http.get<{ values: Array<IEnumValue> }>('api/common/enums', { params: new HttpParams().set('name', encodeURIComponent(name)) }).pipe(
            map((res) => res.values),
            memoryCache(name, this._cache)
        );
    }

    public getEnums(...names: Array<EnumNames>): Observable<Array<Array<IEnumValue>>> {
        return forkJoin(names.map((n) => this.getEnum(n)));
    }

    public getDisplayName(sourceEnum: Array<IEnumValue>, value: number): string {
        sourceEnum = sourceEnum || [];
        const enumValue = sourceEnum.find((x) => x.value === value);
        return enumValue ? enumValue.displayName : '';
    }

    public getEnumFiltered(name: EnumNames, filterEnum: Array<number>): Observable<Array<IEnumValue>> {
        return this.getEnum(name).pipe(
            map((y) => {
                if (filterEnum) {
                    return y.filter((z) => filterEnum.some((x) => z.value === x)).sort((a, b) => filterEnum.indexOf(a.value) - filterEnum.indexOf(b.value));
                } else {
                    return y;
                }
            })
        );
    }
}
