import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class HttpMemoryCache {
    private readonly _cache = new Map<string, Observable<any>>();

    public get<T>(key: string, factory: () => Observable<T>): Observable<T> {
        if (this._cache.has(key)) {
            return this._cache.get(key);
        }
        this._cache.set(key, factory().pipe(shareReplay(1)));
        return this._cache.get(key);
    }

    public remove(key: string) {
        if (this._cache.has(key)) {
            this._cache.delete(key);
        }
    }

    public clear() {
        this._cache.clear();
    }
}

export const memoryCache =
    (key: string, storage: HttpMemoryCache) =>
    <T>(source: Observable<T>) => {
        return storage.get(key, () => source);
    };
