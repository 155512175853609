import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EntityState } from 'app/common/dto/common-dto';
import { INavigationCommand } from 'app/common/dto/navigation-command';
import { InvalidFormError, NavigateToNotFoundError } from 'app/common/errors';
import { ConcurrentSaveFormError } from 'app/common/errors/concurrent-save-form-error';
import { handle404 } from 'app/common/operators';
import { DialogsService } from 'app/common/services';
import { ArrayInnerType, ArrayProperties } from 'app/common/type-helpers';
import { IKeyStateEntityDto } from 'app/models';
import { Observable, catchError, finalize, from, of, switchMap, take, tap, throwError } from 'rxjs';
import { FormHelper } from './form-helper.service';
import { FormArray, FormGroup, FormValue } from './typed-controls';
import { IDataService } from './typed-data-service';
import { FormBuilderConfig, TypedFormBuilder } from './typed-forms';

export abstract class TypedFormService<T> {
    public form: FormGroup<T>;
    private _initialized = false;
    private _isSaving = false;

    private readonly _translate = inject(TranslateService);
    private readonly _dialogs = inject(DialogsService);
    private readonly _formHelper = inject(FormHelper);

    public get id() {
        return this.form.get('id').value;
    }

    constructor(protected readonly dataService: IDataService<T>, config?: FormBuilderConfig<T>) {
        if (config) {
            const fb = inject(TypedFormBuilder);
            this.form = fb.group(config);
        }
    }

    public initialize(key: Partial<T> = null, notFoundRoute?: INavigationCommand) {
        this._initialized = false;
        if (key === null) {
            this._initialized = true;
            return;
        }
        if (Object.values(key).some((val) => Number.isNaN(val))) {
            throw new NavigateToNotFoundError(NaN, notFoundRoute);
        }
        if (Object.values(key).some((val) => val !== null)) {
            this._initialized = true;
            this.reset();
            this.dataService
                .get(key)
                .pipe(handle404(notFoundRoute))
                .subscribe((res) => this.patchForm(res, this.form));
        }
    }

    protected reset() {
        this.form.markAsPristine();
        this.form.markAsUntouched();
    }

    public save(value: T, reload: boolean): Observable<T> {
        if (this._isSaving) {
            return throwError(() => new ConcurrentSaveFormError());
        }

        this._isSaving = true;

        if (!this.form.valid) {
            if (!this.form.pending) {
                this._isSaving = false;
                return this.throwInvalidFormError();
            } else {
                // Wait for form to change state from pending
                return this.form.statusChanges.pipe(
                    take(1),
                    switchMap(() => {
                        if (!this.form.valid) {
                            this._isSaving = false;
                            return this.throwInvalidFormError();
                        } else {
                            return this.saveInternal(value, reload);
                        }
                    })
                );
            }
        }
        return this.saveInternal(value, reload);
    }

    private saveInternal(value: T, reload: boolean): Observable<T> {
        return this.dataService.save(value).pipe(
            tap(() => {
                this.reset();
            }),
            catchError((err) => {
                if (err instanceof HttpErrorResponse && err.status === 400) {
                    this._dialogs.badRequestMessage(err);
                    this._formHelper.mapErrorResponseToForm(this.form, err.error);
                }
                return throwError(() => err);
            }),
            switchMap((key) => {
                if (reload) {
                    this.reset();
                    return this.dataService.get({ ...value, ...key }).pipe(
                        handle404(),
                        tap((x) => {
                            this.patchForm(x, this.form);
                        })
                    );
                } else {
                    return of(value);
                }
            }),
            finalize(() => (this._isSaving = false))
        );
    }

    private throwInvalidFormError() {
        this.form.markAllAsTouched();
        this._dialogs.errorMessage(this._translate.instant('Common_InvalidFormTitle') as string, this._translate.instant('Common_InvalidFormText') as string);
        return throwError(() => new InvalidFormError(this.form));
    }

    public delete(confirm = true) {
        return from(confirm ? this._dialogs.confirmDelete() : Promise.resolve()).pipe(
            switchMap(() =>
                this.dataService.delete<any>(this.form.value).pipe(
                    tap(() => this._dialogs.successMessage(this._translate.instant('Common_MsgDeleted'), '')),
                    catchError((err: HttpErrorResponse) => {
                        this._formHelper.mapErrorResponseToForm(this.form, err);
                        if (err.status === 400) {
                            this._dialogs.badRequestMessage(err);
                        }
                        return throwError(() => err);
                    })
                )
            )
        );
    }

    protected patchForm(data: T, form: FormGroup<T>) {
        this._formHelper.patchValue(form, data);
    }

    public remove<K extends keyof ArrayProperties<T, IKeyStateEntityDto<any>>>(key: K, item: FormValue<ArrayInnerType<T[K]>>) {
        const array = this.form.get(key) as FormArray<ArrayInnerType<T[K]>>;
        const state = item.get('$state').value as EntityState;
        if (state !== 'added') {
            item.get('$state').setValue('deleted');
        } else {
            const index = array.controls.indexOf(item);
            array.removeAt(index);
        }
    }

    public removeAt<K extends keyof ArrayProperties<T>>(key: K, index: number) {
        const array = this.form.get(key) as FormArray<T[K]>;
        const item = array.at(index);
        const state = item.get('$state').value;
        if (state !== 'added') {
            item.get('$state').setValue('deleted');
        } else {
            array.removeAt(index);
        }
    }

    public isInitialized(): boolean {
        return this._initialized;
    }
}
