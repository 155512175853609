import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommodityApi } from '../api/commodity-api';
import { CommoditySegmentApi } from '../api/commodity-segment-api';
import { CountryApi } from '../api/country-api';
import { CultureInfoApi } from '../api/culture-info-api';
import { DeliveryBranchApi } from '../api/delivery-branch-api';
import { EshopApi } from '../api/eshop-api';
import { LanguageApi } from '../api/language-api';
import { ParcelShopApi } from '../api/parcelshop-api';
import { SearchSelectListApi } from '../api/search-select-list-api';
import { SelectListApi } from '../api/select-list-api';

export * from '../dto/common-dto';

@Injectable()
export class CommonDataService {
    public selectList: SelectListApi = new SelectListApi(this.http);
    public searchSelectList: SearchSelectListApi = new SearchSelectListApi(this.http);
    public country: CountryApi = new CountryApi(this.http);
    public eshop: EshopApi = new EshopApi(this.http);
    public cultureInfo: CultureInfoApi = new CultureInfoApi(this.http);
    public language: LanguageApi = new LanguageApi(this.http);
    public deliveryBranch: DeliveryBranchApi = new DeliveryBranchApi(this.http);
    public commoditySegment: CommoditySegmentApi = new CommoditySegmentApi(this.http);
    public commodityByCodes: CommodityApi = new CommodityApi(this.http);
    public parcelShop: ParcelShopApi = new ParcelShopApi(this.http);

    constructor(private http: HttpClient) {}
}
