<div mat-dialog-title>
    <button type="button" class="close" mat-dialog-close><span aria-hidden="true">×</span></button>
    <h4>{{ 'ImageSelector_ModalTitle' | translate }}</h4>
</div>
<mat-dialog-content>
    <div class="form-group">
        <cms-label for="url" [text]="urlLabel" [tooltip]="urlTooltip" [required]="required"></cms-label>
        <input type="text" class="form-control mrgb-5" [(ngModel)]="url" />
        <label class="control-label">{{ 'ImageSelector_ModalPreviewLabel' | translate }}</label>
        <div class="clearfix"></div>
        <img src="{{ url }}" class="previewImg" />
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button data-tid="btnStorno" type="button" class="btn btn-warning" mat-dialog-close><i class="fas fa-ban"></i> {{ 'Common_Storno' | translate }}</button>
    <button data-tid="btnOk" type="button" class="btn btn-primary" (click)="ok()">{{ 'Common_Save' | translate }}</button>
</mat-dialog-actions>
