import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[appFileInputDropzone]',
    exportAs: 'appFileInputDropzone'
})
export class FileInputDropzoneDirective {
    @Output()
    public dropzoneFilesChange = new EventEmitter<FileList>();

    @HostBinding('class.dropzone')
    public get hasDropzone() {
        return true;
    }

    @HostBinding('class.dropzone-dragover')
    public isDragOver = false;

    @HostListener('drop', ['$event'])
    onDrop(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();
        if (event.dataTransfer.files.length) {
            this.dropzoneFilesChange.emit(event.dataTransfer.files);
        }
        this.isDragOver = false;
    }

    @HostListener('dragover', ['$event'])
    onDragOver(event: DragEvent) {
        event.stopPropagation();
        event.preventDefault();
        this.isDragOver = true;
    }

    @HostListener('dragleave', ['$event'])
    public onDragLeave() {
        this.isDragOver = false;
    }
}
