import { ErrorMessages, FormInputBase } from '@alza/cms-components';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Host, Input, OnDestroy, Optional, SkipSelf, ViewChild, forwardRef } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { IFileSystemFileDto } from 'app/models/file-system';
import { Subscription, of, throwError } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { FileInputDropzoneDirective } from './file-input-dropzone.directive';
import { FileInputFileChooserComponent, IData } from './file-input-file-chooser/file-input-file-chooser.component';
import { FileInputFileExistsComponent, IFileExistsData } from './file-input-file-exists/file-input-file-exists.component';
import { FileInputUrlChooserComponent } from './file-input-url-chooser/file-input-url-chooser.component';
import { readFileSystemFile } from './helpers';

const FILE_INPUT_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FileInputComponent),
    multi: true
};

@Component({
    selector: 'app-file-input',
    templateUrl: './file-input.component.html',
    styleUrls: ['./file-input.component.scss'],
    providers: [FILE_INPUT_VALUE_ACCESSOR],
    viewProviders: [{ provide: ErrorMessages, deps: [TranslateService], useClass: ErrorMessages }]
})
export class FileInputComponent extends FormInputBase<IFileSystemFileDto> implements OnDestroy {
    public value = '';

    public acceptString = '*.*';
    private _accept: string | Array<string>;
    private _dropzonesSubscriptions: Array<Subscription> = [];

    @Input() public serverDirectory = '';
    @Input() public bannerCommodityCode: string = null;
    @Input() public showFileFilter = false;
    @Input() public set accept(value: string | Array<string>) {
        if (Array.isArray(value)) {
            this.acceptString = value.join(',');
        } else {
            this.acceptString = value;
        }
        this._accept = value;
    }

    @Input() public set dropzones(value: Array<FileInputDropzoneDirective>) {
        this._dropzonesSubscriptions.forEach((x) => x.unsubscribe());
        if (!value || !value.length) {
            return;
        }
        this._dropzonesSubscriptions = value.map((x) => x.dropzoneFilesChange.subscribe((files) => void this.onInputFileChange(files)));
    }

    @Input() public serverUploadEnabled = true;
    @Input() public urlUploadEnabled = true;

    @ViewChild('hiddenFileInput', { static: true })
    private _hiddenFileInput: ElementRef<HTMLInputElement>;

    constructor(
        @Optional()
        @Host()
        @SkipSelf()
        controlContainer: ControlContainer,
        errorMessages: ErrorMessages,
        private readonly matDialog: MatDialog,
        private readonly http: HttpClient
    ) {
        super(controlContainer, errorMessages, { tidPrefix: 'file' });
    }

    ngOnDestroy() {
        this._dropzonesSubscriptions.forEach((x) => x.unsubscribe());
    }

    updateValue(obj: IFileSystemFileDto): void {
        if (obj) {
            this.value = obj.name;
        } else {
            this._hiddenFileInput.nativeElement.value = null;
            this.value = null;
        }
    }

    public openServerFileChooser() {
        this.matDialog
            .open<FileInputFileChooserComponent, IData, IFileSystemFileDto>(FileInputFileChooserComponent, {
                panelClass: 'dialog-md',
                data: {
                    serverDirectory: this.serverDirectory,
                    accept: this._accept,
                    bannerCommodityCode: this.bannerCommodityCode,
                    showFilter: this.showFileFilter
                }
            })
            .afterClosed()
            .pipe(filter((x) => !!x))
            .subscribe((res) => {
                this.raiseChange(res);
                this.raiseTouched();
                this.value = res.name;
            });
    }

    public openUrlChooser() {
        this.matDialog
            .open<FileInputUrlChooserComponent, void, IFileSystemFileDto>(FileInputUrlChooserComponent, {
                panelClass: 'dialog-sm'
            })
            .afterClosed()
            .pipe(filter((x) => !!x))
            .subscribe((res) => {
                this.raiseChange(res);
                this.raiseTouched();
                this.value = res.name;
            });
    }

    public async onInputFileChange(files: FileList) {
        this.raiseTouched();
        const file = files.item(0);
        if (!file) {
            return;
        }

        const uploadedFile = await readFileSystemFile(file, this.serverDirectory);
        this.http
            .get<IFileSystemFileDto>('/api/file-system/file-info', { params: { path: uploadedFile.path } })
            .pipe(
                switchMap((res) =>
                    this.matDialog
                        .open<FileInputFileExistsComponent, IFileExistsData, IFileSystemFileDto>(FileInputFileExistsComponent, {
                            data: { server: res, client: uploadedFile },
                            panelClass: 'dialog-md'
                        })
                        .afterClosed()
                ),
                catchError((err: HttpErrorResponse) => {
                    if (err.status === 404) {
                        return of(uploadedFile);
                    } else {
                        throwError(err);
                    }
                }),
                filter((x) => !!x)
            )
            .subscribe((res) => {
                this.value = res.name;
                this.raiseChange(res);
            });
    }

    public remove() {
        this.raiseChange(null);
        this.raiseTouched();
        this.value = null;
    }
}
