/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum FileEditingOption {
    None = 0,
    Text = 1,
    Css = 2,
    Javascript = 3,
    Html = 4,
    Xml = 5,
    Json = 6,
}
