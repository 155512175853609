import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IFileSystemFileDto, IFileSystemItemDto } from 'app/models/file-system';
import { Subject } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';

export interface IData {
    serverDirectory: string;
    accept: string | Array<string>;
    bannerCommodityCode?: string;
    showFilter?: boolean;
}

@Component({
    selector: 'app-file-input-file-chooser',
    templateUrl: './file-input-file-chooser.component.html',
    styleUrls: ['./file-input-file-chooser.component.scss']
})
export class FileInputFileChooserComponent implements OnInit, OnDestroy {
    public items: Array<IFileSystemItemDto | IFileSystemFileDto> = [];

    public nameFilter = '';
    public showFilter = false;
    public path = [];
    public rootPathLength = 0;
    private _filter: string;
    private _loadItems = new Subject<void>();
    private _unsubscribe = new Subject<void>();

    constructor(
        private readonly http: HttpClient,
        @Inject(MAT_DIALOG_DATA) private readonly data: IData,
        private readonly dialogRef: MatDialogRef<FileInputFileChooserComponent, IFileSystemFileDto>
    ) {
        this.path = data.serverDirectory.split('\\').filter((x) => !!x);
        this.showFilter = data.showFilter;
        this.rootPathLength = this.path.length;
        if (Array.isArray(this.data.accept)) {
            this._filter = this.data.accept.map((x) => '*' + x).join('|');
        } else {
            this._filter = '*' + this.data.accept;
        }
    }

    ngOnInit() {
        if (!this.data.bannerCommodityCode) {
            this._loadItems
                .pipe(
                    tap(() => (this.items = [])),
                    debounceTime(150),
                    switchMap(() =>
                        this.http.get<Array<IFileSystemFileDto>>('/api/file-system/ls', {
                            params: { path: this.path.join('\\'), filter: this._filter, includeDirectories: 'true' }
                        })
                    )
                )
                .subscribe((res) => (this.items = res));
            this._loadItems.next();
        } else {
            this._loadItems
                .pipe(
                    tap(() => (this.items = [])),
                    debounceTime(150),
                    switchMap(() => this.http.get<Array<IFileSystemFileDto>>(`/api/internal-banner/commodity-images/${this.data.bannerCommodityCode}`))
                )
                .subscribe((res) => (this.items = res));
            this._loadItems.next();
        }
    }

    ngOnDestroy() {
        this._unsubscribe.next();
        this._unsubscribe.complete();
    }

    public filter() {
        if (this.nameFilter !== '') {
            let filter = this.nameFilter;
            filter = filter.startsWith('*') ? filter : `*${filter}`;
            filter = filter.endsWith('*') ? filter : `${filter}*`;

            this.http
                .get<Array<IFileSystemFileDto>>('/api/file-system/ls', {
                    params: { path: this.path.join('\\'), filter: filter, includeDirectories: 'false' }
                })
                .subscribe((res) => {
                    this.items = res;
                });
        } else {
            this._loadItems.next();
        }
    }

    public clearFilter() {
        this.nameFilter = '';
        this._loadItems.next();
    }

    public up() {
        this.path.pop();
        this._loadItems.next();
    }

    public openDirectory(dir: IFileSystemItemDto) {
        this.path.push(dir.name);
        this._loadItems.next();
    }

    public chooseFile(file: IFileSystemFileDto) {
        this.http.get<IFileSystemFileDto>('/api/file-system/file-info', { params: { path: file.path } }).subscribe((res) => {
            this.dialogRef.close(res);
        });
    }
}
