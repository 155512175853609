import { Validators } from '@alza/cms-components';
import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ParameterRenderType, ParameterTypeType } from 'app/models/enums/parameters';
import { IParameterEnumValueDto, IParameterTypeCollectionItemDto } from 'app/models/parameter';
import { ParameterGroupDataService } from 'app/modules/parameter/parameter-group-data.service';
import { ParameterTypeDataService } from 'app/modules/parameter/parameter-type-data.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IParameterValue, IParameterValues } from './models';

@Component({
    selector: 'app-parameter-values',
    templateUrl: './parameter-values.component.html',
    styleUrls: ['./parameter-values.component.scss']
})
export class ParameterValuesComponent implements OnDestroy {
    private _unsubscribe = new Subject<void>();
    private _typeId: number;
    private _typeGroupId: number;
    private _renderType: ParameterRenderType;
    private _typeType: ParameterTypeType;
    public enumForm: UntypedFormArray;
    public valueForm: UntypedFormGroup;
    public valueRangeForm: UntypedFormGroup;
    public numericForm: UntypedFormArray;
    public cableForm: UntypedFormArray;
    public showAll = false;
    public selectedLeftId: number;
    public selectedRightId: number;
    public cableArray: Array<IParameterEnumValueDto>;
    public doesMoreExist: boolean;
    public unitName: string;
    public isHierarchic: boolean;
    public hierarchicParameters: Array<IParameterTypeCollectionItemDto> = [];
    public selectedParameterTypeEnumIds: Array<number> = [];

    @Input()
    // eslint-disable-next-line @typescript-eslint/naming-convention
    private parameterValues: IParameterValues;

    @Input()
    // eslint-disable-next-line @typescript-eslint/naming-convention
    private categoryId: number;

    @Output()
    // eslint-disable-next-line @typescript-eslint/naming-convention
    private valueChanged = new EventEmitter<IParameterValue>();

    constructor(
        private readonly parameterTypeService: ParameterTypeDataService,
        private readonly parameterGroupService: ParameterGroupDataService,
        private formBuilder: UntypedFormBuilder
    ) {}

    @Input()
    public set type(typeId: number) {
        this._typeId = typeId;
        this.init();
    }

    @Input()
    public set typeGroup(typeGroupId: number) {
        this._typeGroupId = typeGroupId;
        this.init();
    }

    private cleanState() {
        this.enumForm = null;
        this.valueForm = null;
        this.valueRangeForm = null;
        this.numericForm = null;
        this.cableForm = null;
        this._typeType = null;
        this.selectedRightId = null;
        this.selectedLeftId = null;
        this._unsubscribe.next();
        this._unsubscribe.complete();
        this._unsubscribe = new Subject<void>();
    }

    private init() {
        this.cleanState();
        if (!this._typeId || !this._typeGroupId) {
            return;
        }

        this.parameterTypeService
            .loadFilterInfo(this._typeGroupId)
            .pipe(takeUntil(this._unsubscribe))
            .subscribe((val) => {
                this._typeType = val.typeType;
                this._renderType = val.renderType;
                this.unitName = val.unitName;
                this.isHierarchic = val.isHierarchic;

                switch (this._typeType) {
                    case ParameterTypeType.Number:
                    case ParameterTypeType.Decimal:
                    case ParameterTypeType.DateTime:
                        switch (this._renderType) {
                            case ParameterRenderType.Checkbox:
                                this.prepareValueNumeric(this._typeGroupId, this.categoryId);
                                break;
                            case ParameterRenderType.Slider:
                                this.prepareValueRangeForm();
                                break;
                        }
                        break;
                    case ParameterTypeType.Enum:
                    case ParameterTypeType.MultiChoiceEnum:
                        if (this._renderType === ParameterRenderType.Checkbox || this._renderType === ParameterRenderType.Image) {
                            this.prepareEnumForm(val.groupId);
                        }

                        break;
                    case ParameterTypeType.Cable:
                        if (this._renderType === ParameterRenderType.Cable) {
                            this.prepareValueCable();
                        }
                        break;
                }
            });
    }

    ngOnDestroy() {
        this.cleanState();
    }

    public prepareEnumForm(groupId: number) {
        this.enumForm = this.formBuilder.array([]);
        if (this.isHierarchic) {
            this.parameterGroupService.getHierarchicTypesForGroup(groupId, this._typeId, false).subscribe((values) => {
                this.hierarchicParameters = values;
                this.selectedParameterTypeEnumIds[0] = this.hierarchicParameters[0]?.values[0]?.id;
                this.hierarchicValueChanged(this.selectedParameterTypeEnumIds[0], 0);
            });
        } else {
            this.parameterTypeService.getEnumValuesByParameterType(this._typeId, this.categoryId).subscribe((values) => {
                values.forEach((v) => {
                    const group = this.formBuilder.group({
                        enumId: v.id,
                        enumText: v.name,
                        existsCommodity: v.existsCommodity,
                        selected: false
                    });
                    if (
                        this.parameterValues &&
                        this.parameterValues[this._typeGroupId]?.enumIds &&
                        this.parameterValues[this._typeGroupId]?.enumIds.some((x) => x.enumId === v.id)
                    ) {
                        group.patchValue({ selected: true });
                    }
                    this.enumForm.push(group);
                });
                this.doesMoreExist = values.some((x) => !x.existsCommodity);
            });
        }
        this.enumForm.valueChanges.pipe(takeUntil(this._unsubscribe)).subscribe((value) => {
            value.forEach((v) => {
                const itemIndex = this.parameterValues[this._typeGroupId]?.enumIds?.findIndex((x) => x.enumId === v.enumId);

                if (v.selected && (itemIndex == null || itemIndex === -1)) {
                    this.parameterValues[this._typeGroupId] ??= { enumIds: [] };
                    this.parameterValues[this._typeGroupId].enumIds.push({ enumId: v.enumId, id: null });

                    this.hierarchicParameters.forEach((x, index) => {
                        const parentItem = this.parameterValues[x.parameterTypeGroupId]?.enumIds?.find(
                            (x) => x.enumId === this.selectedParameterTypeEnumIds[index]
                        );

                        if (!parentItem) {
                            this.parameterValues[x.parameterTypeGroupId] ??= { enumIds: [] };
                            this.parameterValues[x.parameterTypeGroupId].enumIds.push({ enumId: this.selectedParameterTypeEnumIds[index], id: null });
                        }
                    });
                }

                if (!v.selected && itemIndex > -1) {
                    this.parameterValues[this._typeGroupId].enumIds.splice(itemIndex, 1);
                }
            });

            if (this.isHierarchic && value.every((x) => !x.selected)) {
                const index = this.hierarchicParameters.length - 1;

                const lastItem = this.parameterValues[this.hierarchicParameters[index].parameterTypeGroupId]?.enumIds?.findIndex(
                    (x) => x.enumId === this.selectedParameterTypeEnumIds[index]
                );
                if (lastItem > -1) {
                    this.parameterValues[this.hierarchicParameters[index].parameterTypeGroupId]?.enumIds.splice(lastItem, 1);
                }

                for (let i = index - 1; i >= 0; i--) {
                    if (
                        this.hierarchicParameters[i + 1].values.every(
                            (x) => !this.parameterValues[this.hierarchicParameters[i + 1].parameterTypeGroupId].enumIds.find((y) => y.enumId === x.id)
                        )
                    ) {
                        const indexToRemove = this.parameterValues[this.hierarchicParameters[i].parameterTypeGroupId].enumIds.findIndex(
                            (x) => x.enumId === this.selectedParameterTypeEnumIds[i]
                        );
                        this.parameterValues[this.hierarchicParameters[i].parameterTypeGroupId].enumIds.splice(indexToRemove, 1);
                    }
                }
            }

            this.valueChanged.emit(this.parameterValues);
        });
    }

    public hierarchicValueChanged(id: number, index: number) {
        this.enumForm.clear({ emitEvent: false });
        this.selectedParameterTypeEnumIds[index] = id;

        if (this.hierarchicParameters[index + 1]) {
            this.selectedParameterTypeEnumIds[index + 1] = null;
            this.hierarchicParameters[index + 1].values = null;

            this.parameterTypeService.getTypeEnumChildren(id).subscribe((data) => {
                if (!data || !data.length) {
                    return;
                }

                this.hierarchicParameters[index + 1].values = data;
                this.selectedParameterTypeEnumIds[index + 1] = this.hierarchicParameters[index + 1].values[0].id;
                if (index < this.hierarchicParameters.length - 1) {
                    this.hierarchicValueChanged(this.selectedParameterTypeEnumIds[index + 1], index + 1);
                }
            });
        } else {
            this.parameterTypeService.getEnumValuesByParameterType(this._typeId, this.categoryId, id).subscribe((values) => {
                values.forEach((v) => {
                    const group = this.formBuilder.group({
                        enumId: v.id,
                        enumText: v.name,
                        existsCommodity: v.existsCommodity,
                        selected: false
                    });

                    if (
                        this.parameterValues[this._typeGroupId] &&
                        this.parameterValues[this._typeGroupId]?.enumIds &&
                        this.parameterValues[this._typeGroupId]?.enumIds.some((x) => x.enumId === v.id)
                    ) {
                        group.patchValue({ selected: true });
                    }
                    this.enumForm.push(group, { emitEvent: false });
                });
                this.doesMoreExist = values.some((x) => !x.existsCommodity);
            });
        }
    }

    public prepareValueRangeForm() {
        this.valueRangeForm = this.formBuilder.group(
            {
                valueFrom: null,
                valueTo: null
            },
            { validators: [Validators.dateRange({ fromControlName: 'valueFrom', toControlName: 'valueTo' })] }
        );
        if (this.parameterValues && (this.parameterValues[this._typeGroupId]?.valueFrom || this.parameterValues[this._typeGroupId]?.valueTo)) {
            this.valueRangeForm.patchValue(this.parameterValues[this._typeGroupId]);
        }

        this.valueRangeForm.valueChanges.pipe(takeUntil(this._unsubscribe)).subscribe((value) => {
            if (this.valueRangeForm.valid) {
                this.valueChanged.emit({
                    [this._typeGroupId]: { id: this.parameterValues[this._typeGroupId]?.id, valueFrom: value.valueFrom, valueTo: value.valueTo }
                });
            } else {
                this.valueChanged.emit({ [this._typeGroupId]: { valueFrom: null, valueTo: null } });
            }
        });
    }

    public prepareValueNumeric(typeGroupId: number | string, categoryId: number) {
        typeGroupId = Number(typeGroupId);
        this.numericForm = this.formBuilder.array([]);
        this.parameterTypeService.loadCategoryDetailNumeric(typeGroupId, categoryId).subscribe((values) => {
            values.forEach((v) => {
                const group = this.formBuilder.group({
                    value: v.value,
                    existsCommodity: v.existsCommodity,
                    selected: false
                });
                if (
                    this.parameterValues &&
                    this.parameterValues[this._typeGroupId]?.values &&
                    this.parameterValues[this._typeGroupId]?.values.some((x) => x.value === v)
                ) {
                    group.patchValue({ selected: true });
                }
                this.numericForm.push(group);
            });
            this.doesMoreExist = values.some((x) => !x.existsCommodity);
        });
        this.numericForm.valueChanges.pipe(takeUntil(this._unsubscribe)).subscribe((value) => {
            this.valueChanged.emit({
                [this._typeGroupId]: {
                    values: value
                        .filter((v) => v.selected === true)
                        .map((v) => ({ value: v.value, id: this.parameterValues[this._typeGroupId]?.values.find((x) => x.value === v.value)?.id }))
                }
            });
        });
    }

    public prepareValueCable() {
        this.cableForm = this.formBuilder.array([]);
        this.parameterTypeService.getEnumValuesByParameterType(this._typeId, this.categoryId).subscribe((values) => {
            if (this.parameterValues[this._typeGroupId]?.cableRight) {
                this.selectedRightId = this.parameterValues[this._typeGroupId].cableRight;
            }
            if (this.parameterValues[this._typeGroupId]?.cableLeft) {
                this.selectedLeftId = this.parameterValues[this._typeGroupId].cableLeft;
            }
            this.cableArray = values;
            this.doesMoreExist = values.some((x) => !x.existsCommodity);
        });
    }

    public onCableChange(newLeft: number) {
        if (newLeft !== this.selectedLeftId && !newLeft) {
            this.selectedRightId = null;
        }
        if (this.selectedRightId && !this.selectedLeftId) {
            return;
        }

        this.valueChanged.emit({
            [this._typeGroupId]: {
                id: this.parameterValues[this._typeGroupId]?.id,
                cableLeft: this.selectedLeftId,
                cableRight: this.selectedRightId
            }
        });
    }

    public toggleMore() {
        this.showAll = this.showAll ? false : true;
    }
}
