<div *ngFor="let i of items">
    <div class="treeItem" [ngClass]="['lvl' + level]" *ngIf="i.subItems">
        <span
            class="expand"
            [attr.data-tid]="'plus_' + i.name"
            *ngIf="i.subItems?.length && (!maxLevel || level <= maxLevel)"
            (click)="!isSelectionEnabled && toggleExpand(i)"
        >
            <i
                class="fas"
                (click)="isSelectionEnabled && toggleExpand(i)"
                [ngClass]="{
                    'fa-plus-square': !i.isExpanded,
                    'fa-minus-square': i.isExpanded
                }"
            ></i>
        </span>
        <div class="form-group" [class.checkbox]="isSelectionEnabled" (click)="onTreeItemClick(i)">
            <input *ngIf="isSelectionEnabled" [id]="'cbSelectItem' + i.id" [attr.data-tid]="i.name" type="checkbox" [checked]="i.isSelected" />
            <label
                [id]="'lblSelectItem' + i.id"
                class="cursor-pointer"
                [ngClass]="{
                    'cb-item-selected': isSelectionEnabled && i.isSelected,
                    'cb-item-match': i.isMatch,
                    indeterminate: i.isIndeterminate
                }"
                >{{ i.name }}</label
            >
        </div>
    </div>
    <div class="subtree" *ngIf="i.isExpanded && (!maxLevel || level < maxLevel)">
        <app-tree-item
            [items]="i.subItems"
            [level]="level"
            [maxLevel]="maxLevel"
            [isSelectionEnabled]="isSelectionEnabled"
            (treeItemClick)="onChildTreeItemClick($event)"
            (treeItemCheckedChange)="onChildTreeItemCheckedChange($event)"
        ></app-tree-item>
    </div>
</div>
