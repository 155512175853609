<h3>{{ 'Parameters_ParameterGroup_Collection_Title' | translate }}</h3>
<div class="table-responsive" [hidden]="!items.length">
    <table class="table table-striped table-bordered table-hover dataTable">
        <thead>
            <tr role="row">
                <th style="width: 70px">{{ 'Parameters_ParameterGroup_Collection_GroupId' | translate }}</th>
                <th style="width: 50px">{{ 'Parameters_ParameterGroup_Collection_Order' | translate }}</th>
                <th style="width: 30px">{{ 'Parameters_ParameterGroup_Collection_ShowAtShowroom' | translate }}</th>
                <th style="width: 250px">{{ 'Parameters_ParameterGroup_Collection_Name' | translate }}</th>
                <th>{{ 'Parameters_ParameterGroup_Collection_Note' | translate }}</th>
                <th style="width: 90px">{{ 'Parameters_ParameterGroup_Collection_HiearchicGroup' | translate }}</th>
            </tr>
        </thead>
        <tbody>
            <tr
                role="row"
                [attr.data-tid]="'rowGroup_' + ix"
                class="table-row-text-overflow fadeInLeft"
                *ngFor="let item of items; let ix = index"
                (click)="groupChanged(item.id)"
                [ngClass]="{ selectedGroup: groupId === item.id }"
            >
                <td>{{ item.id }}</td>
                <td>{{ item.order }}</td>
                <td class="centered">
                    <span *ngIf="item.orderDescription" class="fas fa-check"></span> <span *ngIf="!item.orderDescription" class="fas fa-times"></span>
                </td>
                <td>{{ item.name }}</td>
                <td>
                    <span [cmsTooltip]="item.note">{{ item.note }}</span>
                </td>
                <td class="centered">
                    <span *ngIf="item.isHierarchic" class="fas fa-check"></span> <span *ngIf="!item.isHierarchic" class="fas fa-times"></span>
                </td>
            </tr>
        </tbody>
    </table>
    <div class="bold">{{ 'Parameters_ParameterGroup_Collection_TotalGroups' | translate }} {{ items.length }}</div>
</div>
<cms-label *ngIf="!items.length" [text]="'Parameters_ParameterGroup_Collection_NoGroups' | translate"></cms-label>
