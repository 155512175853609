<div class="form-group">
    <cms-label
        *ngIf="labelText"
        [required]="labelRequired"
        [for]="id"
        [text]="labelText"
        [tooltip]="labelTooltip"
        [tooltipDirection]="labelTooltipDirection"
        [tooltipIcon]="labelTooltipIcon"
        [class]="labelClass"
    ></cms-label>
    <textarea
        [froalaModel]="value"
        (froalaModelChange)="onValueChange($event)"
        [attr.id]="id"
        class="form-control"
        autocomplete="off"
        [froalaEditor]="options"
        [delayDestroy]="delayDestroy"
        (froalaInit)="initialize($event)"
        *ngIf="!disabled && !showCode"
    ></textarea>
    <app-monaco-editor
        [ngModel]="value"
        (ngModelChange)="onValueChange($event)"
        [id]="id"
        *ngIf="showCode"
        (hideEditor)="switchEditor()"
        [displayToolbar]="true"
        [standalone]="false"
    ></app-monaco-editor>
    <iframe src="about:blank" [attr.id]="'iframe' + id" frameborder="0" style="width: 100%" *ngIf="disabled"> </iframe>
    <cms-field-errors [submitted]="submitted" [control]="control"></cms-field-errors>
</div>
