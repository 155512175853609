/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum AdyenThreeDSecureSetting {
    StoreExecuteThreeDAttributeEnabled = 1,
    StoreExecuteThreeDAttributeDisabled = 2,
    StoreAllow3DS2AttributeEnabled = 4,
    StoreAllow3DS2AttributeDisabled = 8,
    StoreRequestChallengeAsMandateValueEnabled = 16,
    RecurringExecuteThreeDAttributeEnabled = 32,
    RecurringExecuteThreeDAttributeDisabled = 64,
    RecurringAllow3DS2AttributeEnabled = 128,
    RecurringAllow3DS2AttributeDisabled = 256,
    RecurringRequestChallengeAsMandateValueEnabled = 512,
    StandardExecuteThreeDAttributeEnabled = 1024,
    StandardExecuteThreeDAttributeDisabled = 2048,
    StandardAllow3DS2AttributeEnabled = 4096,
    StandardAllow3DS2AttributeDisabled = 8192,
    StandardRequestChallengeAsMandateValueEnabled = 16384,
}
