<h3>
    {{ 'ParametersValues_Title' | translate }}
</h3>

<div *ngIf="valueRangeForm" class="row">
    <cms-number-input
        class="col-xs-12 col-sm-6"
        [id]="'valueFrom'"
        name="valueFrom"
        [labelText]="'ParameterValues_ValueFrom' | translate"
        [formControl]="valueRangeForm.get('valueFrom')"
    >
        <span *ngIf="unitName" right-addon class="input-group-addon" [innerHTML]="unitName"></span>
        <error-message [errorCode]="'dateRangeFrom'" [message]="'ParameterValues_FromMustBeSmallerThanToError' | translate"> </error-message>
    </cms-number-input>
    <cms-number-input
        class="col-xs-12 col-sm-6"
        [id]="'valueTo'"
        name="valueTo"
        [labelText]="'ParameterValues_ValueTo' | translate"
        [formControl]="valueRangeForm.get('valueTo')"
    >
        <span *ngIf="unitName" right-addon class="input-group-addon" [innerHTML]="unitName"></span>
        <error-message [errorCode]="'dateRangeTo'" [message]="'ParameterValues_ToMustBeBiggerThanFromError' | translate"> </error-message>
    </cms-number-input>
</div>

<div *ngIf="isHierarchic" class="row">
    <cms-select
        *ngFor="let value of hierarchicParameters; let ix = index"
        class="col-xs-12 col-lg-6"
        [labelText]="value.name"
        [id]="'HierarchicValue' + ix"
        name="hierarchicValue"
        [ngModel]="selectedParameterTypeEnumIds[ix]"
        (ngModelChange)="hierarchicValueChanged($event, ix)"
        [ngModelOptions]="{ standalone: true }"
        [items]="value.values"
        [bindLabel]="'name'"
        [bindValue]="'id'"
        [virtualScroll]="true"
    >
    </cms-select>
</div>

<div *ngIf="enumForm">
    <ng-container *ngTemplateOutlet="enumFormTemplate; context: { exists: true }"></ng-container>
</div>

<div *ngIf="numericForm">
    <div *ngFor="let control of numericForm.controls">
        <ng-container *ngTemplateOutlet="numFormTemplate; context: { control: control, exists: true }"></ng-container>
    </div>
</div>
<div *ngIf="cableForm" class="row">
    <div class="col-md-6">
        <div class="form-group">
            <ng-select
                [items]="cableArray"
                [id]="'cableLeftSelect'"
                name="cableLeftSelect"
                [(ngModel)]="selectedLeftId"
                (change)="onCableChange($event, selectedRightId)"
                bindLabel="name"
                bindValue="id"
            >
            </ng-select>
        </div>
    </div>
    <div class="col-md-6">
        <ng-select
            [items]="cableArray"
            [id]="'cableRightSelect'"
            name="cableRightSelect"
            [(ngModel)]="selectedRightId"
            (change)="onCableChange(selectedLeftId, $event)"
            bindLabel="name"
            bindValue="id"
            [disabled]="!selectedLeftId"
        >
        </ng-select>
    </div>
</div>
<div class="row" *ngIf="((enumForm && enumForm.controls) || (numericForm && numericForm.controls)) && doesMoreExist">
    <div class="col-xs-12 float-right show-link" (click)="toggleMore()" *ngIf="cableForm || numericForm || enumForm">
        <span *ngIf="!showAll">{{ 'ParameterValues_ModalLoadRest' | translate }}</span>
        <span *ngIf="showAll">{{ 'ParameterValues_ModalHideRest' | translate }}</span>
        <hr *ngIf="showAll" />
    </div>
</div>
<div *ngIf="showAll && enumForm">
    <ng-container *ngTemplateOutlet="enumFormTemplate; context: { exists: false }"></ng-container>
</div>

<div *ngIf="showAll && numericForm">
    <div *ngFor="let control of numericForm.controls">
        <ng-container *ngTemplateOutlet="numFormTemplate; context: { control: control, exists: false }"></ng-container>
    </div>
</div>

<ng-template #numFormTemplate let-control="control" let-exists="exists">
    <div *ngIf="exists === control.get('existsCommodity').value">
        <cms-checkbox class="display-inline-block" [id]="'numericValue' + control.get('value').value" [formControl]="control.get('selected')"></cms-checkbox>
        <label [for]="'numericValue' + control.get('value').value" class="cursor-pointer">
            {{ control.get('value').value }} <span [innerHTML]="unitName"></span>
        </label>
    </div>
</ng-template>

<ng-template #enumFormTemplate let-exists="exists">
    <div *ngFor="let control of enumForm.controls">
        <div *ngIf="exists === control.get('existsCommodity').value">
            <cms-checkbox class="display-inline-block" [id]="'enumId' + control.get('enumId').value" [formControl]="control.get('selected')"> </cms-checkbox>
            <label [for]="'enumId' + control.get('enumId').value" class="cursor-pointer">
                {{ control.get('enumText').value }} <span [innerHTML]="unitName"></span>
            </label>
        </div>
    </div>
</ng-template>
