/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum PaymentProviderServiceType {
    Aisp = 1,
    Pisp = 2,
    PispInstantPayments = 3,
    BankId = 4,
    SplitPayment = 5,
    RecurringPayment = 6,
    BatchPayment = 7,
}
