import { HttpClient, HttpParams } from '@angular/common/http';
import { ISelectListItemDto } from 'app/models';
import { BaseApi } from './base-api';

export class SearchSelectListApi extends BaseApi {
    private _apiRoot = 'api/common/search-select-list';
    public constructor(http: HttpClient) {
        super(http);
    }

    private getSelectListItems(id: number, searchValue: string, apiUrl: string) {
        let params = new HttpParams();

        if (id !== undefined && id !== null) {
            params = params.set('id', id);
        }
        if (searchValue) {
            params = params.set('searchValue', searchValue);
        }

        return this.http.get<Array<ISelectListItemDto>>(apiUrl, { params: params });
    }

    public getArticles(id: number, searchValue: string) {
        return this.getSelectListItems(id, searchValue, `api/articles/search-select-list/`);
    }

    public getCategories(id: number, searchValue: string) {
        return this.getSelectListItems(id, searchValue, `${this._apiRoot}/categories`);
    }

    public getCommodities(id: number, searchValue: string) {
        return this.getSelectListItems(id, searchValue, `${this._apiRoot}/commodities`);
    }

    public getProducers(id: number, searchValue: string) {
        return this.getSelectListItems(id, searchValue, `${this._apiRoot}/producers`);
    }

    public getActionCategories(id: number, searchValue: string) {
        return this.getSelectListItems(id, searchValue, `${this._apiRoot}/action-categories`);
    }

    public getMailingActions(id: number, searchValue: string) {
        return this.getSelectListItems(id, searchValue, `${this._apiRoot}/mailing-actions`);
    }

    public getCommodityPacks(id: number, searchValue: string) {
        return this.getSelectListItems(id, searchValue, `${this._apiRoot}/commodity-packs`);
    }
}
