<nav class="navbar-default navbar-static-side" role="navigation">
    <div class="sidebar-collapse">
        <a class="close-canvas-menu" *ngIf="!isMiniNavbar()"><i class="fas fa-chevron-left" (click)="toggleMenu()" data-tid="btnMinimizeMenu"></i></a>
        <ul class="nav">
            <li class="nav-header">
                <div *ngIf="isMiniNavbar() && !isMobile">
                    <a class="minimalize-styl-2 open-canvas-menu" (click)="toggleMenu()" data-tid="btnMinimizeMenu">
                        <i class="fas fa-chevron-right"></i>
                    </a>
                </div>
                <div class="dropdown profile-element">
                    <a data-toggle="dropdown" class="dropdown-toggle" href="javascript:void(0)" data-tid="ddUserMenu">
                        <span class="clear">
                            <span class="block m-t-xs">
                                <strong class="font-bold"> {{ user?.displayName }} <b class="caret"></b> </strong>
                            </span>
                        </span>
                    </a>
                    <ul class="dropdown-menu animated fadeInRight m-t-xs">
                        <li>
                            <a href="/api/accounts/logout" data-tid="linkLogout"><i class="fas fa-sign-out-alt"></i> {{ 'Menu_SignOut' | translate }}</a>
                        </li>
                        <li>
                            <a routerLink="/change-password" data-tid="linkChangePassword"
                                ><i class="fas fa-fw fa-wrench"></i> {{ 'Menu_ChangePassword' | translate }}</a
                            >
                        </li>
                        <li *ngIf="user?.phoneNumber">
                            <a routerLink="/setup-2fa" data-tid="linkLoginSettings"><i class="fas fa-fw fa-cogs"></i> {{ 'Menu_LoginSettings' | translate }}</a>
                        </li>
                    </ul>
                </div>
                <div class="logo-element">{{ displayNameInitials }}</div>
            </li>
            <li>
                <div class="form-group dropdown">
                    <div class="input-group search-parent" (mouseenter)="searchHovered = true" (mouseleave)="searchHovered = false">
                        <label
                            for="searchInput"
                            class="input-group-addon search-icon"
                            *ngIf="!isMiniNavbar()"
                            [cmsTooltip]="'Menu_SearchModal_Tooltip' | translate"
                        >
                            <i class="fas fa-search"></i>
                        </label>
                        <span
                            class="input-group-addon search-icon search-icon-alone"
                            [class.underline]="searchHovered"
                            *ngIf="isMiniNavbar()"
                            (click)="showSearchModal()"
                        >
                            <i class="fas fa-search"></i>
                        </span>
                        <input
                            #search
                            [(ngModel)]="searchTerm"
                            (blur)="searchFocused = false"
                            (focus)="searchFocused = true"
                            (keyup)="searchTermSub()"
                            type="text"
                            id="searchInput"
                            placeholder="{{ 'Topbar_SearchPlaceholder' | translate }}"
                            class="form-control ui-autocomplete-input dfl search"
                            autocomplete="off"
                            data-tid="tbSearchMenu"
                            *ngIf="!isMiniNavbar()"
                        />
                        <span
                            class="input-group-addon clear-icon cursor-pointer"
                            *ngIf="!isMiniNavbar() && searchTerm.length > 0"
                            (click)="clearSearch()"
                            data-tid="btnClearMenuSearch"
                            ><i class="fas fa-times"></i>
                        </span>
                    </div>
                    <hr [class.underline]="searchFocused" />
                </div>
            </li>

            <li *ngIf="!isProd" class="menuLvl0">
                <a routerLink="/HowTo" style="color: coral"><i class="fas fa-question-circle"></i><span *ngIf="!isMobile">Help pro vývoj Ng</span></a>
            </li>
            <li
                *ngFor="let lvl0 of shownItems"
                [attr.data-has-children]="!!lvl0.items.length"
                [attr.data-tid]="lvl0.id"
                class="menuLvl0"
                [class.active]="lvl0.isActive"
                (click)="menuItemClick($event, lvl0)"
            >
                <a
                    *ngIf="!lvl0.isMvcModule && ((lvl0.hiddenOnProduction && !isProduction) || !lvl0.hiddenOnProduction) && lvl0.url"
                    [routerLink]="lvl0.url"
                    [class.hidden-on-prod]="lvl0.hiddenOnProduction"
                >
                    <i [ngClass]="lvl0.iconFa5"></i> <span class="nav-label">{{ lvl0.title }}</span>
                    <span *ngIf="lvl0.items.length" class="fas arrow"></span>
                </a>
                <a
                    *ngIf="!lvl0.isMvcModule && ((lvl0.hiddenOnProduction && !isProduction) || !lvl0.hiddenOnProduction) && lvl0.items.length"
                    [class.hidden-on-prod]="lvl0.hiddenOnProduction"
                    href="javascript:void(0)"
                >
                    <i [ngClass]="lvl0.iconFa5"></i> <span class="nav-label">{{ lvl0.title }}</span>
                    <span *ngIf="lvl0.items.length" class="fas arrow"></span>
                </a>
                <a
                    *ngIf="lvl0.isMvcModule && ((lvl0.hiddenOnProduction && !isProduction) || !lvl0.hiddenOnProduction) && (lvl0.items.length || lvl0.url)"
                    [attr.href]="lvl0.url"
                    [class.hidden-on-prod]="lvl0.hiddenOnProduction"
                >
                    <i [ngClass]="lvl0.iconFa5"></i> <span class="nav-label">{{ lvl0.title }}</span>
                    <span *ngIf="lvl0.items.length" class="fas arrow"></span>
                </a>
                <ul class="nav nav-second-level collapse" [class.in]="lvl0.isActive" *ngIf="lvl0.items.length">
                    <li
                        *ngFor="let lvl1 of lvl0.items"
                        [attr.data-has-children]="!!lvl1.items.length"
                        [attr.data-tid]="lvl1.id"
                        [class.active]="lvl1.isActive"
                        (click)="menuItemClick($event, lvl1)"
                    >
                        <a
                            *ngIf="lvl0.isActive && !lvl1.isMvcModule && ((lvl1.hiddenOnProduction && !isProduction) || !lvl1.hiddenOnProduction) && lvl1.url"
                            [routerLink]="lvl1.url"
                            [class.hidden-on-prod]="lvl1.hiddenOnProduction"
                        >
                            {{ lvl1.title }} <span *ngIf="lvl1.items.length" class="fas arrow"></span>
                        </a>
                        <a
                            *ngIf="
                                lvl0.isActive &&
                                !lvl1.isMvcModule &&
                                ((lvl1.hiddenOnProduction && !isProduction) || !lvl1.hiddenOnProduction) &&
                                lvl1.items.length
                            "
                            [class.hidden-on-prod]="lvl1.hiddenOnProduction"
                            href="javascript:void(0)"
                        >
                            {{ lvl1.title }} <span *ngIf="lvl1.items.length" class="fas arrow"></span>
                        </a>
                        <a
                            *ngIf="
                                lvl1.isMvcModule && ((lvl1.hiddenOnProduction && !isProduction) || !lvl1.hiddenOnProduction) && (lvl1.items.length || lvl1.url)
                            "
                            [attr.href]="lvl1.url"
                            [class.hidden-on-prod]="lvl1.hiddenOnProduction"
                        >
                            {{ lvl1.title }} <span *ngIf="lvl1.items.length" class="fas arrow"></span>
                        </a>
                        <ul class="nav nav-third-level collapse" [class.in]="lvl1.isActive" *ngIf="lvl1.items.length">
                            <li *ngFor="let lvl2 of lvl1.items" [attr.data-tid]="lvl2.id" [class.active]="lvl2.isActive" (click)="menuItemClick($event, lvl2)">
                                <a
                                    *ngIf="lvl1.isActive && !lvl2.isMvcModule && ((lvl2.hiddenOnProduction && !isProduction) || !lvl2.hiddenOnProduction)"
                                    [routerLink]="lvl2.url"
                                    [class.hidden-on-prod]="lvl2.hiddenOnProduction"
                                >
                                    {{ lvl2.title }}
                                </a>
                                <a
                                    *ngIf="lvl2.isMvcModule && ((lvl2.hiddenOnProduction && !isProduction) || !lvl2.hiddenOnProduction)"
                                    [attr.href]="lvl2.url"
                                    [class.hidden-on-prod]="lvl2.hiddenOnProduction"
                                >
                                    {{ lvl2.title }}
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</nav>
