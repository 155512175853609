<div
    [@openClose]="isOpen ? 'open' : 'closed'"
    (@openClose.start)="isOpen ? null : (overflowVis = false)"
    (@openClose.done)="isOpen ? (overflowVis = true) : (overflowVis = false)"
    [style.overflow]="overflowVis ? 'visible' : 'hidden'"
    id="hiddenContent"
    class="open-close-container"
>
    <ng-content></ng-content>
</div>
