/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum ChangePasswordResult {
    Succeeded = 0,
    AuthFailed = 1,
    WeakPassword = 2,
    PasswordsNotEqual = 3,
    TokenNotValid = 4,
}
