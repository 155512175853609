<div class="loading-wrapper" [class.active]="loading">
    <ng-content></ng-content>
    <div class="loading-bar loading-background" [@openClose]="loading ? 'open' : 'closed'"></div>
    <div class="loading-bar" [@openCloseInner]="loading ? 'open' : 'closed'">
        <div class="content-wrapper">
            <div class="inner-content">
                <h2 class="text-center">{{ 'Common_GridLoading' | translate }} <span class="dots"></span></h2>
                <div class="progress progress-striped active">
                    <div
                        class="progress-bar progress-bar-striped progress-bar-animated progress-bar-primary"
                        style="width: 100%"
                        role="progressbar"
                        aria-valuenow="100"
                        aria-valuemin="0"
                        aria-valuemax="100"
                    ></div>
                </div>
            </div>
        </div>
    </div>
</div>
