import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

interface IMessage {
    messageKey: string;
    value: any;
}

@Injectable({ providedIn: 'root' })
export class MessageService {
    private _subject = new Subject<IMessage>();

    sendMessage(key: string, message: any = null) {
        const msg: IMessage = {
            messageKey: key,
            value: message
        };
        this._subject.next(msg);
    }

    getMessage<T = any>(key: string): Observable<T> {
        return this._subject.asObservable().pipe(
            filter((y) => y.messageKey === key),
            map((x) => x.value)
        );
    }
}
