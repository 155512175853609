/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum WebAdminColor {
    Undefined = 0,
    Green = 1,
    Grey = 2,
    Orange = 3,
    Red = 4,
}
