<div
    [ngClass]="classes[segmentInputsDisplayed]"
    *ngIf="!this.isMultiple[segmentDepth.Root] && !this.isMultiple[segmentDepth.Branch] && !this.isMultiple[segmentDepth.Leaf]"
>
    <div class="form-group">
        <label [attr.for]="id + '_segment' + segmentInputsDisplayed" class="sgs-label" [ngClass]="{ 'br-label': !breakLabelOnOverflow }">
            <span
                *ngFor="let br of this.selectedSegmentsBreadcrumbTexts; let i = index"
                (click)="setSegmentAsEditable(i)"
                [attr.data-tid]="tid + '_label_segment' + i"
                ><span *ngIf="i > 0"> / </span>
                <strong class="segment-selected" *ngIf="i === segmentInputsDisplayed">{{ br }}</strong>
                <span *ngIf="i !== segmentInputsDisplayed" class="small">{{ br }}</span>
            </span>
        </label>

        <ng-container [ngSwitch]="segmentInputsDisplayed">
            <cms-select
                *ngSwitchCase="segmentDepth.Root"
                [id]="tid + '_segment'"
                [items]="segments[segmentDepth.Root]"
                [clearable]="true"
                [bindLabel]="'name'"
                [bindValue]="'id'"
                [disabled]="disabled"
                [multiple]="isMultiple[segmentDepth.Root]"
                [ngModel]="segmentIds[segmentDepth.Root]"
                (ngModelChange)="segmentChanged($event, segmentDepth.Root)"
                [placeholder]="('Common_ChooseSegments' | translate) + ' ' + (segmentDepth.Root + 1)"
                [disabled]="disabled || loading !== 0"
                [appendTo]="appendTo"
            >
            </cms-select>
            <cms-select
                *ngSwitchCase="segmentDepth.Branch"
                [id]="tid + '_segment'"
                [items]="segments[segmentDepth.Branch]"
                [clearable]="true"
                [bindLabel]="'name'"
                [bindValue]="'id'"
                [disabled]="disabled"
                [multiple]="isMultiple[segmentDepth.Branch]"
                [ngModel]="segmentIds[segmentDepth.Branch]"
                (ngModelChange)="segmentChanged($event, segmentDepth.Branch)"
                [placeholder]="('Common_ChooseSegments' | translate) + ' ' + (segmentDepth.Branch + 1)"
                [disabled]="disabled || loading !== 0"
                [appendTo]="appendTo"
            >
            </cms-select>
            <cms-select
                *ngSwitchCase="segmentDepth.Leaf"
                [id]="tid + '_segment'"
                [items]="segments[segmentDepth.Leaf]"
                [clearable]="true"
                [bindLabel]="'name'"
                [bindValue]="'id'"
                [disabled]="disabled"
                [multiple]="isMultiple[segmentDepth.Leaf]"
                [ngModel]="segmentIds[segmentDepth.Leaf]"
                (ngModelChange)="segmentChanged($event, segmentDepth.Leaf)"
                [placeholder]="('Common_ChooseSegments' | translate) + ' ' + (segmentDepth.Leaf + 1)"
                [disabled]="disabled || loading !== 0"
                [appendTo]="appendTo"
            >
            </cms-select>
        </ng-container>
        <cms-field-errors [submitted]="submitted" [control]="control"></cms-field-errors>
    </div>
</div>

<div *ngIf="this.isMultiple[segmentDepth.Root] || this.isMultiple[segmentDepth.Branch] || this.isMultiple[segmentDepth.Leaf]">
    <cms-select
        [id]="tid + '_segment0'"
        [ngClass]="classes[segmentDepth.Root]"
        name="segment1"
        [items]="segments[segmentDepth.Root]"
        [multiple]="isMultiple[segmentDepth.Root]"
        [labelText]="numberOfSegments === 1 ? ('SegmentSelect_Segment' | translate) : ('SegmentSelect_Segment1' | translate)"
        [labelRequired]="required"
        [ngModel]="segmentIds[segmentDepth.Root]"
        (ngModelChange)="segmentChanged($event, segmentDepth.Root)"
        bindLabel="name"
        bindValue="id"
        placeholder="{{ 'Common_ChooseSegments' | translate }}"
        [clearable]="true"
        [disabled]="disabled || loading !== 0"
        [appendTo]="appendTo"
    ></cms-select>
    <cms-select
        [ngClass]="classes[segmentDepth.Branch]"
        [id]="tid + '_segment1'"
        name="segment2"
        [items]="segments[segmentDepth.Branch]"
        [multiple]="isMultiple[segmentDepth.Branch]"
        [labelText]="'SegmentSelect_Segment2' | translate"
        [ngModel]="segmentIds[segmentDepth.Branch]"
        (ngModelChange)="segmentChanged($event, segmentDepth.Branch)"
        bindLabel="name"
        bindValue="id"
        placeholder="{{ 'Common_ChooseSegments' | translate }}"
        [clearable]="true"
        [disabled]="disabled || loading !== 0"
        [appendTo]="appendTo"
    >
    </cms-select>
    <cms-select
        [ngClass]="classes[segmentDepth.Leaf]"
        [id]="tid + '_segment2'"
        name="segment3"
        [items]="segments[segmentDepth.Leaf]"
        [multiple]="isMultiple[segmentDepth.Leaf]"
        [labelText]="'SegmentSelect_Segment3' | translate"
        [ngModel]="segmentIds[segmentDepth.Leaf]"
        (ngModelChange)="segmentChanged($event, segmentDepth.Leaf)"
        bindLabel="name"
        bindValue="id"
        placeholder="{{ 'Common_ChooseSegments' | translate }}"
        [clearable]="true"
        [disabled]="disabled || loading !== 0"
        [appendTo]="appendTo"
    >
    </cms-select>
    <cms-field-errors [submitted]="submitted" [control]="control"></cms-field-errors>
</div>
