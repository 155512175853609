interface ITreeLike<T> {
    subItems?: Array<T>;
    parent?: ITreeLike<T>;
}

export function forEachTree<T extends ITreeLike<T>>(items: Array<T>, callback: (item: T, parent?: T) => void, parent?: T) {
    if (!items) {
        return;
    }
    items.forEach((x) => {
        callback(x, parent);
        if (x.subItems?.length) {
            forEachTree(x.subItems, callback, x);
        }
    });
}

export function someTree<T extends ITreeLike<T>>(input: Array<T>, predicate: (item: T) => boolean): boolean {
    return input.some((x) => {
        if (predicate(x)) {
            return true;
        }
        if (x.subItems?.length) {
            return someTree(x.subItems, predicate);
        }
        return false;
    });
}

export function filterTree<T extends ITreeLike<T>>(items: Array<T>, predicate: (item: T) => boolean) {
    if (!items || items.length === null) {
        return items;
    }
    const res = items.filter(predicate);
    res.forEach((x) => (x.subItems = filterTree(x.subItems, predicate)));
    return res;
}

export function forEachDescendants<T extends ITreeLike<T>>(item: T, callback: (item: T) => void) {
    if (!item.subItems) {
        return;
    }
    item.subItems.forEach((child) => {
        callback(child);
        forEachDescendants(child, callback);
    });
}

export function forEachAscendants<T extends ITreeLike<T>>(item: T, callback: (item: T) => void) {
    let parent = item.parent;
    while (parent) {
        callback(parent as T);
        parent = parent.parent;
    }
}
