import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { CategorySelectDataType, ICategoryInfo } from '../category-info';

@Component({
    selector: 'app-category-select-modal-items',
    styleUrls: ['./category-select-modal-items.component.scss'],
    templateUrl: './category-select-modal-items.component.html'
})
export class CategorySelectModalItemsComponent {
    @Input()
    level = 0;
    @Input()
    items: any;
    @Input()
    maxLevel?: number;
    @Input()
    childrenName?: string;
    @Input()
    dataType: CategorySelectDataType;
    @Input()
    selectedCountryIds: Array<number>;
    @Input()
    addRemoveToLevel: number;

    constructor(private readonly http: HttpClient) {}

    public toggleExpand(item: any) {
        item.isExpanded = !item.isExpanded;
        if (!item.subItems.length) {
            this.http
                .get<Array<ICategoryInfo>>(this.getUrl(), { params: { rootId: item.id, countryIds: this.selectedCountryIds } })
                .subscribe((res) => (item.subItems = res));
        }
    }

    private getUrl(): string {
        return this.dataType === CategorySelectDataType.ArticleCategory ? 'api/article-category/sub-tree' : 'api/category/sub-tree';
    }

    public addRemoveSubcategoriesToLevel(item: ICategoryInfo) {
        if (this.addRemoveToLevel) {
            if (!item.isSelected) {
                item.subItems.forEach((subItem) => {
                    subItem.isSelected = false;
                    if (subItem.level < this.addRemoveToLevel && subItem.hasSubItems) {
                        this.addRemoveSubcategoriesToLevel(subItem);
                    }
                });
            } else {
                if (item.level < this.addRemoveToLevel) {
                    this.getSubCategoriesToLevel(item.id).subscribe((res) => this.addSubitemsAndCheck(item, res));
                }
            }
        }
    }

    private addSubitemsAndCheck(item: ICategoryInfo, subItems: Array<ICategoryInfo>) {
        if (!item.subItems) {
            item.subItems = [];
        }
        item.hasSubItems = item.hasSubItems || !!subItems?.length;
        subItems.forEach((subItem) => {
            const existingItem = item.subItems.find((x) => x.id === subItem.id);
            if (existingItem) {
                existingItem.isSelected = true;
                this.addSubitemsAndCheck(existingItem, subItem.subItems);
            } else {
                subItem.isSelected = true;
                item.subItems.push(subItem);
                this.addSubitemsAndCheck(subItem, subItem.subItems);
            }
        });
    }

    private getSubCategoriesToLevel(categoryId: number) {
        return this.http.get<Array<ICategoryInfo>>(`api/category/${categoryId}/children-to-level/${this.addRemoveToLevel}/`, {
            params: { countryIds: this.selectedCountryIds }
        });
    }
}
