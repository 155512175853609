import { TypeaheadSelectDataSource } from '@alza/cms-components';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CommonDataService } from 'app/common/services';
import { FormGroup, FormHelper } from 'app/components/forms';
import { FormComponentBase } from 'app/components/forms/form-component-base';
import { CategoryType, LinkType } from 'app/models/enums/link';
import { ILinkDto } from 'app/models/link';
import { tap } from 'rxjs';

export interface ILinkModalContext {
    form: FormGroup<ILinkDto>;
    showCountries: boolean;
}

export interface ILinkModalResult {
    link: ILinkDto;
}

@Component({
    selector: 'app-link-modal',
    templateUrl: './link-modal.component.html',
    styleUrls: ['./link-modal.component.scss']
})
export class LinkModalComponent extends FormComponentBase {
    public linkType = LinkType;
    public categoryType = CategoryType;
    public form: FormGroup<ILinkDto>;
    public showCountries: boolean;

    articleDataSource = new TypeaheadSelectDataSource({
        endpoint: (searchValue, selectedValue) => {
            return this.commonData.searchSelectList
                .getArticles(selectedValue, searchValue)
                .pipe(tap((x) => x.forEach((item) => (item.text = item.id + ' - ' + item.text))));
        }
    });

    categoryDataSource = new TypeaheadSelectDataSource({
        endpoint: (searchValue, selectedValue) => {
            return this.commonData.searchSelectList
                .getCategories(selectedValue, searchValue)
                .pipe(tap((x) => x.forEach((item) => (item.text = item.id + ' - ' + item.text))));
        }
    });

    commodityDataSource = new TypeaheadSelectDataSource({
        endpoint: (searchValue, selectedValue) => {
            return this.commonData.searchSelectList
                .getCommodities(selectedValue, searchValue)
                .pipe(tap((x) => x.forEach((item) => (item.text = item.id + ' - ' + item.text))));
        }
    });

    commodityProducerDataSource = new TypeaheadSelectDataSource({
        endpoint: (searchValue, selectedValue) => {
            return this.commonData.searchSelectList
                .getProducers(selectedValue, searchValue)
                .pipe(tap((x) => x.forEach((item) => (item.text = item.id + ' - ' + item.text))));
        }
    });

    commodityPackDataSource = new TypeaheadSelectDataSource({
        endpoint: (searchValue, selectedValue) => {
            return this.commonData.searchSelectList
                .getCommodityPacks(selectedValue, searchValue)
                .pipe(tap((x) => x.forEach((item) => (item.text = item.id + ' - ' + item.text))));
        }
    });

    actionDataSource = new TypeaheadSelectDataSource({
        endpoint: (searchValue, selectedValue) => {
            return this.commonData.searchSelectList
                .getActionCategories(selectedValue, searchValue)
                .pipe(tap((x) => x.forEach((item) => (item.text = item.id + ' - ' + item.text))));
        }
    });

    mailingActionDataSource = new TypeaheadSelectDataSource({
        endpoint: (searchValue, selectedValue) => {
            return this.commonData.searchSelectList
                .getMailingActions(selectedValue, searchValue)
                .pipe(tap((x) => x.forEach((item) => (item.text = item.id + ' - ' + item.text))));
        }
    });

    producerDataSource = new TypeaheadSelectDataSource({
        endpoint: (searchValue, selectedValue) => {
            return this.commonData.searchSelectList
                .getProducers(selectedValue, searchValue)
                .pipe(tap((x) => x.forEach((item) => (item.text = item.id + ' - ' + item.text))));
        }
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) private readonly context: ILinkModalContext,
        private readonly dialogRef: MatDialogRef<LinkModalComponent, ILinkDto>,
        private readonly commonData: CommonDataService,
        protected readonly formHelper: FormHelper,
        private readonly translate: TranslateService
    ) {
        super(formHelper);
        this.form = context.form;
        this.showCountries = context.showCountries;
    }

    public submit() {
        this.form.markAllAsTouched();
        if (!this.form.valid) {
            return;
        }
        this.dialogRef.close({ link: this.form.value } as unknown as ILinkDto);
    }

    public getCatergoryTypeDataSource(): TypeaheadSelectDataSource {
        switch (this.localized('categoryType').value as CategoryType) {
            case CategoryType.ActionCategory:
                return this.actionDataSource;
            case CategoryType.BlackFriday:
                return this.mailingActionDataSource;
            case CategoryType.ProducerCategory:
                return this.producerDataSource;
            case CategoryType.GeneratedCategory:
            default:
                return;
        }
    }

    public getCategoryTypeTitle() {
        switch (this.localized('categoryType').value as CategoryType) {
            case CategoryType.ActionCategory:
                return this.translate.instant('Link_ActionCategoryTitle');
            case CategoryType.BlackFriday:
                return this.translate.instant('Link_MailingActionTitle');
            case CategoryType.ProducerCategory:
                return this.translate.instant('Link_CommodityProducerTitle');
            case CategoryType.GeneratedCategory:
            default:
                return;
        }
    }

    public getCategoryTypePlaceHolder() {
        switch (this.localized('categoryType').value as CategoryType) {
            case CategoryType.ActionCategory:
                return this.translate.instant('TypeaheadSelect_ActionCategoryPlaceholder');
            case CategoryType.BlackFriday:
                return this.translate.instant('TypeaheadSelect_MailingActionPlaceholder');
            case CategoryType.ProducerCategory:
                return this.translate.instant('TypeaheadSelect_CommodityProducerPlaceholder');
            case CategoryType.GeneratedCategory:
            default:
                return;
        }
    }
}
