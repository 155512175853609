import { ErrorMessages, FormInputBase } from '@alza/cms-components';
import { Component, Host, Input, OnInit, Optional, SkipSelf, forwardRef } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CommonDataService } from 'app/common/services/common-data.service';
import { ISegmentDto } from 'app/models/commodity-segment';
const INPUT_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SegmentPrefixSelectComponent),
    multi: true
};

enum SegmentDepth {
    Root = 0,
    Branch = 1,
    Leaf = 2,
    Prefix = 3
}

@Component({
    selector: 'app-segment-prefix-select',
    templateUrl: './segment-prefix-select.component.html',
    styleUrls: ['./segment-prefix-select.component.scss'],
    providers: [INPUT_VALUE_ACCESSOR],
    viewProviders: [{ provide: ErrorMessages, deps: [TranslateService], useClass: ErrorMessages }]
})
export class SegmentPrefixSelectComponent extends FormInputBase implements OnInit {
    public selectedSegmentsBreadcrumbTexts: Array<string> = [];
    public segmentInputDisplayed = 0;
    public segments: Array<Array<ISegmentDto>> = [[], [], []];
    public segmentIds: Array<number> = [];
    public classes: Array<string> = [];
    public loading = 0;
    public prefixes: Array<any> = [];
    public prefixId: number;
    public value: any;
    public segmentDepth = SegmentDepth;

    // update value to the last selected segment
    @Input()
    // eslint-disable-next-line @typescript-eslint/naming-convention
    private lastOnly = true;

    // number of segments to be available to select
    @Input()
    public numberOfSegments = 3;

    @Input()
    breakLabelOnOverflow: boolean;

    @Input() set inputSize(size: Array<number>) {
        let style: string;
        style = this.isColumnSize(size[0]) ? `min-col-xs-${size[0]} col-xs-${size[0]}` : 'min-col-xs-12 col-xs-12';
        style += this.isColumnSize(size[1]) ? `min-col-sm-${size[1]} col-sm-${size[1]}` : '';
        style += this.isColumnSize(size[2]) ? `min-col-md-${size[2]} col-md-${size[2]}` : '';
        style += this.isColumnSize(size[3]) ? `min-col-lg-${size[3]} col-lg-${size[3]}` : '';
        style += this.isColumnSize(size[4]) ? `min-col-xl-${size[4]} col-xl-${size[4]}` : '';
        this.classes[SegmentDepth.Root] = style;
        this.classes[SegmentDepth.Branch] = style;
        this.classes[SegmentDepth.Leaf] = style;
        this.classes[SegmentDepth.Prefix] = style;
    }

    public isColumnSize(value: number) {
        return value && value >= 1 && value <= 12;
    }

    @Input()
    public set segmentsCount(value: SegmentDepth) {
        this.numberOfSegments = SegmentDepth[value] !== null ? value : SegmentDepth.Leaf;
    }

    constructor(
        @Optional()
        @Host()
        @SkipSelf()
        controlContainer: ControlContainer,
        errorMessages: ErrorMessages,
        private commonDataService: CommonDataService,
        private readonly translate: TranslateService
    ) {
        super(controlContainer, errorMessages, { tidPrefix: 'ss' });
        this.inputSize = [12, null, 6, 4];
    }

    ngOnInit() {
        this.loading++;
        this.commonDataService.commoditySegment.getCommoditySegments(0).subscribe(
            (result) => {
                this.loading--;
                this.segments[0] = result;
            },
            () => {
                this.loading--;
            }
        );
    }
    public reloadSegments(parentIds: number | Array<number>, segmentDepth: SegmentDepth) {
        this.loading++;
        this.commonDataService.commoditySegment.getCommoditySegments(parentIds).subscribe(
            (result) => {
                this.loading--;
                this.segments[segmentDepth] = result;
                this.fixSelected(segmentDepth);
            },
            () => {
                this.loading--;
            }
        );
    }

    private fixSelected(segmentDepth: SegmentDepth) {
        const possibleSegment =
            this.segments[segmentDepth].findIndex((s) => s.id === this.segmentIds[segmentDepth]) === -1 ? null : this.segmentIds[segmentDepth];
        this.segmentChanged(possibleSegment, segmentDepth);
    }

    public segmentChanged(value: number | Array<number>, segmentDepth: SegmentDepth) {
        value = value === undefined ? null : value;
        if (JSON.stringify(this.segmentIds[segmentDepth]) !== JSON.stringify(value)) {
            this.segmentIds[segmentDepth] = Array.isArray(value) ? value[0] : value;
        }
        if (segmentDepth < this.numberOfSegments) {
            this.reloadSegments(value, segmentDepth + 1);
            return;
        }
        this.updateValueLocal();

        this.showHideInputsBasedOnValues();
        this.selectedSegmentsBreadcrumbTexts = this.getSelectedSegmentsBreadcrumbText();
    }

    public prefixChanged(value: number) {
        this.updateValueLocal();
        this.prefixId = value;
    }

    public setSegmentAsEditable(idx: number) {
        this.segmentInputDisplayed = idx;
    }

    private showHideInputsBasedOnValues() {
        if (this.segmentIds[0]) {
            this.segmentInputDisplayed = this.numberOfSegments > 1 ? 1 : 0;
        }
        if (this.segmentIds[1]) {
            this.segmentInputDisplayed = this.numberOfSegments > 2 ? 2 : 1;
        }
        if (this.segmentIds[2]) {
            this.segmentInputDisplayed = 2;
        }
    }

    private getSelectedSegmentsBreadcrumbText() {
        const breadCrumbs = [];
        if (this.segmentIds[0]) {
            breadCrumbs.push(this.segments[0].filter((x) => x.id === this.segmentIds[0])[0].name);
        } else {
            this.translate.get('SegmentSelect_Segment1').subscribe((res) => {
                breadCrumbs.push(res);
            });
        }
        if (this.segmentIds[1]) {
            breadCrumbs.push(this.segments[1].filter((x) => x.id === this.segmentIds[1])[0].name);
        } else if (this.segmentIds[0] && this.numberOfSegments > 1) {
            this.translate.get('SegmentSelect_Segment2').subscribe((res) => {
                breadCrumbs.push(res);
            });
        }
        if (this.segmentIds[2]) {
            breadCrumbs.push(this.segments[2].filter((x) => x.id === this.segmentIds[2])[0].name);
        } else if (this.segmentIds[1] && this.numberOfSegments > 2) {
            this.translate.get('SegmentSelect_Segment3').subscribe((res) => {
                breadCrumbs.push(res);
            });
        }

        return breadCrumbs;
    }

    updateValue(obj: any): void {
        this.value = obj === undefined ? null : obj;

        if (this.value === null) {
            this.segmentChanged(null, 0);
            this.segmentInputDisplayed = 0;
            return;
        }

        this.prefixId = this.value.prefixId;
        this.loading++;
        this.commonDataService.commoditySegment.getCommoditySegmentsForChildren(this.value.segmentId).subscribe(
            (segmentSelect) => {
                this.loading--;
                if (segmentSelect == null) {
                    this.segmentIds[0] = null;
                    this.segmentIds[1] = null;
                    this.segmentIds[2] = null;
                    this.segmentChanged(null, 0);
                    this.segmentInputDisplayed = 0;
                    return;
                }
                this.segmentIds[0] = segmentSelect.segments0.map((s) => s.id)[0];
                this.segmentIds[1] = segmentSelect.segments1.map((s) => s.id)[0];
                this.segmentIds[2] = segmentSelect.segments2.map((s) => s.id)[0];
                this.segmentChanged(segmentSelect.segments0.map((s) => s.id)[0], 0);
            },
            () => {
                this.loading--;
            }
        );
    }

    hasValue(depth: SegmentDepth) {
        return this.segmentIds[depth] !== null && this.segmentIds[depth] !== undefined;
    }

    updateValueLocal(): void {
        const data = [];
        if (this.lastOnly) {
            if (this.hasValue(SegmentDepth.Leaf)) {
                this.setValue({ segmentId: this.segmentIds[SegmentDepth.Leaf] });
                return;
            }
            if (this.hasValue(SegmentDepth.Branch)) {
                this.setValue({ segmentId: this.segmentIds[SegmentDepth.Branch] });
                return;
            }
            this.setValue({ segmentId: this.segmentIds[SegmentDepth.Root] });
            return;
        }

        this.setValue({ segmentId: data });
    }
    onValueChange(value: any) {
        if (JSON.stringify(value) !== JSON.stringify(this.value)) {
            this.value = value;
            this.raiseChange(value);
            this.raiseTouched();
        }
    }

    public setValue(value: any) {
        if (value && value !== null) {
            this.commonDataService.selectList.getSegmentPrefixes(value.segmentId).subscribe((prefixes) => {
                this.prefixes = prefixes;
                if (this.prefixes.every((p) => p.id !== this.prefixId)) {
                    this.prefixId = null;
                }
            });
        }
        value.prefixId = this.prefixId;
        this.onValueChange(value);
    }

    setDisabledState?(isDisabled: boolean) {
        this.disabled = isDisabled || this.disabled;
    }
}
