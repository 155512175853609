<div mat-dialog-title>
    <button type="button" class="close" (click)="close()"><span aria-hidden="true">×</span></button>
    <h4>{{ 'SentenceModal_Title' | translate }}</h4>
</div>
<mat-dialog-content>
    <ng-container *ngIf="form">
        <cms-label class="mb-3" [text]="'SentenceModal_Variants' | translate"></cms-label>
        <div class="row mb-2" *ngFor="let variant of form.controls; let ix = index">
            <div class="col-xs-1" *ngIf="hasVariants">
                <cms-checkbox [id]="'selected' + ix" [labelText]="''" [formControl]="variant.get('selected')"> </cms-checkbox>
            </div>
            <div [ngClass]="{ 'col-xs-11': hasVariants, 'col-xs-12': !hasVariants }">
                <cms-textarea [id]="'text' + ix" [labelText]="''" [formControl]="variant.get('text')"> </cms-textarea>
                <cms-checkbox [id]="'checked'" [labelText]="'SentenceModal_Checked' | translate" [formControl]="variant.get('checked')"></cms-checkbox>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-1">
                <app-button [color]="'primary'" (click)="addVariant()"><i class="fas fa-plus"></i> {{ 'SentenceModal_AddVariant' | translate }}</app-button>
            </div>
        </div>
    </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button type="button" data-tid="btnModalSentenceRemove" class="btn btn-danger" (click)="remove()">
        {{ 'SentenceModal_Remove' | translate }}
    </button>
    <button type="button" data-tid="btnModalSentenceCancel" class="btn btn-warning" (click)="close()">{{ 'Common_Storno' | translate }}</button>
    <button type="button" data-tid="btnModalSentenceUpdate" [disabled]="form && form.get('notEditable')?.value" class="btn btn-primary" (click)="update()">
        {{ 'SentenceModal_Update' | translate }}
    </button>
</mat-dialog-actions>
