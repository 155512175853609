import { Component, Input } from '@angular/core';
import { IObjectLock } from 'app/common/dto/common-dto';
import { SessionService } from 'app/common/services/session.service';
import moment from 'moment';

@Component({
    selector: 'app-object-locked',
    templateUrl: './object-locked.component.html',
    styleUrls: ['./object-locked.component.scss']
})
export class ObjectLockedComponent {
    @Input() data: IObjectLock;

    constructor(public session: SessionService) {}

    public getExpirationTime() {
        return moment(this.data.expires).format('DD.MM.YYYY HH:mm:ss');
    }
}
