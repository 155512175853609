<h3>{{ 'Parameters_Search_Title' | translate }}</h3>
<div class="row">
    <form (ngSubmit)="search()">
        <div class="col-xs-12 col-md-6 col-lg-4 mrgb-10">
            <cms-input name="searchValue" [id]="'searchValue'" [(ngModel)]="valueToSearch" [placeholder]="'Parameters_Search_Placeholder' | translate">
            </cms-input>
        </div>
        <div class="col-xs-12 col-md-6 col-lg-8">
            <div class="control-buttons btn-toolbar m-b-md">
                <div class="btn-group">
                    <app-button [id]="'Search'" [color]="'primary'" [type]="'submit'" [loading]="loading" (click)="search()">{{
                        'Common_Search' | translate
                    }}</app-button>
                    <app-button [id]="'Clear'" [color]="'secondary'" (click)="clearFilter()">
                        {{ 'Common_ClearSearch' | translate }}
                    </app-button>
                </div>
                <app-show-more-button [isOpen]="extendedSearch" (click)="toggleSearch()"> </app-show-more-button>
            </div>
        </div>
    </form>
</div>
<div class="row" *ngIf="extendedSearch">
    <div class="wrapper-content animated fadeInRight">
        <app-loading-panel [loading]="loading">
            <app-tabs [active]="selectedTab" (activeChange)="selectedTab = $event">
                <tab [value]="'all'" [title]="'Parameters_Search_All' | translate"></tab>
                <tab [value]="'categories'" [title]="'Parameters_Search_Categories' | translate"></tab>
                <tab [value]="'groups'" [title]="'Parameters_Search_Groups' | translate"></tab>
                <tab [value]="'types'" [title]="'Parameters_Search_Types' | translate"></tab>
                <div body>
                    <div class="table-responsive search-results-table" [hidden]="!resultCount">
                        <table class="table table-striped table-bordered table-hover dataTable">
                            <thead class="display-block">
                                <tr role="row" class="search-result-row">
                                    <th>{{ 'Parameters_Search_Category' | translate }}</th>
                                    <th style="width: 15%">{{ 'Parameters_Search_Group' | translate }}</th>
                                    <th style="width: 15%">{{ 'Parameters_Search_Type' | translate }}</th>
                                </tr>
                            </thead>
                            <tbody class="display-block search-result-body">
                                <tr
                                    role="row"
                                    [attr.data-tid]="'rowSearch_' + ix"
                                    class="table-row-text-overflow search-result-row"
                                    *ngFor="let result of getResultsForTab(selectedTab); let ix = index"
                                    (click)="resultSelected(result)"
                                >
                                    <td>
                                        <span *ngFor="let category of result.categories; let last = last"
                                            >{{ category?.name }}<span *ngIf="!last">&nbsp;&nbsp;>&nbsp;&nbsp;</span></span
                                        >
                                    </td>
                                    <td style="width: 15%">{{ result.parameterGroup?.name }}</td>
                                    <td style="width: 15%">{{ result.parameterType?.name }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="bold" *ngIf="!resultCount && !loading">
                        {{ 'Parameters_Search_NoResults' | translate }}
                    </div>
                    <div class="bold mrgt-10" *ngIf="resultCount">{{ 'Parameters_Search_TotalItems' | translate }} {{ resultCount }}</div>
                </div>
            </app-tabs>
        </app-loading-panel>
    </div>
</div>
