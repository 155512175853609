import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IParameterFilterDto } from 'app/models/parameter';
import { IParemeterPreviewGroup } from './models';
import { createParameterPreview } from './parameter-preview';

@Component({
    selector: 'app-parameter-preview',
    templateUrl: './parameter-preview.component.html',
    styleUrls: ['./parameter-preview.component.scss']
})
export class ParameterPreviewComponent {
    private _parameterFilter?: Array<IParameterFilterDto>;

    public parameters: Array<IParemeterPreviewGroup>;
    @Input() public disabled = false;
    @Output() public removeParameter = new EventEmitter<number>();
    @Input() set parameterFilter(info: Array<IParameterFilterDto>) {
        this._parameterFilter = info || null;
        this.parameters = createParameterPreview(this._parameterFilter, this.translate);
    }

    constructor(private readonly translate: TranslateService) {}
}
