<div class="row table-pager" *ngIf="itemsCount">
    <div class="alzaPager form-inline">
        <div class="col-md-12" *ngIf="itemsCount && showItemsCount">
            <div class="totalItems display-inline-block">
                <div class="dataTables_info">{{ 'Pager_TotalItems' | translate }} {{ itemsCount | number }}</div>
            </div>
            <div class="pull-right dataTables_paginate" *ngIf="showPaging">
                <div class="itemsPerPage">
                    <select class="form-control input-sm" [(ngModel)]="pageSize">
                        <option *ngFor="let item of pageSizeLimit" [ngValue]="item">{{ item }}</option>
                    </select>
                    <span class="m-l-sm">{{ 'Pager_ItemsOnPage' | translate }}</span>
                </div>
                <app-pagination [pageSize]="pageSize" [collectionSize]="itemsCount" [page]="currentPage" (pageChange)="setPage($event)"></app-pagination>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!itemsCount && !loading" class="alert alert-info">
    <h4><i class="fas fa-info-circle"></i> {{ 'Pager_NoItems' | translate }}</h4>
</div>
