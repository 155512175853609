import { HttpClient } from '@angular/common/http';
import { ISelectListItemDto } from 'app/models';
import { BaseApi } from './base-api';

export class DeliveryBranchApi extends BaseApi {
    public constructor(http: HttpClient) {
        super(http);
    }

    // Gets delivery brachnes from DeliveryBranchCollection
    public getDeliveryBranches() {
        return this.http.get<Array<ISelectListItemDto>>('api/common/delivery-branches');
    }

    // Gets delivery brachnes for pop ups
    public getPopUpDeliveryBranches() {
        return this.http.get<Array<ISelectListItemDto>>('api/common/delivery-branches/pop-up');
    }
}
