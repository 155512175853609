import { ErrorMessages, FormInputBase } from '@alza/cms-components';
import { Component, EventEmitter, Host, Input, Optional, Output, SkipSelf, forwardRef } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { forEachAscendants, forEachTree, someTree } from 'app/common/tree-utils';
import { StringUtils } from 'app/common/utils';
import { ITreeItemModel } from './tree-item/models';

const INPUT_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    useExisting: forwardRef(() => TreeComponent),
    multi: true
};
@Component({
    selector: 'app-tree',
    templateUrl: './tree.component.html',
    styleUrls: ['./tree.component.scss'],
    providers: [INPUT_VALUE_ACCESSOR],
    viewProviders: [{ provide: ErrorMessages, deps: [TranslateService], useClass: ErrorMessages }]
})
export class TreeComponent extends FormInputBase implements ControlValueAccessor {
    private _items: Array<ITreeItemModel>;
    public isSelectionEnabled = false;
    public searchValue?: string = '';
    public value: Array<number> = [];

    public get items() {
        return this._items;
    }

    /** Tree items */
    @Input() public set items(value: Array<ITreeItemModel>) {
        this._items = value;
        this.loadTree();
    }

    /** Initial level of tree */
    @Input() level: number = 1;

    /** Maximum level of tree */
    @Input() maxLevel?: number;

    /** Determines whether to expand root tree item(s) */
    @Input() expandRoot = false;

    /** Determines whether to expand the tree to display all selected items */
    @Input() expandSelected = true;

    /** Determines whether to show search input or not */
    @Input() showSearch = true;

    /** Search placeholder */
    @Input() searchPlaceholder = '';

    /** Event raised on tree item click */
    @Output() treeItemClick = new EventEmitter<ITreeItemModel>();

    /** Event raised on tree item selection changed */
    @Output() treeItemCheckedChange = new EventEmitter<ITreeItemModel>();

    constructor(
        @Optional()
        @Host()
        @SkipSelf()
        controlContainer: ControlContainer,
        errorMessages: ErrorMessages
    ) {
        super(controlContainer, errorMessages, { tidPrefix: 'ct' });
    }

    override ngOnInit(): void {
        this.isSelectionEnabled = this.control !== undefined;
    }

    override updateValue(obj: any): void {
        if (obj === this.value) {
            return;
        }
        this.value = obj === undefined || obj === null ? [] : obj;
        this.setSelected();
    }

    public onValueChange(value: any) {
        this.value = value;
        this.raiseChange(value);
        this.raiseTouched();
    }

    public searchWord() {
        if (!this.searchValue) {
            forEachTree(this.items, (item) => {
                item.isMatch = false;
                item.isExpanded = this.expandRoot && !item.parent;
            });
            this.setSelected();
        } else {
            const searchValueNumber = Number(this.searchValue);
            const normalizedSearchValue = StringUtils.removeAccents((this.searchValue || '').toLowerCase());

            forEachTree(this.items, (item) => {
                item.isMatch = item.normalizedName.includes(normalizedSearchValue) || item.id === searchValueNumber;
            });
            forEachTree(this.items, (item) => {
                item.isExpanded = someTree(item.subItems, (x) => x.isMatch);
            });
        }
    }

    public clearSearch() {
        this.searchValue = '';
        this.searchWord();
    }

    private loadTree() {
        forEachTree(this.items, (item, parent) => {
            item.parent = parent;
            item.isExpanded = this.expandRoot && !parent;
            item.isIndeterminate = false;
            item.isSelected = false;
            item.normalizedName = StringUtils.removeAccents((item.name || '').toLowerCase());
        });
        this.setSelected();
    }

    private setSelected() {
        forEachTree(this.items, (item) => {
            if (this.value.includes(item.id)) {
                item.isSelected = true;
                forEachTree(item.subItems, (i) => (i.isSelected = item.isSelected));
                forEachAscendants(item, (i) => {
                    i.isExpanded ||= this.expandSelected;
                    i.isSelected = i.subItems.every((s) => s.isSelected);
                    i.isIndeterminate = !i.isSelected && i.subItems.some((s) => s.isSelected || s.isIndeterminate);
                });
            }
        });
    }

    onTreeItemCheckedChange(event: ITreeItemModel) {
        const value: Array<number> = [];
        forEachTree(this.items, (item) => {
            if (item.isSelected && (!item.parent?.isSelected || !item.parent)) {
                if (value.every((v) => v !== item.id)) {
                    value.push(item.id);
                }
            }
        });
        this.onValueChange(value);
        this.treeItemCheckedChange.emit(event);
    }

    onTreeItemClick(event: ITreeItemModel) {
        this.treeItemClick.emit(event);
    }
}
