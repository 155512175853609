import { EnvironmentType } from 'app/common/enums/enums';
import { UserRight } from '../user-right';

export class User {
    id: number;
    machineName: string;
    login: string;
    displayName: string;
    languageCode: string;
    languageId: number;
    permissions: Array<UserRight> = [];
    environment: EnvironmentType;
    cultureInfo: string;
    availableLanguages: Array<{ id: number; code: string; name: string }>;
    phoneNumber: string;
    isProofReader: boolean;
    isInternProofreader: boolean;
    isExterne: boolean;
    isAdmin: boolean;
    buildInfo: { buildNumber: string; buildId: number; buildEnvironment: string; buildDate: string };
    email: string;
    latestTag: string;
    isProductionDb: boolean;
    applicationInsights: {
        instrumentationKey: string;
    };
    isRunningLocally: boolean;
    externistLanguageId?: number;
    externistCountryId?: number;
    public hasPermission(value: UserRight) {
        return this.permissions.some((x) => x === value);
    }
}
