import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'app-localized',
    templateUrl: './localized.component.html',
    styleUrls: ['./localized.component.scss']
})
export class LocalizedComponent {
    @Input() formGroup: UntypedFormGroup;
    public country = 1;

    @ContentChild(TemplateRef) formInput;
}
