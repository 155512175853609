/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum ImageDensityType {
    HomePageAccessories = 0,
    HomePageProductPromo = 1,
    HomePageProduct = 2,
    HomePageAudiobook = 3,
    HomePageEbook = 4,
    ProductsProduct = 10,
    ProductsProductSmall = 11,
    DetailProduct = 20,
    DetailGallery = 21,
    DetailGalleryMax = 22,
    DetailRecommendedProduct = 23,
    BasketProduct = 30,
    OrderProduct = 40,
    OrderDetailProduct = 50,
    RecommendationProduct = 60,
    AudioBookProduct = 70,
    AudioBookProductSmall = 71,
    AudioBookProductBig = 72,
    LibraryProduct = 80,
    LibraryProductSmall = 81,
    SearchProduct = 90,
    FavouriteProduct = 100,
    LocalTitlePageCarousels = 110,
    FreeLibraryProduct = 120,
    MyMusicProduct = 130,
    MyMusicProductBig = 131,
    TitleProduct = 140,
    MyVideoCollectionItem = 150,
    MyMagazineCollectionItem = 160,
    MobileWebCommodityLineControl = 200,
    MobileWebBoxControl = 210,
    MobileWebCatalogControl = 220,
    Category = 500,
    Sticker = 510,
}
