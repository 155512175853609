/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum NameextStateItem {
    SeoPrefix = 0,
    ActiveForOR = 1,
    Custom = 2,
    Generate = 3,
    CustomGenerate = 4,
    WrongNameext = 5,
    EmptyNameext = 6,
    DeadlineMet = 7,
    DeadlineNotMet = 8,
    Off = 9,
}
