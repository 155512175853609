/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum Countries {
    Czech = 0,
    Slovak = 1,
    Europe = 2,
    Germany = 3,
    Ireland = 4,
    Malta = 5,
    GreatBritain = 6,
    Bulgaria = 7,
    Italy = 8,
    Poland = 9,
    Hungary = 10,
    Belgium = 11,
    France = 12,
    Cyprus = 13,
    Netherlands = 14,
    Denmark = 15,
    Estonia = 16,
    Finland = 17,
    Lithuania = 18,
    Latvia = 19,
    Luxembourg = 20,
    Portugal = 21,
    Austria = 22,
    Romania = 23,
    Greece = 24,
    Slovenia = 25,
    Spain = 26,
    Sweden = 27,
    Cratia = 28,
    Russia = 29,
}
