import { Component, OnInit, Input, QueryList, ContentChildren, ContentChild, AfterContentInit } from '@angular/core';
import { BreadcrumbDirective } from './breadcrumb.directive';
import { Title } from '@angular/platform-browser';
import { WikiModalComponent, IWikiModalData } from '../wiki-modal/wiki-modal.component';
import { MenuService, IMenuItem } from 'app/common/services/menu.service';
import { HttpClient } from '@angular/common/http';
import { ICountryTranslationDto } from 'app/common/dto/common-dto';
import { SessionService } from 'app/common/services/session.service';
import { CommonDataService } from 'app/common/services/common-data.service';
import { MatDialog } from '@angular/material/dialog';
import { BreadcrumbDetailDirective } from './breadcrumb-detail.directive';

interface IFlatMenuItem {
    path: Array<{ title: string; loweredTitle: string; active: boolean }>;
    url: string;
    isMvcModule: boolean;
}

@Component({
    selector: 'app-page-header',
    templateUrl: './page-header.component.html',
    styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit, AfterContentInit {
    public countries: Array<ICountryTranslationDto> = [];
    public selectedCountry?: ICountryTranslationDto;
    private _title?: string;
    public get title() {
        return this._title;
    }

    @Input()
    public set title(value: string | undefined) {
        if (value) {
            this._title = value;
            this.titleService.setTitle(this._title + (this.detailBreadcrumb?.titleSuffix$.value || ''));
        } else {
            this._title = '';
            this.titleService.setTitle('');
        }
    }

    public wikiUrl?: string;

    @ContentChildren(BreadcrumbDirective) public breadcrumbs?: QueryList<BreadcrumbDirective>;
    @ContentChild(BreadcrumbDetailDirective) public detailBreadcrumb: BreadcrumbDetailDirective;

    public menuItems: Array<IFlatMenuItem> = [];

    constructor(
        private readonly titleService: Title,
        private readonly menu: MenuService,
        private readonly http: HttpClient,
        private readonly commonData: CommonDataService,
        private readonly session: SessionService,
        private readonly menuService: MenuService,
        private readonly modal: MatDialog
    ) { }

    ngOnInit() {
        this.menuService.getVisibleItems().subscribe((res) => {
            res.forEach((i) => {
                this.menuItems.push(...this.createMenuItem(i));
            });
        });
        this.commonData.language.getLanguages().subscribe((res) => {
            this.countries = res.filter((x) => x.id !== this.session.user.languageId);
            this.selectedCountry = res.find((x) => x.id === this.session.user.languageId);
        });
        this.menu.findWikiUrl().subscribe((url) => (this.wikiUrl = url));
    }

    public changeLanguage(code: string) {
        this.http.post('/api/i18n/set-lang', { language: code }).subscribe(() => {
            location.reload();
        });
    }

    private createMenuItem(root: IMenuItem, rootPath: Array<{ title: string; loweredTitle: string; active: boolean }> = []): Array<IFlatMenuItem> {
        const res: Array<IFlatMenuItem> = [];
        const currentPath = [...rootPath, { active: false, title: root.title, loweredTitle: root.title.toLowerCase() }];
        if (root.url) {
            res.push({ url: root.url, path: currentPath, isMvcModule: root.isMvcModule });
        }
        root.items.forEach((i) => {
            res.push(...this.createMenuItem(i, currentPath));
        });

        return res;
    }

    public openWiki() {
        this.modal.open<any, IWikiModalData>(WikiModalComponent, { data: { path: this.wikiUrl }, width: '80%' });
    }

    ngAfterContentInit() {
        if (this.detailBreadcrumb) {
            this.titleService.setTitle(this.title + this.detailBreadcrumb.titleSuffix$.value);
            this.detailBreadcrumb.titleSuffix$.subscribe((suffix) => {
                this.titleService.setTitle(this.title + suffix);
            });
        }
    }
}
