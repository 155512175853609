<ng-template [ngTemplateOutlet]="listTemplate" [ngTemplateOutletContext]="{ $implicit: categoryTree.tree }"></ng-template>

<ng-template #listTemplate let-list>
    <div class="dd">
        <ol class="dd-list">
            <ng-template ngFor [ngForOf]="list" let-item>
                <ng-template [ngTemplateOutlet]="rowTemplate" [ngTemplateOutletContext]="{ $implicit: item }"> </ng-template>
            </ng-template>
        </ol>
    </div>
</ng-template>

<ng-template let-item #rowTemplate>
    <li
        *ngIf="item"
        class="dd-handle"
        [id]="'item' + item.id"
        [attr.data-tid]="'item' + item.id"
        class="dd-handle"
        [class.is-match]="item.isMatch"
        [class.is-selected]="item.isSelected"
    >
        <div class="item-expander" (click)="toogleOnSelect ? select(item.id) : this.categoryTree.toggleExpand(item)">
            <i class="fas item-clickable" [class.fa-minus]="item.isExpanded" [class.fa-plus]="item.hasSubItems && !item.isExpanded"></i>
        </div>
        <div class="item-content">
            <ng-template
                [ngTemplateOutlet]="itemTemplate ? itemTemplate : defaultItemTemplate"
                [ngTemplateOutletContext]="{ $implicit: item, select: select.bind(this) }"
            ></ng-template>
        </div>
    </li>
    <ng-template *ngIf="item.isExpanded" [ngTemplateOutlet]="listTemplate" [ngTemplateOutletContext]="{ $implicit: item.subItems }"></ng-template>
</ng-template>

<ng-template #defaultItemTemplate let-item>
    <span class="item-clickable item-default" (click)="select(item.id)">{{ item.name }}</span>
</ng-template>
