<div class="mb-4" *ngFor="let group of parameters; let ix = index">
    <h3>{{ group.groupName }}</h3>

    <div class="parameters-tags-container mb-3" *ngFor="let type of group.types">
        <span class="parameter-tags label-default">
            {{ type.typeName }}
        </span>
        <span class="parameter-tags label-custom" *ngFor="let value of type.values">
            <span [innerHTML]="value.text"></span>
            <i *ngIf="!disabled" class="fas fa-times cursor-pointer fa-danger-hover ml-2" (click)="removeParameter.emit(value.id)"></i>
        </span>
    </div>
</div>
