/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum DataTimePeriod {
    Optional = 0,
    Last15Minutes = 1,
    LastHour = 2,
    LastDay = 3,
    LastWeek = 4,
    LastMonth = 5,
}
