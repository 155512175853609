import { ErrorMessages, FormInputBase } from '@alza/cms-components';
import { Component, Host, Input, OnInit, Optional, SkipSelf, forwardRef } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CommonDataService } from 'app/common/services/common-data.service';
import { ISegmentDto } from 'app/models/commodity-segment';
const INPUT_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SegmentSelectComponent),
    multi: true
};

export enum SegmentDepth {
    Root = 0,
    Branch = 1,
    Leaf = 2
}

@Component({
    selector: 'app-segment-select',
    templateUrl: './segment-select.component.html',
    styleUrls: ['./segment-select.component.scss'],
    providers: [INPUT_VALUE_ACCESSOR],
    viewProviders: [{ provide: ErrorMessages, deps: [TranslateService], useClass: ErrorMessages }]
})
export class SegmentSelectComponent extends FormInputBase implements OnInit {
    public selectedSegmentsBreadcrumbTexts: Array<string> = [];
    public segmentDepth = SegmentDepth;
    public segmentInputsDisplayed = 0;
    public segments: [Array<ISegmentDto>, Array<ISegmentDto>, Array<ISegmentDto>] = [[], [], []];
    public segmentIds: Array<Array<number>> | Array<number> = [[], [], []];
    public classes: Array<string> = [];
    public isMultiple = [true, true, true];
    public loading = 0;
    public value: any;
    @Input()
    // eslint-disable-next-line @typescript-eslint/naming-convention
    private lastOnly = true;
    @Input()
    public numberOfSegments = 3;
    @Input()
    required: boolean;
    @Input()
    breakLabelOnOverflow: boolean;
    /** null, or 'body', or valid css selector that points to something that has relative position */
    @Input() public appendTo = null;

    @Input() set inputSize(size: Array<number>) {
        let style: string;
        style = this.isColumnSize(size[0]) ? `min-col-xs-${size[0]} col-xs-${size[0]}` : ' min-col-xs-12 col-xs-12';
        style += this.isColumnSize(size[1]) ? ` min-col-sm-${size[1]} col-sm-${size[1]}` : '';
        style += this.isColumnSize(size[2]) ? ` min-col-md-${size[2]} col-md-${size[2]}` : '';
        style += this.isColumnSize(size[3]) ? ` min-col-lg-${size[3]} col-lg-${size[3]}` : '';
        style += this.isColumnSize(size[4]) ? ` min-col-xl-${size[4]} col-xl-${size[4]}` : '';
        this.classes[SegmentDepth.Root] = style;
        this.classes[SegmentDepth.Branch] = style;
        this.classes[SegmentDepth.Leaf] = style;
    }

    public isColumnSize(value: number) {
        return value && value >= 1 && value <= 12;
    }

    @Input()
    public set multiple(value: boolean) {
        this.isMultiple[SegmentDepth.Root] = !!value;
        this.isMultiple[SegmentDepth.Branch] = !!value;
        this.isMultiple[SegmentDepth.Leaf] = !!value;
        // eslint-disable-next-line @typescript-eslint/no-for-in-array
        for (const key in this.isMultiple) {
            if (this.isMultiple.hasOwnProperty(key)) {
                if (this.isMultiple[key]) {
                    this.segmentIds[key] = [];
                } else {
                    this.segmentIds[key] = null;
                }
            }
        }
    }

    @Input()
    public set segmentsCount(value: SegmentDepth) {
        this.numberOfSegments = SegmentDepth[value] !== null ? value : SegmentDepth.Leaf;
    }

    constructor(
        @Optional()
        @Host()
        @SkipSelf()
        controlContainer: ControlContainer,
        errorMessages: ErrorMessages,
        private commonDataService: CommonDataService,
        private readonly translate: TranslateService
    ) {
        super(controlContainer, errorMessages, { tidPrefix: 'ss' });
        this.inputSize = [12, null, 6, 4];
        this.multiple = false;
    }

    ngOnInit() {
        this.loading++;
        this.commonDataService.commoditySegment.getCommoditySegments(0).subscribe(
            (result) => {
                this.loading--;
                this.segments[SegmentDepth.Root] = result;
            },
            () => {
                this.loading--;
            }
        );
    }

    public reloadSegments(parentIds: number | Array<number>, segmentDepth: SegmentDepth) {
        this.loading++;
        this.commonDataService.commoditySegment.getCommoditySegments(parentIds).subscribe(
            (result) => {
                this.loading--;
                this.segments[segmentDepth] = result;
                this.fixSelected(segmentDepth);
            },
            () => {
                this.loading--;
            }
        );
    }

    private fixSelected(segmentDepth: SegmentDepth) {
        if (this.isMultiple[segmentDepth]) {
            const possibleSegments = (this.segmentIds[segmentDepth] as Array<number>).filter((s) => this.segments[segmentDepth].some((ss) => ss.id === s));
            this.segmentChanged(possibleSegments, segmentDepth);
        } else {
            const possibleSegment =
                this.segments[segmentDepth].findIndex((s) => s.id === this.segmentIds[segmentDepth]) === -1 ? null : this.segmentIds[segmentDepth];
            this.segmentChanged(possibleSegment, segmentDepth);
        }
    }

    public segmentChanged(value: number | Array<number>, segmentDepth: SegmentDepth) {
        value = value === undefined ? null : value;
        if (JSON.stringify(this.segmentIds[segmentDepth]) !== JSON.stringify(value)) {
            if (this.isMultiple[segmentDepth]) {
                this.segmentIds[segmentDepth] = Array.isArray(value) ? value : [value];
            } else {
                this.segmentIds[segmentDepth] = Array.isArray(value) ? value[0] : value;
            }
        }
        if (segmentDepth < this.numberOfSegments) {
            this.reloadSegments(value, segmentDepth + 1);
            return;
        }

        this.updateValueLocal();

        if (!this.isMultiple[0] && !this.isMultiple[1] && !this.isMultiple[2]) {
            this.showHideInputsBasedOnValues();
            this.selectedSegmentsBreadcrumbTexts = this.getSelectedSegmentsBreadcrumbText();
        }
    }

    public setSegmentAsEditable(idx: number) {
        this.segmentInputsDisplayed = idx;
    }

    private showHideInputsBasedOnValues() {
        if (this.segmentIds[0]) {
            this.segmentInputsDisplayed = this.numberOfSegments > 1 ? 1 : 0;
        }
        if (this.segmentIds[1]) {
            this.segmentInputsDisplayed = this.numberOfSegments > 2 ? 2 : 1;
        }
        if (this.segmentIds[2]) {
            this.segmentInputsDisplayed = 2;
        }
    }

    private getSelectedSegmentsBreadcrumbText() {
        const breadCrumbs = [];
        if (this.segmentIds[0]) {
            breadCrumbs.push(this.segments[0].filter((x) => x.id === this.segmentIds[0])[0].name);
        } else {
            this.translate.get('SegmentSelect_Segment1').subscribe((res) => {
                breadCrumbs.push(res);
            });
        }
        if (this.segmentIds[1]) {
            breadCrumbs.push(this.segments[1].filter((x) => x.id === this.segmentIds[1])[0].name);
        } else if (this.segmentIds[0] && this.numberOfSegments > 1) {
            this.translate.get('SegmentSelect_Segment2').subscribe((res) => {
                breadCrumbs.push(res);
            });
        }
        if (this.segmentIds[2]) {
            breadCrumbs.push(this.segments[2].filter((x) => x.id === this.segmentIds[2])[0].name);
        } else if (this.segmentIds[1] && this.numberOfSegments > 2) {
            this.translate.get('SegmentSelect_Segment3').subscribe((res) => {
                breadCrumbs.push(res);
            });
        }

        return breadCrumbs;
    }

    updateValue(obj: any): void {
        this.value = obj === undefined ? null : obj;

        if (this.value === this.isMultiple[0] ? [] : null) {
            this.segmentChanged(this.isMultiple[0] ? [] : null, 0);
            this.segmentInputsDisplayed = 0;
            return;
        }

        this.loading++;
        this.commonDataService.commoditySegment.getCommoditySegmentsForChildren(this.value).subscribe(
            (segmentSelect) => {
                this.loading--;
                if (segmentSelect == null) {
                    this.segmentIds[0] = this.isMultiple[0] ? [] : null;
                    this.segmentIds[1] = this.isMultiple[1] ? [] : null;
                    this.segmentIds[2] = this.isMultiple[2] ? [] : null;
                    this.segmentChanged(this.isMultiple[0] ? [] : null, 0);
                    this.segmentInputsDisplayed = 0;
                    return;
                }
                this.segmentIds[0] = this.isMultiple[0] ? segmentSelect.segments0.map((s) => s.id) : segmentSelect.segments0.map((s) => s.id)[0];
                this.segmentIds[1] = this.isMultiple[1] ? segmentSelect.segments1.map((s) => s.id) : segmentSelect.segments1.map((s) => s.id)[0];
                this.segmentIds[2] = this.isMultiple[2] ? segmentSelect.segments2.map((s) => s.id) : segmentSelect.segments2.map((s) => s.id)[0];
                this.segmentChanged(this.isMultiple[0] ? segmentSelect.segments0.map((s) => s.id) : segmentSelect.segments0.map((s) => s.id)[0], 0);
            },
            () => {
                this.loading--;
            }
        );
    }

    hasValue(depth: SegmentDepth) {
        return (
            this.segmentIds[depth] !== null &&
            this.segmentIds[depth] !== undefined &&
            (!Array.isArray(this.segmentIds[depth]) || (Array.isArray(this.segmentIds[depth]) && (this.segmentIds[depth] as []).length !== 0))
        );
    }

    updateValueLocal(): void {
        let data = [];
        if (this.lastOnly) {
            if (this.hasValue(SegmentDepth.Leaf)) {
                this.setValue(this.segmentIds[SegmentDepth.Leaf]);
                return;
            }
            if (this.hasValue(SegmentDepth.Branch)) {
                this.setValue(this.segmentIds[SegmentDepth.Branch]);
                return;
            }
            this.setValue(this.segmentIds[SegmentDepth.Root]);
            return;
        }
        // eslint-disable-next-line @typescript-eslint/no-for-in-array
        for (const key in this.isMultiple) {
            if (this.isMultiple.hasOwnProperty(key)) {
                if (this.segmentIds[key] !== null) {
                    data = data.concat(this.segmentIds[key]);
                }
            }
        }
        this.setValue(data);
    }

    public setValue(value: any) {
        if (Array.isArray(value)) {
            value = value.sort();
        }
        if (Array.isArray(this.value)) {
            this.value = this.value.sort();
        }

        if (JSON.stringify(value) !== JSON.stringify(this.value)) {
            this.value = value;
            this.raiseChange(value);
            this.raiseTouched();
        }
    }

    setDisabledState?(isDisabled: boolean) {
        this.disabled = isDisabled || this.disabled;
    }
}
