import { Pipe, PipeTransform } from '@angular/core';
import { filesize } from 'filesize';

@Pipe({
    name: 'fileSize',
    pure: false
})
export class FileSizePipe implements PipeTransform {
    transform(value: any): any {
        return filesize(value);
    }
}
