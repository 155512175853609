<div class="btn-group display-block" *ngIf="enumValues" [attr.data-tid]="getTid('btnGrp')">
    <button
        type="button"
        [attr.data-tid]="getTidItem('btnGrp', e)"
        class="btn"
        [class.btn-white]="value !== e.value"
        [class.btn-primary]="value === e.value"
        *ngFor="let e of enumValues"
        (click)="setValue(e.value)"
        [disabled]="disabled"
    >
        {{ e.displayName }}
    </button>
</div>
