import { Validators } from '@alza/cms-components';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ITranslationSentenceDto, ITranslationSentenceItemDto } from 'app/models/translation-sentence';
import { FormGroup } from '../../typed-controls';
import { TypedFormBuilder } from '../../typed-forms';

export interface INewSentenceContext {
    groupId: number;
    languageId: number;
}

@Component({
    selector: 'app-new-sentence-modal',
    templateUrl: './new-sentence-modal.component.html',
    styleUrls: ['./new-sentence-modal.component.scss']
})
export class NewSentenceModalComponent implements OnInit {
    public originSentence: ITranslationSentenceDto;
    public form: FormGroup<ITranslationSentenceItemDto>;

    constructor(
        @Inject(MAT_DIALOG_DATA) readonly context: INewSentenceContext,
        private http: HttpClient,
        private readonly dialogRef: MatDialogRef<NewSentenceModalComponent>,
        private ngZone: NgZone,
        private fb: TypedFormBuilder
    ) {}

    ngOnInit() {
        this.getSentence(this.context.groupId).subscribe((sentence) => {
            this.originSentence = sentence;
            this.form = this.initForm();
        });
    }

    public close() {
        this.ngZone.run(() => {
            this.dialogRef.close();
        });
    }

    private initForm() {
        const form = this.fb.group<ITranslationSentenceItemDto>({
            checked: true,
            id: 0,
            notEditable: false,
            text: [null, Validators.required]
        });

        return form;
    }

    private formToDto(): any {
        const formData = this.form.getRawValue();
        const sentence: ITranslationSentenceDto = {
            id: 0,
            localization: {}
        };

        sentence.localization[1] = { ...this.originSentence.localization[1] };
        sentence.localization[this.context.languageId] = {
            ...formData
        };

        return sentence;
    }

    public save() {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return;
        }
        this.createSentence(this.formToDto()).subscribe(() => {
            this.dialogRef.close(true);
        });
    }

    private getSentence(groupId: number) {
        return this.http.get<ITranslationSentenceDto>(`/api/translation-sentences/${groupId}`);
    }

    private createSentence(dto: ITranslationSentenceDto) {
        return this.http.post<any>(`/api/translation-sentences/`, dto);
    }
}
