import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ClipboardService {
    copy(text: string) {
        return new Promise<string>((resolve, reject) => {
            const inputValue = text.trim();
            if (!inputValue) {
                return;
            }

            if (navigator.clipboard) {
                navigator.clipboard
                    .writeText(inputValue)
                    .then(() => {
                        resolve(inputValue);
                    })
                    .catch((error) => {
                        console.error('Cannot write to clipboard: ', error);
                        reject(error);
                    });
            } else {
                const clipboard = document.createElement('textarea');
                clipboard.style.position = 'fixed';
                clipboard.style.left = '0';
                clipboard.style.top = '0';
                clipboard.style.opacity = '0';
                clipboard.value = inputValue;
                document.body.appendChild(clipboard);
                clipboard.focus();
                clipboard.select();
                document.execCommand('copy', null, null);
                document.body.removeChild(clipboard);
                resolve(inputValue);
            }
        });
    }

    readText(): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            if (navigator.clipboard) {
                setTimeout(
                    () =>
                        void navigator.clipboard
                            .readText()
                            .then((clipText) => {
                                resolve(clipText);
                            })
                            .catch((error) => {
                                console.error('Cannot read clipboard text: ', error);
                                reject(error);
                            })
                );
            } else {
                const clipboard = document.createElement('textarea');
                clipboard.style.position = 'fixed';
                clipboard.style.left = '0';
                clipboard.style.top = '0';
                clipboard.style.opacity = '0';
                document.body.appendChild(clipboard);
                clipboard.focus();
                clipboard.select();
                document.execCommand('paste', null, null);
                const clipText = clipboard.value;
                console.log('pasted:' + clipText);
                document.body.removeChild(clipboard);

                resolve(clipText);
            }
        });
    }
}
