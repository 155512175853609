import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router, UrlSegment } from '@angular/router';
import { DialogsService } from 'app/common/services/dialogs.service';
import { BehaviorSubject, Observable, ReplaySubject, Subject, Subscription, merge, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { EMPTY } from '../constants';

export interface ISortInfo {
    key: number | null;
    desc: boolean;
}

export interface IPagerParams extends Params {
    page: number;
    pageSize: number;
    sortKey: number | null;
    sortDesc: boolean;
    count: boolean;
}

@Injectable()
export class PagerService<TFilter extends object, TItem extends object> implements OnDestroy {
    private _data: any = {};
    private _pageSize = 20;
    private _reloadSubscription: Subscription | null = null;
    private _sortChangeSub = new ReplaySubject<ISortInfo>(1);
    private _sort: ISortInfo = { key: null, desc: false };
    private _defaultSort?: ISortInfo;
    private _httpParams?: HttpParams;
    private _loadingSub = new BehaviorSubject<boolean>(false);
    private _releadDataSub = new Subject<{ recount: boolean }>();
    // Last filter without paging nad sorting. Used to determinate if change is only in paging or sorting
    private _lastFilter: Partial<TFilter> = {};
    public get queryString() {
        if (this._httpParams) {
            return this._httpParams.toString();
        } else {
            return '';
        }
    }
    public get lastFilter() {
        return this._lastFilter;
    }
    public get sort() {
        return this._sort;
    }
    public get data() {
        return this._data;
    }
    public get pageSize() {
        return this._pageSize;
    }
    public get sortChange$() {
        return this._sortChangeSub.asObservable();
    }
    private _page = 1;
    public get page() {
        return this._page;
    }

    public get loading$() {
        return this._loadingSub.asObservable();
    }
    private _routeSegment: string;

    private _defaultFilter: Partial<TFilter> = {};
    public filter: Partial<TFilter> = {};
    public items: Array<TItem> = [];
    private _itemsSub = new BehaviorSubject<Array<TItem>>(this.items);
    public items$: Observable<Array<TItem>>;

    private _itemsCountSub = new BehaviorSubject<number>(0);
    public itemsCount$: Observable<number>;
    private _collectionEndpoint?: string;

    constructor(private route: ActivatedRoute, private router: Router, private http: HttpClient, private dialogs: DialogsService) {
        this.itemsCount$ = this._itemsCountSub.asObservable();
        const segments: Array<UrlSegment> = [];
        for (const part of route.snapshot.pathFromRoot) {
            segments.push(...part.url);
        }
        this._routeSegment = segments.join('/');
        this.items$ = this._itemsSub.asObservable();
    }

    public getDefaultParams(): IPagerParams {
        return { page: 1, pageSize: 20, sortKey: null, sortDesc: false, count: true };
    }

    public initialize(
        endpoint: string,
        defaultFilter: Partial<TFilter> = {},
        defaultSort: ISortInfo = { key: null, desc: false },
        defaultPageSize?: number,
        allRecords?: boolean
    ) {
        this._defaultFilter = defaultFilter;
        this.filter = { ...defaultFilter };
        this._defaultSort = defaultSort;
        this._collectionEndpoint = endpoint;

        if (this._reloadSubscription && !this._reloadSubscription.closed) {
            this._reloadSubscription.unsubscribe();
        }
        const routeParams$ = this.route.queryParams.pipe(
            filter(() => !!this._collectionEndpoint),
            map((params) => {
                let httpParams = new HttpParams();
                let newFilter: any = {};
                if (params.page && params.pageSize) {
                    for (const [key, value] of Object.entries(params)) {
                        if (['page', 'pageSize', 'sortKey', 'sortDesc'].indexOf(key) === -1) {
                            newFilter[key] = this.convertToPrimitive(value, key.endsWith('Ids'));
                        }
                    }
                } else {
                    /**
                     * We only want to use default filter in two scenarios:
                     * 1. User navigate to the collection for the first time
                     * 2. User hits the reset button
                     * The reset button creates new query string, so we already have it stored here in params,
                     * Only when we have empty query string (without page and pageSize) we should force default params here
                     * Please BE CAREFUL future me or anybody else, when editing this code :).
                     */
                    newFilter = { ...(this._defaultFilter as any) };
                    Object.keys(params)
                        .filter((x) => x.startsWith('returnQuery'))
                        .forEach((k) => {
                            newFilter[k] = params[k];
                        });
                }

                const recount = JSON.stringify(newFilter) !== JSON.stringify(this._lastFilter) || this._itemsCountSub.value === 0;
                this._lastFilter = { ...newFilter };

                this._page = +params['page'] || 1;
                this._pageSize = allRecords ? 0 : +params['pageSize'] || defaultPageSize || 20;
                this._sort.key = +(params['sortKey'] || this._defaultSort.key);
                this._sort.desc = (params['sortDesc'] || this._defaultSort.desc).toString() === 'true';
                this._sortChangeSub.next(this._sort);
                this.filter = newFilter as TFilter;
                httpParams = httpParams.set('page', this._page.toString());
                httpParams = httpParams.set('pageSize', this._pageSize.toString());
                httpParams = httpParams.set('sortKey', this._sort.key.toString());
                httpParams = httpParams.set('sortDesc', this._sort.desc.toString());
                for (const [key, value] of Object.entries(this.filter)) {
                    if (key.startsWith('returnQuery')) {
                        continue;
                    }
                    if (value !== null && value !== undefined) {
                        if (Array.isArray(value)) {
                            value.forEach((x) => {
                                httpParams = httpParams.append(key, x.toString());
                            });
                        } else {
                            // eslint-disable-next-line @typescript-eslint/no-base-to-string
                            httpParams = httpParams.set(key, value.toString());
                        }
                    }
                }
                this._httpParams = httpParams;
                return { recount: recount };
            })
        );

        this._reloadSubscription = merge(routeParams$, this._releadDataSub)
            .pipe(
                switchMap((options) => {
                    this._loadingSub.next(true);
                    return this.http.get<any>(this._collectionEndpoint, { params: this._httpParams.set('$count', options.recount.toString()) }).pipe(
                        catchError((err: HttpErrorResponse) => {
                            if (err.status === 400) {
                                this.dialogs.badRequestMessage(err);
                            }
                            return of(null);
                        })
                    );
                })
            )
            .subscribe((data) => {
                this._loadingSub.next(false);
                if (data) {
                    this._data = data;
                    this.items = data.values;
                    this._itemsSub.next(this.items);
                    if (data.totalItems !== -1) {
                        this._itemsCountSub.next(data.totalItems);
                    }
                }
            });
    }

    private convertToPrimitive(value: any, forceArray = false) {
        if (forceArray) {
            if (!Array.isArray(value)) {
                value = [value];
            }
        }
        if (Array.isArray(value)) {
            value = [...value];
            for (let index = 0; index < value.length; index++) {
                value[index] = this.convertToPrimitive(value[index]);
            }
            return value;
        } else {
            if (value === 'true') {
                return true;
            } else if (value === 'false') {
                return false;
            } else if (value !== '' && Number.isFinite(+value)) {
                return +value;
            } else {
                return value;
            }
        }
    }

    public reloadData() {
        if (this._collectionEndpoint) {
            this._releadDataSub.next({ recount: false });
        }
    }

    public setSort(col: number) {
        if (this._sort.key === col) {
            this._sort.desc = !this._sort.desc;
        } else {
            this._sort.key = col;
            this._sort.desc = false;
        }
        this._sortChangeSub.next(this._sort);
        this.refresh();
    }

    public clearFilter(value: TFilter = {} as TFilter) {
        this.filter = Object.entries(value).length === 0 && value.constructor === Object ? { ...this._defaultFilter } : value;
        this.refresh();
    }

    public setPage(page: number, pageSize: number) {
        if (page === this._page && pageSize === this._pageSize) {
            return;
        }
        this._page = page;
        this._pageSize = pageSize;
        this.refresh();
    }

    public search() {
        this._page = 1; // reset paging when search terms changes
        this.refresh();
    }

    private refresh() {
        const queryParams: Params = { page: this._page, pageSize: this._pageSize, sortKey: this._sort.key, sortDesc: this._sort.desc };
        for (const [key, value] of Object.entries(this.filter)) {
            if (value !== null && value !== undefined) {
                queryParams[key] = value;
            }
        }
        this.router
            .navigate([this._routeSegment], { queryParams: queryParams })
            .then((r) => {
                if (r === null) {
                    this.reloadData();
                }
            })
            .catch(EMPTY);
    }

    ngOnDestroy() {
        if (this._reloadSubscription && !this._reloadSubscription.closed) {
            this._reloadSubscription.unsubscribe();
        }
        this._itemsCountSub.complete();
        this._sortChangeSub.complete();
        this._itemsSub.complete();
        this._loadingSub.complete();
        this._releadDataSub.complete();
    }
}
