<div mat-dialog-title>
    <button type="button" class="close" mat-dialog-close><span aria-hidden="true">×</span></button>
    <h4>{{ 'ReleaseNotes_Header' | translate }} - {{ releaseNotes?.latestTag }}</h4>
</div>
<mat-dialog-content>
    <div *ngIf="releaseNotes" [innerHTML]="releaseNotes.releaseNotes?.html"></div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-dialog-close type="button" class="btn btn-white">{{ 'Common_Close' | translate }}</button>
</mat-dialog-actions>
