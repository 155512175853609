import { ErrorMessages, FormInputBase } from '@alza/cms-components';
import { Component, forwardRef, Host, Input, Optional, SkipSelf } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ITreeSelectListItemDto } from 'app/models';
import { ITreeSelectListItemStateDto } from './tree-select-list-item-state-dto';

const INPUT_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    useExisting: forwardRef(() => TreeSelectComponent),
    multi: true
};
@Component({
    selector: 'app-tree-select',
    templateUrl: './tree-select.component.html',
    styleUrls: ['./tree-select.component.scss'],
    providers: [INPUT_VALUE_ACCESSOR],
    viewProviders: [{ provide: ErrorMessages, deps: [TranslateService], useClass: ErrorMessages }]
})
export class TreeSelectComponent extends FormInputBase implements ControlValueAccessor {
    private _items: Array<ITreeSelectListItemStateDto>;
    public value: Array<number> = [];

    /** if you change the input array, you need to trigger change (example: yourArr = [...yourArr]), or new elements won't be initialized properly */
    @Input() public set items(value: Array<ITreeSelectListItemDto>) {
        this._items = value;
        this.prepareTree();
        this.fillDataAndCollapse();
    }

    public get items() {
        return this._items;
    }

    constructor(
        @Optional()
        @Host()
        @SkipSelf()
        controlContainer: ControlContainer,
        errorMessages: ErrorMessages
    ) {
        super(controlContainer, errorMessages, { tidPrefix: 'ts' });
    }

    public updateValue(obj: any): void {
        if (obj === this.value) {
            return;
        }
        this.value = obj === undefined || obj === null ? [] : obj;
        this.fillDataAndCollapse();
    }

    public onValueChange(value: any) {
        this.value = value;
        this.raiseChange(value);
        this.raiseTouched();
    }

    public clear() {
        this.onValueChange([]);
    }

    public toggleExpand(item: ITreeSelectListItemStateDto) {
        if (!this.hasChildren(item)) {
            return;
        }
        item.isExpanded = !item.isExpanded;
    }

    public isChecked(item: ITreeSelectListItemStateDto) {
        return !!item.isSelected;
    }

    public hasChildren(item: ITreeSelectListItemStateDto) {
        return !!item?.children?.some(() => true);
    }

    private propagateCheckedParent(item: ITreeSelectListItemStateDto) {
        let parent = item.parent;
        while (parent) {
            const isChecked = this.isChecked(parent);
            const shouldBeChecked = !parent.children?.some((child) => !this.isChecked(child));
            if (isChecked !== shouldBeChecked) {
                this._check(parent, shouldBeChecked);
            }
            parent = parent.parent;
        }
    }

    public check(item: ITreeSelectListItemStateDto) {
        const shouldBeChecked = !this.isChecked(item);
        this._check(item, shouldBeChecked);
        if (this.hasChildren(item)) {
            this.treeForEach(item.children, (child) => this._check(child, shouldBeChecked));
        }
        this.propagateCheckedParent(item);
        this.onValueChange(this.value);
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    private _check(item: ITreeSelectListItemStateDto, shouldBeChecked: boolean) {
        if (shouldBeChecked) {
            item.isSelected = true;
            if (item.value) {
                if (this.value.every((v) => v !== item.value)) {
                    this.value.push(item.value);
                }
            }
        } else {
            item.isSelected = false;
            if (item.value) {
                const indexToRemove = this.value.indexOf(item.value);
                if (indexToRemove < 0) {
                    return;
                }
                this.value.splice(indexToRemove, 1);
            }
        }
    }

    private prepareTree() {
        this.treeForEach(this._items, (item) => {
            item.isSelected = false;
            if (item.children) {
                item.children.forEach((child) => {
                    child.parent = item;
                });
            }
        });
    }

    private fillDataAndCollapse() {
        this.treeForEach(this._items, (item) => {
            item.isExpanded = true;
            item.isSelected = this.value.some((v) => v === item.value);
            if (item.isSelected) {
                this.propagateCheckedParent(item);
            }
        });
        this.treeForEach(this._items, (item) => {
            if (!item.children) {
                return;
            }
            if (item.children?.every((child) => child.isSelected) || item.children?.every((child) => !child.isSelected)) {
                item.isExpanded = false;
            }
        });
    }

    private treeForEach(items: Array<ITreeSelectListItemStateDto>, onEvery: (item: ITreeSelectListItemStateDto) => void) {
        if (!Array.isArray(items)) {
            return;
        }
        items.forEach((item) => {
            onEvery(item);
            if (Array.isArray(item.children)) {
                this.treeForEach(item.children, onEvery);
            }
        });
    }
}
