import { Component, Input } from '@angular/core';
import { DataSource } from '@alza/cms-components';

@Component({
    selector: 'app-data-grid-filter',
    templateUrl: './data-grid-filter.component.html',
    styleUrls: ['./data-grid-filter.component.scss']
})
export class DataGridFilterComponent {
    @Input() dataSource: DataSource;
}
