import { Component, QueryList, ContentChildren, Input, Output, EventEmitter, ChangeDetectorRef, AfterViewInit, TemplateRef } from '@angular/core';
import { TabDirective } from './tab.directive';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

@Component({
    selector: 'app-tabs',
    templateUrl: './tabs.component.html',
    styleUrls: ['./tabs.component.scss'],
    providers: []
})
export class TabsComponent implements AfterViewInit {
    @ContentChildren(TabDirective)
    public tabs?: QueryList<TabDirective>;
    @Input() tabTemplate: TemplateRef<TabDirective>;
    @Input() sortable = false;
    @Input()
    public id = '';
    private _active?: any;
    @Input()
    set active(value: any) {
        this._active = value;
        if (this.tabs && (value || value === 0)) {
            this.selectedTab = this.tabs.toArray().find((x) => x.value === value);
            this.selectTab(this.selectedTab, false);
        }
    }

    @Output()
    activeChange = new EventEmitter<any>();

    @Output()
    sortChange = new EventEmitter<CdkDragDrop<any>>();

    @Input() disabled = false;

    public selectedTab?: TabDirective;
    constructor(private changeDetectionRef: ChangeDetectorRef) {}

    ngAfterViewInit() {
        if (this._active || this._active === 0) {
            this.selectTab(
                this.tabs.toArray().find((x) => x.value === this._active),
                false
            );
            this.changeDetectionRef.detectChanges();
        }
        this.tabs.changes.subscribe(() => {
            if (this._active || this._active === 0) {
                this.selectTab(
                    this.tabs.toArray().find((x) => x.value === this._active),
                    false
                );
            }
        });
    }

    onDrop(event: CdkDragDrop<any>) {
        this.sortChange.emit(event);
    }

    public selectTab(tab?: TabDirective, notify = true, $event = null) {
        if ($event) {
            tab.click.next($event);
        }
        if ((this.disabled && notify) || (tab && tab.disabled)) {
            return;
        }
        if (tab) {
            this.selectedTab = tab;
            if (notify) {
                this.activeChange.next(tab.value);
            }
        }
    }

    public getTabClass(count: number) {
        if (this.tabs.length > 5) {
            return this.getTabClassBySection(count);
        } else {
            return this.getDefaultTabClass(count);
        }
    }

    private getDefaultTabClass(count: number) {
        let tabclass = '';
        switch (count) {
            case 0:
                tabclass = 'nav-primary';
                break;
            case 1:
                tabclass = 'nav-warning';
                break;
            case 2:
                tabclass = 'nav-info';
                break;
            case 3:
                tabclass = 'nav-success';
                break;
            case 4:
                tabclass = 'nav-danger';
                break;
            default:
                break;
        }
        return tabclass;
    }

    private getTabClassBySection(count: number) {
        let tabclass = '';
        switch (count) {
            case 1:
                tabclass = 'nav-alza';
                break;
            case 2:
                tabclass = 'nav-media';
                break;
            case 3:
                tabclass = 'nav-hracky';
                break;
            case 4:
                tabclass = 'nav-trendy';
                break;
            case 5:
                tabclass = 'nav-maxi';
                break;
            case 6:
                tabclass = 'nav-gaming';
                break;
            case 7:
                tabclass = 'nav-sport';
                break;
            case 8:
                tabclass = 'nav-hobby';
                break;
            case 9:
                tabclass = 'nav-alzapc';
                break;
            case 10:
                tabclass = 'nav-moto';
                break;
            default:
                tabclass = 'nav-primary';
                break;
        }
        return tabclass;
    }
}
