<div class="btns" [attr.data-tid]="'btnGrpLocalization'">
    <div class="grid-container" [ngStyle]="getGridColumns()">
        <span *ngFor="let c of countryArray; let index = index" class="grid-item" [ngStyle]="getBtnGridposition(c, index)" (mousedown)="selectCountry(c)">
            <button class="btn btn-xs" *ngIf="index <= shownCountries" [ngClass]="getButtonClass(c, index)" type="button" [attr.data-tid]="'btn_' + c.code">
                {{ c.code }}
            </button>
            <button
                class="btn btn-xs"
                *ngIf="index > shownCountries && isExpanded"
                [ngClass]="getButtonClass(c, index)"
                [attr.data-tid]="'btnGrp_' + c.code"
                type="button"
            >
                {{ c.code }}
            </button>
        </span>
        <button
            *ngIf="this.countryArray.length > 6"
            class="btn btn-xs more-button"
            type="button"
            (click)="isExpanded = !isExpanded"
            (blur)="isExpanded = false"
        >
            <span class="invalid-count" [ngClass]="{ 'is-active': getInvalidCount() > 0 }">{{ getInvalidCount() }}</span>
            ...
        </button>
    </div>
</div>
