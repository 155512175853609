import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ICountryTranslationDto } from 'app/common/dto/common-dto';
import { CommonDataService } from 'app/common/services/common-data.service';
import { Observable, Subscription } from 'rxjs';
import { CountrySelectService } from '../country-select/country-select.service';

@Component({
    selector: 'app-localization-switch',
    styleUrls: ['./localization-switch.component.scss'],
    templateUrl: './localization-switch.component.html'
})
export class LocalizationSwitchComponent implements OnInit, OnDestroy {
    country?: number;
    public countryArray: Array<any> = [];
    private _maxShownCountries = 5;
    // Number of shown countries by default. Usualy used as index inside array.
    public shownCountries = this._maxShownCountries;
    public isExpanded: boolean;
    private _watchedProperties: Array<string> = [];
    public countries$?: Observable<Array<ICountryTranslationDto>>;
    private _sub?: Subscription;

    constructor(private countrySelectService: CountrySelectService, private commonData: CommonDataService) {}

    @Output()
    localizationChanged = new EventEmitter<number>();

    @Input()
    set properties(value: string | Array<string>) {
        if (!Array.isArray(value)) {
            value = [value];
        }
        this._watchedProperties = value;
    }

    @Input()
    public localizedGroup: UntypedFormGroup;

    ngOnInit() {
        this.countries$ = this.commonData.language.getLanguages();
        this._sub = this.countrySelectService.currentLanguage$.subscribe((country) => {
            this.localizationChanged.emit(country);
            this.country = country;

            // Puts selected country with selected index to the shownIndex position. Does not shift countries that are under th shownIndex.
            const index = this.countryArray.indexOf(this.countryArray.find((x) => x.id === country));
            if (country === this.country && index >= this.shownCountries) {
                const tmp: object = this.countryArray[index];
                this.countryArray[index] = this.countryArray[this.shownCountries];
                this.countryArray[this.shownCountries] = tmp;
            }
        });

        this.commonData.language.getLanguages().subscribe((res) => {
            this.processLanguages(res);
        });
    }

    ngOnDestroy() {
        if (this._sub) {
            this._sub.unsubscribe();
        }
    }

    public selectCountry(item: any) {
        this.countrySelectService.changeLanguage(item.id);
    }

    public isFilled(country: ICountryTranslationDto): boolean {
        if (!this.localizedGroup || country.id === this.country) {
            return false;
        }
        let isFilled = false;
        let checkedProperties = this._watchedProperties;
        if (checkedProperties.length === 0) {
            checkedProperties = Object.keys((this.localizedGroup.get(country.id.toString()) as UntypedFormGroup).controls);
        }
        checkedProperties.forEach((property) => {
            if (this.localizedGroup.get(country.id + '.' + property) && !!this.localizedGroup.get(country.id + '.' + property).value) {
                isFilled = true;
            }
        });
        return isFilled;
    }

    public isInvalid(country: ICountryTranslationDto): boolean {
        if (!this.localizedGroup || country.id === this.country) {
            return false;
        }
        let isInvalid = false;
        let checkedProperties = this._watchedProperties;
        if (checkedProperties.length === 0) {
            checkedProperties = Object.keys((this.localizedGroup.get(country.id.toString()) as UntypedFormGroup).controls);
        }
        checkedProperties.forEach((property) => {
            if (
                this.localizedGroup.get(country.id + '.' + property) &&
                this.localizedGroup.get(country.id + '.' + property).enabled &&
                this.localizedGroup.get(country.id + '.' + property).invalid
            ) {
                isInvalid = true;
            }
        });
        return isInvalid;
    }

    public getButtonClass(c: ICountryTranslationDto) {
        if (!this.country) {
            return 'btn-white';
        }
        if (c.id === this.country) {
            return 'btn-primary';
        }
        if (this.isInvalid(c)) {
            return 'btn-danger';
        }
        if (this.isFilled(c)) {
            return 'filled-g';
        }
        return 'btn-white';
    }

    getBtnGridposition(c: ICountryTranslationDto, index: number) {
        // Sets the position inside grid of the last country that is selected.
        if (c.id === this.country && index >= this.shownCountries) {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            return { 'grid-column': `${this.shownCountries + 1}`, 'grid-row': '1' };
        }
    }

    getGridColumns() {
        // Sets the position inside grid of the last country that is selected.
        let result: object;
        // eslint-disable-next-line @typescript-eslint/naming-convention
        result = { 'grid-template-columns': `repeat(${this.shownCountries + 1}, 1fr)` };

        // handles positioning of country-select items, so It always fits with the overall grid
        if (this.countryArray.length > 6) {
            result = { ...result, transform: `translate(${(this.shownCountries + 2) * -30}px, 0%)` };
        } else {
            result = { ...result, width: `${this.shownCountries * 30}px` };
            if (this.countryArray.length === 6) {
                result = { ...result, transform: `translate(${(this.shownCountries + 1) * -30}px, 0%)` };
            } else {
                result = { ...result, transform: `translate(${this.shownCountries * -30}px, 0%)` };
            }
        }
        return result;
    }

    getInvalidCount(): number {
        return this.countryArray.filter((el, index) => (index > this.shownCountries ? this.isInvalid(el) : false)).length;
    }

    private processLanguages(res: Array<ICountryTranslationDto>) {
        this.countryArray = res;
        // maxShownCountries = 5 is the max value of static countries. If number of countries is lower, we decrease shownCountries
        if (this.countryArray.length < this._maxShownCountries) {
            this.shownCountries = this.countryArray.length;
        }
        // Shifts countries array so Hungary is on the 4th index, if It exists
        if (this.countryArray.find((x) => x.code === 'HU')) {
            const hungaryIndex = this.countryArray.findIndex((x) => x.code === 'HU');
            const tmp = this.countryArray.splice(hungaryIndex, 1);
            this.countryArray.splice(4, 0, tmp[0]);
            this.countryArray.forEach((el) => {
                if (el.id === this.country) {
                    this.selectCountry(el);
                }
            });
        }
    }
}
