import { ErrorMessages, FormInputBase } from '@alza/cms-components';
import { Component, EventEmitter, forwardRef, Host, Input, Optional, Output, SkipSelf } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import 'moment-timezone';
const INPUT_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SliderComponent),
    multi: true
};
@Component({
    selector: 'app-slider',
    templateUrl: './slider.component.html',
    styleUrls: ['./slider.component.scss'],
    providers: [INPUT_VALUE_ACCESSOR],
    viewProviders: [{ provide: ErrorMessages, deps: [TranslateService], useClass: ErrorMessages }]
})
export class SliderComponent extends FormInputBase implements ControlValueAccessor {
    @Input() public minValue: number;
    @Input() public maxValue: number;
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() blur = new EventEmitter<Event>();
    @Output() emitValue = new EventEmitter<number>();
    public value: number | null = null;
    constructor(
        @Optional()
        @Host()
        @SkipSelf()
        controlContainer: ControlContainer,
        errorMessages: ErrorMessages
    ) {
        super(controlContainer, errorMessages, { tidPrefix: 'tb' });
        this.value = this.minValue ?? null;
    }
    updateValue(obj: any): void {
        this.value = obj === undefined ? null : obj;
    }
    onValueChange() {
        this.raiseChange(this.value);
        this.raiseTouched();
    }
    onBlur() {
        this.blur.emit();
    }
}
