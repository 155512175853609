import { HttpClient } from '@angular/common/http';
import { TranslationType } from 'app/models/enums/translations';
import { ICountryTranslationDto } from '../dto/common-dto';
import { BaseApi } from './base-api';

export class LanguageApi extends BaseApi {
    public constructor(http: HttpClient) {
        super(http);
    }

    /**
     * Gets the collection of allowd languages
     */
    public getLanguages() {
        return this.load<Array<ICountryTranslationDto>>('api/common/languages/allowed', 'countryTranslations');
    }

    /**
     * Gets the collection of all languages
     */
    public getAllLanguages() {
        return this.load<Array<ICountryTranslationDto>>('api/common/languages', 'countryTranslationsAll');
    }

    public getTargetTranslationLanguages(translationType: TranslationType = TranslationType.Commodity) {
        return this.load<Array<ICountryTranslationDto>>(`api/common/languages/targetLanguageTranslations/${translationType}`, 'targetLanguageTranslations');
    }

    public getSourceTranslationLanguages() {
        return this.load<Array<ICountryTranslationDto>>('api/common/languages/sourceLanguageTranslations', 'sourceLanguageTranslations');
    }

    // Gets default languages for all eshops from LanguageCollection
    public getEshopLanguages() {
        return this.http.get<Array<ICountryTranslationDto>>('api/common/languages/eshop-languages');
    }

    // Gets default languages for translation from LanguageCollection
    public getTranslationLanguages() {
        return this.http.get<Array<ICountryTranslationDto>>('api/common/languages/translation-languages');
    }
}
