import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ICountryExtended } from '../country-extended';

export interface ISelectCountryContext {
    countries: Array<ICountryExtended>;
}

@Component({
    templateUrl: './select-country-modal.component.html',
    styleUrls: ['./select-country-modal.component.scss']
})
export class SelectCountryModalComponent {
    public countries: Array<ICountryExtended> = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) private readonly context: ISelectCountryContext,
        private readonly dialogRef: MatDialogRef<SelectCountryModalComponent, Array<ICountryExtended>>
    ) {
        this.countries = this.context.countries;
    }

    public ok() {
        this.dialogRef.close(this.countries);
    }
}
