<div mat-dialog-title>
    <button type="button" class="close" (click)="closeDialog(false)"><span aria-hidden="true">×</span></button>
    <h4>{{ 'External_Banner_Campaign_Vendor_MPLA_Title' | translate }}</h4>
</div>
<mat-dialog-content>
    <div>
        {{ 'External_Banner_Campaign_Vendor_MPLA_Text' | translate }}
    </div>
    <div>
        <cms-checkbox
            [(ngModel)]="noMpla"
            id="noMpla"
            name="noMpla"
            [labelText]="'External_Banner_Campaign_Vendor_MPLA_CheckboxLabel' | translate"
        ></cms-checkbox>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button type="button" class="btn btn-primary ml-auto" (click)="closeDialog(true)" data-tid="mpla-confirm-ok">{{ 'Common_Ok' | translate }}</button>
</mat-dialog-actions>
