<div class="form-group" [class.mb-0]="!labelText" [ngClass]="formGroupClass">
    <cms-label
        *ngIf="labelText"
        [required]="labelRequired"
        [for]="id"
        [text]="labelText"
        [tooltip]="labelTooltip"
        [tooltipDirection]="labelTooltipDirection"
        [tooltipIcon]="labelTooltipIcon"
        [class]="labelClass"
    ></cms-label>

    <div class="input-group" [class.cursor-not-allowed]="disabled" [class.cursor-pointer]="!disabled">
        <div class="input-group-btn">
            <button (click)="openModal()" [attr.data-tid]="'btnCategorySelector'" tabindex="-1" class="btn btn-primary" type="button" [disabled]="disabled">
                <i class="fas fa-sitemap fa-rotate-270"></i>
            </button>
        </div>
        <span [attr.data-tid]="'textPlacement'" (click)="!disabled && openModal()" class="form-control overflow-styling" [attr.disabled]="disabled || null">
            <span [class.overflow-tooltip]="text == null">{{ text || placeholder }}</span>
        </span>
        <span [attr.data-tid]="'btnClear'" *ngIf="clearable && value && !disabled" right-addon class="input-group-addon"
            ><i class="fas fa-times fa-danger-hover" (click)="clear()"></i
        ></span>
    </div>
    <cms-field-errors [submitted]="submitted" [control]="control"></cms-field-errors>
</div>
