<div mat-dialog-title>
    <button type="button" class="close" mat-dialog-close>
        <span aria-hidden="true"><i class="fas fa-times"></i></span>
    </button>
    <h4>{{ 'Link_SelectUrl' | translate }}</h4>
</div>
<mat-dialog-content class="mat-dialog-content-overflow-visible">
    <div class="localizationGroup">
        <app-country-localization-switch
            [localizedArray]="control('localization')"
            [properties]="['url', 'linkType', 'categoryType', 'categoryId', 'articleId', 'commodityId', 'commodityPackId']"
            [countryBy]="'translations'"
            [showCountries]="showCountries"
        ></app-country-localization-switch>

        <cms-select
            [id]="'linkType'"
            [enum]="'Link+LinkType'"
            [labelText]="'Link_LinkType' | translate"
            [formControl]="localized('linkType')"
            appendTo="body"
            clearable="true"
        >
        </cms-select>

        <cms-select
            *ngIf="localized('linkType').value === linkType.Category"
            [id]="'categoryType'"
            [enum]="'Link+CategoryType'"
            [labelText]="'Link_CategoryType' | translate"
            [formControl]="localized('categoryType')"
            appendTo="body"
        >
        </cms-select>

        <cms-typeahead-select
            *ngIf="localized('linkType').value === linkType.Category"
            [labelText]="'Link_CategoryTitle' | translate"
            [placeholder]="'TypeaheadSelect_CategoryPlaceholder' | translate"
            [dataSource]="categoryDataSource"
            [clearable]="true"
            bindLabel="text"
            bindValue="id"
            [formControl]="localized('categoryId')"
            appendTo="body"
        >
        </cms-typeahead-select>

        <cms-typeahead-select
            *ngIf="localized('linkType').value === linkType.Article"
            [labelText]="'Link_ArticleTitle' | translate"
            [placeholder]="'TypeaheadSelect_TypetoSearchTextPlaceholder' | translate"
            [dataSource]="articleDataSource"
            [clearable]="true"
            bindLabel="text"
            bindValue="id"
            [formControl]="localized('articleId')"
            appendTo="body"
        >
        </cms-typeahead-select>

        <cms-typeahead-select
            *ngIf="localized('linkType').value === linkType.Commodity"
            [labelText]="'Link_CommodityTitle' | translate"
            [placeholder]="'TypeaheadSelect_CommodityPlaceholder' | translate"
            [dataSource]="commodityDataSource"
            [clearable]="true"
            bindLabel="text"
            bindValue="id"
            [formControl]="localized('commodityId')"
            appendTo="body"
        >
        </cms-typeahead-select>

        <cms-typeahead-select
            *ngIf="localized('linkType').value === linkType.CommodityPack"
            [labelText]="'Link_CommodityPackTitle' | translate"
            [placeholder]="'TypeaheadSelect_CommodityPackPlaceholder' | translate"
            [dataSource]="commodityPackDataSource"
            [clearable]="true"
            bindLabel="text"
            bindValue="id"
            [formControl]="localized('commodityPackId')"
            appendTo="body"
        >
        </cms-typeahead-select>

        <cms-typeahead-select
            *ngIf="localized('linkType').value === linkType.Category && localized('categoryType').value !== categoryType.GeneratedCategory"
            [labelText]="getCategoryTypeTitle()"
            [placeholder]="getCategoryTypePlaceHolder()"
            [dataSource]="getCatergoryTypeDataSource()"
            [clearable]="true"
            bindLabel="text"
            bindValue="id"
            [formControl]="localized('categoryTypeId')"
            appendTo="body"
        >
        </cms-typeahead-select>

        <cms-input
            *ngIf="localized('linkType').value === linkType.Other"
            [id]="'linkUrl'"
            [labelText]="'Link_Url' | translate"
            [formControl]="localized('url')"
        ></cms-input>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-dialog-close type="button" data-tid="btnClose" class="btn btn-white">{{ 'Common_Close' | translate }}</button>
    <button type="button" class="btn btn-primary" data-tid="btnOk" (click)="submit()">{{ 'Common_Ok' | translate }}</button>
</mat-dialog-actions>
