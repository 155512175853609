import { Component, EventEmitter, Output } from '@angular/core';
import { EMPTY } from 'app/common/constants';
import { ICategoryDto, IParameterDto, IParameterGroupDto } from './models';
import { ParameterSelectService } from './parameter-select.service';
import { Path } from './path';

@Component({
    selector: 'app-parameter-select',
    templateUrl: './parameter-select.component.html',
    styleUrls: ['./parameter-select.component.scss'],
    providers: [ParameterSelectService]
})
export class ParameterSelectComponent {
    public categoryPath: Path<ICategoryDto>;
    private _parameterGroupSelected: IParameterGroupDto | null = null;
    public parameterSelected: IParameterDto | null = null;
    public categoryList: Array<ICategoryDto> | null = null;
    public parameterGroupList: Array<IParameterGroupDto> | null = null;
    public parameterList: Array<IParameterDto> | null = null;

    @Output()
    parameterChange = new EventEmitter<number>();

    constructor(protected dataService: ParameterSelectService) {
        // shortcut, since we know first/root category
        this.categoryPath = new Path<ICategoryDto>({ id: 1, name: 'Alza' });
        this.restoreState();
    }

    public saveState() {
        this.dataService.saveState({
            categoryId: this.categoryPath.current.id,
            groupId: this.parameterGroupSelected !== null ? this.parameterGroupSelected.id : null
        });
    }

    public restoreState() {
        const state = this.dataService.loadState();
        this.loadPath(state.categoryId);
        this.loadCategories(state.categoryId);
        this.loadCategoryGroups(state.categoryId)
            .then(() => {
                if (state.groupId && this.parameterGroupList) {
                    this.parameterGroupSelected = this.parameterGroupList.find((t) => t.id === state.groupId) || null;
                }
            })
            .catch(EMPTY);
        this.getGroupParameters(state.groupId);
    }

    public loadPath(categoryId: number) {
        this.dataService.getPath(categoryId).subscribe((res) => (this.categoryPath.path = res));
    }

    public get categorySelected() {
        return this.categoryPath.current;
    }

    public set categorySelected(value) {
        this.categoryPath.findOrPush(value, (obj1, obj2) => obj1.id === obj2.id);
        this.loadCategories(this.categoryPath.current.id);
        this.loadCategoryGroups(this.categoryPath.current.id).catch(EMPTY);
        this.saveState();
    }

    loadCategories(categoryId: number | null) {
        this.categoryList = null;
        if (categoryId !== null) {
            this.dataService.getCategories(categoryId).subscribe((res) => (this.categoryList = res));
        }
    }

    loadCategoryGroups(categoryId: number | null) {
        return new Promise<void>((resolve) => {
            this.parameterSelected = null;
            this.parameterList = null;
            this._parameterGroupSelected = null;
            this.parameterGroupList = null;
            if (categoryId === null) {
                return;
            }
            this.dataService.getCategoryGroups(categoryId).subscribe((res) => {
                this.parameterGroupList = res;
                resolve();
            });
        });
    }

    getGroupParameters(groupId: number | null) {
        this.parameterSelected = null;
        this.parameterList = null;
        if (groupId === null) {
            return;
        }
        this.dataService.getGroupParameters(groupId).subscribe((res) => (this.parameterList = res));
    }

    public get parameterListWithoutCurrent() {
        // if (this.parameterList !== null && this.context !== null && this.context.filteredParameterId !== null) {
        // 	const i = this.context.filteredParameterId;
        // 	return this.parameterList.filter(p => p.id !== i);
        // }
        return this.parameterList;
    }

    public get canSubmit() {
        return this.parameterSelected ? true : false;
    }

    public get parameterGroupSelected() {
        return this._parameterGroupSelected;
    }

    public set parameterGroupSelected(value) {
        this._parameterGroupSelected = value;
        this.getGroupParameters(value ? value.id : null);
        this.saveState();
    }

    public selectParameter(parameter: IParameterDto) {
        this.parameterSelected = parameter;
        this.parameterChange.emit(parameter.id);
    }
}
