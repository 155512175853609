<div class="form-group" [class.mb-0]="!labelText" [ngClass]="formGroupClass">
    <cms-label
        *ngIf="labelText"
        [required]="labelRequired"
        [for]="id"
        [text]="labelText"
        [tooltip]="labelTooltip"
        [tooltipDirection]="labelTooltipDirection"
        [tooltipIcon]="labelTooltipIcon"
        [class]="labelClass"
    ></cms-label>
    <div #wrapper [class.input-group]="wrapper && wrapper.childElementCount !== 1">
        <div class="form-control">
            <i class="fas fa-file"></i>
            <span *ngIf="file" class="fileinput-filename">{{ file.name }}</span>
        </div>
        <span class="input-group-addon btn btn-default btn-file">
            <span *ngIf="!file" class="" [attr.data-tid]="'file_choose'">{{ 'InputFile_Choose' | translate }}</span>
            <span *ngIf="file" class="" [attr.data-tid]="'file_change'">{{ 'InputFile_Change' | translate }}</span>
            <input (input)="onValueChange($event.target.value)" type="file" name="file" [accept]="accept" (change)="addFile($event.target.files)" />
        </span>
        <a *ngIf="file" [attr.data-tid]="'file_remove'" (click)="removeFile()" class="input-group-addon btn btn-default">{{
            'InputFile_Remove' | translate
        }}</a>
    </div>
    <cms-field-errors [submitted]="submitted" [control]="control"></cms-field-errors>
</div>
