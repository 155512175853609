/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum ParameterTableType {
    Group = 1,
    Type = 2,
    TypeGroup = 3,
    TypeEnum = 4,
    Unit = 5,
    UnitType = 6,
}
