<div class="form-group" [class.mb-0]="!labelText">
    <cms-label
        *ngIf="labelText"
        [required]="labelRequired"
        [for]="id"
        [text]="labelText"
        [tooltip]="labelTooltip"
        [tooltipDirection]="labelTooltipDirection"
        [tooltipIcon]="labelTooltipIcon"
        [class]="labelClass"
    ></cms-label>
    <div #wrapper [class.input-group]="wrapper.childElementCount !== 1">
        <ng-content select="[left-addon]"></ng-content>
        <input
            [value]="value"
            #input
            [attr.id]="id"
            type="text"
            class="form-control"
            [disabled]="disabled"
            autocomplete="off"
            [(colorPicker)]="value"
            [cpDisableInput]="true"
            [cpPosition]="'bottom'"
            [cpOutputFormat]="'hex'"
            (colorPickerChange)="onValueChange($event)"
            (cpInputChange)="onValueChange($event)"
            (input)="onValueChange($event.target.value)"
        />
        <div (click)="input.click()" class="input-group-addon color-addon" [style.background]="value"></div>
        <ng-content select="[right-addon]"></ng-content>
    </div>
    <cms-field-errors [submitted]="submitted" [control]="control"></cms-field-errors>
</div>
