import cloneDeep from 'clone-deep';
import $ from 'jquery';
import { maxSafeInteger, unicodeBomCharacter } from './constants';
import { NavigateToNotFoundError } from './errors';
import { ISelectIdParamOptions } from './operators';

/**
 * Some parts of application may behave diferently for automated test environment.
 * This function determinates if the aplication is running tests.
 * @returns true if in test environment
 */
export function isRunningWithinTestEnvironment() {
    return (sessionStorage.getItem('isAutomationTest') && sessionStorage.getItem('isAutomationTest') === 'true') || false;
}

export class FileUtils {
    static downloadBinaryFile(fileName: string, fileContent: string, type = 'application/octet-stream') {
        const a = document.createElement('a');
        document.body.appendChild(a);
        const blob = new Blob([this.base64ToArrayBuffer(fileContent)], { type });
        a.href = URL.createObjectURL(blob);
        a.download = fileName;
        a.click();
    }

    static downloadCsvFile(fileName: string, fileContent: ArrayBuffer) {
        const a = document.createElement('a');
        document.body.appendChild(a);
        const textEncoder = new TextDecoder('utf-8');
        const arr = new Uint8Array(fileContent);
        const blob = new Blob([unicodeBomCharacter, textEncoder.decode(arr)], { type: 'text/csv' });
        a.href = URL.createObjectURL(blob);
        a.download = fileName;
        a.click();
    }

    static downloadXMLFile(fileName: string, fileContent: ArrayBuffer) {
        const a = document.createElement('a');
        document.body.appendChild(a);
        const textEncoder = new TextDecoder('utf-8');
        const arr = new Uint8Array(fileContent);
        const blob = new Blob([unicodeBomCharacter, textEncoder.decode(arr)], { type: 'application/xml' });
        a.href = URL.createObjectURL(blob);
        a.download = fileName;
        a.click();
    }

    static base64ToArrayBuffer(base64: string): Uint8Array {
        const binaryString = window.atob(base64);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
            const ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    static arrayBufferToBase64(buffer: ArrayBuffer): string {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }
}

export class UrlUtils {
    /**
     * Converts string to correct relative url
     * @param value The string value
     */
    static toUrlFormat(value: string) {
        let strAccentsOut = StringUtils.removeAccents(value).trim();
        strAccentsOut = strAccentsOut.replace(/ /g, '-');
        strAccentsOut = strAccentsOut.replace(/–/g, '-');
        strAccentsOut = strAccentsOut.replace(/,/g, '');
        strAccentsOut = strAccentsOut.replace(/!/g, '');
        strAccentsOut = strAccentsOut.replace(/\|/g, '');
        strAccentsOut = strAccentsOut.replace(/"/g, '');
        strAccentsOut = strAccentsOut.replace(/„/g, '');
        strAccentsOut = strAccentsOut.replace(/“/g, '');
        strAccentsOut = strAccentsOut.replace(/'/g, '');
        strAccentsOut = strAccentsOut.replace(/\*/g, '');
        strAccentsOut = strAccentsOut.replace(/\+/g, '');
        strAccentsOut = strAccentsOut.replace(/±/g, '');
        strAccentsOut = strAccentsOut.replace(/×/g, '');
        strAccentsOut = strAccentsOut.replace(/:/g, '');
        strAccentsOut = strAccentsOut.replace(/=/g, '');
        strAccentsOut = strAccentsOut.replace(/>/g, '');
        strAccentsOut = strAccentsOut.replace(/</g, '');
        strAccentsOut = strAccentsOut.replace(/#/g, '');
        strAccentsOut = strAccentsOut.replace(/&/g, '');
        strAccentsOut = strAccentsOut.replace(/%/g, '');
        strAccentsOut = strAccentsOut.replace(/\./g, '');
        strAccentsOut = strAccentsOut.replace(/\\/g, '');
        strAccentsOut = strAccentsOut.replace(/\//g, '');
        strAccentsOut = strAccentsOut.replace(/\?/g, '');
        strAccentsOut = strAccentsOut.replace(/\[/g, '');
        strAccentsOut = strAccentsOut.replace(/\]/g, '');
        strAccentsOut = strAccentsOut.replace(/\{/g, '');
        strAccentsOut = strAccentsOut.replace(/\}/g, '');
        strAccentsOut = strAccentsOut.replace(/\(/g, '');
        strAccentsOut = strAccentsOut.replace(/\)/g, '');
        strAccentsOut = strAccentsOut.replace(/----/g, '-');
        strAccentsOut = strAccentsOut.replace(/---/g, '-');
        strAccentsOut = strAccentsOut.replace(/--/g, '-');
        return strAccentsOut.toLowerCase();
    }
}

export class StringUtils {
    /** Remove diactrits*/
    static removeAccents(value: string) {
        return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }
    /**
     * Returns random identifier string. Used for identifing new items in collection editors.
     */
    static generateIdentifier() {
        return Math.random().toString(36).substr(2, 9);
    }

    /**
     * Returns reverse string
     */
    static reverseString(value: string) {
        const splitString = value.split('');
        const reverseArray = splitString.reverse();
        const joinArray = reverseArray.join('');

        return joinArray;
    }

    /**
     * Created new string with first letter in upper-case
     * @param value The string
     */
    static firstUpper(value: string) {
        if (!value) {
            return value;
        }
        return value.charAt(0).toUpperCase() + value.slice(1);
    }

    /**
     * Copy string to clipboard
     * @param value The string
     */
    static copyToClipboard(value: string) {
        const $temp = $('<input>');
        $('body').append($temp);

        $temp.val(value).trigger('select');

        document.execCommand('copy');
        $temp.remove();
    }

    /**
     * Remove linebreaks
     * @param value The string
     */
    static removeLineBreaks(value: string) {
        if (!value) {
            return value;
        }
        return value.replace(/\n/g, '');
    }

    /**
     * Decode html entities
     * @param value The string
     */
    static htmlDecode(value: string) {
        const e = document.createElement('div');
        e.innerHTML = value;
        return e.childNodes[0].nodeValue;
    }
}

/** Query string utils*/
export class QueryString {
    /**
     * Parses the given query string to object, where properties are its keys
     * @param qs The QueryString
     */
    public static parse(qs: string): any {
        const res: any = {};
        if (qs) {
            qs.split('&').forEach((p) => {
                const vals = p.split('=');
                const key = vals[0];
                const value = decodeURIComponent(vals[1]);

                if (res[key]) {
                    if (Array.isArray(res[key])) {
                        res[key].push(value);
                    } else {
                        res[key] = [res[key]];
                        res[key].push(value);
                    }
                } else {
                    res[key] = value;
                }
            });
            return res;
        } else {
            return {};
        }
    }

    public static validateNumericParams(options?: ISelectIdParamOptions, ...args): boolean {
        let ret = true;
        args.forEach((x) => {
            const p = String(x);
            if (p === 'new') {
                if (options?.throwIfNew) {
                    throw new NavigateToNotFoundError(p, options.notFoundRoute);
                } else {
                    ret = false;
                    return;
                }
            }
            if (!Number.isInteger(+p) || +p <= 0 || +p > maxSafeInteger) {
                if (options?.throwIfNaN) {
                    throw new NavigateToNotFoundError(p, options.notFoundRoute);
                } else {
                    ret = false;
                    return;
                }
            }
        });

        return true && ret;
    }
}

export class ObjectUtils {
    public static cloneDeep<T>(item: T) {
        return cloneDeep(item) as T;
    }
}

/** Enum utils*/
export class EnumUtils {
    /**
     * Returns sum value of flagged enum
     * @param enumType The enum
     */
    public static enumSize(enumType: any) {
        let enumSize = 0;

        for (const key in enumType) {
            if (Object.prototype.hasOwnProperty.call(enumType, key)) {
                if (Number(key)) {
                    enumSize += +key;
                }
            }
        }

        return enumSize;
    }
}
