import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-loading-panel',
    animations: [
        trigger('openClose', [
            state(
                'open',
                style({
                    opacity: 0.75,
                    display: 'visible'
                })
            ),
            state(
                'closed',
                style({
                    opacity: 0,
                    display: 'none'
                })
            ),
            transition('open => closed', [animate('0.3s' + ' ease-in-out')]),
            transition('closed => open', [animate('0.3s' + ' ease-in-out')])
        ]),
        trigger('openCloseInner', [
            state(
                'open',
                style({
                    opacity: 1,
                    display: 'visible'
                })
            ),
            state(
                'closed',
                style({
                    opacity: 0,
                    display: 'none'
                })
            ),
            transition('open => closed', [animate('0.3s' + ' ease-in-out')]),
            transition('closed => open', [animate('0.3s' + ' ease-in-out')])
        ])
    ],
    templateUrl: './loading-panel.component.html',
    styleUrls: ['./loading-panel.component.scss']
})
export class LoadingPanelComponent {
    @Input()
    loading = false;
}
