/*
  README:
  I would NOT recommend to include enums in Enums module because you can't use import aliases.
  It's much more readeable to shorten enum name in component.
  import { GenerateDescriptionStyleType as Style } from './enums';
  However for backward compability reasons we should leave existing enum in Enums module

  Also note that you can get enum definition by using this API: /api/common/enums/ts?name=EnumName
  Or list all enums here: /api/common/enums/list
  JD
*/

export enum MailingTemplateType {
    Container = 1,
    RowWide = 2,
    RowDouble = 3,
    RowNormal = 4,
    ItemDouble = 5,
    ItemNormal = 6,
    ItemWide = 7,
    RowFive = 8,
    ItemFive = 9,
    Text = 10
}

export enum MailListActionState {
    Created = 0,
    Ready = 1,
    Running = 2,
    Cancelling = 3,
    Cancelled = 4,
    Finished = 5,
    Archived = 6
}

export enum ObjectType {
    None = 0,
    Login = 1,
    User = 2,
    UserGroup = 3,
    UserRight = 4,
    Configuration = 5,
    WeekLink = 6,
    Translation = 7,
    Partner = 8,
    CategoryFavorite = 9,
    Language = 10,
    Vario = 11,
    PriceGroup = 12,
    DiscountAction = 13,
    DiscountVoucher = 14,
    CommodityLine = 15,
    Delivery = 16,
    Payment = 17,
    Article = 18,
    Category = 19,
    ParameterValue = 20,
    Banner = 21,
    RestrictedIp = 22,
    CommodityMainImage = 23,
    CommodityGalleryImage = 24,
    Exchange = 25,
    Review = 26,
    Pack = 27,
    CommodityAward = 28,
    ParameterTranslation = 29,
    LeasingProduct = 30,
    CommodityDisable = 31,
    CommonSetting = 32,
    ActionCategory = 33,
    FreeItem = 34,
    CommodityArtist = 35,
    CommodityGroup = 36,
    FastOrderVoucher = 37,
    PrideSerie = 38,
    PrideItem = 39,
    InfoList = 40,
    Cashback = 41,
    CommodityComparison = 42,
    TechZoneEvent = 43,
    Holiday = 44,
    Commodity = 45,
    TranslationRequest = 46,
    MailListAction = 47,
    JobOffer = 48,
    PriceComparerBindingOptimisationSchedule = 49,
    InfoMessage = 50,
    ParameterType = 51,
    ParameterUnit = 52,
    ParameterUnitType = 53,
    ParameterGroup = 54,
    Producer = 55,
    UserReward = 56,
    Slot = 57,
    PriceSlasher = 58,
    SlotOriginal = 59,
    SearchWhispererSegmentSuspend = 60,
    WebshareDocumentState = 61,
    WebshareDocumentItemState = 62,
    TranslationDeadline = 63,
    Warranty = 64,
    UserRewardSetting = 65,
    PackUnit = 66,
    Section = 67,
    WebSetting = 74,
    GeoArea = 75
}

export enum OpeningHoursType {
    StandardOpeningHours = 1,
    NightDispatch = 2
}

export enum Action {
    None = 0,
    Create = 1,
    Edit = 2,
    Delete = 3,
    Login = 4,
    Logout = 5,
    Confirm = 6,
    Other = 7
}

export enum ValueType {
    Simple = 0,
    Complex = 1,
    List = 2,
    ListItem = 3,
    LocalizationCollection = 4,
    LocalizationCollectionItem = 5
}

export enum Source {
    OldAdmin = 0,
    NewAdmin = 1,
    Aik = 2,
    Other = 3
}

export enum EnvironmentType {
    Local = 0,
    Dev = 1,
    Beta = 2,
    Production = 3
}

export enum Languages {
    Czech = 1,
    Slovakia = 2,
    English = 3,
    Deutsch = 5,
    Magyar = 13,
    Russian = 14
}

export enum Country {
    CzechRepublic = 0,
    Slovakia = 1,
    Europe = 2,
    Germany = 3,
    UnitedKingdom = 6,
    Hungary = 10,
    RussianFederation = 19
}

export enum MarginType {
    Imkt = 0,
    Spd5 = 1
}

export enum TaskType {
    Other = 1,
    CommodityTranslationCheck = 2,
    CommodityTranslationProof = 3,
    CategoryTranslation = 4,
    CommodityBasicTranslationCheck = 5,
    CommodityComparerBid = 6
}

export enum TaskState {
    New = 1,
    Active = 2,
    Waiting = 3,
    Testing = 4,
    Closed = 5
}

export enum CommodityTranslationState {
    Default = 0,
    ToRobotTranslate = 1,
    RobotTranslated = 2,
    XmlTranslationAssigned = 3,
    XmlTranslationApplied = 4
}

export enum CategoryTextFieldWithoutTranslation {
    UpperDescription = 1,
    BottomDescription = 2,
    Name = 3,
    SeoName = 4,
    Anything = 5
}

export enum GenerateDescriptionStyleType {
    None = 0,
    Bold = 1,
    Italic = 2,
    H1 = 4,
    H2 = 8,
    H3 = 16,
    H4 = 32,
    H5 = 64,
    Space = 128,
    Unit = 256,
    UpperCase = 512,
    LowerCase = 1024,
    FirstUpper = 2048,
    FirstLower = 4096,
    RewriteToDot = 8192,
    RewriteToComma = 16384,
    RemoveSpaceAfter = 32768,
    CommaSeperatedList = 65536,
    CommaSeperatedListWithAndAtEnd = 131072,
    RoundNumberToZeroDecimals = 262144,
    RoundNumberToOneDecimal = 524288,
    RoundNumberToTwoDecimals = 1048576,
    Hyperlink = 2097152,

    AllRounds = RoundNumberToZeroDecimals + RoundNumberToOneDecimal + RoundNumberToTwoDecimals,
    AllLists = CommaSeperatedList + CommaSeperatedListWithAndAtEnd,
    AllCases = UpperCase + LowerCase + FirstLower + FirstUpper,
    AllH = H1 + H2 + H3 + H4 + H5
}

export enum GenerateDescriptionItemType {
    None = 0,
    Text = 1,
    List = 2
}

export enum BulkType {
    Android = 1,
    Ios = 2,
    Windows = 3,
    ConsoleSegment = 4,
    RegistrationList = 5
}

export enum CampaignType {
    OBC = 0,
    VND = 1
}

export enum MobileApplicationType {
    Alza = 1,
    Media = 2,
    CodeCamp = 3,
    SecApp = 4,
    FirebaseGcm = 5,
    Firebase = 6
}

export enum CommonState {
    Disabled = 0,
    OnlyBeta = 1,
    Enabled = 2
}

export enum BranchServiceType {
    WiFi = 1,
    CardPayment = 2,
    WheelchairAccess = 3,
    Freeparking = 4,
    TechSupport = 5,
    BigStore = 6,
    Services = 7,
    Showroom = 8,
    NonStop = 9,
    NoQueue = 10,
    Discretion = 11,
    WeekendOpen = 12,
    AlzaDrive = 13,
    AppleShop = 14,
    ElectricChargingStation = 15,
    Alzacafe = 16,
    PickUpFromApplication = 17
}

export enum DeliveryPaymentStateEnum {
    Hidden = 0,
    Disabled = 1,
    Enabled = 2
}

export enum UrlRewriteRuleType {
    BeforeRewrite = 1,
    UserRewrite = 2,
    AfterRewrite = 3,
    MobilePage = 4
}

export enum UrlRewriteRuleAction {
    Pass = 1,
    Stop = 2,
    Redirect = 3,
    Redirect301 = 4
}

export enum EshopPlatform {
    Web = 0,
    Mobile = 1,
    Android = 2,
    IOS = 3,
    AndroidTablet = 5,
    IOSTablet = 6,
    WindowsPhone = 7,
    WebTablet = 8,
    OnlineReader = 9
}

export enum DeliveryType {
    InPersonCentral = 48,
    AlzaExpress = 49,
    Courier = 50,
    PPL = 51,
    DPD = 52,
    CzechPost = 53,
    PPLEvening = 54,
    CourierExpress = 55,
    SlovakPost = 57,
    Gebruder = 65,
    AlzaBox = 66,
    GebruderComfort = 67,
    AlzaDrive = 68,
    Email = 69,
    Geis = 70,
    GeisParcel = 71,
    StreetShop = 72,
    HungaryPostPackage = 73,
    GLSParcelShopHU = 74,
    PPLSprint = 76,
    EuropeStandard = 77,
    EuropeExpress = 78,
    SeegmullerComfort = 79,
    InPersonBranch = 80,
    Rhenus = 81,
    Virtual = 82,
    SeegmullerInstall = 83,
    SeegmullerStandard = 84,
    UPS = 85,
    SpecialService = 86,
    DPDSaturday = 88,
    SlovakPostPackage = 89,
    CzechPostPackage = 90,
    Helicar = 193,
    VirtualResorts = 344,
    InPerson2020 = 356,
    DHLDE = 381
}

export enum ArticleWorkflowState {
    Draft = 0,
    ForApproval = 1,
    Approved = 2
}

export enum ChangePasswordResult {
    Succeeded = 0,
    AuthFailed = 1,
    WeakPassword = 2,
    PasswordsNotEqual = 3,
    TokenNotValid = 4
}

export enum ParameterTypeRenderType {
    Checkbox = 0,
    Slider = 1,
    CheckboxIsPresent = 2,
    Cable = 3,
    Image = 4
}

export enum PreferenceType {
    BiggerBetter = 0,
    SmallerBetter = 1,
    NoPreference = 2
}

export enum ParameterTypeEnumsOrderBy {
    Name = 0,
    UseCount = 1
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Enums {
    export enum NameExtType {
        Inherit = 0,
        Custom = 1,
        Generate = 2,
        GenerateWithCustom = 3,
        Off = 4
    }

    export enum InstallationType {
        Inherit = 0,
        Custom = 1,
        Generate = 2
    }

    export enum CommodityAvailabilityType {
        InStock = 0,
        VGP = 1,
        EnRoute = 2,
        CanBuy = 3,
        Other = 4
    }

    export enum CommodityVariantType {
        None = 0,
        Root = 1,
        Variant = 2
    }

    export enum Column {
        None = 0,
        Name = 1,
        Legend = 2,
        SeoName = 4,
        NameExt = 8,
        SeoPrefix = 16,
        LegendReference = 32,
        LegendCloseOut = 64,
        Anotation = 128,
        UrlItem = 256,
        PackUnit = 512
    }

    export enum GenerateDescriptionState {
        None = 0,
        New = 1,
        InProgress = 2,
        InProgressChanged = 3,
        Failed = 4
    }

    export enum GenerateDescriptionGenerateColumn {
        None = 0,
        NameExt = 1,
        Anotation = 2,
        Parameter = 4,
        Legend = 8,
        Installation = 16
    }

    export enum GenerateDescriptionPriorityLevel {
        None = 0,
        Prefix = 1,
        Template = 2,
        Paragraph = 3,
        Phrase = 4
    }

    export enum GenerateDescriptionOperationType {
        None = 0,
        Defined = 1,
        NotDefined = 2,
        IsEqual = 3,
        IsNotEqual = 4,
        IsGreater = 5,
        IsGreaterOrEqual = 6,
        IsLesser = 7,
        IsLesserOrEqual = 8,
        Contains = 9,
        DoesNotContain = 10,
        In = 11,
        NotIn = 12
    }

    export enum ParameterTypeType {
        Text = 0,
        Number = 1,
        Decimal = 2,
        Enum = 3,
        MultiChoiceEnum = 4,
        Cable = 5,
        DateTime = 6
    }

    export enum BannerType {
        CategoryLeft = 0,
        CategoryRight = 1,
        Category = 2,
        HomePage = 3,
        Leaderboard = 4,
        Detail = 5,
        CommodityPack = 6,
        Order2 = 7,
        Catalog = 8
    }

    export enum InternalBannerType {
        ImageType = 0,
        TextType = 1
    }

    export enum TagObjectType {
        Undefined = 0,
        Category = 1,
        Article = 2,
        ArticlePreview = 3
    }

    export enum TranslationCheckType {
        Other = 0,
        Legend = 1,
        Basic = 2,
        ProofRead = 3,
        Processed = 4
    }

    export enum LegendStateItem {
        SeoPrefix = 0,
        ActiveForOR = 1,
        Generated = 2,
        GeneratedEmptyLegend = 3,
        Checked = 4,
        OffOrAnalysis = 5,
        ImportedCSV = 6,
        EmptyLegend = 7,
        CustomTop = 8,
        BasicChecked = 9
    }

    export enum NameextStateItem {
        SeoPrefix = 0,
        ActiveForOR = 1,
        Custom = 2,
        Generate = 3,
        CustomGenerate = 4,
        WrongNameExt = 5
    }

    export enum ParameterStateItem {
        SeoPrefix = 0,
        ActiveForOR = 1
    }

    export enum TranslationStateType {
        Legend = 0,
        Nameext = 1,
        Parameter = 2
    }

    export enum UserRewardType {
        MainImage = 1,
        GalleryImages = 2,
        Legend = 3,
        Review = 4,
        Parameters = 5,
        Mistake = 6,
        UserReview = 7,
        Bonus = 8,
        CopiedMainImage = 9,
        CopiedGalleryImages = 10,
        Other = 11,
        LegendRatingCheck = 12,
        CommodityTranslation = 13,
        CommodityTranslationMemory = 14,
        UpDescription = 15,
        SeoText = 16,
        Icon = 17,
        Fix = 18,
        ArticleTranslate = 19,
        Article = 20,
        AddParameters = 21,
        CheckProductDescription = 22,
        CommodityManual = 23,
        CommodityBasicTexts = 24,
        CommodityComparerBid = 25,
        CommodityTranslation2 = 26,
        CommodityBasicTexts2 = 27,
        CopyCommodityTranslation = 28,
        CommodityTranslationUpdate = 29,
        GeneratedParametersTemplate = 30,
        GeneratedNameextTemplate = 31,
        CommodityDescriptionVariant = 32,
        CommodityCheckVariant = 33,
        PremiumDescription = 34,
        PremiumDescriptionVariant = 35,
        DescriptionUpdate = 36,
        PremiumDescriptionCheck = 37,
        PremiumDescriptionCheckVariant = 38,
        CommoditytDiscussion = 39,
        CategoryBasicText = 40,
        CategoryCommodityInfo = 41,
        Brandpage = 42,
        BottomDescription = 43,
        CategoryImage = 44,
        Parametrization = 45,
        ParametersTranslations = 46,
        CategoryGeneralTranslation = 47,
        CategoryUpperDescriptionTranslation = 48,
        CategoryBottomDescriptionTranslation = 49,
        CategoryCommodityInformationTranslation = 50,
        TranslationsForCompany = 51,
        CommodityLegendComposite = 71,
        CommodityLegendCompositeProofReading = 72,
        CommodityLegendCompositeTranslation = 73,
        CommodityLegendCompositeImages = 74
    }

    export enum UserRewardObjectType {
        Commodity = 1,
        Category = 2,
        Prefix = 3,
        Article = 4,
        Other = 5
    }

    export enum ParameterSource {
        None = 0,
        Konzole = 1,
        Web = 2,
        Soap = 3,
        IceCat = 4,
        CustomAikImport = 5,
        MuveCz = 6,
        Vapol = 7,
        Microsoft = 8,
        TecDoc = 9,
        AlzaRentProduct = 10,
        AutomaticWebParameters = 11
    }

    export enum CommodityEditableParameters {
        Inherit = 0,
        On = 1,
        Off = 2
    }

    export enum TranslationApplication {
        Eshop = 0,
        Affil = 1,
        Admin = 2,
        Partner = 3
    }
}
export enum ElectronicContentState {
    Invisible = 0,
    Disabled = 10,
    ActivationPending = 20,
    FirstPlayReady = 30,
    Active = 40
}

export enum SurveyObjectTypeEnum {
    None = 0,
    Article = 1,
    Commodity = 2,
    CategoryUpperDescription = 3,
    CategoryBottomDescription = 4,
    CategoryInfoCommodityGroup = 5
}
