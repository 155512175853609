import { Component, OnDestroy } from '@angular/core';
import { MessageService } from 'app/common/services';
import { Subscription } from 'rxjs';
import { IParameterGroupCollectionItemDto } from '../dto/parameter-group-collection-item-dto';
import { ParameterTypeSelectDataService } from '../parameter-type-select-data.service';

@Component({
    selector: 'app-parameter-type-select-groups',
    templateUrl: './parameter-type-select-groups.component.html',
    styleUrls: ['./parameter-type-select-groups.component.scss']
})
export class ParameterTypeSelectGroupsComponent implements OnDestroy {
    public items: Array<IParameterGroupCollectionItemDto> = [];
    public categoryId: number;
    public groupId: number;

    private _subscribers: Array<Subscription> = [];

    constructor(private readonly dataService: ParameterTypeSelectDataService, private messageService: MessageService) {
        this._subscribers.push(
            this.messageService.getMessage('categoryId').subscribe((value) => {
                this.categoryId = value;
                this.clearItems();

                if (this.categoryId) {
                    this.dataService.getGroupsByCategory(this.categoryId).subscribe((res) => {
                        this.items = res;
                        this.messageService.sendMessage('groupsCount', this.items.length);
                    });
                }
            })
        );

        this._subscribers.push(
            this.messageService.getMessage('groupId').subscribe((value) => {
                this.groupId = value;
            })
        );
    }

    groupChanged(groupId: number) {
        this.messageService.sendMessage('groupId', groupId);
    }

    clearItems() {
        this.items = [];
        this.messageService.sendMessage('groupsCount', 0);
    }

    ngOnDestroy(): void {
        this._subscribers.forEach((subscriber) => {
            subscriber.unsubscribe();
        });
    }
}
