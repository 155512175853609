import { Component, Input } from '@angular/core';
import { PagerService } from '../../common/services/pager.service';

@Component({
    selector: 'app-grid-pager',
    styleUrls: ['./grid-pager.component.scss'],
    templateUrl: './grid-pager.component.html'
})
export class GridPagerComponent {
    @Input()
    defaultPageSize?: number;

    @Input()
    showPaging = true;

    @Input()
    showItemsCount = true;

    public get currentPage() {
        return this.pager ? this.pager.page : 1;
    }
    public get pageSize() {
        if (this.pager) {
            if (!this.pageSizeLimit.find((x) => x === this.pager.pageSize)) {
                this.pageSizeLimit.push(this.pager.pageSize);
                this.pageSizeLimit.sort((n1, n2) => n1 - n2);
            }
            return this.pager.pageSize;
        } else {
            return this.defaultPageSize ? this.defaultPageSize : 20;
        }
    }
    public set pageSize(value: number) {
        if (this.pager && this.pager.pageSize !== value) {
            this.pager.setPage(1, value);
        }
    }
    public totalPages = 0;
    public pages: Array<number> = [];
    public itemsCount = 0;
    public loading = true;
    public pageSizeLimit: Array<number> = [10, 20, 30, 50, 100, 200];

    constructor(private pager: PagerService<any, any>) {
        pager.itemsCount$.subscribe((itemsCount) => (this.itemsCount = itemsCount));
        pager.loading$.subscribe((res) => (this.loading = res));
    }

    public setPage(pageNumber: number) {
        if (this.pager) {
            this.pager.setPage(pageNumber, this.pageSize);
        }
    }
}
