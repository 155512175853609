/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum InstallNoteTarget {
    Dev = 0,
    Beta = 1,
    Release = 2,
    Gamma = 4,
    Delta = 5,
    Epsilon = 6,
    Kappa = 7,
    Lambda = 8,
    Sigma = 9,
    Omega = 10,
    Alfa = 11,
    Theta = 12,
    Iota = 13,
    Tau = 14,
    Aragorn = 15,
    Batman = 16,
    Bender = 17,
    Frodo = 18,
    Hulk = 19,
    Legolas = 20,
    Odin = 21,
    Pikachu = 22,
    Thor = 23,
    Tyrion = 24,
}
