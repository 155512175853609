import { AlzaCmsComponentsModule } from '@alza/cms-components';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatSliderModule } from '@angular/material/slider';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { ContextMenuModule } from '@perfectmemory/ngx-contextmenu';

import { BootstrapDropdownDirective } from 'app/directives/bootstrap-dropdown.directive';
import { DisableIfBusyDirective } from 'app/directives/disable-if-busy.directive';
import { HasPermissionDirective } from 'app/directives/has-permission.directive';
import { InitialFocusDirective } from 'app/directives/initial-focus.directive';
import { SortableColumnDirective } from 'app/directives/sortable-column.directive';
import { BypassSanitizationPipe } from 'app/pipes/bypass-sanitization.pipe';
import { FileSizePipe } from 'app/pipes/file-size.pipe';
import { FlagEnumPipe } from 'app/pipes/flag-enum.pipe';
import { HideDeletedPipe } from 'app/pipes/hide-deleted.pipe';
import { LanguagePipe } from 'app/pipes/language.pipe';
import { LocalizePipe } from 'app/pipes/localize.pipe';
import { MapToIterablePipe } from 'app/pipes/map-to-iterable.pipe';
import { SuffixPipe } from 'app/pipes/suffix.pipe';
import { TimePipe } from 'app/pipes/time.pipe';
import { UserDisplayNamePipe } from 'app/pipes/user-display-name.pipe';
import { ColorPickerModule } from 'ngx-color-picker';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { AlphabetSelectComponent } from './alphabet-select/alphabet-select.component';
import { ButtonComponent } from './button/button.component';
import { CategorySelectComponent } from './category-select/category-select';
import { CategorySelectModalItemsComponent } from './category-select/category-select-modal-items/category-select-modal-items.component';
import { CategorySelectModalComponent } from './category-select/category-select-modal/category-select-modal';
import { CategoryTreeViewComponent } from './category-tree-view/category-tree-view.component';
import { CheckedItemsComponent } from './checked-items/checked-items.component';
import { ClipboardHolderComponent } from './clipboard/clipboard-holder/clipboard-holder.component';
import { ConfigurationSelectComponent } from './configuration-select/configuration-select';
import { ConfigurationSelectModalItemsComponent } from './configuration-select/configuration-select-modal-items/configuration-select-modal-items.component';
import { ConfigurationSelectModalComponent } from './configuration-select/configuration-select-modal/configuration-select-modal';
import { ConfirmCampaignMplaModalComponent } from './confirm-campaign-mpla-modal/confirm-campaign-mpla-modal.component';
import { CountryLocalizationSwitchComponent } from './country-localization-switch/country-localization-switch.component';
import { SelectCountryModalComponent } from './country-localization-switch/select-country-modal/select-country-modal.component';
import { CountrySelectComponent } from './country-select/country-select.component';
import { DataGridFilterComponent } from './data-grid-filter/data-grid-filter.component';
import { EnumButtongroupComponent } from './enum-buttongroup/enum-buttongroup.component';
import { FooterInfoComponent } from './footer-info/footer-info.component';
import { CategorySelectOneModalComponent } from './forms/category-select-one/category-select-one-modal/category-select-one-modal.component';
import { CategorySelectOneComponent } from './forms/category-select-one/category-select-one.component';
import { ColorPickerComponent } from './forms/color-picker/color-picker.component';
import { FileInputDropzoneDirective } from './forms/file-input/file-input-dropzone.directive';
import { FileInputFileChooserComponent } from './forms/file-input/file-input-file-chooser/file-input-file-chooser.component';
import { FileInputFileExistsComponent } from './forms/file-input/file-input-file-exists/file-input-file-exists.component';
import { FileInputUrlChooserComponent } from './forms/file-input/file-input-url-chooser/file-input-url-chooser.component';
import { FileInputComponent } from './forms/file-input/file-input.component';
import { FroalaSentencesComponent } from './forms/froala-sentences/froala-sentences.component';
import { NewSentenceModalComponent } from './forms/froala-sentences/new-sentence-modal/new-sentence-modal.component';
import { SentenceModalComponent } from './forms/froala-sentences/sentence-modal/sentence-modal.component';
import { FroalaEditorDirective } from './forms/froala/editor.directive';
import { FroalaComponent } from './forms/froala/froala.component';
import { InputFileComponent } from './forms/input-file/input-file.component';
import { LocalizedComponent } from './forms/localized/localized.component';
import { MonacoEditorComponent } from './forms/monaco-editor/monaco-editor.component';
import { SliderComponent } from './forms/slider/slider.component';
import { TreeSelectComponent } from './forms/tree-select/tree-select.component';
import { GridPagerComponent } from './grid-pager/grid-pager.component';
import { GridComponent } from './grid/grid.component';
import { ImageSelectorModalComponent } from './image-selector-modal/image-selector-modal.component';
import { ImageSelectorComponent } from './image-selector/image-selector.component';
import { LinkModalComponent } from './link/link-modal/link-modal.component';
import { LinkComponent } from './link/link.component';
import { LoadingPanelComponent } from './loading-panel/loading-panel.component';
import { LocalizationSwitchComponent } from './localization-switch/localization-switch.component';
import { NavbarComponent } from './navbar/navbar.component';
import { ObjectLockedComponent } from './object-locked/object-locked.component';
import { OnOffSwitchComponent } from './on-off-switch/on-off-switch.component';
import { OpenCloseContentComponent } from './open-close-content/open-close-content.component';
import { OpenLayersComponent } from './open-layers/open-layers.component';
import { PageBoxComponent } from './page-box/page-box.component';
import { PageContentComponent } from './page-content/page-content.component';
import { CustomButtonDirective } from './page-footer/custom-button.directive';
import { DeleteButtonDirective } from './page-footer/delete-button.directive';
import { LaddaLoadingDirective } from './page-footer/ladda-loading.directive';
import { PageFooterComponent } from './page-footer/page-footer.component';
import { SaveAndCloseButtonDirective } from './page-footer/save-and-close.directive';
import { SaveButtonDirective } from './page-footer/save-button.directive';
import { StornoButtonDirective } from './page-footer/storno-button.directive';
import { BreadcrumbDetailDirective } from './page-header/breadcrumb-detail.directive';
import { BreadcrumbDirective } from './page-header/breadcrumb.directive';
import { PageHeaderComponent } from './page-header/page-header.component';
import { PaginationComponent } from './pagination/pagination.component';
import { ParameterPreviewComponent } from './parameter-preview/parameter-preview.component';
import { ParameterSelectModalComponent } from './parameter-select/parameter-select-modal.component';
import { ParameterSelectComponent } from './parameter-select/parameter-select.component';
import { ParameterTypeSelectDataService } from './parameter-type-select/parameter-type-select-data.service';
import { ParameterTypeSelectGroupsComponent } from './parameter-type-select/parameter-type-select-groups/parameter-type-select-groups.component';
import { ParameterTypeSelectSearchComponent } from './parameter-type-select/parameter-type-select-search/parameter-type-select-search.component';
import { ParameterTypeSelectTypesComponent } from './parameter-type-select/parameter-type-select-types/parameter-type-select-types.component';
import { ParameterTypeSelectComponent } from './parameter-type-select/parameter-type-select.component';
import { ParameterValuesComponent } from './parameter-values/parameter-values.component';
import { ReleaseNotesModalComponent } from './release-notes-modal/release-notes-modal.component';
import { RemovableContentComponent } from './removable-content/removable-content.component';
import { SearchModalComponent } from './search-modal/search-modal.component';
import { SegmentPrefixSelectComponent } from './segment-prefix-select/segment-prefix-select.component';
import { SegmentSelectComponent } from './segment-select/segment-select.component';
import { ShowMoreButtonComponent } from './show-more-button/show-more-button.component';
import { TabDirective } from './tabs/tab.directive';
import { TabsComponent } from './tabs/tabs.component';
import { TreeItemComponent } from './tree/tree-item/tree-item.component';
import { TreeComponent } from './tree/tree.component';
import { TrendComponent } from './trend/trend.component';
import { WikiModalComponent } from './wiki-modal/wiki-modal.component';

const exported = {
    pipes: [
        LocalizePipe,
        HideDeletedPipe,
        BypassSanitizationPipe,
        TimePipe,
        HideDeletedPipe,
        MapToIterablePipe,
        FlagEnumPipe,
        UserDisplayNamePipe,
        LanguagePipe,
        SuffixPipe,
        FileSizePipe
    ],
    directives: [
        BreadcrumbDirective,
        BreadcrumbDetailDirective,
        TabDirective,
        SortableColumnDirective,
        SaveButtonDirective,
        StornoButtonDirective,
        DeleteButtonDirective,
        SaveAndCloseButtonDirective,
        CustomButtonDirective,
        LaddaLoadingDirective,
        SearchModalComponent,
        FileInputDropzoneDirective,
        HasPermissionDirective,
        InitialFocusDirective,
        DisableIfBusyDirective,
        BootstrapDropdownDirective,
        FroalaEditorDirective
    ],
    components: [
        CategorySelectOneComponent,
        GridPagerComponent,
        FooterInfoComponent,
        CountrySelectComponent,
        PageHeaderComponent,
        TabsComponent,
        NavbarComponent,
        EnumButtongroupComponent,
        CategorySelectComponent,
        CategoryTreeViewComponent,
        ConfigurationSelectComponent,
        ParameterSelectComponent,
        ParameterPreviewComponent,
        ParameterValuesComponent,
        ParameterSelectModalComponent,
        PageFooterComponent,
        ClipboardHolderComponent,
        ImageSelectorComponent,
        ImageSelectorModalComponent,
        AlphabetSelectComponent,
        LoadingPanelComponent,
        GridComponent,
        OnOffSwitchComponent,
        OpenCloseContentComponent,
        ShowMoreButtonComponent,
        InputFileComponent,
        TreeSelectComponent,
        LocalizedComponent,
        LocalizationSwitchComponent,
        CountryLocalizationSwitchComponent,
        FroalaComponent,
        FroalaSentencesComponent,
        ColorPickerComponent,
        ObjectLockedComponent,
        CheckedItemsComponent,
        SegmentSelectComponent,
        PaginationComponent,
        ButtonComponent,
        ParameterTypeSelectComponent,
        ParameterTypeSelectTypesComponent,
        ParameterTypeSelectGroupsComponent,
        MonacoEditorComponent,
        SearchModalComponent,
        TrendComponent,
        SliderComponent,
        FileInputComponent,
        RemovableContentComponent,
        SegmentPrefixSelectComponent,
        SelectCountryModalComponent,
        OpenLayersComponent,
        ConfirmCampaignMplaModalComponent,
        PageContentComponent,
        PageBoxComponent,
        DataGridFilterComponent,
        ConfirmCampaignMplaModalComponent,
        PageBoxComponent,
        PageContentComponent,
        SentenceModalComponent,
        NewSentenceModalComponent,
        LinkComponent,
        LinkModalComponent,
        TreeComponent,
        TreeItemComponent
    ]
};

@NgModule({
    imports: [
        FormsModule.withConfig({
            callSetDisabledState: 'whenDisabledForLegacyCode' // workaround from https://github.com/angular/angular/issues/48350
        }),
        CommonModule,
        DragDropModule,
        ReactiveFormsModule.withConfig({
            callSetDisabledState: 'whenDisabledForLegacyCode' // workaround from https://github.com/angular/angular/issues/48350
        }),
        TranslateModule,
        RouterModule.forChild([]),
        ColorPickerModule,
        NgSelectModule,
        ColorPickerModule,
        MonacoEditorModule,
        MatDialogModule,
        MatMenuModule,
        ScrollingModule,
        MatSliderModule,
        ContextMenuModule,
        AlzaCmsComponentsModule
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        NgSelectModule,
        ColorPickerModule,
        MatDialogModule,
        MatMenuModule,
        ScrollingModule,
        MatSliderModule,
        AlzaCmsComponentsModule,
        ...exported.pipes,
        ...exported.directives,
        ...exported.components
    ],
    declarations: [
        CategorySelectModalItemsComponent,
        CategorySelectModalComponent,
        CategorySelectOneModalComponent,
        ConfigurationSelectModalItemsComponent,
        ConfigurationSelectModalComponent,
        WikiModalComponent,
        ParameterTypeSelectTypesComponent,
        ParameterTypeSelectGroupsComponent,
        ParameterTypeSelectSearchComponent,
        ReleaseNotesModalComponent,
        ConfigurationSelectComponent,
        FileInputFileChooserComponent,
        FileInputUrlChooserComponent,
        FileInputFileExistsComponent,
        ...exported.pipes,
        ...exported.directives,
        ...exported.components,
        LinkComponent,
        LinkModalComponent
    ],
    providers: [ParameterTypeSelectDataService, { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } }]
})
export class ComponentsModule {}
