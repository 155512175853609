import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { environment } from 'environments/environment';

interface IValidationErrors {
    [key: string]: { value: any; errors: object };
}

export class InvalidFormError implements Error {
    constructor(form: AbstractControl) {
        if (!environment.production) {
            this.validationErrors = this.createValidationErrors(form, null, {});
        }
    }

    name = 'Invalid form';
    message = 'Invalid form';
    stack?: string;
    validationErrors: IValidationErrors;

    private createValidationErrors(control: AbstractControl, path: string, errorObj: IValidationErrors) {
        if (control instanceof UntypedFormControl) {
            if (control.invalid) {
                errorObj[path] = {
                    value: control.valid,
                    errors: control.errors
                };
            }
        } else if (control instanceof UntypedFormArray) {
            control.controls.forEach((c, ix) => {
                this.createValidationErrors(c, path ? `${path}.${ix}` : String(ix), errorObj);
            });
        } else if (control instanceof UntypedFormGroup) {
            Object.keys(control.controls).forEach((key) => {
                this.createValidationErrors(control.controls[key], path ? `${path}.${key}` : String(key), errorObj);
            });
        }
        return errorObj;
    }
}
