import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { SessionService } from 'app/common/services/session.service';
import { ICountryLocalizationDto } from 'app/models';
import camelCase from 'camelcase';
import { ICountryLocalization } from '../country-localization-switch/country-localization';
import { FormControl } from './typed-controls';

@Injectable({ providedIn: 'root' })
export class FormHelper {
    private _localizationToIndex: { [key: string]: number } = {};

    constructor(private readonly session: SessionService) {
        this.buildTranslationToIndex();
    }

    private buildTranslationToIndex() {
        if (!this.session.languages) {
            return;
        }
        let index = 0;
        this.session.languages
            .filter((x) => !x.disabled)
            .forEach((language) => {
                this._localizationToIndex[String(language.id)] = index++;
                this.session.countries
                    .filter((x) => x.enabled)
                    .forEach((country) => {
                        this._localizationToIndex[`${language.id}_${country.id}`] = index++;
                    });
            });
    }

    /**
     * Creates formgroup for all languages
     * @deprecated new api uses localizationGroup, which is backwards compatible
     * @param validator Validators used for all languages
     */
    public localizationFormGroup(
        validator?: ValidatorFn | Array<ValidatorFn> | null,
        asyncValidator?: AsyncValidatorFn | Array<AsyncValidatorFn> | null,
        defaultValue: any = ''
    ) {
        const controls: { [id: number]: AbstractControl } = {};
        this.session.user.availableLanguages.forEach((l) => {
            controls[l.id] = new UntypedFormControl(defaultValue, validator, asyncValidator);
        });
        const fg = new UntypedFormGroup(controls);

        return fg;
    }

    /**
     * Creates formgroup for all languages
     * @deprecated we should now use localizationArray, which acts differently
     * @param validator Validators used for all languages
     */
    public localizationGroup(factory: (id: number) => AbstractControl = () => new UntypedFormControl()): UntypedFormGroup {
        const controls: { [id: number]: AbstractControl } = {};
        this.session.user.availableLanguages.forEach((l) => {
            controls[l.id] = factory(l.id);
        });
        return new UntypedFormGroup(controls);
    }

    /**
     * Creates FormArray for all languages and countries
     * @param factory FormGroup containing localizations
     */
    public localizationArray(factory: (languageId: number, countryId?: number) => UntypedFormGroup): UntypedFormArray {
        const newLocalizationGroup = (languageId: number, countryId: number = null) => {
            const formGroup = factory(languageId, countryId);
            formGroup.addControl('languageId', new UntypedFormControl(languageId));
            formGroup.addControl('countryId', new UntypedFormControl(countryId));
            return formGroup;
        };

        const controls: UntypedFormArray = new UntypedFormArray([]);

        this.session.languages
            .filter((x) => !x.disabled)
            .forEach((language) => {
                controls.push(newLocalizationGroup(language.id));

                this.session.countries
                    .filter((x) => x.enabled)
                    .forEach((country) => {
                        controls.push(newLocalizationGroup(language.id, country.id));
                    });
            });

        return controls;
    }

    /**
     * Updates value of this control (and all its subcontrols if it's a FormGroup or an FormArray)
     * @param control The form group
     */
    public updateAllValuesAndValidities(control: AbstractControl) {
        if (control instanceof UntypedFormControl) {
            control.updateValueAndValidity();
        }
        if (control instanceof UntypedFormArray || control instanceof UntypedFormGroup) {
            for (const key in control.controls) {
                if (control.controls.hasOwnProperty(key)) {
                    this.updateAllValuesAndValidities(control.controls[key]);
                }
            }
        }
    }

    /**
     * recursively disables all FormControls in form group-array (can be used on abstractcontrol)
     * beware that disabling triggers valueChanges, if you don't disable emitEvent with opts
     * @param control The form group-control-array to be disabled
     * @param opts onlySelf and emitEvent options controls
     */
    public disableAll(
        control: AbstractControl,
        opts?: {
            onlySelf?: boolean;
            emitEvent?: boolean;
        }
    ) {
        if (control instanceof UntypedFormArray || control instanceof UntypedFormGroup) {
            for (const key in control.controls) {
                if (control.controls.hasOwnProperty(key)) {
                    this.disableAll(control.controls[key], opts);
                }
            }
        }
        if (control.enabled) {
            control.disable(opts);
        }
    }

    /**
     * recursively enables all FormControls in form group-array (can be used on abstractcontrol)
     * beware that enabling triggers valueChanges, if you don't disable emitEvent with opts
     * @param control The form group-control-array to be enabled
     * @param opts onlySelf and emitEvent options controls
     */
    public enableAll(
        control: AbstractControl,
        opts?: {
            onlySelf?: boolean;
            emitEvent?: boolean;
        }
    ) {
        if (control instanceof UntypedFormArray || control instanceof UntypedFormGroup) {
            for (const key in control.controls) {
                if (control.controls.hasOwnProperty(key)) {
                    this.enableAll(control.controls[key], opts);
                }
            }
        }
        if (control.disabled) {
            control.enable(opts);
        }
    }

    public addError(control: AbstractControl, errorObject: ValidationErrors) {
        if (!errorObject || (Object.entries(errorObject).length === 0 && errorObject.constructor === Object)) {
            return;
        }
        if (control.errors == null) {
            control.setErrors(errorObject);
            return;
        }
        // if we find any error that isn't already on control, we add it and stop working.
        // we do that so that we don't retrigger form on same validation errors endlessly
        for (const key of Object.keys(errorObject)) {
            if (control.errors[key] === undefined || (!(errorObject[key] instanceof Object) && control.errors[key] !== errorObject[key])) {
                control.setErrors({ ...control.errors, ...errorObject });
                return;
            }
        }
    }

    public removeError(control: AbstractControl, errorCode: string) {
        if (control.errors !== null) {
            if (control.errors[errorCode] !== undefined) {
                delete control.errors[errorCode];
            }
            if (Object.entries(control.errors).length === 0 && control.errors.constructor === Object) {
                control.setErrors(null);
            }
        }
    }

    /** Tries to match errors from httpErrorrEsponse to the given form.
     *
     *  If an error code is found in the form structure, it's placed directly on a given form input.
     *
     *  By default, this also removes given error code from errorresponse object (you won't see it in error modal).
     *  This can be changed with `deleteMappedErrors` param.
     * */
    public mapErrorResponseToForm(form: AbstractControl, error: HttpErrorResponse, deleteMappedErrors = true) {
        if (!error.error) {
            return;
        }
        const keys = Object.keys(error.error);
        keys.forEach((key) => {
            let clientKey = camelCase(key);
            clientKey = clientKey.replace('[', '.');
            clientKey = clientKey.replace(']', '');
            clientKey = clientKey.replace(/localization\.(\d+)\.value/, 'localization.$1');
            const input = form.get(clientKey);
            if (input) {
                this.addError(input, { serverError: error.error[key] });
                input.markAllAsTouched();

                if (deleteMappedErrors) {
                    delete error.error[key];
                }
            }
        });
    }

    /**
     * Returns localization FormGroup from localization FormArray for given language and country
     * @param localizationArray FormArray containing all localizations
     * @param languageId Language identifier
     * @param countryId Country identifier
     */
    public getLocalizationGroup(localizationArray: UntypedFormArray, localization: ICountryLocalizationDto): UntypedFormGroup {
        return localizationArray?.at(this.localizationToIndex(localization)) as UntypedFormGroup;
    }

    /**
     * Patches the value of the control with given value. Method properly patches value of localization array.
     * @param control Destination control
     * @param value Source value
     * @param skipLocalization When true, localization will not be patched.
     */
    public patchValue(
        control: UntypedFormGroup | UntypedFormArray,
        value: { [key: string]: any },
        { onlySelf, emitEvent }: { onlySelf?: boolean; emitEvent?: boolean } = {},
        skipLocalization = false
    ): void {
        Object.keys(value).forEach((name) => {
            if (control.controls[name]) {
                if (name === 'localization' && control.controls[name] instanceof UntypedFormArray) {
                    if (!skipLocalization) {
                        this.patchCoutryLocalization(control.controls[name], value[name]);
                    }
                } else {
                    if (control.controls[name] instanceof UntypedFormArray || control.controls[name] instanceof UntypedFormGroup) {
                        this.patchValue(control.controls[name], value[name], { onlySelf: true, emitEvent });
                    }
                    if (control.controls[name] instanceof UntypedFormControl) {
                        control.controls[name].patchValue(value[name], { onlySelf: true, emitEvent });
                    }
                }
            }
        });
        control.updateValueAndValidity({ onlySelf, emitEvent });
    }

    /**
     * Patches the value of the localization array with given value.
     * @param localization Localization array
     * @param value Source value
     */
    public patchCoutryLocalization(localization: UntypedFormArray, value: { [key: string]: any }) {
        if (!localization || !value) {
            return;
        }
        Object.keys(value).forEach((name) => {
            if (!value[name].languageId) {
                return;
            }

            const localizationGroup = this.getLocalizationGroup(localization, value[name]);
            if (localizationGroup) {
                localizationGroup.patchValue(value[name]);
            }
        });
    }

    /**
     * changes the value AND marks control as dirty, if the value is different
     */
    public patchDirtyValue(control: AbstractControl, value: any) {
        const orig = control.value;
        if (orig !== value) {
            control.patchValue(value);
            control.markAsDirty();
        }
    }

    /**
     * Returns control's value. Includes values only from dirty controls.
     * When the control (or any child control) is dirty but $state is 'unchanged',
     * then $state is chanded to 'modified'.
     * @param inputControl Abstract control from which you want to get data
     */
    public getRawDirtyValue(inputControl: AbstractControl): any {
        if (inputControl instanceof UntypedFormControl) {
            return inputControl.value;
        }

        if (inputControl instanceof UntypedFormArray) {
            return inputControl.controls
                .filter((control) => {
                    if (control instanceof UntypedFormGroup) {
                        if ('$state' in control.value && (control.value.$state === 'added' || control.value.$state === 'deleted')) {
                            return true;
                        }
                    }
                    return control.dirty;
                })
                .map((control: AbstractControl) => {
                    return control instanceof UntypedFormControl ? control.value : this.getRawDirtyValue(control);
                });
        }

        if (inputControl instanceof UntypedFormGroup) {
            const values: Record<string, any> = {};
            const state = inputControl.get('$state');

            if (state?.value === 'unchanged' && inputControl.dirty) {
                state.setValue('modified');
            }

            if (state?.value === 'unchanged') {
                return;
            }

            Object.keys(inputControl.controls).forEach((key) => {
                values[key] =
                    inputControl.controls[key] instanceof UntypedFormControl
                        ? inputControl.controls[key].value
                        : this.getRawDirtyValue(inputControl.controls[key]);
            });

            return values;
        }
    }

    /**
     * Returns group index from localization array for given language and country.
     *
     * Example: form.get(['localization', formHelper.index(languageId, countryId), 'nameLocalization']);
     *
     * @param languageId Language identifier
     * @param countryId Country identifier
     */
    public localizationToIndex(localization: ICountryLocalizationDto) {
        return this._localizationToIndex[this.localizationToKey(localization)];
    }

    /**
     * transforms ICountryLocalization to string key
     * @returns '1' for empty country or '1_2' for some country */
    protected localizationToKey(localization: ICountryLocalization) {
        return `${localization.languageId}${localization.countryId != null ? `_${localization.countryId}` : ''}`;
    }

    /**
     * Returns property names from localization group without languageId and countryId. LanguageId and countryId
     * are excluded because often you want to work with localization properties (setting validators, clearing values,...)
     * but you don't want to do that for languageId and countryId.
     * @param localizationGroup FormGroup containing localization properties
     */
    public localizedProperties(localizationGroup: UntypedFormGroup): Array<string> {
        return Object.keys(localizationGroup.controls).filter((name) => name !== 'languageId' && name !== 'countryId');
    }

    /**
     * Moves an item in a FormArray to another position.
     * @param formArray FormArray instance in which to move the item.
     * @param fromIndex Starting index of the item.
     * @param toIndex Index to which he item should be moved.
     */
    public moveItemInFormArray(formArray: UntypedFormArray, fromIndex: number, toIndex: number): void {
        const dir = toIndex > fromIndex ? 1 : -1;

        const from = fromIndex;
        const to = toIndex;

        const temp = formArray.at(from);
        for (let i = from; i * dir < to * dir; i = i + dir) {
            const current = formArray.at(i + dir);
            formArray.setControl(i, current);
        }
        formArray.setControl(to, temp);
    }

    /**
     * Returns if the control provided is required.
     * @param control Abstract control
     */
    public isControlRequired(control: FormControl) {
        if (control && control.validator) {
            const validator = control.validator({} as AbstractControl);
            if (validator && validator.required) {
                return true;
            }
        }
        return false;
    }
}
