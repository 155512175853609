<div class="form-group" [class.mb-0]="!labelText" [ngClass]="formGroupClass">
    <cms-label
        *ngIf="labelText"
        [required]="labelRequired"
        [for]="id"
        [text]="labelText"
        [tooltip]="labelTooltip"
        [tooltipDirection]="labelTooltipDirection"
        [tooltipIcon]="labelTooltipIcon"
        [class]="labelClass"
    ></cms-label>

    <ng-template [ngTemplateOutlet]="listTemplate" [ngTemplateOutletContext]="{ $implicit: items }"></ng-template>

    <ng-template #listTemplate let-list>
        <ul *ngIf="list">
            <ng-template ngFor [ngForOf]="list" let-item>
                <ng-template [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{ $implicit: item }"> </ng-template>
            </ng-template>
        </ul>
    </ng-template>

    <ng-template let-item #itemTemplate>
        <li *ngIf="item" [id]="'item' + item.value" [attr.data-tid]="'item' + item.value">
            <div class="item-expander" (click)="toggleExpand(item)">
                <i class="fas" [class.fa-minus]="item.isExpanded" [class.fa-plus]="hasChildren(item) && !item.isExpanded"></i>
            </div>
            <div class="item-content">
                <cms-checkbox
                    class="compact"
                    [id]="'' + item.value"
                    [labelText]="item.text"
                    [ngModel]="isChecked(item)"
                    (ngModelChange)="check(item)"
                ></cms-checkbox>
            </div>
            <ng-template *ngIf="item.isExpanded" [ngTemplateOutlet]="listTemplate" [ngTemplateOutletContext]="{ $implicit: item.children }"></ng-template>
        </li>
    </ng-template>
    <cms-field-errors [submitted]="submitted" [control]="control"></cms-field-errors>
</div>
