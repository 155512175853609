import { Directive, Input } from '@angular/core';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'breadcrumb' })
export class BreadcrumbDirective {
    @Input() text?: string;
    @Input() link?: Array<string> | string;

    /**
     * @deprecated Do not use this input since last breadcrumb is automatically active
     */
    @Input() active?: boolean;
}
