import { Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[appBootstrapDropdown]'
})
export class BootstrapDropdownDirective {
    constructor(private element: ElementRef<HTMLButtonElement>) {
        // eslint-disable-next-line @typescript-eslint/unbound-method
        element.nativeElement.addEventListener('click', this.dropButtonClicked);
    }

    dropButtonClicked(this: HTMLButtonElement) {
        const dropdownMenuElementName = this.getAttribute('id');
        const foundElements = this.parentElement.querySelectorAll('[aria-labelledby="' + dropdownMenuElementName + '"]');
        const footerElement = document.getElementsByClassName('footer-floating')[0];

        if (foundElements.length === 1) {
            const dropdownEl = foundElements.item(0) as HTMLUListElement;
            dropdownEl.style.visibility = 'hidden';
            dropdownEl.style.display = 'block';
            const rect1 = dropdownEl.getBoundingClientRect();
            const rect2 = footerElement.getBoundingClientRect();

            const overlap = !(rect1.right < rect2.left || rect1.left > rect2.right || rect1.bottom < rect2.top || rect1.top > rect2.bottom);
            dropdownEl.setAttribute('style', '');

            if (overlap) {
                this.parentElement.classList.remove('dropdown');
                this.parentElement.classList.add('dropup');
            }
        }
    }
}
