import { ClipboardService } from '@alza/cms-components';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
    selector: 'app-clipboard-holder',
    styleUrls: ['./clipboard-holder.component.scss'],
    templateUrl: './clipboard-holder.component.html'
})
export class ClipboardHolderComponent implements OnInit {
    value: string;
    offsetPosition = 0;
    @ViewChild('textHolder') holder: ElementRef<HTMLDivElement>;

    constructor(private clipboard: ClipboardService) {}

    ngOnInit() {
        this.clipboard.clipboardItems.subscribe((res) => {
            this.value = res.toString();
            setTimeout(() => {
                this.changeValue(this.holder.nativeElement.offsetWidth);
                setTimeout(() => {
                    this.changeValue(0);
                }, 1000);
            });
        });
    }

    public changeValue(offsetPosition: number) {
        this.offsetPosition = offsetPosition;
    }
}
