<div class="wrapper-content animated fadeInRight" style="padding-bottom: 0">
    <div class="ibox">
        <div class="ibox-content">
            <app-parameter-type-select-search></app-parameter-type-select-search>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-12 col-lg-4 float-left">
            <div class="ibox">
                <div class="ibox-content">
                    <app-category-tree-view [toogleOnSelect]="true" [categoryId]="categoryId" (categoryIdChange)="select($event)"></app-category-tree-view>
                </div>
            </div>
        </div>
        <div class="col-xs-12 col-lg-8 float-right" [hidden]="!categoryId">
            <div class="ibox">
                <div class="ibox-content">
                    <app-parameter-type-select-groups></app-parameter-type-select-groups>
                </div>
            </div>
        </div>
        <div class="col-xs-12 col-lg-8 float-right" [hidden]="!groupId">
            <div class="ibox">
                <div class="ibox-content">
                    <app-parameter-type-select-types></app-parameter-type-select-types>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #treeTemplate let-item>
    <div class="item-default">
        <span class="item-clickable mr-4">{{ item.name }}</span>
        <span *ngIf="item.isLink" class="text-muted">({{ item.originCategoryName }})</span>
        <div class="item-icons">
            <i class="far fa-eye-slash fa-enhanced fa-fw" *ngIf="item.isInvisible" [placement]="'left'" [cmsTooltip]="'Category_Invisible' | translate"></i>
            <i class="fas fa-share fa-enhanced fa-fw" *ngIf="item.isLink" [placement]="'left'" [cmsTooltip]="'Category_IsLink' | translate"></i>
            <i
                class="fas fa-exchange-alt fa-enhanced fa-fw"
                *ngIf="item.isSupplementary"
                [placement]="'left'"
                [cmsTooltip]="'Category_IsSupplementary' | translate"
            ></i>
        </div>
    </div>
</ng-template>
