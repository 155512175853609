import { HttpClient } from '@angular/common/http';
import { IParcelShopDto, IParcelShopTypeDto } from 'app/models/parcel-shop';
import { BaseApi } from './base-api';

export class ParcelShopApi extends BaseApi {
    public constructor(http: HttpClient) {
        super(http);
    }

    public getParcelShops(typeId?: number) {
        return this.load<Array<IParcelShopDto>>(`api/common/parcelshops/${typeId}`, `parcelshops-${typeId}`);
    }

    public getParcelShopTypes() {
        return this.load<Array<IParcelShopTypeDto>>('api/common/parcelshop-types', 'parcelshop-types');
    }
}
