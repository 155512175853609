/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum ClientApplication {
    Eshop = 0,
    Media = 1,
    Showroom = 2,
    WebApi = 3,
    AdminApi = 4,
    LogApi = 5,
    JeanWeb = 6,
    SignalR = 7,
    DatabaseSaver = 8,
    BrokerManager = 9,
    IdentityServer = 10,
    AlzaExpress = 11,
    AlzaRma = 12,
    AlzaReader = 13,
    AlzaWarehouse = 14,
}
