import { Directive, EventEmitter, Input, Output } from '@angular/core';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'tab' })
export class TabDirective {
    private _formInvalid = false;
    private _formFilled = false;
    @Input() value: any;
    @Input() title?: string;
    @Input() tooltip?: string;
    @Input() entityState: 'unchanged' | 'modified' | 'added' | 'deleted' | null = null;
    @Input() disabled?: boolean;
    @Input() sortDisabled?: boolean;
    @Input() set formInvalid(value: boolean) {
        this._formInvalid = value;
    }
    get formInvalid() {
        return this._formInvalid;
    }
    @Input() set formFilled(value: boolean) {
        this._formFilled = value;
    }
    get formFilled() {
        return this._formFilled;
    }
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() click = new EventEmitter<MouseEvent>();
}
