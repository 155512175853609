import { Circle, Fill, Stroke, Style } from 'ol/style';

export class OlStyles {
    private static _polygonLine = [0, 0, 255, 1];
    private static _polygonFill = [0, 0, 255, 0.1];
    private static _pointLine = [255, 255, 255, 1];
    private static _pointFill = [0, 0, 255, 1];
    private static _width = 3;

    static getStyles() {
        const styles: Array<Style> = [
            new Style({
                stroke: new Stroke({
                    color: this._polygonLine,
                    width: this._width / 2
                }),
                fill: new Fill({
                    color: this._polygonFill
                })
            }),
            new Style({
                image: new Circle({
                    radius: this._width * 2,
                    fill: new Fill({
                        color: this._pointFill
                    }),
                    stroke: new Stroke({
                        color: this._pointLine,
                        width: this._width / 2
                    })
                }),
                zIndex: Infinity
            })
        ];
        return styles;
    }
}
