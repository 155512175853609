import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { DeleteButtonEvent } from './button-events';
import { IButton } from './page-footer.component';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'delete-button' })
export class DeleteButtonDirective implements IButton {
    @Input() disabled: boolean;
    @Input() requireConfirm = true;
    @Input() confirmTitleMsg: string;
    @Input() confirmBodyMsg: string;

    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() click = new EventEmitter<DeleteButtonEvent>();

    public loading = false;
}
