/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum InfoMessageType {
    Primary = 0,
    Secondary = 1,
    Success = 2,
    Danger = 3,
    Warning = 4,
    Info = 5,
    Dark = 6,
}
