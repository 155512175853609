/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum ApiMethodType {
    GetHomePage = 0,
    GetProducts = 10,
    GetDetail = 20,
    GetOrder = 30,
    GetActiveOrders = 40,
    GetOrderDetail = 50,
    GetRecommendations = 60,
    GetMyAudioBooks = 70,
    GetMyLibrary = 80,
    Search = 90,
    Favourites = 100,
    GetLocalTitlePage = 110,
    GetMyFreeLibrary = 120,
    GetMyMusic = 130,
    GetTitle = 140,
    GetMyVideoCollection = 150,
    GetMyMagazines = 160,
    GetMobileWebCommodityLineControl = 200,
    GetMobileWebBoxControl = 210,
    GetMobileWebCatalogControl = 220,
    GetAlternatives = 230,
}
