import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { SaveButtonEvent } from './button-events';
import { IButton } from './page-footer.component';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'save-button' })
export class SaveButtonDirective implements IButton {
    @Input() type: 'button' | 'submit' = 'submit';
    @Input() disabled = false;
    @Input() text: string;
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() click = new EventEmitter<SaveButtonEvent>();

    public loading = false;
}
