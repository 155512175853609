import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IConfigurationInfo, IConfigurationSelect } from '../configuration-info';

@Component({
    selector: 'app-configuration-select-modal-items',
    styleUrls: ['./configuration-select-modal-items.component.scss'],
    templateUrl: './configuration-select-modal-items.component.html'
})
export class ConfigurationSelectModalItemsComponent {
    @Input()
    level = 1;
    @Input()
    items: any;
    @Output()
    public checkboxChange = new EventEmitter<any>();

    public toggleExpand(item: IConfigurationSelect) {
        item.isExpanded = item.isExpanded ? false : true;
    }

    public toggleCheckbox(e: Event, item: any) {
        if (item.children) {
            if (e && item.isRoot) {
                item.children.forEach((x) => (x.isSelected = true));
            } else {
                item.children.forEach((x) => (x.isSelected = false));
            }
        }
        this.checkboxChange.emit();
    }
    public onCheckboxChange(item: IConfigurationInfo) {
        if (item.children) {
            item.isSelected = item.children.every((x) => x.isSelected);
        }
    }
}
