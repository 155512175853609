import { Component, Input } from '@angular/core';
import { PagerService } from '../../common/services/pager.service';

@Component({
    selector: 'app-grid',
    templateUrl: './grid.component.html',
    styleUrls: ['./grid.component.scss']
})
export class GridComponent {
    @Input() customClass: string;
    @Input() showPaging = true;
    @Input() showItemsCount = true;
    constructor(public readonly pager: PagerService<any, any>) {}
}
