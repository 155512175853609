import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IReleaseNotesDto } from 'app/models/azure-dev-ops';

@Component({
    selector: 'app-release-notes-modal',
    templateUrl: './release-notes-modal.component.html',
    styleUrls: ['./release-notes-modal.component.scss']
})
export class ReleaseNotesModalComponent implements OnInit {
    public releaseNotes: IReleaseNotesDto;

    constructor(private readonly http: HttpClient) {}

    ngOnInit() {
        this.http.get<IReleaseNotesDto>('/api/release-notes').subscribe((res) => (this.releaseNotes = res));
    }
}
