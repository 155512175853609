<form (ngSubmit)="dataSource.reload()">
    <div class="row">
        <ng-content></ng-content>

        <div class="form-group btn-group withoutHeading ml-4 mr-4" role="group">
            <button type="submit" data-tid="btnSearch" class="btn btn-primary">
                {{ 'Common_Search' | translate }}
            </button>
            <button type="button" data-tid="btnClear" class="btn btn-secondary" (click)="dataSource.resetFilter()">
                {{ 'Common_ClearSearch' | translate }}
            </button>
        </div>
    </div>
</form>
