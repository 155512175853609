import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-confirm-campaign-mpla-modal',
    templateUrl: './confirm-campaign-mpla-modal.component.html',
    styleUrls: ['./confirm-campaign-mpla-modal.component.scss']
})
export class ConfirmCampaignMplaModalComponent {
    noMpla = false;
    constructor(private readonly http: HttpClient, private readonly dialogRef: MatDialogRef<ConfirmCampaignMplaModalComponent>) {}

    closeDialog(save: boolean) {
        if (save) {
            this.dialogRef.close(this.noMpla);
        } else {
            this.dialogRef.close();
        }
    }
}
