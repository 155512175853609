import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpMemoryCache, memoryCache } from '../http-memory-cache';
import { ObjectUtils } from '../utils';

export class BaseApi {
    protected readonly cache = new HttpMemoryCache();

    public constructor(protected http: HttpClient) {}

    protected load<T>(url: string, cacheKey: string): Observable<T> {
        return this.http.get<T>(url).pipe(
            memoryCache(cacheKey, this.cache),
            map((res) => this.cloneResult(res))
        );
    }

    protected cloneResult<T>(res: T) {
        if (Array.isArray(res)) {
            const clone: any = res.slice(0).map((x) => ObjectUtils.cloneDeep(x));
            return <T>clone;
        } else {
            return ObjectUtils.cloneDeep(res);
        }
    }
}
