/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum CfChatStates {
    Disabled = 0,
    TechnicalSupportAnonymous = 1,
    TechnicalSupport = 2,
    OrderChangesAnonymous = 4,
    OrderChanges = 8,
    ServicesAnonymous = 16,
    Services = 32,
}
