/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum TranslationType {
    Commodity = 0,
    Seo = 1,
    CommodityChecker = 2,
    CommodityExceptions = 3,
    Review = 4,
    Category = 5,
    Api = 6,
    InternalBanners = 7,
    Ingredients = 8,
}
