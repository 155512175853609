<div [attr.id]="id ? id : null" class="tabs-container">
    <div class="tabs">
        <ul
            class="nav nav-tabs"
            cdkDropList
            [cdkDropListDisabled]="!sortable"
            (cdkDropListDropped)="onDrop($event)"
            cdkDropListOrientation="horizontal"
            cdkDropListLockAxis="x"
        >
            <li
                *ngFor="let i of tabs; let index = index"
                class="nav-li cursor-pointer"
                [class.active]="i === selectedTab"
                [class.form-invalid]="i.formInvalid"
                [class.with-tooltip]="i.tooltip"
                [class.form-filled]="i.formFilled"
                [ngClass]="getTabClass(index)"
                (click)="selectTab(i, true, $event)"
                [cdkDragDisabled]="i.sortDisabled"
                cdkDrag
            >
                <div
                    [class.not-allowed]="(i !== selectedTab && disabled) || i.disabled"
                    [attr.data-tid]="'tab_' + id + (id ? '_' : '') + index"
                    [class.bg-success]="i.entityState === 'added'"
                    [class.bg-danger]="i.entityState === 'deleted'"
                    class="tabClass"
                    [class.p-0]="!!tabTemplate"
                >
                    <div *ngIf="sortable && !i.sortDisabled" class="drag" cdkDragHandle><i class="fas fa-grip-horizontal cursor-move"></i></div>
                    <ng-template [ngTemplateOutlet]="tabTemplate ? tabTemplate : defaultTabTemplate" [ngTemplateOutletContext]="{ $implicit: i }">
                    </ng-template>
                </div>
            </li>
        </ul>
        <div [class.display-none]="ref.childElementCount == 0" class="tab-content">
            <div #ref class="panel-body"><ng-content select="[body]"></ng-content></div>
        </div>
    </div>
</div>

<ng-template #defaultTabTemplate let-item>
    {{ item.title }}
    <span *ngIf="item.tooltip" style="margin-left: 5px" class="appTooltip" [cmsTooltip]="item.tooltip" [html]="true">
        &nbsp;<i class="fas fa-fw fa-question-circle"></i>
    </span>
</ng-template>
