/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum MobileApplicationType {
    Alza = 1,
    Media = 2,
    CodeCamp = 3,
    SecApp = 4,
    FirebaseGcm = 5,
    Firebase = 6,
}
