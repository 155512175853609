<div *ngFor="let i of items">
    <div class="treeItem lvl{{ level }}">
        <span class="expand" [attr.data-tid]="'plus_' + i.name ? i.name : i.sectionName" *ngIf="i.children" (click)="toggleExpand(i)">
            <i
                class="fas"
                [ngClass]="{
                    'fa-plus-square': !i.isExpanded,
                    'fa-minus-square': i.isExpanded
                }"
            ></i>
        </span>
        <div class="checkbox form-group">
            <span class="nochild" *ngIf="i.children"></span>
            <input
                id="configurationSelectModal_{{ i.id }}"
                [attr.data-tid]="i.name"
                type="checkbox"
                [(ngModel)]="i.isSelected"
                (ngModelChange)="toggleCheckbox($event, i)"
            />
            <label for="configurationSelectModal_{{ i.id }}" class="font-noraml" [ngStyle]="{ 'background-color': i.isFound ? 'yellow' : '' }">{{
                i.name ? i.name : i.sectionName
            }}</label>
        </div>
    </div>
    <div class="subtree" *ngIf="i.isExpanded" [ngStyle]="{ 'padding-left.px': 15 * level }">
        <app-configuration-select-modal-items
            [items]="i.children"
            [level]="level + 1"
            (checkboxChange)="onCheckboxChange(i)"
        ></app-configuration-select-modal-items>
    </div>
</div>
