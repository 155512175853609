import { HttpErrorResponse } from '@angular/common/http';
import { Params } from '@angular/router';
import { IIdDto } from 'app/models';
import { merge, Observable, throwError } from 'rxjs';
import { catchError, map, take, withLatestFrom } from 'rxjs/operators';
import { maxSafeInteger } from './constants';
import { INavigationCommand } from './dto/navigation-command';
import { NavigateToNotFoundError } from './errors';

export interface ISelectIdParamOptions {
    /**
     * If true instead of returning NaN throws {@link NavigateToNotFoundError}
     */
    throwIfNaN?: boolean;

    /**
     * If true when 'new' throws {@link NavigateToNotFoundError}
     */
    throwIfNew?: boolean;

    /**
     * Route where to navigate if {@link NavigateToNotFoundError} is thrown
     */
    notFoundRoute?: INavigationCommand;
}

/**
 * both observables will trigger code, but data will always be from the first one
 * @param observableWithData observable with data - route.params for example
 * @param observableUsedAsTrigger observable that can also trigger this code - form.reload for example
 */
export function mergeFirst(observableWithData: Observable<any>, observableUsedAsTrigger: Observable<any>) {
    return merge(
        observableWithData,
        observableUsedAsTrigger.pipe(
            withLatestFrom(observableWithData),
            map(([, params]) => params)
        )
    );
}

/**
 * Selects id parameter from route.
 * @param {Object} options Pass additial options.
 * @example
 * ```ts
    ngOnInit() {
        this.route.params.pipe(selectIdParam()).subscribe((id) => {
            return this.formService.initialize(id);
        });
    }
 * ```
* @throws {@link NavigateToNotFoundError} when id param is not integer or 'new'
 */
export function selectIdParam(options?: ISelectIdParamOptions) {
    return function (source: Observable<Params>): Observable<IIdDto<number>> {
        return source.pipe(
            map((params) => {
                const id = String(params.id);
                if (id === 'new') {
                    if (options?.throwIfNew) {
                        throw new NavigateToNotFoundError(params.id, options.notFoundRoute);
                    } else {
                        return null;
                    }
                }
                const num = +id;
                if (Number.isInteger(num) && num > 0 && num <= maxSafeInteger) {
                    return { id: num };
                } else {
                    if (options?.throwIfNaN) {
                        throw new NavigateToNotFoundError(params.id, options.notFoundRoute);
                    } else {
                        return { id: NaN };
                    }
                }
            })
        );
    };
}

export function selectStringIdParam() {
    return function (source: Observable<Params>): Observable<IIdDto<string>> {
        return source.pipe(
            map((params) => {
                const id = String(params.id);
                if (id === 'new') {
                    return null;
                }
                return { id };
            }),
            take(1)
        );
    };
}

export function handle404(notFoundRoute?: INavigationCommand) {
    return function <T>(source: Observable<T>): Observable<T> {
        return source.pipe(
            catchError((err) => {
                if (err instanceof HttpErrorResponse && err.status === 404) {
                    return throwError(() => new NavigateToNotFoundError(err.error?.primaryKey || NaN, notFoundRoute));
                }
                return throwError(() => err);
            })
        );
    };
}
