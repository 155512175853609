/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum ParameterRenderType {
    Checkbox = 0,
    Slider = 1,
    CheckboxIsPresent = 2,
    Cable = 3,
    Image = 4,
}
