/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum CommodityLegendTemplateVariableType {
    String = 1,
    Slider = 2,
    ListOfValues = 3,
    Dropdown = 4,
    Image = 5,
    Video = 6,
}
