/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum LeasingEnabledState {
    Disabled = 0,
    Enabled = 1,
    ConditionallyPermitted = 2,
    PaymentOfThird = 4,
    ConditionallyPermittedRepaymentsWithoutIncrease = 8,
}
