import { IFroalaOptions } from 'app/components/forms/froala/froala-options';
import { environment } from 'environments/environment';
import { Languages } from './enums/enums';

/**
 * Empty function
 */
// eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
export const EMPTY = () => {};

export const dateFormats = {
    date: 'DD.MM.YYYY',
    dateTime: 'DD.MM.YYYY HH:mm',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    DDMMYYYY: 'DDMMYYYY',
    ngDateTimePicker: 'YYYY-MM-DDTHH:mm:ss'
};

/** nHmotnost on commodity, often translated as "Status" */
export const webStatusCodes = [-2, -1, 0, 1, 2, 3, 4, 7, 8, 10, 11, 12, 40, 41, 42, 43, 44, 50, 101, 102, 103, 107];

// czech language
export const defaultCountryTranslation = Languages.Czech;

/** czech for icu translations */
export const defaultIcuLocalization = '616ef3b7f3719682ffd9873c';

// czech country
export const defaultCountry = 0;

// max safe integer that is accepted by C#
export const maxSafeInteger = 2147483647;

// min safe integer that is accepted by C#
export const minSafeInteger = -2147483648;

// max small int for mssql
export const maxSmallInt = 32767;

// min small int for mssql
export const minSmallInt = -32768;

// max money for mssql
export const maxMoney = 922337203685477;

// global animation time
export const animationTime = '0.3s';

export const allowedImageTypes = ['image/jpeg', 'image/png'];

export const allowedVideoTypes = ['video/mp4'];

// typical froala config
export const froalaOptions: Partial<IFroalaOptions> = {
    key: environment.froalaKey,
    toolbarButtons: [
        'fullscreen',
        'bold',
        'italic',
        'underline',
        'strikeThrough',
        'subscript',
        'superscript',
        '|',
        'fontFamily',
        'fontSize',
        'color',
        'paragraphStyle',
        '|',
        'paragraphFormat',
        'align',
        'formatOL',
        'formatUL',
        'outdent',
        'indent',
        'quote',
        '-',
        'insertLink',
        'insertImage',
        'insertVideo',
        'insertTable',
        '|',
        'emoticons',
        'specialCharacters',
        'insertHR',
        'selectAll',
        'clearFormatting',
        '|',
        'print',
        'help',
        'html',
        '|',
        'undo',
        'redo'
    ],
    imageInsertButtons: ['imageBack', '|', 'imageByURL'],
    videoInsertButtons: ['videoBack', '|', 'videoByURL'],
    quickInsertButtons: ['table', 'ul', 'ol', 'hr'],
    htmlUntouched: true,
    charCounterCount: true,
    linkAutoPrefix: 'https://'
};

export const maximumRowsInExcel = 1048570;

export const unicodeBomCharacter = '\ufeff';

// eslint-disable-next-line no-control-regex
export const restrictedXmlCharsRegex = /([^\x09\x0A\x0D\x20-\uD7FF])/iu;
