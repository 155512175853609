import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ObjectLockService {
    constructor(private readonly http: HttpClient) {}

    public unlockObject(objectType: number, objectId: number, additionalId?: number) {
        return this.http.post<any>('api/common/object-lock/unlock', { objectType, objectId, additionalId });
    }
}
