/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum TranslationApplication {
    Eshop = 0,
    Affil = 1,
    Admin = 2,
    Marketplace = 3,
    WebIdm = 4,
    WarrantyClaim = 5,
    WebApi = 6,
    Phoenix = 7,
}
