<div class="monaco-container" [class.container-fullscreen]="isFullscreen" *ngIf="!standalone">
    <div class="monaco-toolbar sticky" *ngIf="displayToolbar">
        <button class="btn btn-sm btn-white" (click)="onHideEditor()" [cmsTooltip]="'Froala_SourceCode_Hide' | translate" [placement]="'bottom'">
            <i class="fas fa-code"></i>
        </button>
        <button class="btn btn-sm btn-white" (click)="toggleFullscreen()" [cmsTooltip]="'Froala_CodeEditor_ToggleFullscren' | translate" [placement]="'bottom'">
            <i class="fas" [ngClass]="{ 'fa-expand': !isFullscreen, 'fa-compress': isFullscreen }"></i>
        </button>
        <button class="btn btn-sm btn-white" (click)="switchTheme()" [cmsTooltip]="'Froala_CodeEditor_SwitchTheme' | translate" [placement]="'bottom'">
            <i class="far" [ngClass]="{ 'fa-moon': editorTheme === 'vs', 'fa-sun': editorTheme === 'vs-dark' }"></i>
        </button>
        <button class="btn btn-sm btn-white" (click)="formatCode()" [cmsTooltip]="'Froala_CodeEditor_FormatCode' | translate" [placement]="'bottom'">
            <i class="fas fa-sort-amount-down"></i>
        </button>
        <button class="btn btn-sm btn-white" (click)="replaceSpaces()" [cmsTooltip]="'Froala_CodeEditor_ReplaceSpaces' | translate" [placement]="'bottom'">
            <i class="fas fa-magnet"></i>
        </button>
        <button
            class="btn btn-sm btn-white"
            (click)="showCommandPallete()"
            [cmsTooltip]="'Froala_CodeEditor_CommandPallete' | translate"
            [placement]="'bottom'"
        >
            <i class="far fa-lightbulb"></i>
        </button>
        <div class="monaco-newline"></div>
    </div>
    <ngx-monaco-editor
        class="code-editor"
        [attr.id]="id"
        [options]="codeEditorOptions"
        [style.height.px]="contentHeight"
        [ngModel]="value"
        (ngModelChange)="onValueChange($event)"
        (onInit)="onEditorInit($event)"
        *ngIf="codeEditorOptions"
    ></ngx-monaco-editor>
</div>
<div class="form-group" *ngIf="standalone">
    <cms-label [required]="labelRequired" [for]="id" [text]="labelText" [tooltip]="labelTooltip" [class]="labelClass"></cms-label>
    <div class="monaco-container" [class.container-fullscreen]="isFullscreen">
        <div class="monaco-toolbar sticky" *ngIf="displayToolbar">
            <button
                class="btn btn-sm btn-white"
                (click)="toggleFullscreen()"
                [cmsTooltip]="'Froala_CodeEditor_ToggleFullscren' | translate"
                [placement]="'bottom'"
            >
                <i class="fas" [ngClass]="{ 'fa-expand': !isFullscreen, 'fa-compress': isFullscreen }"></i>
            </button>
            <button class="btn btn-sm btn-white" (click)="switchTheme()" [cmsTooltip]="'Froala_CodeEditor_SwitchTheme' | translate" [placement]="'bottom'">
                <i class="far" [ngClass]="{ 'fa-moon': editorTheme === 'vs', 'fa-sun': editorTheme === 'vs-dark' }"></i>
            </button>
            <button class="btn btn-sm btn-white" (click)="formatCode()" [cmsTooltip]="'Froala_CodeEditor_FormatCode' | translate" [placement]="'bottom'">
                <i class="fas fa-sort-amount-down"></i>
            </button>
            <button
                class="btn btn-sm btn-white"
                (click)="showCommandPallete()"
                [cmsTooltip]="'Froala_CodeEditor_CommandPallete' | translate"
                [placement]="'bottom'"
            >
                <i class="far fa-lightbulb"></i>
            </button>
        </div>
        <ngx-monaco-editor
            class="code-editor"
            [attr.id]="id"
            [options]="codeEditorOptions"
            [style.height.px]="contentHeight"
            [ngModel]="value"
            (ngModelChange)="onValueChange($event)"
            (onInit)="onEditorInit($event)"
            *ngIf="codeEditorOptions"
        ></ngx-monaco-editor>
    </div>
    <cms-field-errors [submitted]="submitted" [control]="control"></cms-field-errors>
</div>
