/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum GeocodeEntityTypeName {
    Regional = 0,
    Country = 1,
    Region = 2,
    Municipality = 3,
    MunicipalityPart = 4,
    Street = 5,
    Address = 6,
    Poi = 7,
}
