/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum Color {
    DarkPurple = 0,
    LightPurple = 1,
    Pink = 2,
    Yellow = 3,
    Green = 4,
    LightBlue = 5,
    Blue = 6,
    DarkGreen = 7,
    DarkBlue = 8,
    Gray = 9,
}
