import { HttpClient } from '@angular/common/http';
import { ICultureInfoDto } from '../dto/common-dto';
import { BaseApi } from './base-api';

export class CultureInfoApi extends BaseApi {
    public constructor(http: HttpClient) {
        super(http);
    }

    // Gets culture info list from System.Globalization where name length is equals to 5
    public getCultureInfo() {
        return this.http.get<Array<ICultureInfoDto>>('api/common/culture-info');
    }
}
