import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface IImageSelectorData {
    url?: string;
    urlLabel: string;
    labelTooltip?: string;
    required: boolean;
}

@Component({
    selector: 'app-image-selector-modal',
    templateUrl: './image-selector-modal.component.html',
    styleUrls: ['./image-selector-modal.component.scss']
})
export class ImageSelectorModalComponent {
    public url?: string;
    public urlLabel = '';
    public urlTooltip?: string;
    public required: boolean;

    constructor(@Inject(MAT_DIALOG_DATA) data: IImageSelectorData, private readonly dialogRef: MatDialogRef<ImageSelectorModalComponent, { url: string }>) {
        this.url = data.url;
        this.urlLabel = data.urlLabel;
        this.urlTooltip = data.labelTooltip;
        this.required = data.required;
    }

    public ok() {
        this.dialogRef.close({ url: this.url });
    }
}
