import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable()
export class AdminTitleService extends Title {
    /**
     * Set the title of the current HTML document.
     * @param newTitle
     */
    setTitle(newTitle: string) {
        super.setTitle(`${newTitle} | Admin v2`);
    }
}
