/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum StoreType {
    New = 1,
    Montage = 2,
    Showroom = 3,
    Openbox = 4,
    Newish = 5,
    Bazaar = 6,
    Aftersale = 7,
    Virtual = 8,
    Material = 9,
    Marketing = 10,
    Repair = 11,
    SpecialService = 12,
    TangibleFixedAssets = 13,
    SmallTangibleAssets = 14,
    Consumption = 15,
    AccountOfConsumption = 16,
    WriteOffs = 16,
    SpareParts = 18,
    NonStock = 19,
    Disposal = 20,
    Complaint = 99,
}
