import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'time'
})
export class TimePipe implements PipeTransform {
    transform(value: string): any {
        if (value == null) {
            return null;
        }
        const blocks = value.split(':');
        if (blocks.length !== 3) {
            return value;
        }
        return blocks[0] + ':' + blocks[1];
    }
}
