<div mat-dialog-title>
    <button type="button" class="close" mat-dialog-close><span aria-hidden="true">×</span></button>
    <h4 class="modal-title">{{ 'Common_WikiDocumentationHeader' | translate }}</h4>
</div>

<mat-dialog-content>
    <div [innerHTML]="html"></div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button data-tid="btnClose" mat-dialog-close type="button" class="btn btn-white">{{ 'Common_Close' | translate }}</button>
</mat-dialog-actions>
