import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FroalaMessageService {
    private _subject = new Subject<any>();

    reInitialize() {
        this._subject.next({ reInit: true });
    }

    clearMessage() {
        this._subject.next(true);
    }

    getMessage() {
        return this._subject.asObservable();
    }
}
