/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum CommodityTranslationState {
    Default = 0,
    ToRobotTranslate = 1,
    RobotTranslated = 2,
    XmlTranslationAssigned = 3,
    XmlTranslationApplied = 4,
}
