import { Directive, ElementRef, OnDestroy } from '@angular/core';
import { BusyService } from 'app/common/services';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Directive({
    selector: 'button[appDisableIfBusy]'
})
export class DisableIfBusyDirective implements OnDestroy {
    private _busySubscription: Subscription;

    constructor(busyService: BusyService, private readonly element: ElementRef<HTMLButtonElement>) {
        this._busySubscription = busyService.busy$
            .pipe(
                filter((x) => x.method === 'ANY'),
                map((x) => x.hasActiveRequests)
            )
            .subscribe((x) => {
                this.element.nativeElement.disabled = x;
            });
    }

    ngOnDestroy() {
        this._busySubscription?.unsubscribe();
    }
}
