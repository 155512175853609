import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { defaultCountry } from 'app/common/constants';
import { CommonDataService } from 'app/common/services';
import { ICountryDto } from 'app/models/country';
import { CategorySelectDataType, ICategoryInfo, ICategorySelect } from '../category-info';

@Component({
    templateUrl: './category-select-modal.html',
    styleUrls: ['./category-select-modal.scss']
})
export class CategorySelectModalComponent implements OnInit {
    public items: any;
    public maxLevel?: number;
    public sectionCategories?: boolean;
    public dataType: CategorySelectDataType;
    // If children names of object differ
    public childrenName = 'subItems';
    public search: boolean;
    public countryFilter: boolean;
    public main: number;
    public loading = true;
    public addRemoveToLevel: number;
    public eshopId: number;

    public expanded = true;
    public searchString?: string = '';
    public countries: Array<ICountryDto>;
    public selectedCountryIds: Array<number> = [defaultCountry];

    constructor(
        private http: HttpClient,
        @Inject(MAT_DIALOG_DATA) private readonly context: ICategorySelect,
        private readonly dialogRef: MatDialogRef<CategorySelectModalComponent, Array<ICategoryInfo>>,
        private readonly commonDataService: CommonDataService
    ) {}

    ngOnInit() {
        this.maxLevel = this.context.maxLevel;
        this.sectionCategories = this.context.sectionCategories;
        this.search = this.context.search;
        this.countryFilter = this.context.countryFilter;
        this.dataType = this.context.dataType;
        this.items = this.context.items;
        this.main = this.context.main;
        this.addRemoveToLevel = this.context.addRemoveToLevel;

        const selectedIds = this.context.items.map((x) => x.id);
        if (this.sectionCategories) {
            this.search = false;
            this.countryFilter = false;
            this.http.get('api/category/section-category').subscribe((res) => this.fillData(selectedIds, res));
        } else {
            if (this.countryFilter) {
                this.commonDataService.country.getTranslationCountries().subscribe((countries) => (this.countries = countries));
            }
            this.getTree(selectedIds);
        }
    }

    public searchWord() {
        this.loading = true;
        this.getTree();
    }

    public clearSearch() {
        this.loading = true;
        this.searchString = '';
        this.selectedCountryIds = [defaultCountry];
        this.expanded = true;
        this.getTree();
    }

    public ok() {
        this.dialogRef.close(this.getSelectedItems());
    }

    private getTree(selected: Array<number> = null) {
        const selectedIds = selected ?? this.getSelectedIds();
        this.http.post(this.getUrl(), this.getFilter(selectedIds)).subscribe((res) => this.fillData(selectedIds, res));
    }

    private getUrl(): string {
        return this.dataType === CategorySelectDataType.ArticleCategory ? 'api/article-category/tree' : 'api/category/tree';
    }

    private fillData(selectedIds: Array<number>, data: any) {
        this.items = data;
        this.fillClientProperties(selectedIds);
        this.loading = false;
    }

    private fillClientProperties(selectedIds: Array<number>) {
        const select = (input: Array<ICategoryInfo>) => {
            input.forEach((x) => {
                if (selectedIds?.includes(x.id)) {
                    x.isSelected = true;
                    x.isMain = x.id === this.main;
                }
                if (x.subItems?.length > 0) {
                    x.isExpanded = true;
                    select(x.subItems);
                }
            });
        };
        select(this.items);
    }

    private getSelectedItems(): Array<ICategoryInfo> {
        const selectedItems: Array<ICategoryInfo> = [];
        const get = (input: Array<ICategoryInfo>) => {
            input.filter((x) => x.isSelected).forEach((x) => selectedItems.push(x));
            input.forEach((x) => {
                if (x.subItems?.length > 0) get(x.subItems);
            });
        };
        get(this.items);
        return selectedItems;
    }

    private getSelectedIds(): Array<number> {
        return this.getSelectedItems().map((x) => x.id);
    }

    private getFilter(selectedIds: Array<number>) {
        return { searchValue: this.searchString, selectedIds: selectedIds, countryIds: this.selectedCountryIds, expanded: this.expanded };
    }
}
