<div mat-dialog-title>
    <button type="button" class="close" mat-dialog-close><span aria-hidden="true">×</span></button>
    <h4>{{ 'Components_FileInputUrlChooser_Title' | translate }}</h4>
</div>
<mat-dialog-content>
    <div class="row">
        <cms-input
            [id]="'url'"
            [labelText]="'Components_FileInputUrlChooser_Url' | translate"
            [formControl]="url"
            [labelTooltip]="'Components_FileInputUrlChooser_UrlTooltip' | translate"
        >
            <error-message [errorCode]="'pattern'" [message]="'Components_FileInputUrlChooser_UrlFormatError' | translate"></error-message>
        </cms-input>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button data-tid="btnOk" type="button" class="btn btn-primary" (click)="ok()">{{ 'Common_Ok' | translate }}</button>
    <button data-tid="btnClose" mat-dialog-close type="button" class="btn btn-white">{{ 'Common_Close' | translate }}</button>
</mat-dialog-actions>
