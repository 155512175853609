/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum BasketOrderDeliveryType {
    None = 0,
    AlzaBox = 1,
    AlzaBranch = 2,
    ShowroomCentral = 3,
    BestDeliveryBigSize = 4,
    PersonalPickup = 5,
    BestDelivery = 6,
    MyFlexBox = 7,
    Balikovo = 8,
    AlzaBoxXl = 9,
}
