import { HttpClient } from '@angular/common/http';
import { ICountryDto } from 'app/models/country';
import { ICountryVatDto } from '../dto/common-dto';
import { BaseApi } from './base-api';

export class CountryApi extends BaseApi {
    public constructor(http: HttpClient) {
        super(http);
    }

    // Gets all CountryCollection
    public getCountries() {
        return this.http.get<Array<ICountryDto>>('api/common/countries/');
    }

    // Gets default countries for all eshops from CountryCollection
    public getEshopCountries() {
        return this.http.get<Array<ICountryDto>>('api/common/countries/eshop-countries');
    }

    // Gets default countries for translation from CountryCollection
    public getTranslationCountries() {
        return this.http.get<Array<ICountryDto>>('api/common/countries/translation-countries');
    }

    // Gets the collection of all countries
    public getAllCountries() {
        return this.load<Array<ICountryDto>>('api/common/countries', 'countriesAll');
    }

    // Gets delivery VATs from CountryCollection
    public getCountriesVat() {
        return this.load<Array<ICountryVatDto>>('api/delivery/delivery-country-vat', 'countries-vat');
    }
}
