import { ErrorMessages, FormInputBase, Validators } from '@alza/cms-components';
import { HttpClient } from '@angular/common/http';
import { Component, Host, Input, OnInit, Optional, Provider, SkipSelf, forwardRef } from '@angular/core';
import { ControlContainer, FormArray, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Languages } from 'app/common/enums';
import { CommonDataService, DialogsService, EnumsService, IEnumValue, SessionService } from 'app/common/services';
import { FormGroup, FormHelper, TypedFormBuilder } from 'app/components/forms';
import { ICountryLocalizationDto } from 'app/models';
import { CategoryType, LinkType } from 'app/models/enums/link';
import { IEshopDto } from 'app/models/eshop';
import { ILinkDto, ILinkLocalizationDto, ILinkUrlDto, ILinkUrlLocalizationDto, IUrlDto } from 'app/models/link';
import { CountryLocalizationSwitchService } from '../country-localization-switch/country-localization-switch.service';
import { ILinkModalContext, ILinkModalResult, LinkModalComponent } from './link-modal/link-modal.component';

const INPUT_VALUE_ACCESSOR: Provider = {
    provide: NG_VALUE_ACCESSOR,
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    useExisting: forwardRef(() => LinkComponent),
    multi: true
};
@Component({
    selector: 'app-link',
    templateUrl: './link.component.html',
    styleUrls: ['./link.component.scss'],
    providers: [INPUT_VALUE_ACCESSOR],
    viewProviders: [{ provide: ErrorMessages, deps: [TranslateService], useClass: ErrorMessages }]
})
export class LinkComponent extends FormInputBase implements OnInit {
    @Input() public placeholder = '';
    @Input() public clearable = true;
    public form: FormGroup<ILinkDto>;
    public selectedLocalization: ICountryLocalizationDto;
    public value?: ILinkDto = null;
    public text?: string;
    public eshops: Array<IEshopDto> = [];
    public isRequired = false;
    public isDisabled = false;
    private _categoryTypes: Array<IEnumValue> = [];
    private _linkTypes: Array<IEnumValue> = [];
    private _linkTypeProperies = ['articleId', '', 'commodityId', 'commodityPackId'];
    private _isInitialized = false;

    @Input() public showCountries: boolean = false;

    constructor(
        @Optional()
        @Host()
        @SkipSelf()
        controlContainer: ControlContainer,
        errorMessages: ErrorMessages,
        private readonly enums: EnumsService,
        private readonly matDialog: MatDialog,
        private readonly commonData: CommonDataService,
        private readonly http: HttpClient,
        private readonly session: SessionService,
        private readonly translate: TranslateService,
        private readonly dialogs: DialogsService,
        private readonly formBuilder: TypedFormBuilder,
        protected readonly formHelper: FormHelper,
        countryLocalization: CountryLocalizationSwitchService
    ) {
        super(controlContainer, errorMessages, { tidPrefix: 'cso' });

        countryLocalization.currentLocalization$.subscribe((countryLocalization) => {
            if (
                this.selectedLocalization?.languageId !== countryLocalization.languageId ||
                this.selectedLocalization?.countryId !== countryLocalization.countryId
            ) {
                this.selectedLocalization = countryLocalization;
                this.updateText();
            }
            this.isDisabled = !(this.selectedLocalization?.languageId === Languages.Czech && this.selectedLocalization?.countryId === null);
        });

        this.commonData.eshop.getEshops().subscribe((res) => {
            this.eshops = res;
        });

        enums.getEnums('Link+CategoryType', 'Link+LinkType').subscribe((res) => {
            this._categoryTypes = res[0];
            this._linkTypes = res[1];
        });

        this.form = this.formBuilder.group<ILinkDto>({
            id: 0,
            localization: this.formBuilder.localizationGroup<ILinkLocalizationDto>((languageId, countryId) => {
                return this.formBuilder.group<ILinkLocalizationDto>({
                    linkType: [null, languageId === Languages.Czech && countryId === null ? [Validators.required] : null],
                    categoryType: null,
                    articleId: null,
                    categoryId: null,
                    commodityPackId: null,
                    commodityId: null,
                    categoryTypeId: null,
                    url: [null, [Validators.url('relative'), Validators.maxLength(500)]]
                });
            }),
            linkUrl: this.formBuilder.group<ILinkUrlDto>({
                localization: this.formBuilder.localizationGroup<ILinkUrlLocalizationDto>(() => {
                    return this.formBuilder.group<ILinkUrlLocalizationDto>({
                        url: null
                    });
                })
            })
        });

        this.form.get('localization').controls.forEach((localization) => {
            localization.get('linkType').valueChanges.subscribe((linkType: LinkType) => {
                this.linkTypeChanged(linkType, localization);
            });
            localization.get('categoryType').valueChanges.subscribe((categoryType: CategoryType) => {
                this.categoryTypeChanged(categoryType, localization);
            });
        });
    }

    ngOnInit(): void {
        this.control.statusChanges.subscribe(() => {
            if ((!this._isInitialized || Object.entries(this.control.value?.linkUrl).length === 0) && this.control.value) {
                if (!this.control.value.localization) {
                    this.resetLink();
                } else {
                    if (Object.entries(this.control.value?.linkUrl).length === 0 && !this.isEmpty(this.control.value)) {
                        this.resetLink(this.control.value?.id);
                        this.value.linkUrl = { localization: [{ url: '', languageId: Languages.Czech }] } as ILinkUrlDto;
                        this.onValueChange(this.control.value);
                    }
                    this.formHelper.patchValue(this.form, this.value);
                }
                this._isInitialized = true;
            }
        });
    }

    public openModal() {
        const link = { ...this.value };
        this.matDialog
            .open<LinkModalComponent, ILinkModalContext, ILinkModalResult>(LinkModalComponent, {
                data: {
                    form: this.form,
                    showCountries: this.showCountries
                },
                panelClass: 'dialog-md'
            })
            .afterClosed()
            .subscribe((res) => {
                if (res) {
                    this.onValueChange(res.link);
                } else {
                    this.value = { ...link };
                    this.onValueChange(link);
                }
                this.formHelper.patchValue(this.form, this.value);
                this.control.updateValueAndValidity();
            });
    }

    updateText() {
        if (this.value) {
            this.text = this.getFinalUrl();
        } else {
            this.text = null;
        }
    }

    onValueChange(value: ILinkDto) {
        this.value = value;
        if (!this.isEmpty(value)) {
            this.getLinkUrl(value).subscribe({
                next: (url) => {
                    this.value.linkUrl = url;
                    this.updateText();
                },
                error: (error) => {
                    this.dialogs.badRequestMessage(error);
                }
            });
        }
        this.raiseChange(value);
        this.raiseTouched();
    }

    public clear() {
        this.onValueChange(null);
    }

    updateValue(value: ILinkDto): void {
        this.value = !value ? ({} as ILinkDto) : value;
        if (!this.value.linkUrl) this.value.linkUrl = {} as ILinkUrlDto;
        this.updateText();
    }

    public getEshop(localization: ICountryLocalizationDto): string {
        if (this.eshops.length) {
            const eshops = this.eshops.filter((x) => x.languageId === localization.languageId && !x.name.startsWith('beta'));
            if (eshops.length) {
                return eshops[0].name;
            }
            return this.eshops.filter((x) => x.languageId === Languages.Czech && !x.name.startsWith('beta'))[0].name;
        } else {
            return '';
        }
    }

    public onInputChange(event: any) {
        const url = event.target.value;

        if (url == null || url === '') {
            this.resetLink(this.value.id);
            this.onValueChange(this.form.value);
            this.formHelper.patchValue(this.form, this.value);
            return;
        }

        return this.http.post<ILinkDto>(`api/link/parse-url`, { url: url } as IUrlDto).subscribe({
            next: (value) => {
                if (value) {
                    if (this.value.id) {
                        value.id = this.value.id;
                    }
                    this.resetLink(this.value.id);
                    this.onValueChange(value);
                    this.formHelper.patchCoutryLocalization(this.form.get('localization'), this.value.localization);
                }
            },
            error: (error) => {
                this.dialogs.badRequestMessage(error);
            }
        });
    }

    private getLinkUrl(value: ILinkDto) {
        return this.http.post<ILinkUrlDto>(`api/link/link-url`, value);
    }

    private getFinalUrl(): string {
        const urls = this.value?.linkUrl?.localization;
        const url = urls?.filter((x) => x.languageId === this.selectedLocalization.languageId && x.countryId == this.selectedLocalization.countryId)?.[0]?.url;

        const result = url ?? this.placeholder;
        return result ? result?.replace(/^\/?/, '/') : '';
    }

    public getEshopUrl() {
        if (this.selectedLocalization) {
            return this.session.getAlzaUrl('web', this.session.user.environment, this.selectedLocalization.countryId, this.selectedLocalization.languageId);
        }
    }

    public getLinkInfo() {
        if (!this.selectedLocalization) {
            return null;
        }

        const czechLocalization = this.formHelper.getLocalizationGroup(this.form.get('localization'), {
            languageId: Languages.Czech,
            countryId: null
        } as ICountryLocalizationDto) as FormGroup<ILinkLocalizationDto>;
        let localization = this.formHelper.getLocalizationGroup(this.form.get('localization'), this.selectedLocalization) as FormGroup<ILinkLocalizationDto>;

        if (localization.value.linkType == null && czechLocalization.value.linkType !== null) {
            localization = czechLocalization;
        }

        if (localization.value.linkType == null) {
            return null;
        }

        const { categoryType, categoryId, categoryTypeId, articleId, commodityId, commodityPackId } = localization.value;

        const typeDisplay =
            categoryType != null
                ? this.enums.getDisplayName(this._categoryTypes, categoryType)
                : this.enums.getDisplayName(this._linkTypes, localization.value.linkType);
        const catIdDisplay = categoryId ? `${this.translate.instant('Link_CatId')} ${categoryId} / ` : '';
        const catTypeIdDisplay = categoryTypeId != null ? `${this.translate.instant('Link_CatTypeId')} ${categoryTypeId} / ` : '';
        const articleIdDisplay = articleId ? `${this.translate.instant('Link_ArticleId')} ${articleId}` : '';
        const commodityIdDisplay = commodityId ? `${this.translate.instant('Link_CommodityId')} ${commodityId}` : '';
        const commodityPackIdDisplay = commodityPackId ? `${this.translate.instant('Link_CommodityPackId')} ${commodityPackId}` : '';

        const result = `${this.translate.instant(
            'Link_LinkOrCatType'
        )} ${typeDisplay} / ${catIdDisplay}${catTypeIdDisplay}${articleIdDisplay}${commodityIdDisplay}${commodityPackIdDisplay}`.trim();

        return result.endsWith('/') ? result.slice(0, -1) : result;
    }

    private removeValidators(localization: FormGroup<ILinkLocalizationDto>) {
        this._linkTypeProperies.forEach((property) => {
            if (property) {
                localization.get(property).removeValidators(Validators.required);
            }
        });

        localization.get('categoryId').removeValidators(Validators.required);
        localization.get('categoryTypeId').removeValidators(Validators.required);
        localization.get('categoryType').removeValidators(Validators.required);
        localization.get('url').removeValidators(Validators.required);
    }

    private linkUpdateValueAndValidity(localization: FormGroup<ILinkLocalizationDto>) {
        this._linkTypeProperies.forEach((property) => {
            if (property) {
                localization.get(property).updateValueAndValidity();
            }
        });

        localization.get('categoryId').updateValueAndValidity();
        localization.get('categoryTypeId').updateValueAndValidity();
        localization.get('categoryType').updateValueAndValidity({ onlySelf: true, emitEvent: false });
        localization.get('url').updateValueAndValidity();
        localization.updateValueAndValidity();

        this.control.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    }

    private linkTypeChanged(linkType: LinkType, localization: FormGroup<ILinkLocalizationDto>) {
        if (linkType == localization.value.linkType) {
            return;
        }

        let requiredLanguages = this.control.getError('requiredLanguages');

        this.isRequired = this.hasRequiredValidator(this.control) && (linkType === null || linkType === undefined);

        if (!requiredLanguages && this.hasRequiredValidator(this.control)) {
            requiredLanguages = [Languages.Czech];
        }

        if (linkType === null && localization.value.languageId !== Languages.Czech) {
            if (requiredLanguages.some((x) => x === localization.value.languageId)) {
                localization.get('linkType').addValidators(Validators.required);
            } else {
                localization.get('linkType').removeValidators(Validators.required);
            }
            localization.get('linkType').updateValueAndValidity({ onlySelf: true, emitEvent: false });
        }

        if (linkType == LinkType.Category && localization.value.categoryType == null && this._isInitialized) {
            localization.get('categoryType').setValue(CategoryType.GeneratedCategory);
        }

        this.removeValidators(localization);

        this.categoryTypeChanged(localization.value.categoryType, localization);

        switch (linkType) {
            case LinkType.Article:
                localization.get('articleId').addValidators(Validators.required);
                break;
            case LinkType.Category:
                localization.get('categoryType').addValidators(Validators.required);
                break;
            case LinkType.Commodity:
                localization.get('commodityId').addValidators(Validators.required);
                break;
            case LinkType.CommodityPack:
                localization.get('commodityPackId').addValidators(Validators.required);
                break;
            case LinkType.Other:
                localization.get('url').addValidators([Validators.required, Validators.url('relative', { strict: true }), Validators.maxLength(500)]);
        }

        this.linkUpdateValueAndValidity(localization);
    }

    private categoryTypeChanged(categoryType: CategoryType, localization: FormGroup<ILinkLocalizationDto>) {
        if (categoryType != localization.value.categoryType && this._isInitialized) {
            localization.get('categoryId').setValue(null);
            localization.get('categoryTypeId').setValue(null);
        }

        if (categoryType != null) {
            if (categoryType === CategoryType.GeneratedCategory) {
                localization.get('categoryId').addValidators(Validators.required);
                localization.get('categoryTypeId').removeValidators(Validators.required);
            } else {
                localization.get('categoryId').removeValidators(Validators.required);
                localization.get('categoryTypeId').addValidators(Validators.required);
            }
            this.linkUpdateValueAndValidity(localization);
        }
    }

    private resetLink(id: number = null) {
        this.form.get('id').patchValue(id ?? 0);
        this.form.get('localization').controls.forEach((localization) => {
            localization.patchValue({
                linkType: null,
                categoryType: null,
                articleId: null,
                categoryId: null,
                commodityPackId: null,
                commodityId: null,
                categoryTypeId: null,
                url: null
            });
            localization.markAsDirty();
        });
        (this.form.get('linkUrl').get('localization') as FormArray).clear();
    }

    private isEmpty(link: ILinkDto): boolean {
        if (!link || !link.localization) {
            return true;
        }
        return link.localization.every((x) => x.linkType === null);
    }
}
