import { Component, EventEmitter, Input, Output } from '@angular/core';
import { forEachAscendants, forEachTree } from 'app/common/tree-utils';
import { ITreeItemModel } from './models';

@Component({
    selector: 'app-tree-item',
    templateUrl: './tree-item.component.html',
    styleUrls: ['./tree-item.component.scss']
})
export class TreeItemComponent {
    /** Tree items */
    @Input() items: Array<ITreeItemModel>;

    /** Determines whether selection is enabled or not */
    @Input() isSelectionEnabled = false;

    /** Initial level of tree */
    @Input() level: number = 1;

    /** Maximum level of tree */
    @Input() maxLevel?: number;

    /** Event raised on tree item click */
    @Output() treeItemClick = new EventEmitter<ITreeItemModel>();

    /** Event raised on tree item selection changed */
    @Output() treeItemCheckedChange = new EventEmitter<ITreeItemModel>();

    public toggleExpand(item: ITreeItemModel) {
        item.isExpanded = !item.isExpanded;
    }

    onTreeItemClick(item: ITreeItemModel) {
        if (this.isSelectionEnabled) {
            item.isSelected = !item.isSelected;
            item.isIndeterminate = false;
            forEachTree(item.subItems, (i) => (i.isSelected = item.isSelected));
            forEachAscendants(item, (i) => {
                i.isSelected = i.subItems.every((s) => s.isSelected);
                i.isIndeterminate = !i.isSelected && i.subItems.some((s) => s.isSelected || s.isIndeterminate);
            });
            this.treeItemCheckedChange.emit(item);
        }
        this.treeItemClick.emit(item);
    }

    onChildTreeItemClick(event: ITreeItemModel) {
        this.treeItemClick.emit(event);
    }

    onChildTreeItemCheckedChange(event: ITreeItemModel) {
        this.treeItemCheckedChange.emit(event);
    }
}
