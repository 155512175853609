import { Component, OnInit, HostListener, ViewChild, ElementRef, Inject } from '@angular/core';
import { IMenuItem } from 'app/common/services/menu.service';
import { Subject } from 'rxjs';
import { distinctUntilChanged, debounceTime, map } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface IFlatMenuItem {
    path: Array<{ title: string; loweredTitle: string; active: boolean }>;
    url: string;
    isMvcModule: boolean;
}

@Component({
    selector: 'app-search-modal',
    templateUrl: './search-modal.component.html',
    styleUrls: ['./search-modal.component.scss']
})
export class SearchModalComponent implements OnInit {
    public searchTermSub = new Subject<string>();
    public searchTerm = '';
    public searchResult: Array<IFlatMenuItem> = [];
    public searchFocused = false;
    public menuMouseOver = false;
    public currentUrl = '';
    public itemTree: Array<IMenuItem> = [];
    public menuItems: Array<IFlatMenuItem> = [];

    constructor(@Inject(MAT_DIALOG_DATA) private readonly context: any, private readonly dialogRef: MatDialogRef<SearchModalComponent, Array<any>>) {}

    @ViewChild('search') input: ElementRef;
    @HostListener('window:keyup', ['$event'])
    onClick(e: KeyboardEvent) {
        if (e.ctrlKey && e.keyCode === 81) {
            this.close();
        }
    }

    ngOnInit() {
        this.itemTree = this.context.itemTree;
        this.itemTree.forEach((i) => {
            this.menuItems.push(...this.createMenuItem(i));
        });

        this.searchTermSub
            .pipe(
                debounceTime(150),
                distinctUntilChanged(),
                map((x) =>
                    x
                        .toLocaleLowerCase()
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                )
            )
            .subscribe((term) => {
                let res: Array<IFlatMenuItem> = [];
                if (term) {
                    this.menuItems.forEach((m) => {
                        if (m) {
                            m.path.forEach((p) => {
                                p.active = p.loweredTitle.indexOf(term) !== -1;
                            });
                            if (m.path.some((t) => t.active)) {
                                res.push(m);
                            }
                        }
                    });
                } else {
                    res = [];
                }
                this.searchResult = res;
            });
    }
    private createMenuItem(root: IMenuItem, rootPath: Array<{ title: string; loweredTitle: string; active: boolean }> = []): Array<IFlatMenuItem> {
        const res: Array<IFlatMenuItem> = [];
        const currentPath = [
            ...rootPath,
            {
                active: false,
                title: root.title,
                loweredTitle: root.title
                    .toLocaleLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
            }
        ];
        if (root.url) {
            res.push({ url: root.url, path: currentPath, isMvcModule: root.isMvcModule });
        }
        root.items.forEach((i) => {
            res.push(...this.createMenuItem(i, currentPath));
        });

        return res;
    }

    public clearSearch() {
        this.searchResult = [];
        this.searchTerm = '';
    }

    public close() {
        this.dialogRef.close();
    }
}
