import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpMemoryCache } from 'app/common/http-memory-cache';
import { IParameterGroupCollectionItemDto, IParameterGroupsOrderDto, IParameterTypeCollectionItemDto } from 'app/models/parameter';
import { mapTo } from 'rxjs/operators';
import { IParameterGroupDetailDto } from './dto/parameter-group-detail-dto';

@Injectable()
export class ParameterGroupDataService {
    private readonly _cache = new HttpMemoryCache();

    constructor(private http: HttpClient) {}

    public getGroupsByCategory(categoryId: number) {
        return this.http.get<Array<IParameterGroupCollectionItemDto>>(`/api/parameter/groups-by-category/${categoryId}`);
    }

    public getHierarchicTypesForGroup(groupId: number, typeId: number, includeCurrentType: boolean) {
        return this.http.get<Array<IParameterTypeCollectionItemDto>>(`/api/parameter/hierarchic-types/${groupId}/${typeId}/${includeCurrentType}`);
    }

    public getCategoriesByGroups(data: IParameterGroupDetailDto) {
        return this.http.post<any>(`/api/parameter/parameter-group-categories`, { groupId: data.id, categoryId: data.categoryId });
    }

    public loadDetail(id: number, categoryId?: number) {
        return this.http.get<any>(`/api/parameter/parameter-group/${id}/${categoryId}`);
    }

    public cutGroupFromCategory(groupId: number, categoryId?: number) {
        return this.http.delete<any>(`/api/parameter/parameter-group-cut/${groupId}/${categoryId}`);
    }

    public deleteGroup(groupId: number, categoryId?: number) {
        return this.http.delete<any>(`/api/parameter/parameter-group/${groupId}/${categoryId}`);
    }

    public save(data: IParameterGroupDetailDto) {
        if (data.id) {
            return this.http.put<{ id: number }>(`/api/parameter/parameter-group/${data.id}/${data.categoryId}`, data.main).pipe(mapTo({ id: data.id }));
        } else {
            return this.http.post<{ id: number }>(`/api/parameter/parameter-group`, data.main);
        }
    }

    public addToCategory(categoryId: number, data: Array<number>) {
        return this.http.post<{ id: number }>(`/api/parameter/add-to-category/${categoryId}`, data);
    }

    public orderParameterGroups(dto: IParameterGroupsOrderDto) {
        return this.http.post<any>(`/api/parameter/order-parameter-groups`, dto);
    }

    public isHierarchic(id: number) {
        return this.http.get<boolean>(`/api/parameter/is-group-hierarchic/${id}`);
    }

    public exportParametersForCateory(categoryId: number) {
        return this.http.get(`api/parameter/export-category/${categoryId}`, { responseType: 'arraybuffer', observe: 'response' });
    }
}
