import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DialogsService } from 'app/common/services/dialogs.service';
import { IConfigurationInfo, IConfigurationSelect } from './configuration-info';
import { ConfigurationSelectModalComponent } from './configuration-select-modal/configuration-select-modal';

@Component({
    selector: 'app-configuration-select',
    styleUrls: ['./configuration-select.scss'],
    templateUrl: './configuration-select.html'
})
export class ConfigurationSelectComponent {
    @Output()
    public configurationsChange = new EventEmitter<Array<IConfigurationInfo>>();
    @Input()
    public set configurations(value: Array<any>) {
        if (value && value.length) {
            this.items = [];
            value.forEach((v) => this.addConfiguration(v));
        }
    }
    @Input()
    search = false;
    @Input()
    isBeta = false;
    @Input()
    disabled: boolean;
    @Input() public id: string;

    public items: Array<any> = [];

    public input = '';
    public get inputIsNumber() {
        return !isNaN(+this.input);
    }
    public n = 0;

    constructor(
        private readonly dialogs: DialogsService,
        private readonly matDialog: MatDialog,
        private readonly http: HttpClient,
        private readonly translate: TranslateService
    ) {}

    public openModal() {
        this.matDialog
            .open<ConfigurationSelectModalComponent, IConfigurationSelect, Array<IConfigurationInfo>>(ConfigurationSelectModalComponent, {
                data: {
                    items: this.items,
                    search: this.search,
                    isBeta: this.isBeta
                },
                panelClass: 'dialog-lg'
            })
            .afterClosed()
            .subscribe((res) => {
                if (res) {
                    for (const i of res) {
                        this.addConfiguration(i.id);
                    }
                    this.raiseChange();
                }
            });
    }

    public inputKeyDown(event: KeyboardEvent) {
        if (event.keyCode === 13) {
            this.plusClicked();
        }
    }

    public plusClicked() {
        if (isFinite(+this.input)) {
            this.addConfiguration(+this.input);
            this.input = '';
        }
    }

    public remove(id: number) {
        const item = this.items.find((x) => x.id === id);

        if (!item) {
            return;
        }

        const index = this.items.indexOf(item);
        this.items.splice(index, 1);
        this.raiseChange();
    }

    public searchConfiguration(): Array<any> {
        const foundItems = new Set();
        if (!+this.input) {
            for (const el of this.items) {
                const name = el.name ? el.name : el.sectionName;
                if (name.toLocaleLowerCase().includes(this.input.toLocaleLowerCase())) {
                    if (el.url.includes('beta') && this.isBeta) {
                        foundItems.add(el);
                    } else if (!el.url.includes('beta') && !this.isBeta) {
                        foundItems.add(el);
                    }
                }
            }
            return Array.from(foundItems);
        }
        return this.items;
    }

    private addConfiguration(id: number) {
        if (!this.items.some((x) => x.id === id)) {
            this.http.get<IConfigurationInfo>(`/api/new-configuration/select-list/${id}`).subscribe(
                (res) => {
                    if (res) {
                        res.isSelected = true;
                        this.items.push(res);
                        this.raiseChange();
                    }
                },
                (err: HttpErrorResponse) => {
                    if (err.status === 404) {
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        this.dialogs.warningMessage('', this.translate.instant('CategorySelect_NotFound', { 0: id }));
                    }
                }
            );
        }
    }

    private raiseChange() {
        const foundItems = new Set();
        for (const el of this.items) {
            const name = el.name ? el.name : el.sectionName;
            if (name.toLocaleLowerCase().includes(this.input.toLocaleLowerCase())) {
                if (el.url.includes('beta') && this.isBeta) {
                    foundItems.add(el);
                } else if (!el.url.includes('beta') && !this.isBeta) {
                    foundItems.add(el);
                }
            }
        }
        this.configurationsChange.next(Array.from(foundItems).map((x: any) => x.id));
    }
}
