import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'app/common/services';
import { Subscription } from 'rxjs';
import { IParameterTypeCollectionItemDto } from '../dto/parameter-type-collection-item-dto';
import { ParameterTypeSelectDataService } from '../parameter-type-select-data.service';

@Component({
    selector: 'app-parameter-type-select-types',
    templateUrl: './parameter-type-select-types.component.html',
    styleUrls: ['./parameter-type-select-types.component.scss']
})
export class ParameterTypeSelectTypesComponent implements OnDestroy {
    public items: Array<IParameterTypeCollectionItemDto> = [];
    public categoryId: number;
    public groupId: number;
    public typeId: number;
    private _subscribers: Array<Subscription> = [];

    constructor(private readonly dataService: ParameterTypeSelectDataService, private messageService: MessageService, private router: Router) {
        this._subscribers.push(
            this.messageService.getMessage('categoryId').subscribe((value) => {
                this.categoryId = value;
                this.clearItems();
            })
        );

        this._subscribers.push(
            this.messageService.getMessage('groupId').subscribe((value) => {
                this.groupId = value;
                this.clearItems();

                if (this.groupId) {
                    this.dataService.getTypesByGroup(this.groupId).subscribe((res) => {
                        this.items = res;
                        this.messageService.sendMessage('typesCount', this.items.length);

                        if (this.typeId) {
                            const item = this.items.find((x) => x.id === this.typeId);
                            this.messageService.sendMessage('typeGroupId', item ? item.parameterTypeGroupId : null);
                        }
                    });
                }
            })
        );

        this._subscribers.push(
            this.messageService.getMessage('typeId').subscribe((value) => {
                this.typeId = value;
            })
        );
    }

    typeChanged(item: any) {
        this.messageService.sendMessage('typeId', item.id);
        this.messageService.sendMessage('typeGroupId', item.parameterTypeGroupId);
    }

    clearItems() {
        this.items = [];
        this.messageService.sendMessage('typesCount', 0);
        this.messageService.sendMessage('typeGroupId', null);
    }

    ngOnDestroy(): void {
        this._subscribers.forEach((subscriber) => {
            subscriber.unsubscribe();
        });
    }
}
