/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum PromoActionPriceType {
    Premium = 0,
    Cashback = 1,
    Scrappage = 2,
    DiscountCode = 3,
    ApPlus = 4,
}
