/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { MonacoEditor, editor } from 'monaco-types';
import ngHtmlParser from 'prettier/parser-html';
import ngCssParser from 'prettier/parser-postcss';
import prStandalone from 'prettier/standalone';

export function registerFormattingProviders(monaco: MonacoEditor, codeEditor: editor.ICodeEditor) {
    monaco.languages.registerDocumentFormattingEditProvider('html', {
        provideDocumentFormattingEdits(model) {
            const text = prStandalone.format(model.getValue(), {
                parser: 'html',
                plugins: [ngHtmlParser],
                singleQuote: true,
                useTabs: true,
                printWidth: codeEditor.getLayoutInfo().contentWidth
            });

            return [
                {
                    range: model.getFullModelRange(),
                    text
                }
            ];
        }
    });

    monaco.languages.registerDocumentFormattingEditProvider('css', {
        provideDocumentFormattingEdits(model) {
            const text = prStandalone.format(model.getValue(), {
                parser: 'css',
                plugins: [ngCssParser],
                singleQuote: true,
                useTabs: true,
                printWidth: codeEditor.getLayoutInfo().contentWidth
            });

            return [
                {
                    range: model.getFullModelRange(),
                    text
                }
            ];
        }
    });
}
