/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum EshopPlatform {
    Web = 0,
    Mobile = 1,
    Android = 2,
    IOS = 3,
    AndroidTablet = 5,
    IOSTablet = 6,
    WindowsPhone = 7,
    WebTablet = 8,
    OnlineReader = 9,
    Reserved = 10,
    AlzaMedia = 11,
}
