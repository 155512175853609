import { Directive, HostBinding, HostListener, Input, OnDestroy } from '@angular/core';
import { PagerService } from 'app/common/services/pager.service';
import { Subscription } from 'rxjs';

@Directive({ selector: '[appSortableColumn]' })
export class SortableColumnDirective implements OnDestroy {
    private _sub?: Subscription;
    private _appSortableColumn?: number;
    private _state: 'default' | 'asc' | 'desc' = 'default';

    @Input()
    set appSortableColumn(value: number) {
        if (value || value === 0) {
            this.unsubscribe();
            this._appSortableColumn = value;
            this._sub = this.pager.sortChange$.subscribe((event) => {
                if (this._appSortableColumn === event.key) {
                    this._state = event.desc ? 'desc' : 'asc';
                } else {
                    this._state = 'default';
                }
            });
        }
    }

    @HostBinding('class.sorting')
    get sorting() {
        return this._state === 'default';
    }
    @HostBinding('class.sorting_desc')
    get sortingDesc() {
        return this._state === 'desc';
    }
    @HostBinding('class.sorting_asc')
    get sortingAsc() {
        return this._state === 'asc';
    }

    constructor(private pager: PagerService<any, any>) {}

    @HostListener('click')
    onClick() {
        if (this._appSortableColumn !== null) {
            this.pager.setSort(this._appSortableColumn);
        }
    }

    ngOnDestroy() {
        this.unsubscribe();
    }

    private unsubscribe() {
        if (this._sub && !this._sub.closed) {
            this._sub.unsubscribe();
        }
    }
}
