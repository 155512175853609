import { ErrorMessages, FormInputBase } from '@alza/cms-components';
import { Component, forwardRef, Host, Input, OnInit, Optional, SkipSelf } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CategoryTreeDataService } from 'app/components/category-tree-view';
import { CategorySelectOneModalComponent } from './category-select-one-modal/category-select-one-modal.component';

const INPUT_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    useExisting: forwardRef(() => CategorySelectOneComponent),
    multi: true
};
@Component({
    selector: 'app-category-select-one',
    templateUrl: './category-select-one.component.html',
    styleUrls: ['./category-select-one.component.scss'],
    providers: [INPUT_VALUE_ACCESSOR],
    viewProviders: [CategoryTreeDataService, { provide: ErrorMessages, deps: [TranslateService], useClass: ErrorMessages }]
})
export class CategorySelectOneComponent extends FormInputBase implements ControlValueAccessor, OnInit {
    @Input() public placeholder: string;
    @Input() public clearable = true;
    public value?: number = null;
    public text?: string;

    constructor(
        @Optional()
        @Host()
        @SkipSelf()
        controlContainer: ControlContainer,
        errorMessages: ErrorMessages,
        private readonly categoryTreeDataService: CategoryTreeDataService,
        private readonly matDialog: MatDialog
    ) {
        super(controlContainer, errorMessages, { tidPrefix: 'cso' });
    }

    updateValue(obj: any): void {
        this.value = obj === undefined ? null : obj;
        this.updateText();
    }

    ngOnInit() {
        this.updateText();
    }

    updateText() {
        if (this.value) {
            this.categoryTreeDataService.categoryBase(Number(this.value)).subscribe((category) => {
                this.text = `${this.value} - ${category.name}`;
            });
        } else {
            this.text = null;
        }
    }

    onValueChange(value: any) {
        this.value = value;
        this.updateText();
        this.raiseChange(value);
        this.raiseTouched();
    }

    public clear() {
        this.onValueChange(null);
    }

    public openModal() {
        this.matDialog
            .open<CategorySelectOneModalComponent, number, Array<number>>(CategorySelectOneModalComponent, {
                data: this.value,
                panelClass: 'dialog-md'
            })
            .afterClosed()
            .subscribe((res) => {
                if (res) {
                    this.onValueChange(res);
                }
            });
    }
}
