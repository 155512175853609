import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { InvalidFormError } from 'app/common/errors';
import { DialogsService } from 'app/common/services/dialogs.service';
import { environment } from 'environments/environment';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { FormHelper } from './form-helper.service';

export class FormServiceBase<TKey extends { id: number | string; [key: string]: number | string | boolean } = { id: number }> {
    protected form: UntypedFormGroup;
    /**
     * Emits an value whenever `FormServiceBase#init` method is called.
     */
    public reload$ = new Observable<void>();
    private _reloadSubject = new ReplaySubject<void>(1);
    private _formReloadSubscriptions: Array<Subscription> = [];

    constructor(protected readonly dialogs: DialogsService, protected readonly formHelper: FormHelper, protected readonly translate: TranslateService) {
        this.reload$ = this._reloadSubject.asObservable();
    }

    /**
     * Adds an subscription to an array that will be unsubscribed when `init()` is called eg. the form is recreated.
     * Usually for ValueChanges or StatusChanges observables of the main form.
     * Alternatively `takeUntil($reload)` operator can be used.
     * @param subscription An form subscription
     */
    public formReloadSubscriptions(...subscription: Array<Subscription>) {
        this._formReloadSubscriptions.push(...subscription);
    }

    /**
     * Initialize the main form with the primary key of edited object
     * Calling this method will emit reload$ observable
     * @param data The primary key.
     */
    public init(data: TKey) {
        this._formReloadSubscriptions.forEach((x) => x.unsubscribe());
        this.form = new UntypedFormGroup({});
        for (const [key, value] of Object.entries(data)) {
            this.form.addControl(key, new UntypedFormControl(value));
        }
        this._reloadSubject.next();
    }

    /**
     * Gets the form primary key
     */
    public get id() {
        return this.getKey('id');
    }

    /**
     * Gets the form primary key or its part
     * @param key The name of the key
     */
    public getKey<K extends keyof TKey>(key: K): TKey[K] {
        this.checkInitForm();

        return this.form.get(key as string).value;
    }

    /**
     * Check if the main form is valid and sets all of it's controls as **touched**
     * If the form is invalid this method will show also an error message to the user
     * @returns Boolean indicationg if the form is valid
     */
    public validate() {
        this.checkInitForm();

        if (!this.form.valid) {
            if (!environment.production) {
                console.error(new InvalidFormError(this.form));
            }
            this.form.markAllAsTouched();
            this.translate.get(['Common_InvalidFormTitle', 'Common_InvalidFormText']).subscribe((texts) => {
                this.dialogs.errorMessage(texts['Common_InvalidFormTitle'], texts['Common_InvalidFormText']);
            });
            return false;
        }
        return true;
    }

    /**
     * Check if the main form is initialized
     * @returns Boolean indicating if the form is initialized
     */
    public isInitialized() {
        return !!this.form;
    }

    private checkInitForm() {
        if (!this.form) {
            throw new Error('You need first initialize form service base');
        }
    }
}
