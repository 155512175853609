import { Validators } from '@alza/cms-components';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DialogsService } from 'app/common/services';
import { IFileSystemFileDto } from 'app/models/file-system';

@Component({
    selector: 'app-file-input-url-chooser',
    templateUrl: './file-input-url-chooser.component.html',
    styleUrls: ['./file-input-url-chooser.component.scss']
})
export class FileInputUrlChooserComponent {
    public url = new UntypedFormControl(null, [Validators.required, Validators.url('absolute'), Validators.pattern(/^https?:\/\/(cdn|image|i)\.alza\..*$/)]);

    constructor(
        private readonly http: HttpClient,
        private readonly dialogRef: MatDialogRef<FileInputUrlChooserComponent, IFileSystemFileDto>,
        private readonly dialogs: DialogsService,
        private readonly translate: TranslateService
    ) {}

    public ok() {
        if (this.url.invalid) {
            this.url.markAsTouched();
            return;
        }
        const url = this.url.value;
        this.http.get<IFileSystemFileDto>('/api/file-system/file-info', { params: { url: url } }).subscribe(
            (res) => {
                this.dialogRef.close(res);
            },
            (err: HttpErrorResponse) => {
                if (err.status === 400) {
                    this.dialogs.badRequestMessage(err);
                } else if (err.status === 404) {
                    this.dialogs.warningMessage(null, this.translate.instant('Components_FileInputUrlChooser_UrlNotFound'));
                }
            }
        );
    }
}
