import { ErrorMessages, FormInputBase } from '@alza/cms-components';
import { Component, EventEmitter, forwardRef, Host, Input, Optional, Output, SkipSelf } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import 'moment-timezone';
const INPUT_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputFileComponent),
    multi: true
};
@Component({
    selector: 'app-input-file',
    templateUrl: './input-file.component.html',
    styleUrls: ['./input-file.component.scss'],
    providers: [INPUT_VALUE_ACCESSOR],
    viewProviders: [{ provide: ErrorMessages, deps: [TranslateService], useClass: ErrorMessages }]
})
export class InputFileComponent extends FormInputBase implements ControlValueAccessor {
    @Input() public accept = '.xml';
    @Input() public file: File;
    @Output() public fileChange = new EventEmitter<File>();
    @Input() public placeholder: string;

    constructor(
        @Optional()
        @Host()
        @SkipSelf()
        controlContainer: ControlContainer,
        errorMessages: ErrorMessages
    ) {
        super(controlContainer, errorMessages, { tidPrefix: 'file' });
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    updateValue(): void {}
    onValueChange(value: any) {
        this.raiseChange(value);
        this.raiseTouched();
    }
    public addFile(files: FileList) {
        if (files.length === 0) {
            return;
        } else {
            this.file = files[0];
        }
        this.fileChange.emit(this.file);
    }
    public removeFile() {
        this.file = null;
    }
}
