<div class="form-group" [class.mb-0]="!labelText">
    <cms-label
        *ngIf="labelText"
        [required]="labelRequired"
        [for]="id"
        [text]="labelText"
        [tooltip]="labelTooltip"
        [tooltipDirection]="labelTooltipDirection"
        [tooltipIcon]="labelTooltipIcon"
        [class]="labelClass"
        ><ng-content label-addon select="[label-addon]"></ng-content
    ></cms-label>
    <div class="tree">
        <div *ngIf="showSearch">
            <div class="row">
                <cms-input
                    class="col-xs-12 col-md-6"
                    [id]="'treeSearchValue'"
                    [labelText]="'Common_Search_Heading' | translate"
                    [placeholder]="searchPlaceholder"
                    name="treeSearchValue"
                    [(ngModel)]="searchValue"
                    (keydown.enter)="searchWord()"
                ></cms-input>

                <div class="form-group btn-group withoutHeading ml-4 mr-4" role="group">
                    <button type="submit" data-tid="treeBtnSearch" class="btn btn-primary" (click)="searchWord()">
                        {{ 'Common_Search' | translate }}
                    </button>
                    <button type="button" data-tid="treeBtnClear" class="btn btn-secondary" (click)="clearSearch()">
                        {{ 'Common_ClearSearch' | translate }}
                    </button>
                </div>
            </div>
        </div>
        <div class="tree-items">
            <app-tree-item
                [items]="items"
                [level]="level"
                [maxLevel]="maxLevel"
                [isSelectionEnabled]="isSelectionEnabled"
                (treeItemClick)="onTreeItemClick($event)"
                (treeItemCheckedChange)="onTreeItemCheckedChange($event)"
            ></app-tree-item>
        </div>
    </div>
    <cms-field-errors [submitted]="submitted" [control]="control"></cms-field-errors>
</div>
