<div mat-dialog-title>
    <button type="button" class="close" mat-dialog-close><span aria-hidden="true">×</span></button>
    <h4>{{ 'LocalTitlePage_Add_ChooseCategory' | translate }}</h4>
</div>
<mat-dialog-content>
    <app-loading-panel [loading]="loading">
        <div class="well" *ngIf="search || countryFilter">
            <div class="row">
                <div class="col-md-5" *ngIf="search">
                    <label for="search">{{ 'Common_Search' | translate }}</label>
                    <input
                        class="form-control"
                        type="text"
                        name="search"
                        id="search"
                        [(ngModel)]="searchString"
                        placeholder="{{ 'CategorySelect_SearchPlaceholder' | translate }}"
                        (keydown.enter)="searchWord()"
                    />
                </div>
                <div class="col-md-7" *ngIf="countryFilter">
                    <cms-select
                        [id]="'countryIds'"
                        name="countryIds"
                        [labelText]="'Common_Country' | translate"
                        [(ngModel)]="selectedCountryIds"
                        [items]="countries"
                        bindLabel="name"
                        bindValue="id"
                        multiple="true"
                    ></cms-select>
                </div>
                <div class="col-md-4">
                    <div class="control-buttons btn-toolbar" [ngClass]="{'withoutHeading': !countryFilter}">
                        <div class="btn-group">
                            <button type="button" data-tid="btnSearch" class="btn btn-primary" (click)="searchWord()">{{ 'Common_Search' | translate }}</button>
                            <button type="button" data-tid="btnClear" class="btn btn-secondary" (click)="clearSearch()">
                                {{ 'Common_ClearSearch' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-md-2" [ngClass]="{'withoutHeading': !countryFilter}">
                    <cms-checkbox [id]="'expanded'" [labelText]="'CategorySelect_SearchExpanded' | translate" [(ngModel)]="expanded"></cms-checkbox>
                </div>
            </div>
        </div>
        <div class="categoryTree ml-4">
            <app-category-select-modal-items
                [childrenName]="childrenName"
                [items]="items"
                [maxLevel]="maxLevel"
                [dataType]="dataType"
                [selectedCountryIds]="selectedCountryIds"
                [addRemoveToLevel]="addRemoveToLevel"
            ></app-category-select-modal-items>
        </div>
    </app-loading-panel>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button data-tid="btnClose" type="button" class="btn btn-default" mat-dialog-close>{{ 'Common_Close' | translate }}</button>
    <button data-tid="btnOk" type="button" class="btn btn-primary" (click)="ok()">{{ 'Common_Ok' | translate }}</button>
</mat-dialog-actions>
