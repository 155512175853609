<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-sm-5 col-md-5 col-lg-6 col-xl-8">
        <div class="inline" data-tid="labelTitle">
            <h2>{{ title + (detailBreadcrumb?.titleSuffix$.value || '') }}</h2>
            <ol class="breadcrumb" *ngIf="breadcrumbs.length">
                <li>
                    <a routerLink="/dashboard">{{ 'Breadcrumb_Home' | translate }}</a>
                </li>
                <li [class.active]="last" *ngFor="let b of breadcrumbs?.toArray(); let last = last">
                    <strong *ngIf="last && !detailBreadcrumb; else notLast">
                        <span>{{ b.text }}</span>
                    </strong>
                    <ng-template #notLast>
                        <a [routerLink]="b.link" *ngIf="b.link">{{ b.text }}</a> <span *ngIf="!b.link">{{ b.text }}</span>
                    </ng-template>
                </li>
                <li *ngIf="detailBreadcrumb">
                    <strong>{{ detailBreadcrumb.text }}</strong>
                </li>
            </ol>
        </div>
    </div>
    <!-- Hidden only on sm and xs, so the title stays left -->
    <div class="col-sm-7 col-md-7 col-lg-6 col-xl-4 hidden-sm hidden-xs">
        <div class="inline pull-right">
            <ul class="nav navbar-top-links white-bg navbar-right nwfl">
                <a
                    [cmsTooltip]="'Common_ReportBug' | translate"
                    class="btn btn-bug-report fa-danger-hover btn-white"
                    href="https://jira.alza.cz/servicedesk/customer/portal/2/group/14"
                    data-tid="btnBug"
                    [placement]="'bottom'"
                >
                    <i class="fas fa-bug"></i>
                </a>
                <a
                    *ngIf="wikiUrl"
                    [cmsTooltip]="'Common_ShowWiki' | translate"
                    [placement]="'bottom'"
                    class="btn btn-white fa-primary-hover"
                    (click)="openWiki()"
                    href="javascript:void(0)"
                    data-tid="btnWiki"
                >
                    <i class="fab fa-wikipedia-w"></i>
                </a>
                <li>
                    <div class="language-select">
                        <a class="dropdown-toggle" data-toggle="dropdown" href="javascript:void(0)" data-tid="ddLanguageHeader">
                            <i class="lang {{ selectedCountry?.code | lowercase }}"></i>{{ selectedCountry?.name }} <i class="fas fa-caret-down"></i>
                        </a>
                        <ul class="dropdown-menu dropdown-user">
                            <li *ngFor="let c of countries">
                                <a href="javascript:void(0)" (click)="changeLanguage(c.code)" [attr.data-tid]="'lang_' + c.name">
                                    <i class="lang {{ c.code | lowercase }}"></i>{{ c.name }}</a
                                >
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <a href="/api/accounts/logout" data-tid="btnLogoutHeader">
                        <i class="fas fa-sign-out-alt"></i> <span>{{ 'Menu_SignOut' | translate }}</span>
                    </a>
                </li>
            </ul>
            <br />
        </div>
    </div>
    <!-- Visible only on sm and xs, so the title pops down on small display sizes -->
    <div class="col-sm-7 col-md-7 col-lg-6 col-xl-4 visible-xs-inline visible-sm-inline">
        <div class="inline pull-right">
            <ul class="nav navbar-top-links white-bg navbar-right nwfl">
                <a
                    [cmsTooltip]="'Common_ReportBug' | translate"
                    class="btn btn-bug-report fa-danger-hover btn-white"
                    href="https://jira.alza.cz/servicedesk/customer/portal/2/group/14"
                    data-tid="btnBugSmall"
                    [placement]="'bottom'"
                >
                    <i class="fas fa-bug"></i>
                </a>
                <a
                    *ngIf="wikiUrl"
                    [cmsTooltip]="'Common_ShowWiki' | translate"
                    [placement]="'bottom'"
                    class="btn btn-white fa-primary-hover"
                    (click)="openWiki()"
                    href="javascript:void(0)"
                    data-tid="btnWikiSmall"
                >
                    <i class="fab fa-wikipedia-w"></i>
                </a>
                <li>
                    <div class="language-select">
                        <a class="dropdown-toggle" data-toggle="dropdown" href="javascript:void(0)" data-tid="ddLanguageHeaderSmall">
                            <i class="lang {{ selectedCountry?.code | lowercase }}"></i>{{ selectedCountry?.name }} <i class="fas fa-caret-down"></i>
                        </a>
                        <ul class="dropdown-menu dropdown-user">
                            <li *ngFor="let c of countries">
                                <a href="javascript:void(0)" (click)="changeLanguage(c.code)" [attr.data-tid]="'lang_' + c.name">
                                    <i class="lang {{ c.code | lowercase }}"></i>{{ c.name }}</a
                                >
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <a href="/api/accounts/logout" data-tid="btnLogoutHeaderSmall">
                        <i class="fas fa-sign-out-alt"></i> <span>{{ 'Menu_SignOut' | translate }}</span>
                    </a>
                </li>
            </ul>
            <br />
        </div>
    </div>
</div>
