/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum PriceBoxPriceGroupType {
    None = 0,
    Bronze = 1,
    Silver = 2,
    Gold = 3,
    B2B = 4,
    Benefit = 5,
    Isic = 6,
}
