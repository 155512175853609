import { Pipe, PipeTransform } from '@angular/core';
import { SessionService } from 'app/common/services/session.service';

@Pipe({
    name: 'localize',
    pure: false
})
export class LocalizePipe implements PipeTransform {
    private _fallbackLangId = 1;
    constructor(private session: SessionService) {}

    transform(value: any): any {
        if (value) {
            return value[this.session.user.languageId] || value[this._fallbackLangId];
        } else {
            return value;
        }
    }
}
