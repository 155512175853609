import { HttpClient } from '@angular/common/http';
import { ICommodityBaseDto } from 'app/models/commodity';
import { Observable } from 'rxjs';
import { BaseApi } from './base-api';

export class CommodityApi extends BaseApi {
    public constructor(http: HttpClient) {
        super(http);
    }

    // Get commodity
    public getProducts(codes: Array<string>): Observable<Array<ICommodityBaseDto>> {
        return this.http.post<Array<ICommodityBaseDto>>(`api/common/commodity/get-by-codes`, { codes: codes });
    }

    public getProduct(code: string): Observable<ICommodityBaseDto> {
        return this.http.get<ICommodityBaseDto>(`api/common/commodity/get-by-code`, { params: { code: code } });
    }
}
