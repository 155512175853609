/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum UploadFilePath {
    Docs = 1,
    Download = 2,
    Photo = 3,
    Icons = 4,
    IconsFloor = 5,
    Image = 6,
    Article = 7,
    Mailing = 8,
    Title = 9,
}
