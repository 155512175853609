import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ICountryTranslationDto } from 'app/common/dto/common-dto';
import { CommonDataService } from 'app/common/services/common-data.service';
import { ICountryDto } from 'app/models/country';
import { Subscription } from 'rxjs';
import { CountrySelectService } from './country-select.service';

@Component({
    selector: 'app-country-select',
    styleUrls: ['./country-select.component.scss'],
    templateUrl: './country-select.component.html'
})
export class CountrySelectComponent implements OnInit, OnDestroy {
    @Input()
    countryBy: 'languages' | 'countries' | 'translations' = 'languages';
    country?: number;
    public countryArray: Array<any> = [];
    private _maxShownCountries = 5;
    // Number of shown countries by default. Usualy used as index inside array.
    public shownCountries = this._maxShownCountries;

    public expand: boolean;
    private _watchedProperties: Array<string> = [];
    private _item: any;
    private _properties = '';

    @Input() id: string;

    @Output() countryChange = new EventEmitter<number>();

    @Input() mode: 'single' | 'multiple' = 'single';

    @Input()
    set item(value: any) {
        this._item = value;
        this.setWatchedProperties();
    }
    @Input()
    set properties(value: string) {
        this._properties = value;
        this.setWatchedProperties();
    }

    private _formGroup?: UntypedFormGroup;
    private _sub?: Subscription;

    public getTid(prefix: string) {
        if (this.id == null) {
            return prefix;
        }
        return prefix + this.id.charAt(0).toUpperCase() + this.id.slice(1);
    }

    public getTidItem(prefix: string, tidVal: string) {
        tidVal = tidVal + '';
        tidVal = tidVal.replace(/[^a-z0-9]/gi, '');
        return this.getTid(prefix) + '_' + tidVal;
    }

    @Input()
    set formGroup(value: UntypedFormGroup) {
        this._formGroup = value;
        value.valueChanges.subscribe(() => {
            this.item = value.value;
        });
    }

    get formGroup() {
        return this._formGroup;
    }

    get countryLocalization() {
        return this.countryBy === 'countries';
    }

    constructor(private countrySelectService: CountrySelectService, private commonData: CommonDataService) {}

    ngOnInit() {
        this._sub = this.countrySelectService.currentLanguage$.subscribe((country) => {
            if (this.countryBy === 'countries') {
                return;
            }
            this.countryChange.emit(country);
            this.country = country;

            // Puts selected country with selected index to the shownIndex position. Does not shift countries that are under th shownIndex.
            const index = this.countryArray.indexOf(this.countryArray.find((x) => x.id === country));
            if (country === this.country && index >= this.shownCountries) {
                const tmp: object = this.countryArray[index];
                this.countryArray[index] = this.countryArray[this.shownCountries];
                this.countryArray[this.shownCountries] = tmp;
            }
        });

        this._sub = this.countrySelectService.currentCountry$.subscribe((country) => {
            if (this.countryBy !== 'countries') {
                return;
            }
            this.country = country;

            // Puts selected country with selected index to the shownIndex position. Does not shift countries that are under the shownIndex.
            const index = this.countryArray.indexOf(this.countryArray.find((x) => x.id === country));
            if (country === this.country && index >= this.shownCountries) {
                const tmp: object = this.countryArray[index];
                this.countryArray[index] = this.countryArray[this.shownCountries];
                this.countryArray[this.shownCountries] = tmp;
            }
        });

        if (this.countryBy === 'languages') {
            this.commonData.language.getLanguages().subscribe((res) => {
                this.processLanguages(res);
            });
        } else if (this.countryBy === 'countries') {
            this.commonData.country.getTranslationCountries().subscribe((res) => {
                this.processCountries(res);
            });
        } else {
            this.commonData.language.getTranslationLanguages().subscribe((res) => {
                this.processLanguages(res);
            });
        }
    }

    ngOnDestroy() {
        if (this._sub) {
            this._sub.unsubscribe();
        }
    }

    private setWatchedProperties() {
        let res: Array<string> = [];
        if (this._item || this._properties) {
            if (this._properties) {
                res = this._properties.split(';');
            } else {
                for (const [key] of Object.entries(this._item)) {
                    if (key.endsWith('Localizations')) {
                        res.push(key);
                    }
                }
            }
        }
        this._watchedProperties = res;
    }

    public selectCountry(item: any) {
        if (this.countryBy === 'countries') {
            this.countrySelectService.changeCountry(item.id);
        } else {
            this.countrySelectService.changeLanguage(item.id);
        }
    }

    public isFilled(country: ICountryTranslationDto | ICountryDto): boolean {
        if (this._item == null && !this.formGroup) {
            return false;
        }
        if (country.id === this.country) {
            return false;
        }
        if (this.mode === 'single') {
            if (this.formGroup) {
                return !!this.formGroup.get(`${country.id}`)?.value;
            }
            return this.hasValue(this._item[country.id]);
        } else if (this.mode === 'multiple') {
            if (this.formGroup) {
                return this._watchedProperties.some((p) => !!this.formGroup.get(`${p}.${country.id}`)?.value);
            }
            return this._watchedProperties.some((p) => this.hasValue(this._item[p][country.id]));
        }
        return false;
    }

    collapseExpand() {
        this.expand = !this.expand;
    }

    private hasValue(data: any) {
        if (data === 0) {
            return true;
        }
        if (!data) {
            return false;
        }
        if (Array.isArray(data)) {
            return data.length > 0;
        }
        if (Object(data) === data) {
            return Object.keys(data).some((key) => this.hasValue(data[key]));
        }
        return true;
    }

    public isInvalid(country: ICountryTranslationDto | ICountryDto): boolean {
        if (this._item == null && !this.formGroup) {
            return false;
        }
        if (country.id === this.country) {
            return false;
        }
        if (this.mode === 'single') {
            const c = this.formGroup.get(`${country.id}`);
            return c?.enabled && c?.invalid;
        } else if (this.mode === 'multiple') {
            if (this.formGroup) {
                return this._watchedProperties.some((p) => {
                    const c = this.formGroup.get(`${p}.${country.id}`);
                    return c?.enabled && c?.invalid;
                });
            }
        }
        return false;
    }

    public getButtonClass(c: ICountryTranslationDto | ICountryDto) {
        if (this.country == null) {
            return 'btn-white';
        }
        if (c.id === this.country) {
            return 'btn-primary';
        }
        if (this.isInvalid(c)) {
            return 'btn-danger';
        }
        if (this.isFilled(c)) {
            return 'filled-g';
        }
        return 'btn-white';
    }

    getBtnGridposition(c: ICountryTranslationDto | ICountryDto, index: number) {
        // Sets the position inside grid of the last country that is selected.
        if (c.id === this.country && index >= this.shownCountries) {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            return { 'grid-column': `${this.shownCountries + 1}`, 'grid-row': '1' };
        }
    }

    getGridColumns() {
        // Sets the position inside grid of the last country that is selected.
        let result: object;
        // eslint-disable-next-line @typescript-eslint/naming-convention
        result = { 'grid-template-columns': `repeat(${this.shownCountries + 1}, 1fr)` };

        // handles positioning of country-select items, so It always fits with the overall grid
        if (this.countryArray.length > 6) {
            result = { ...result, transform: `translate(${(this.shownCountries + 2) * -30}px, 0%)` };
        } else {
            result = { ...result, width: `${this.shownCountries * 30}px` };
            if (this.countryArray.length === 6) {
                result = { ...result, transform: `translate(${(this.shownCountries + 1) * -30}px, 0%)` };
            } else {
                result = { ...result, transform: `translate(${this.shownCountries * -30}px, 0%)` };
            }
        }
        return result;
    }

    getInvalidCount(): number {
        return this.countryArray.filter((el, index) => (index > this.shownCountries ? this.isInvalid(el) : false)).length;
    }

    private processLanguages(res: Array<ICountryTranslationDto>) {
        this.countryArray = res;
        // maxShownCountries = 5 is the max value of static countries. If number of countries is lower, we decrease shownCountries
        if (this.countryArray.length < this._maxShownCountries) {
            this.shownCountries = this.countryArray.length;
        }
        // Shifts countries array so Hungary is on the 4th index, if It exists
        if (this.countryArray.find((x) => x.code === 'HU')) {
            const hungaryIndex = this.countryArray.findIndex((x) => x.code === 'HU');
            const tmp = this.countryArray.splice(hungaryIndex, 1);
            this.countryArray.splice(4, 0, tmp[0]);
            this.countryArray.forEach((el) => {
                if (el.id === this.country) {
                    this.selectCountry(el);
                }
            });
        }
    }

    private processCountries(res: Array<ICountryDto>) {
        this.countryArray = res.sort((a, b) => a.id - b.id);
        // maxShownCountries = 5 is the max value of static countries. If number of countries is lower, we decrease shownCountries
        if (this.countryArray.length < this._maxShownCountries) {
            this.shownCountries = this.countryArray.length;
        }
        // Shifts countries array so Hungary is on the 4th index, if It exists
        if (this.countryArray.find((x) => x.countryCode === 'HU')) {
            const hungaryIndex = this.countryArray.findIndex((x) => x.countryCode === 'HU');
            const tmp = this.countryArray.splice(hungaryIndex, 1);
            this.countryArray.splice(4, 0, tmp[0]);
            this.countryArray.forEach((el) => {
                if (el.id === this.country) {
                    this.selectCountry(el);
                }
            });
        }
    }
}
