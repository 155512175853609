import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SessionService } from 'app/common/services';
import { ICountryTranslationDto } from 'app/common/services/common-data.service';
import { FormHelper } from 'app/components/forms';
import { ICountryDto } from 'app/models/country';
import { Subscription } from 'rxjs';
import { ICountryExtended } from './country-extended';
import { ICountryLocalization } from './country-localization';
import { CountryLocalizationSwitchService } from './country-localization-switch.service';
import { ILanguageExtended } from './language-extended';
import { ISelectCountryContext, SelectCountryModalComponent } from './select-country-modal/select-country-modal.component';

@Component({
    selector: 'app-country-localization-switch',
    styleUrls: ['./country-localization-switch.component.scss'],
    templateUrl: './country-localization-switch.component.html'
})
export class CountryLocalizationSwitchComponent implements OnInit, OnDestroy {
    @Input()
    countryBy: 'languages' | 'translations' = 'languages';

    @Input()
    localization?: ICountryLocalization;

    @Input()
    set properties(value: string | Array<string>) {
        if (!Array.isArray(value)) {
            value = [value];
        }
        this._watchedProperties = value;
        this.setCountriesForLanguages();
    }

    @Input()
    public localizedArray: UntypedFormArray;

    @Input()
    set showCountries(value: boolean) {
        this._showCountries = value;

        if (!this._showCountries && this.localization?.countryId) {
            this.service.changeCountry({ languageId: this.localization.languageId });
        }
    }

    get showCountries(): boolean {
        return this._showCountries;
    }

    @Output()
    localizationChanged = new EventEmitter<ICountryLocalization>();

    public languages: Array<ILanguageExtended> = [];
    public countries: Array<ICountryDto> = [];

    private _maxShownLanguages = 5;
    // Number of shown countries by default. Usualy used as index inside array.
    public shownLanguages = this._maxShownLanguages;
    public isExpanded: boolean;
    public buttonEntered: number | null = null;
    private _watchedProperties: Array<string> = [];
    private _subscription?: Subscription;
    private _showCountries = true;

    constructor(
        private readonly service: CountryLocalizationSwitchService,
        private readonly formHelper: FormHelper,
        private readonly session: SessionService,
        private readonly matDialog: MatDialog
    ) {}

    public get containerClass() {
        return this.buttonEntered !== null ? 'active' : 'inactive';
    }

    ngOnInit() {
        this._subscription = this.service.currentLocalization$.subscribe((localization) => {
            this.localizationChanged.emit(localization);
            this.localization = localization;

            // Puts selected country with selected index to the shownIndex position. Does not shift countries that are under th shownIndex.
            const index = this.languages.indexOf(this.languages.find((language) => language.id === localization.languageId));
            if (index >= this.shownLanguages) {
                const tmp: ILanguageExtended = this.languages[index];
                this.languages[index] = this.languages[this.shownLanguages];
                this.languages[this.shownLanguages] = tmp;
            }
        });

        this.countries = this.session.countries.filter((country) => country.enabled);

        if (this.countryBy === 'languages') {
            this.languages = this.service.languages;
        } else {
            this.languages = this.service.languages.filter((language) =>
                this.session.countries.filter((x) => x.isActiveForTranslation).some((country) => country.languageId === language.id)
            );
        }
        this.setCountriesForLanguages();

        this.localizedArray.valueChanges.subscribe(() => {
            this.setCountriesForLanguages();
        });

        // maxShownCountries = 5 is the max value of static countries. If number of countries is lower, we decrease shownCountries
        if (this.languages.length < this._maxShownLanguages) {
            this.shownLanguages = this.languages.length;
        }
        // Shifts countries array so Hungary is on the 4th index, if It exists
        if (this.languages.find((language) => language.code === 'HU')) {
            const hungaryIndex = this.languages.findIndex((language) => language.code === 'HU');
            const tmp = this.languages.splice(hungaryIndex, 1);
            this.languages.splice(4, 0, tmp[0]);
            this.languages.forEach((language) => {
                if (language.id === this.localization.languageId) {
                    this.selectLocalization(this.localization);
                }
            });
        }
    }

    ngOnDestroy() {
        if (this._subscription) {
            this._subscription.unsubscribe();
        }
    }

    public selectLocalization(localization: ICountryLocalization) {
        this.service.changeCountry(localization);
    }

    public isFilled(languageId: number, countryId: number | null = null): boolean {
        if (!this.localizedArray) {
            return false;
        }
        let isFilled = false;
        let checkedProperties = this._watchedProperties;
        const localizationGroup = this.formHelper.getLocalizationGroup(this.localizedArray, { languageId, countryId });

        if (checkedProperties.length === 0) {
            checkedProperties = this.formHelper.localizedProperties(localizationGroup);
        }
        checkedProperties.forEach((property) => {
            if (localizationGroup?.get(property) && !!localizationGroup?.get(property).value) {
                isFilled = true;
            }
        });
        return isFilled;
    }

    public isInvalid(languageId: number, countryId: number | null = null): boolean {
        if (!this.localizedArray) {
            return false;
        }
        let isInvalid = false;
        let checkedProperties = this._watchedProperties;
        const localizationGroup = this.formHelper.getLocalizationGroup(this.localizedArray, { languageId, countryId });

        if (checkedProperties.length === 0) {
            checkedProperties = this.formHelper.localizedProperties(localizationGroup);
        }

        checkedProperties.forEach((property) => {
            if (localizationGroup?.get(property) && localizationGroup?.get(property).enabled && localizationGroup?.get(property).invalid) {
                isInvalid = true;
            }
        });
        return isInvalid;
    }

    public isRequired(languageId: number, countryId: number | null = null): boolean {
        if (!this.localizedArray) {
            return false;
        }
        let isRequired = false;
        let checkedProperties = this._watchedProperties;
        const localizationGroup = this.formHelper.getLocalizationGroup(this.localizedArray, { languageId, countryId });

        if (checkedProperties.length === 0) {
            checkedProperties = this.formHelper.localizedProperties(localizationGroup);
        }

        checkedProperties.forEach((property) => {
            if (
                localizationGroup?.get(property) &&
                localizationGroup?.get(property).enabled &&
                localizationGroup?.get(property).validator &&
                localizationGroup?.get(property).validator({} as AbstractControl)?.required
            ) {
                isRequired = true;
            }
        });
        return isRequired;
    }

    public getButtonClass(language: ILanguageExtended, country: ICountryDto = null) {
        let className = 'btn-white';

        if (!this.localization) {
            return className;
        }

        if (language.id === this.localization.languageId && country?.id == this.localization.countryId) {
            className = 'btn-primary';
        } else if (this.isInvalid(language.id, country?.id)) {
            className = 'btn-danger';
        } else if (this.isFilled(language.id, country?.id)) {
            className = 'filled';
        }

        if (country == null) {
            if (language.countries.some((c) => this.isInvalid(language.id, c.id))) {
                className += ' sub-invalid';
            } else if (language.countries.some((c) => this.localization.languageId === language.id && this.localization.countryId === c.id)) {
                className += ' sub-selected';
            } else if (language.countries.some((c) => this.isFilled(language.id, c.id))) {
                className += ' sub-filled';
            }
        }

        return className;
    }

    getBtnGridposition(language: ICountryTranslationDto, index: number) {
        // Sets the position inside grid of the last country that is selected.
        if (language.id === this.localization.languageId && index >= this.shownLanguages) {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            return { 'grid-column': `${this.shownLanguages + 1}`, 'grid-row': '1' };
        }
    }

    getGridColumns() {
        // Sets the position inside grid of the last country that is selected.
        let result: object;
        // eslint-disable-next-line @typescript-eslint/naming-convention
        result = { 'grid-template-columns': `repeat(${this.shownLanguages + 1}, 1fr)` };

        // handles positioning of country-select items, so It always fits with the overall grid
        if (this.languages.length > 6) {
            result = { ...result, transform: `translate(${(this.shownLanguages + 2) * -30}px, 0%)` };
        } else {
            result = { ...result, width: `${this.shownLanguages * 30}px` };
            if (this.languages.length === 6) {
                result = { ...result, transform: `translate(${(this.shownLanguages + 1) * -30}px, 0%)` };
            } else {
                result = { ...result, transform: `translate(${this.shownLanguages * -30}px, 0%)` };
            }
        }
        return result;
    }

    getInvalidCount(): number {
        return this.languages.filter((el, index) => (index > this.shownLanguages ? this.isInvalid(el.id) : false)).length;
    }

    getCountryCodebyId(countryId: number): string {
        return this.countries.find((country) => country.id === countryId).countryCode;
    }

    public selectCountryModal(language: ILanguageExtended) {
        const context: ISelectCountryContext = {
            countries: this.countries.map((x) => {
                const country = <ICountryExtended>x;
                country.required = this.isRequired(language.id, country.id);
                country.selected = this.isFilled(language.id, country.id) || country.required || language.countries.some((c) => c.id === country.id);
                return country;
            })
        };

        this.matDialog
            .open<SelectCountryModalComponent, ISelectCountryContext, Array<ICountryExtended>>(SelectCountryModalComponent, {
                data: context,
                panelClass: 'dialog-md',
                id: 'selectCountryDialog'
            })
            .afterClosed()
            .subscribe((result) => {
                if (result) {
                    const removedCountries = language.countries
                        .filter((country) => result.find((x) => x.id === country.id && !x.selected))
                        .map((country) => country.id);

                    if (removedCountries) {
                        removedCountries.forEach((countryId) => {
                            const localizationGroup = this.formHelper.getLocalizationGroup(this.localizedArray, { languageId: language.id, countryId });

                            let checkedProperties = this._watchedProperties;
                            if (checkedProperties.length === 0) {
                                checkedProperties = this.formHelper.localizedProperties(localizationGroup);
                            }

                            checkedProperties.forEach((property) => {
                                const control = localizationGroup?.get(property);
                                if (control && control.value) {
                                    control.setValue(null);
                                    control.markAsDirty();
                                }
                            });
                        });
                    }

                    language.countries = result.filter((x) => x.selected);

                    if (
                        this.localization.countryId &&
                        !this.languages.find((l) => l.id === this.localization.languageId).countries.find((c) => c.id === this.localization.countryId)
                    ) {
                        this.localization.countryId = null;
                    }
                }
            });
    }

    private setCountriesForLanguages() {
        this.languages.forEach((language) => {
            language.countries = this.countries
                .filter(
                    (country) =>
                        (this.isFilled(language.id, country.id) || this.isRequired(language.id, country.id)) &&
                        !language.countries.find((c) => c.id === country.id)
                )
                .concat(language.countries);
        });
    }
}
