<div class="alphabet-select m-b-sm">
    <div
        *ngFor="let char of chars"
        class="alphabet-char"
        [attr.data-tid]="'btnChar_' + char"
        [class.alphabet-char-selected]="char == value"
        (click)="selectChar(char)"
    >
        {{ char }}
    </div>
</div>
