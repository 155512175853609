/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum ParameterFilterType {
    EnumListAny = 0,
    EnumListAll = 1,
    EnumFromTo = 2,
    NumericList = 3,
    NumericFromTo = 4,
    Cable = 5,
    HierarchicEnumListAny = 6,
    HierarchicEnumListAll = 7,
    Invalid = -1,
}
