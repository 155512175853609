import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { EntityState } from 'app/common/dto/common-dto';
import { Subscription } from 'rxjs';

const entityStates: Array<EntityState> = ['added', 'deleted', 'modified', 'unchanged'];

@Component({
    selector: 'app-removable-content',
    templateUrl: './removable-content.component.html',
    styleUrls: ['./removable-content.component.scss']
})
export class RemovableContentComponent implements OnInit, OnDestroy {
    @Input() deleteButtonTid: string;
    @Input() restoreButtonTid: string;
    @Input() disabled = false;
    @Output() delete = new EventEmitter<MouseEvent>();
    @Output() restore = new EventEmitter<MouseEvent>();
    @Output() stateChange = new EventEmitter<EntityState>();
    @Input() restorable = false;
    @Input() deleteTitle: string;
    @Input() restoreTitle: string;
    @Input() styledAs: 'block' | 'row' | 'none';

    @Input() set stateControl(value: UntypedFormControl) {
        if (value && value instanceof UntypedFormControl && entityStates.indexOf(value.value) !== -1) {
            this._stateControlChangedSubscription?.unsubscribe();
            this._stateControlChangedSubscription = value.valueChanges.subscribe((val: EntityState) => {
                this.state = val;
            });
            this.state = value.value;
        }
    }

    @Input() deleteButtonVisible: boolean;
    @Input() restoreButtonVisible: boolean;
    @Input() buttonClass = 'pull-right';
    @Input() public state: EntityState = 'unchanged';
    private _initialState: EntityState = 'unchanged';
    public ignoreState = false;
    private _stateControlChangedSubscription: Subscription;

    ngOnInit(): void {
        this._initialState = this.state;
        if (this.deleteButtonVisible !== undefined || this.restoreButtonVisible !== undefined) {
            this.ignoreState = true;
        }
    }

    ngOnDestroy() {
        this._stateControlChangedSubscription?.unsubscribe();
    }

    public deleteAction(event: MouseEvent) {
        this.state = 'deleted';
        this.delete.emit(event);
        this.stateChange.emit(this.state);
    }

    public restoreAction(event: MouseEvent) {
        this.state = this._initialState;
        this.restore.emit(event);
        this.stateChange.emit(this.state);
    }
}
