import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { CustomButtonEvent } from './button-events';
import { IButton } from './page-footer.component';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'custom-button' })
export class CustomButtonDirective implements IButton {
    @Input() disabled = false;
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() click = new EventEmitter<CustomButtonEvent>();
    @Input() type: 'button' | 'submit' = 'button';
    @Input() text: string;
    @Input() color = 'primary';
    @Input() iconClass: string;
    @Input() loading = false;
}
