/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum CategoryCountryVisibilityChange {
    None = 1,
    ToVisible = 2,
    ToInvisible = 4,
    NotPossible = 8,
    ThisCategory = 16,
    ChildCategories = 32,
}
