<div class="modal-content animated fadeIn">
    <div class="dropdown input-group">
        <span class="input-group-addon"><i class="fas fa-search"></i></span>
        <input
            #search
            [(ngModel)]="searchTerm"
            (blur)="searchFocused = false"
            (focus)="searchFocused = true"
            (keyup)="searchTermSub.next(search.value)"
            type="text"
            placeholder="{{ 'Topbar_SearchPlaceholder' | translate }}"
            class="form-control search-input"
            autocomplete="off"
            data-tid="tbSearchMenu"
            cdkFocusInitial
        />
        <ul
            (mouseenter)="menuMouseOver = true"
            (mouseleave)="menuMouseOver = false"
            class="dropdown-menu dropdown-messages dropdown-searchbar animated fadeInDown"
            *ngIf="(searchFocused || menuMouseOver) && searchResult.length > 0"
        >
            <li class="ui-menu-item" *ngFor="let r of searchResult; last as last">
                <a [attr.href]="r.url" (click)="close()" *ngIf="r.isMvcModule">
                    <div class="dropdown-messages-box fadeInLeft">
                        <div class="media-body">
                            <span *ngFor="let p of r.path; last as lastPath">
                                <small *ngIf="!p.active">{{ p.title }}</small> <strong *ngIf="p.active">{{ p.title }}</strong>
                                <span *ngIf="!lastPath"> / </span>
                            </span>
                        </div>
                    </div>
                </a>
                <a [routerLink]="r.url" (click)="close()" *ngIf="!r.isMvcModule" (click)="clearSearch()">
                    <div class="dropdown-messages-box fadeInLeft">
                        <div class="media-body">
                            <span *ngFor="let p of r.path; last as lastPath">
                                <small *ngIf="!p.active">{{ p.title }}</small> <strong *ngIf="p.active">{{ p.title }}</strong>
                                <span *ngIf="!lastPath"> / </span>
                            </span>
                        </div>
                    </div>
                </a>
                <div *ngIf="!last" class="ui-widget-content ui-menu-divider"></div>
            </li>
        </ul>
    </div>
</div>
