import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IParameterGroupCollectionItemDto } from './dto/parameter-group-collection-item-dto';
import { IParameterTypeCollectionItemDto } from './dto/parameter-type-collection-item-dto';
import { IParametersSearchResultDto } from './dto/parameters-search-result-dto';

@Injectable()
export class ParameterTypeSelectDataService {
    constructor(private http: HttpClient) {}

    public search(value: string) {
        return this.http.get<Array<IParametersSearchResultDto>>(`api/parameter/search/${value}`);
    }

    public getTypesByGroup(groupId: number) {
        return this.http.get<Array<IParameterTypeCollectionItemDto>>(`/api/parameter/types-by-group/${groupId}`);
    }

    public getGroupsByCategory(categoryId: number) {
        return this.http.get<Array<IParameterGroupCollectionItemDto>>(`/api/parameter/groups-by-category/${categoryId}`);
    }
}
