/* This file is GENERATED and will be DELETED each time the generator runs. */
/*             Do NOT modify anything here! You've been warned.             */

export enum PriceType {
    Minimal = 0,
    Normal = 1,
    Third = 2,
    Neo = 3,
    Installments = 4,
}
