import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IMenuItemDto } from 'app/models/menu';
import { Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';

export interface IMenuItem extends IMenuItemDto {
    items: Array<IMenuItem>;
    isActive?: boolean;
    lvl?: number;
    isHovered?: boolean;
    foundChildren?: boolean;
    normalizedTitle: string;
}

@Injectable({
    providedIn: 'root'
})
export class MenuService {
    private _items$?: Observable<Array<IMenuItem>>;
    public isMiniNavbar: boolean;

    constructor(private readonly http: HttpClient, private readonly router: Router) {}

    public getVisibleItems() {
        return this.getItems().pipe(map((items) => this.filterVisible(items)));
    }

    private filterVisible(items: Array<IMenuItem>) {
        if (items) {
            items = items.filter((x) => x.title);
            items.forEach((i) => {
                if (i.items) {
                    i.items = this.filterVisible(i.items);
                }
            });
        }
        return items;
    }

    private getItems() {
        if (!this._items$) {
            this._items$ = this.http.get<Array<IMenuItem>>('/api/common/menu').pipe(
                shareReplay(1),
                tap((e) => {
                    for (const lvl0 of e) {
                        lvl0.lvl = 0;
                        lvl0.normalizedTitle = (lvl0.title || '')
                            .toLocaleLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '');
                        for (const lvl1 of lvl0.items) {
                            lvl1.lvl = 1;
                            lvl1.normalizedTitle = (lvl1.title || '')
                                .toLocaleLowerCase()
                                .normalize('NFD')
                                .replace(/[\u0300-\u036f]/g, '');
                            for (const lvl2 of lvl1.items) {
                                lvl2.lvl = 2;
                                lvl2.normalizedTitle = (lvl2.title || '')
                                    .toLocaleLowerCase()
                                    .normalize('NFD')
                                    .replace(/[\u0300-\u036f]/g, '');
                            }
                        }
                    }
                })
            );
        }
        return this._items$;
    }

    public findWikiUrl(): Observable<string> {
        const url = this.router.url;
        return this.getItems().pipe(map((items) => this.findWikiUrlInternal(url, items)));
    }

    public getMenuItem(url: string) {
        if (url && url.indexOf('?') !== -1) {
            url = url.split('?')[0];
        }
        return this.getItems().pipe(map((items) => this.getMenuItemInternal(url, items)));
    }

    private getMenuItemInternal(url: string, items: Array<IMenuItem>) {
        let result: IMenuItem;
        (items || []).forEach((i) => {
            if (this.isMatch(url, i)) {
                result = i;
            }
            const childItem = this.getMenuItemInternal(url, i.items);
            if (childItem) {
                result = childItem;
            }
        });
        return result;
    }

    private findWikiUrlInternal(url: string, items: Array<IMenuItem>) {
        let res = '';
        items.forEach((i) => {
            if (this.isMatch(url, i)) {
                if (i.wikiUrl) {
                    res = i.wikiUrl;
                }
            }
            const childWiki = this.findWikiUrlInternal(url, i.items);
            if (childWiki) {
                res = childWiki;
            }
        });
        return res;
    }

    public isMatch(url: string, menuItem: IMenuItem) {
        if (!menuItem.url || !url) {
            return false;
        }
        if (!menuItem.urlRegex) {
            return url.startsWith(menuItem.url);
        } else {
            if (url.indexOf('?') !== -1) {
                url = url.split('?')[0];
            }
            return RegExp(menuItem.urlRegex).test(url);
        }
    }
}
