<div mat-dialog-title>
    <button type="button" class="close" (click)="close()"><span aria-hidden="true">×</span></button>
    <h4>{{ 'NewSentenceModal_Title' | translate }}</h4>
</div>
<mat-dialog-content>
    <ng-container *ngIf="form">
        <cms-textarea [id]="'text'" [labelText]="'NewSentenceModal_Text' | translate" [formControl]="form.get('text')"> </cms-textarea>
    </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button type="button" data-tid="btnNewModalSentenceCancel" class="btn btn-warning" (click)="close()">{{ 'Common_Storno' | translate }}</button>
    <button type="button" data-tid="btnNewModalSentenceCreate" class="btn btn-primary" (click)="save()">
        {{ 'NewSentenceModal_Save' | translate }}
    </button>
</mat-dialog-actions>
