import { Component, Input } from '@angular/core';
import { CheckedItems } from './checked-items';

@Component({
    selector: 'app-checked-items',
    templateUrl: './checked-items.component.html',
    styleUrls: ['./checked-items.component.scss']
})
export class CheckedItemsComponent {
    @Input()
    checkedItems: CheckedItems<any>;
}
