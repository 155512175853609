import { Injectable } from '@angular/core';
import {
    AbstractControlOptions,
    AsyncValidatorFn,
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn
} from '@angular/forms';
import { Country, Languages } from 'app/common/enums';
import { SessionService } from 'app/common/services';
import { ICountryLocalizationDto } from 'app/models';
import { IFileSystemFileDto } from 'app/models/file-system';
import { AbstractControl, FormArray, FormControl, FormGroup } from './typed-controls';

export type FormBuilderConfig<T> = Partial<{ [P in keyof T]: FormBuilderValue<T[P]> }> | { [id: number]: AbstractControl<T> };
type ValidatorsConfig = ValidatorFn | Array<ValidatorFn> | ValidationErrors;
type AsyncValidatorsConfig = AsyncValidatorFn | Array<AsyncValidatorFn>;
type ControlBuilder<T> = [value?: T | { value: T; disabled: boolean }, validators?: ValidatorsConfig, asyncValidators?: AsyncValidatorsConfig] | T;
type FormBuilderValue<T> = T extends Array<infer U>
    ? U extends string
        ? ControlBuilder<Array<string>>
        : U extends number
        ? ControlBuilder<Array<number>>
        : U extends boolean
        ? ControlBuilder<Array<boolean>>
        : FormArray<U>
    : T extends string
    ? ControlBuilder<string>
    : T extends number
    ? ControlBuilder<number>
    : T extends boolean
    ? ControlBuilder<boolean>
    : T extends IFileSystemFileDto
    ? ControlBuilder<IFileSystemFileDto>
    : ControlBuilder<any>;

@Injectable({ providedIn: 'root' })
export class TypedFormBuilder {
    constructor(private readonly ngFormBuilder: UntypedFormBuilder, private readonly session: SessionService) {}

    public group<T = any>(config: FormBuilderConfig<T>, options?: AbstractControlOptions) {
        return this.ngFormBuilder.group(config, options) as FormGroup<T>;
    }

    public array<T = any>(
        config: Array<FormBuilderConfig<T> | FormGroup<T>>,
        validatorsOrOpts?: ValidatorsConfig | AbstractControlOptions,
        asyncValidators?: AsyncValidatorsConfig
    ) {
        return this.ngFormBuilder.array(config, validatorsOrOpts, asyncValidators) as FormArray<T>;
    }

    public control<T = any>(formState: T) {
        return this.ngFormBuilder.control(formState) as FormControl<T>;
    }

    public localizationGroup<T extends ICountryLocalizationDto>(
        factory: (languageId: Languages, countryId?: Country) => FormBuilderConfig<T> | FormGroup<T>
    ): FormArray<T> {
        const newLocalizationGroup = (languageId: number, countryId: number = null) => {
            const res = factory(languageId, countryId);
            const formGroup: FormGroup<T> = res instanceof UntypedFormGroup ? res : this.group(res);
            formGroup.addControl('languageId', new UntypedFormControl(languageId));
            formGroup.addControl('countryId', new UntypedFormControl(countryId));
            return formGroup;
        };
        const formArray = new UntypedFormArray([]);
        this.session.languages
            .filter((x) => !x.disabled)
            .forEach((language) => {
                formArray.push(newLocalizationGroup(language.id));
                this.session.countries
                    .filter((x) => x.enabled)
                    .forEach((country) => {
                        formArray.push(newLocalizationGroup(language.id, country.id));
                    });
            });
        return formArray as FormArray<T>;
    }
}
