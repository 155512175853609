import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface IWikiModalData {
    path: string;
}

@Component({
    templateUrl: './wiki-modal.component.html',
    styleUrls: ['./wiki-modal.component.scss']
})
export class WikiModalComponent implements OnInit {
    result?: void;

    public html = '';

    constructor(private readonly http: HttpClient, @Inject(MAT_DIALOG_DATA) public data: IWikiModalData) {}

    ngOnInit() {
        this.http
            .get<any>('/api/wiki', { params: new HttpParams().set('path', this.data.path) })
            .subscribe((res) => {
                this.html = res.html;
            });
    }
}
